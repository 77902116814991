const DateTimePicker = require('anytime')
const moment = require('moment')

module.exports = (dateFormat, model) => el => {
  const options = {
    input: el,
    format: dateFormat,
    initialDate: new Date()
  }

  el.readOnly = true

  if (el.value) {
    options.initialDate = moment(el.value).toDate()
    el.value = moment(el.value).format(dateFormat)
  }

  const picker = new DateTimePicker(options)

  picker.on('change', value => {
    if (value) value = moment(value).toDate()
    if (model) model.set(el.name, value)
  })
  picker.render()
  return picker
}
