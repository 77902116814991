const modal = require('modal')

const createItemedFunctions = ({
  view,
  name,
  FormView,
  ItemModel,
  ItemView,
  slug,
  property
}) => {
  const handleNewItem = (e, itemModel) => {
    if (e) e.preventDefault()
    const model = itemModel || new ItemModel({})
    const itemFormView = new FormView(model, view.serviceLocator)
    view.modal = modal({
      title: `New ${name}`,
      className: 'wide',
      buttons: [],
      content: itemFormView.$el,
      clickOutsideToClose: false
    })
    itemFormView.on('save', model => {
      view.modal.close()
      view[property].push(model)
      renderItems()
    })
    itemFormView.on('cancel', () => {
      view.modal.close()
    })
  }

  const renderItems = () => {
    view.$(`.js-selected-${slug}-list`).empty()
    view[property].forEach((model, i) => {
      model.id = i + 1
      const itemView = new ItemView(model, view.serviceLocator)
      itemView.on('re-render', renderItems)
      itemView.on('remove', removeItem)
      itemView.on('duplicate', duplicateItem)
      view.$(`.js-selected-${slug}-list`).append(itemView.$el)
    })

    view.$el
      .find(`.js-selected-${slug}-list`)
      .sortable({
        handle: '.js-sort-handle',
        cursor: 'move',
        addClasses: false,
        connectWith: '.js-item-drag'
      })
      .off('sortupdate')
      .on('sortupdate', () => {
        reorderItems()
      })
  }

  const duplicateItem = model => {
    const newItem = Object.assign({}, model.attributes)
    delete newItem.name
    delete newItem.label
    handleNewItem(null, new ItemModel(newItem))
  }

  const reorderItems = () => {
    const reorderedItems = []
    const specifications = view.$el.find(`.js-selected-${slug}-list`).children()
    specifications.each((index, el) => {
      const id = $(el).data('id')
      view[property].forEach(item => {
        if (item.id === id) {
          reorderedItems.push(item)
        }
      })
    })
    view[property] = reorderedItems
  }

  const removeItem = id => {
    let itemIndex = null
    view[property].some((item, index) => {
      if (item.id === id) {
        itemIndex = index
        return true
      }
    })
    view[property].splice(itemIndex, 1)
    renderItems()
  }

  view.$el.on('click', `.js-new-${slug}`, handleNewItem)
  return renderItems
}

module.exports = createItemedFunctions
