module.exports = SectionModel
const Model = require('merstone')
const createSchema = require('../../../service/section/schema')

function SectionModel(serviceLocator, attributes) {
  Model.apply(this, arguments)
  this.schemata = createSchema()
  this.attributes = this.schemata.cast(attributes || {})

  this.hook(
    'preSet',
    function(attrs, cb) {
      cb(null, this.schemata.cast(attrs))
    }.bind(this)
  )
}

SectionModel.prototype = Object.create(Model.prototype)

SectionModel.prototype.validate = function(cb) {
  this.schemata.validate(this.attributes, cb)
}

SectionModel.prototype.reset = function(attrs) {
  attrs = this.schemata.cast(attrs || {})
  Model.prototype.reset.call(this, attrs)
}
