const IndexView = require('../views/index')
const FormsView = require('../views/forms')
const LegacyFormsView = require('../views/legacy-forms')
const SummariesView = require('../views/summaries')
const AutotraderView = require('../views/autotrader')

module.exports = function(serviceLocator) {
  serviceLocator.hub.once('acl:load', function() {
    var router = serviceLocator.router

    function renderView(View) {
      var view = new View(serviceLocator)
      router.render(view)
      view.on('route', function(route) {
        serviceLocator.router.navigate(route, { trigger: true })
      })
    }

    router.route(
      'style-guide(/)',
      'style-guide',
      renderView.bind(null, IndexView)
    )

    router.route(
      'style-guide/forms(/)',
      'style-guide',
      renderView.bind(null, FormsView)
    )

    router.route(
      'style-guide/legacy-forms(/)',
      'style-guide',
      renderView.bind(null, LegacyFormsView)
    )

    router.route(
      'style-guide/summaries(/)',
      'style-guide',
      renderView.bind(null, SummariesView)
    )

    router.route(
      'style-guide/autotrader(/)',
      'style-guide',
      renderView.bind(null, AutotraderView)
    )
  })
}
