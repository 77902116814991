module.exports = createController
const ReorderView = require('../views/reorder')
const SectionCollection = require('../collections/lite-section')
const debug = require('../../../../admin/source/js/lib/debug')('router')

function createController(serviceLocator) {
  const router = serviceLocator.router
  const collection = new SectionCollection()

  router.route('sections/reorder', 'reorderSections', function() {
    if (!serviceLocator.allow('section', 'reorder')) return false

    var reorderView = new ReorderView({
      collection: collection,
      serviceLocator: serviceLocator
    })
    debug('section list reorder view route triggered')

    router.render(bindSaveAndCancel(reorderView), 'Reorder Sections')
    collection.applyFilter({ filter: {} })
  })

  function bindSaveAndCancel(view) {
    view.on('save cancel', function() {
      router.navigate('sections', { trigger: true })
    })
    return view
  }
}
