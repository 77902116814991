const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"api-error\" class=\"api-error\"><div class=\"notification notification--error\"><div class=\"centering\"><p>The API is down&hellip;</p></div></div></div>");;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  initialize: function() {
    this.showing = false
    $('#api-error').remove()
  },
  showing: false,
  render: function() {
    if (!this.showing) {
      $('body').prepend(template())
      $('#api-error').animate(
        {
          height: '82px'
        },
        300,
        function() {
          $(this)
            .find('.message')
            .addClass('visible')
        }
      )
      this.showing = true
    }
    return this
  },
  remove: function() {
    var that = this

    $('#api-error')
      .find('.message')
      .removeClass('visible')
    $('#api-error').animate(
      {
        height: '0px'
      },
      1000,
      function() {
        $('#api-error').remove()
        that.showing = false
      }
    )
    return this
  }
})
