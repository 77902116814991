const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format, state) {
buf.push("<div class=\"image-wrapper\"><i" + (jade.attr("style", "background-image: url('" + data.previewImageUrlSmall + "');", true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\">");
if ( (data.type === 'Custom'))
{
buf.push("<span class=\"label label--large label--notice\">Custom</span>");
}
if ( (typeof data.state !== undefined))
{
buf.push("<span class=\"label label--large label--notice\">" + (jade.escape(null == (jade_interp = state) ? "" : jade_interp)) + "</span>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li>");
if ( (data.type === 'Custom'))
{
buf.push("<a class=\"js-edit\">Edit</a>");
}
buf.push("<a class=\"js-remove\">Remove</a></li></ul></div></div><h2>" + (jade.escape(null == (jade_interp = data.shortHeadline) ? "" : jade_interp)) + "</h2></div><div class=\"list-item-content\"><dl><dt>Live:</dt><dd>" + (jade.escape(null == (jade_interp = data.liveDate ? format(data.liveDate, 'calendar') : 'Always') ? "" : jade_interp)) + "</dd><dt>Expires:</dt><dd>" + (jade.escape(null == (jade_interp = data.expiryDate ? format(data.expiryDate, 'calendar') : 'Never') ? "" : jade_interp)) + "</dd></dl></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"state" in locals_for_with?locals_for_with.state:typeof state!=="undefined"?state:undefined));;return buf.join("");
}
const stateMap = require('../../article/state-map')
const getImageUrl = require('../../lib/default-image-url-generator')

class ManualItemView extends View {
  constructor(serviceLocator, model) {
    super(serviceLocator, model)
    this.model = model
    this.$el.addClass('js-selected-item list-item list-item-detail')
    this.$el.data('item', this.model)
    this.$el.on(
      'click',
      '.js-remove',
      this.model.emit.bind(this.model, 'remove')
    )
    this.listenTo(this.model, 'remove', this.remove.bind(this))
  }

  serialize() {
    const config = window.config
    const data = this.model.toJSON()
    data.previewImageUrlSmall = getImageUrl({
      model: this.model,
      width: 150
    })

    if (this.model.get('isCustom')) {
      data.type = 'Custom'
      return data
    }

    const getVisibilityState = stateMap.visibility.get(
      this.model.contentModel.getVisibilityState()
    )
    const getScheduleState = stateMap.schedule.get(
      this.model.contentModel.getScheduleState()
    )
    data.schedule = getScheduleState(
      this.model.contentModel.get('liveDate'),
      this.model.contentModel.get('expiryDate')
    )
    data.visibility = getVisibilityState()

    data.hasSection = typeof data.__fullUrlPath !== 'undefined'

    // Add include path
    data.publicUrlPath = config.url + data.__fullUrlPath

    return data
  }

  render() {
    this.$el.append(
      template({ data: this.serialize(), format: this.serviceLocator.format })
    )
    return this
  }
}

module.exports = ManualItemView
