const View = require('ventnor')
const join = require('path').join
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<form><div class=\"panel-content\"><div class=\"js-error hidden form-error notification notification--error\"><p>An unknown error occurred, please try again later.</p></div><div class=\"js-success hidden form-error notification notification--success\"><strong>Submission successful</strong><p>You will receive an email with a link to the CSV when processing has completed.</p></div><div class=\"js-input\"><div class=\"form-row\"><textarea name=\"registrations\" rows=\"20\" class=\"control control--text control--multiline form-field js-registration-input\"></textarea><div class=\"form-copy\"><p>Paste or manually enter a list of registrations with mileage from a spreadsheet.</p><p>Each registration must be on a new line. Copying straight from Excel should work.</p></div></div><div class=\"form-row\"><h2>Registrations Detected: <span class=\"js-registration-count\">0</span></h2></div></div></div><div class=\"form-row form-row-actions\"><button class=\"btn js-cancel\">Cancel</button><button class=\"btn btn--action js-submit\">Submit</button><button class=\"btn hidden js-close\">Close</button></div></form>");;return buf.join("");
}
const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')

class AutotraderQueryModalView extends View {
  constructor(serviceLocator) {
    super(serviceLocator)
    this.registrations = []
  }

  get template() {
    return template
  }

  handleCancel(e) {
    e.preventDefault()
    this.emit('close')
  }

  handleSubmit(e) {
    e.preventDefault()
    const $error = this.$el.find('.js-error')
    const $success = this.$el.find('.js-success')
    $error.addClass('hidden')
    $success.addClass('hidden')
    const registrations = this.registrations

    const authedRequest = createAuthedRequester(
      this.serviceLocator.config.apiUrl
    )

    authedRequest(
      'POST',
      '/autotrader/bulk-query',
      registrations,
      (err, res, body) => {
        if (err) {
          $error.removeClass('hidden')
          return
        }
        if (res.statusCode !== 200) {
          $error.removeClass('hidden')
          return
        }

        $success.removeClass('hidden')

        // hide actions + input field
        this.$el.find('.js-cancel').toggleClass('hidden')
        this.$el.find('.js-submit').toggleClass('hidden')
        this.$el.find('.js-input').toggleClass('hidden')

        // Only allow closing modal
        this.$el.find('.js-close').toggleClass('hidden')
      }
    )
  }

  render() {
    this.$el.append(this.template())

    const $regInput = this.$el.find('.js-registration-input')
    const $regCount = this.$el.find('.js-registration-count')
    $regInput.on('input propertychange', () => {
      const value = $regInput.val()

      const registrations = value
        .trim()
        .split(/[\n]/)
        .map(reg => reg && reg.trim())
        .filter(Boolean)
      this.registrations = registrations
      $regCount.text(registrations.length)
    })

    const $cancel = this.$el.find('.js-cancel')
    $cancel.on('click', this.handleCancel.bind(this))

    const $close = this.$el.find('.js-close')
    $close.on('click', this.handleCancel.bind(this))

    const $submit = this.$el.find('.js-submit')
    $submit.on('click', this.handleSubmit.bind(this))
    return this
  }
}
module.exports = AutotraderQueryModalView
