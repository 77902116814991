const ListView = require('../views/list')
const Collection = require('chale')
const CareerModel = require('../models/career')
const Model = require('merstone')
const async = require('async')
const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['createdDate', 'desc']
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an career is updated, reset the career with its new attributes
  serviceLocator.careerService.on('update', (id, attrs) => {
    const career = collection.get(id)
    if (career) career.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.careerService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getModels(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('careers(/)', 'listModels', () => {
    if (!serviceLocator.allow('career', 'discover')) return false

    getModels(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('career', 'create')) return false
      serviceLocator.router.navigate('careers/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('career', 'update')) return false
      serviceLocator.router.navigate('careers/' + id + '/form', {
        trigger: true
      })
    })

    list.on('duplicate', model => {
      if (!serviceLocator.allow('career', 'duplicate')) return false
      serviceLocator.router.navigate('careers/' + model.id + '/duplicate', {
        trigger: true
      })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('career', 'delete')) return false
      const deleteOne = (id, cb) => {
        serviceLocator.careerService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })
    })

    list.on('filter', params => {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      getModels(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendModels(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    serviceLocator.router.render(list, 'Models')
  })

  const getModels = (keywords, filter, sort, pagination) => {
    serviceLocator.careerService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load careers')
        embellishCareersWithDealership(
          res.results,
          (error, embellishedCareers) => {
            if (error)
              return serviceLocator.logger.error(
                err,
                'Could not embellish careers'
              )

            collection.reset(
              embellishedCareers.map(
                career => new CareerModel(serviceLocator, career)
              )
            )
            paginationModel.set('totalItems', res.totalItems)
            paginationModel.set('showing', collection.models.length)
          }
        )
      }
    )
  }

  const appendModels = (keywords, filter, sort, pagination) => {
    serviceLocator.careerService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        embellishCareersWithDealership(
          res.results,
          (error, embellishedCareers) => {
            if (error)
              return serviceLocator.logger.error(
                err,
                'Could not embellish careers'
              )
            embellishedCareers.forEach(career => {
              collection.add(new CareerModel(serviceLocator, career))
            })
            paginationModel.set('totalItems', res.totalItems)
            paginationModel.set('showing', collection.models.length)
          }
        )
      }
    )
  }

  const embellishCareersWithDealership = (careers, callback) => {
    serviceLocator.careerService.embellishCareersWithDealership(
      careers,
      (error, embellishedCareers) => {
        if (error) return callback(error)
        return callback(null, embellishedCareers)
      }
    )
  }
}

module.exports = createController
