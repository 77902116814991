const CrudService = require('../../../../admin/source/js/lib/crud-service')

class IDealFileUploadServoce extends CrudService {
  get name() {
    return 'IDealFileUploadService'
  }
  get urlRoot() {
    return '/idealfile-upload'
  }
}

module.exports = IDealFileUploadServoce
