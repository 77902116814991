const schemata = require('schemata')
const fileSchema = require('../s3/file-schema')

module.exports = (name, extraProperties) =>
  schemata({
    name,
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String
      },
      title: {
        type: String
      },
      firstName: {
        type: String
      },
      lastName: {
        type: String
      },
      email: {
        type: String
      },
      phone: {
        type: String
      },
      postcode: {
        type: String
      },
      files: {
        type: schemata.Array(fileSchema('Upload Form File'))
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      ...extraProperties
    }
  })
