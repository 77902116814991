import BaseModel from 'cf-base-model'
import createSchema from '../../../../service/widget/model/base-widget'

const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: createSchema('Review Carousel'),

  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'reviewsCarousel' })
  },

  validate: validateDelegate
})
