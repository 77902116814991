import BaseModel from 'cf-base-model'
import createSchema from '../../../../service/widget/model/base-widget'
import validityDelegate from '../../../../../admin/source/js/lib/validate-delegate'

export default BaseModel.extend({
  schemata: createSchema('All Dealerships'),
  type: 'allDealerships',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'allDealerships' })
  },
  validate: validityDelegate()
})
