const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name, undefined) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
if ( model.get('visible'))
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This widget is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><dl>");
if ( model.get('heading'))
{
buf.push("<dt>Heading:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('heading')) ? "" : jade_interp)) + "</dd>");
}
var items = model.get('offerItems')
if ( items.length)
{
buf.push("<dt>Tabs:");
// iterate items
;(function(){
  var $$obj = items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<dd>" + (jade.escape(null == (jade_interp = item.heading) ? "" : jade_interp)) + "</dd><br/>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<dd>" + (jade.escape(null == (jade_interp = item.heading) ? "" : jade_interp)) + "</dd><br/>");
    }

  }
}).call(this);

buf.push("</dt>");
}
buf.push("</dl></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const EditBaseItemView = require('../../../widget/views/item/edit-base')
const async = require('async')

module.exports = EditBaseItemView.extend({
  template: template,
  render: function() {
    const filter = this.model.get('filter')
    const serviceLocator = this.options.serviceLocator
    if (!filter) {
      EditBaseItemView.prototype.render.apply(this, arguments)
      return this
    }

    const setModels = cb => {
      const service =
        filter === 'models'
          ? serviceLocator.modelService
          : serviceLocator.usedVehicleService
      const ids =
        filter === 'models'
          ? this.model.get('models')
          : this.model.get('usedVehicles')

      service.find('', { _id: { $in: ids } }, {}, [], (error, values) => {
        if (error) return cb(error)

        const itemFilter = filter === 'models' ? 'name' : 'registration'
        const vehicles = values.results.map(item => item[itemFilter])
        this.extraProperties[filter] = vehicles.join(', ')
        cb()
      })
    }

    const setLocations = cb => {
      serviceLocator.dealershipService.find(
        '',
        { _id: { $in: this.model.get('selectedLocations') } },
        {},
        [],
        (error, values) => {
          if (error) return cb(error)

          const locations = values.results.map(i => i.location)
          this.extraProperties.locations = locations.join(', ')
          cb()
        }
      )
    }

    const setOfferCategories = cb => {
      serviceLocator.offerCategoryService.find(
        '',
        { _id: { $in: this.model.get('offerCategories') } },
        {},
        [],
        (error, values) => {
          if (error) return cb(error)

          const categories = values.results.map(i => i.name)
          this.extraProperties.categories = categories.join(', ')
          cb()
        }
      )
    }

    async.parallel(
      [
        setModels.bind(this),
        setLocations.bind(this),
        setOfferCategories.bind(this)
      ],
      error => {
        if (error) {
          serviceLocator.logger.error(error)
          return this
        }

        EditBaseItemView.prototype.render.apply(this, arguments)
      }
    )
  }
})
