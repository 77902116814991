module.exports = () => ({
  car: {
    label: 'Car',
    slug: 'cars'
  },
  van: {
    label: 'Van',
    slug: 'vans'
  }
})
