const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"form js-asset-form-view\"><div class=\"grid\"><div class=\"grid__item\"><div class=\"asset-preview-large\"><img" + (jade.attr("src", (data.previewDataUri) ? data.previewDataUri : data.imageUrl, true, false)) + "/></div></div><div class=\"asset-info\"><div class=\"grid__item one-third\"><dl><dt>Filename</dt><dd><span class=\"label label--notice label--wrap\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span></dd></dl></div><div class=\"grid__item one-third\"><dl><dt>Size</dt><dd><span class=\"label label--notice\">" + (jade.escape(null == (jade_interp = data.size) ? "" : jade_interp)) + "</span></dd></dl></div><div class=\"grid__item one-third\"><dl><dt>Dimensions</dt><dd><span class=\"label label--notice\">" + (jade.escape((jade_interp = data.width) == null ? '' : jade_interp)) + " x " + (jade.escape((jade_interp = data.height) == null ? '' : jade_interp)) + "</span></dd></dl></div></div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const debug = require('../../../../../admin/source/js/lib/debug')(
  'image edit view'
)
const mapFormToObject = require('cf-map-form-to-object')
const TagSelect = require('../../../tag/views/tag-select')
const BaseWidgetView = require('../../../widget/views/form/base')
const bytes = require('bytes')

module.exports = BaseWidgetView.extend({
  events: BaseWidgetView.prototype.events,

  template,

  debug,

  handleSave() {
    var formData = mapFormToObject(this.$el.find('form'), this.model.schemata)
    formData.tags = this.consolidateTags()
    this.model.set(formData)
    this.trigger('save', this.model)
  },

  consolidateTags() {
    return [].concat(this.imageTagControl.tags.toJSON())
  },

  render() {
    var viewData = this.model.toJSON()
    viewData.imageUrl = this.model.buildPreviewUrl(810)
    viewData.size = bytes(viewData.size)

    this.imageTagControl = new TagSelect(
      this.options.serviceLocator,
      this.model.get('tags'),
      'Image'
    )
    this.imageTagControl.loadTags()

    this.$el
      .empty()
      .append(this.template({ className: 'base', data: viewData }))
    this.$('.js-tags-input').append(this.imageTagControl.render().$el)
    this.trigger('afterRender')
    return this
  }
})
