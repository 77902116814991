// https://www.iban.com/currency-codes

const currencyFormatter = ({
  value = 0,
  locale,
  currency = 'GBP',
  noMinor = false,
  format
}) => {
  if (typeof value !== 'number') return false

  const roundedValue = noMinor ? value : Math.ceil(value) / 100
  const formattedValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(roundedValue)

  return format === 'short' ? formattedValue.replace('.00', '') : formattedValue
}

module.exports = currencyFormatter
