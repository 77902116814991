const schema = require('schemata')
const baseSchema = require('../../../service/widget/model/base-widget')()
const assetSchemata = require('../../../service/asset/schema')()

module.exports = () =>
  schema({
    name: 'Image',
    properties: {
      size: {
        type: Number,
        thresholds: {
          warning: '8mb',
          error: '40mb'
        }
      },
      ...baseSchema.getProperties(),
      ...assetSchemata.getProperties()
    }
  })
