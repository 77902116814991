const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListItemView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return { singular: 'Colour', plural: 'Colours', resource: 'colour' }
  }

  loadData(cb) {
    this.serviceLocator.colourService.cachedFind(
      '',
      { type: 'base' },
      [],
      { page: 1, pageSize: 500 },
      (err, data) => {
        if (err) {
          alert(err.message)
          return cb(err)
        }

        this.colours = data.results

        cb()
      }
    )
  }

  addListItem(model, index) {
    var listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      index,
      this.collection.length,
      this.colours
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
    return listItem
  }
}

module.exports = ListView
