const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListView {
  constructor(...args) {
    super(...args)
    this.imageSetConfigs = null
  }

  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return {
      singular: 'Image Set',
      plural: 'Image Sets',
      resource: 'imageSets'
    }
  }

  loadData(cb) {
    this.serviceLocator.imageSetConfigService.cachedFind('', {}, [], { page: 1, pageSize: 500 }, (err, res) => {
      this.imageSetConfigs = (res && res.results) ? res.results : []
      cb()
    })    
  }

  addListItem(model) {
    const imageSetConfig = this.imageSetConfigs.find(i => i._id === model.get('imageSetConfigId'))
    const listItem = new this.ListItemView(this.serviceLocator, model, imageSetConfig)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }
}

module.exports = ListView
