const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (admin, allowed, data, dealers, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('customer', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
var title = `${data.firstName} ${data.lastName}`
if ( allowed('customer', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/customer/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Email:</dt><dd>" + (jade.escape(null == (jade_interp = data.email) ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd>");
if ( admin)
{
buf.push("<dt>Added by:</dt><dd>" + (jade.escape(null == (jade_interp = admin) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div>");
if ( dealers.length > 0)
{
buf.push("<div class=\"list-item-content\"><dl><dt>Dealers:</dt><dd>" + (jade.escape(null == (jade_interp = dealers) ? "" : jade_interp)) + "</dd></dl></div>");
}
buf.push("</div>");}.call(this,"admin" in locals_for_with?locals_for_with.admin:typeof admin!=="undefined"?admin:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"dealers" in locals_for_with?locals_for_with.dealers:typeof dealers!=="undefined"?dealers:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, index, totalItems, admins, dealers) {
    super(...arguments)
    this.admins = admins
    this.dealers = dealers
  }

  get template() {
    return template
  }

  serialize() {
    const admin = this.admins.find(
      admin => admin._id === this.model.get('adminId')
    )
    const dealers = this.dealers
      .filter(d => this.model.get('locations').includes(d._id))
      .map(d => d.shortName)
      .join(', ')

    const data = {
      data: this.model.toJSON(),
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
      index: this.index,
      totalItems: this.totalItems,
      dealers
    }

    if (admin) {
      data.admin = `${admin.firstName} ${admin.lastName}`
    }

    return data
  }

  render() {
    this.$el.empty().append(this.template(this.serialize()))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
