const CrudService = require('../../../../admin/source/js/lib/crud-service')

class UsedVehicleService extends CrudService {
  get name() {
    return 'UsedVehicleService'
  }
  get urlRoot() {
    return '/used-vehicles'
  }

  getDistinct(key, query, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/distinct-entries/${key}`,
      query,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  statsFind(query, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/stats-find`,
      query,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  lookupUsedVehicle(type, value, cb) {
    const val = value.replace(/\W/g, '')
    this.authedRequest(
      'GET',
      `${this.urlRoot}/import-used?type=${type}&value=${val}`,
      null,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)

        this.emit('import')

        cb(null, body)
      }
    )
  }

  lookupNewVehicle(vehicle, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/import-new`,
      vehicle,
      (err, res, body) => {
        if (err) return cb(err)

        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)

        this.emit('import')

        cb(null, body)
      }
    )
  }

  getFinance(vehicle, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/calculate-finance`,
      vehicle,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  getHistory(path, vehicle, cb) {
    this.authedRequest(
      'GET',
      `${this.urlRoot}/${path}/${vehicle}`,
      null,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  getAdvertInfo(vehicle, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/check-autotrader`,
      vehicle,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)

        cb(null, body)
      }
    )
  }

  getAutoTraderData(vehicle, cb) {
    this.authedRequest(
      'GET',
      `${this.urlRoot}/autotrader/${vehicle}`,
      null,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  unreserve(vehicleId, cb) {
    this.authedRequest(
      'GET',
      `/reservations/${vehicleId}/unreserve`,
      null,
      function(err, res, body) {
        if (err) return cb(err)
        this.emit('unreserve', vehicleId)
        cb(null, body)
      }.bind(this)
    )
  }

  reserve(vehicleId, cb) {
    this.authedRequest(
      'GET',
      `/reservations/${vehicleId}/reserve`,
      null,
      function(err, res, body) {
        if (err) return cb(err)
        this.emit('reserve', vehicleId, body.reservedDate)
        cb(null, body)
      }.bind(this)
    )
  }

  resendAutotraderImages(vehicleId, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/resend-images`,
      { vehicleId },
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  getInfoByReg(registration, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/registration-info`,
      { registration },
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }

  fetchVehicleCapData(vin, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/cap-data`,
      { vin },
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }
}

module.exports = UsedVehicleService
