const compileJade = require('browjadify-compile')
const { join } = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"><label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label></div><h2><a href=\"#base\" class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const BaseListItemView = require('../../base/views/list-item')

class ListItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
