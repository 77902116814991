const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const dialogContainer = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Import Type<select id=\"requestType\" class=\"control control--choice form-label-text\"><option value=\"reg\">Add by REG</option><option value=\"vin\">Add by VIN</option></select></span></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Vehicle to Import<input type=\"text\" id=\"requestValue\" class=\"control control--text form-field\"/></span></label></div>");;return buf.join("");
}

class ImportDialogView extends View {
  getVehicleDetails() {
    this.serviceLocator.usedVehicleService.lookupUsedVehicle(
      this.$el.find('#requestType').val(),
      this.$el.find('#requestValue').val(),
      (err, data) => this.displayVehicle(err, data)
    )
  }

  displayVehicle(err, data) {
    const container = this.$el.find('.js-import-details')
    container.empty()

    if (err) {
      return this.emit('error', err)
    }

    this.emit('vehicle', data._id)
  }

  render() {
    this.$el.append(dialogContainer())

    return this
  }
}

module.exports = ImportDialogView
