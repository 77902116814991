module.exports = initWidget

function initWidget() {
  return { signpost: ['sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    name: 'Signpost',
    description: '',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }
  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('signpost', widget)
  )
  done()
}
