const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const length = require('validity-length')

const extraProperties = {
  offers: {
    type: Array,
    validators: [length(1)]
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Unmissable Special Offers', extraProperties),
  type: 'unmissableSpecialOffers',
  defaults() {
    return this.schemata.makeDefault({
      type: this.type || 'unmissableSpecialOffers'
    })
  },

  validate: validateDelegate
})
