const FormView = require('../views/form')
const Model = require('../models/section')
const notify = require('../../notification/foreground')

function createController(serviceLocator) {
  // Edit
  serviceLocator.router.route('sections/:id/form', 'editSection', function(id) {
    if (!serviceLocator.allow('section', 'update')) return false

    serviceLocator.sectionService.read(id, function(err, section) {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      var form = new FormView(
        serviceLocator,
        new Model(serviceLocator, section),
        false
      ).render()
      serviceLocator.router.render(form, 'Edit Section')

      form.on('back', back)

      form.on('save', function() {
        saveExisting(id, form, function(err) {
          if (err) return
          notify('Saved', 'save')
        })
      })

      form.on('saveAndClose', function() {
        saveExisting(id, form, function(err) {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('sections', { trigger: true })
        })
      })
    })
  })

  // Create
  serviceLocator.router.route('sections/form', 'createList', function() {
    if (!serviceLocator.allow('section', 'create')) return false

    var form = new FormView(
      serviceLocator,
      new Model(serviceLocator),
      true
    ).render()

    serviceLocator.router.render(form, 'New Section')

    form.on('back', back)

    form.on('save', function() {
      saveNew(form, function(err, saved) {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('sections/' + saved._id + '/form', {
          trigger: true
        })
      })
    })

    form.on('saveAndClose', function() {
      saveNew(form, function(err) {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('sections', { trigger: true })
      })
    })
  })

  function saveExisting(id, form, cb) {
    serviceLocator.sectionService.update(id, form.model.toJSON(), function(
      err,
      section
    ) {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, section)
    })
  }

  function saveNew(form, cb) {
    serviceLocator.sectionService.create(form.model.toJSON(), function(
      err,
      section
    ) {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, section)
    })
  }

  function back() {
    serviceLocator.router.navigate('sections', { trigger: true })
  }
}

module.exports = createController
