const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format, getColourHex, parent) {
jade_mixins["name"] = jade_interp = function(data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<span>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span>");
if ( data.type === 'manufacturer')
{
buf.push(" <span>" + (jade.escape((jade_interp = data.finish) == null ? '' : jade_interp)) + "</span>");
}
buf.push(" <span>(" + (jade.escape((jade_interp = data.type) == null ? '' : jade_interp)) + ")</span>");
};
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-color: ' + (!!parent ? getColourHex(parent.name.toLowerCase()) : getColourHex(data.name.toLowerCase())) + ';', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed(data.type + 'Colour', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed(data.type + 'Colour', 'update'))
{
buf.push("<h2 class=\"list-item-upper\"><a" + (jade.attr("href", '/colours/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">");
jade_mixins["name"](data);
buf.push("</a></h2>");
}
else
{
jade_mixins["name"](data);
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"getColourHex" in locals_for_with?locals_for_with.getColourHex:typeof getColourHex!=="undefined"?getColourHex:undefined,"parent" in locals_for_with?locals_for_with.parent:typeof parent!=="undefined"?parent:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, index, totalItems, colours) {
    super(...arguments)
    this.colours = colours
  }

  get template() {
    return template
  }

  // return a hex for bronze or Burgundy colours
  getColourHex(colour) {
    switch (colour) {
      case 'bronze':
        return '#CD7F32'
      case 'burgundy':
        return '#900020'
      default:
        return colour
    }
  }

  serialize() {
    const parent = this.colours.find(
      colour => colour._id === this.model.get('parent')
    )

    const data = {
      data: this.model.toJSON(),
      allowed: this.serviceLocator.allowed,
      format: this.serviceLocator.format,
      index: this.index,
      totalItems: this.totalItems,
      getColourHex: this.getColourHex,
      parent
    }

    return data
  }

  render() {
    this.$el.empty().append(this.template(this.serialize()))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
