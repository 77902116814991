const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, netlifyEnabled, roles) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering\">");
if ( allowed('netlify', 'deploy') && netlifyEnabled)
{
buf.push("<a class=\"js-deploy-netlify\"><img src=\"https://www.netlify.com/img/deploy/button.svg\" title=\"Deploy to Netlify\"/></a>");
}
buf.push("</div></div></div><div class=\"centering\">");
if ( roles && roles.length && roles[0] !== '')
{
buf.push("<div class=\"grid\"><div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Content</h2></div><div class=\"panel-content\"><ul>");
if ( allowed('asset', 'create'))
{
buf.push("<li><a href=\"/asset-manager\" data-route=\"asset-manager\">Add Assets</a></li>");
}
buf.push("</ul></div></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Accounts</h2></div><div class=\"panel-content\"><ul>");
if ( allowed('account', 'create'))
{
buf.push("<li><a href=\"/accounts/form\" data-route=\"accounts/form\">Add accounts</a></li>");
}
buf.push("</ul></div></div></div><div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Administrators</h2></div><div class=\"panel-content\">");
if ( allowed('administrator', 'create') || allowed('administrator', 'read') || allowed('role', 'read'))
{
buf.push("<ul>");
if ( allowed('administrator', 'create'))
{
buf.push("<li><a href=\"/administrators/form\" data-route=\"administrators/form\">Add an Administrator</a></li>");
}
if ( allowed('administrator', 'read'))
{
buf.push("<li><a href=\"/administrators\" data-route=\"administrators\">View All Administrators</a></li>");
}
if ( allowed('role', 'read'))
{
buf.push("<li><a href=\"/roles\" data-route=\"roles\">Manage Roles</a></li>");
}
buf.push("</ul>");
}
buf.push("</div></div></div></div>");
}
else
{
buf.push("<h1>You do not have any roles assigned to you.</h1><p>You have successfully logged into Auto.Works but you'll need an administrator to assign your permissions, please contact your manager</p>");
}
buf.push("</div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"netlifyEnabled" in locals_for_with?locals_for_with.netlifyEnabled:typeof netlifyEnabled!=="undefined"?netlifyEnabled:undefined,"roles" in locals_for_with?locals_for_with.roles:typeof roles!=="undefined"?roles:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  events: {
    'click [data-route]': 'handleRoute'
  },
  handleRoute: function(e) {
    // Detect middle click or CMD click to allow <a> to open in new tab
    if (e.which === 2 || e.metaKey) return
    e.preventDefault()
    this.trigger('route', $(e.target).attr('data-route'))
  },
  initialize: function(options) {
    this.options = options
    this.render()
  },
  render: function() {
    this.$el.empty().append(
      template({
        config: window.config,
        allowed: this.options.serviceLocator.allowed,
        roles: this.options.serviceLocator.session.roles,
        netlifyEnabled:
          window.config &&
          window.config.enabledCmsFeatures &&
          window.config.enabledCmsFeatures.includes('deployToNetlify')
      })
    )
    return this
  }
})
