const formController = require('./controllers/form')
const listController = require('./controllers/list')
const OfferService = require('./services/offer')
const createRevisionController = require('../revision/controllers/controller')
const createWidgetFactory = require('../widget/lib/base-factory')

const initAdmin = () => ({ offerAdmin: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.offerService = new OfferService(serviceLocator)
  serviceLocator.widgetFactories.register('offerBody', createWidgetFactory())

  formController(serviceLocator)
  listController(serviceLocator)
  createRevisionController(
    serviceLocator,
    serviceLocator.offerService,
    'offer',
    'offers/:id/revisions',
    'offers',
    'heading'
  )
  cb(null)
}

module.exports = initAdmin
