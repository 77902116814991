const parseUrl = require('url').parse
const CrudService = require('../../../../admin/source/js/lib/crud-service')

class AccountService extends CrudService {
  get name() {
    return 'AccountService'
  }
  get urlRoot() {
    return '/accounts'
  }

  createUrl(account) {
    const parsed = parseUrl(this.serviceLocator.config.url)
    let portPostfix = ''
    if (parsed.port) portPostfix = ':' + parsed.port
    const url = 'http://' + account.domain + portPostfix
    return url
  }
}

module.exports = AccountService
