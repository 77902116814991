const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: createSchema('Primary Actions'),

  defaults() {
    return this.schemata.makeDefault({
      type: this.type || 'primaryActions'
    })
  },

  validate: validateDelegate
})
