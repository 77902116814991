module.exports = getActionHash

var hash = require('crypto').createHash

function getActionHash(salt, baseActions, extraActions, uri) {
  var extraAction = Object.keys(extraActions).map(function (key) {
    return key + '=' + JSON.stringify(extraActions[key])
  })
  return hash('md5')
    .update(
      '/' + baseActions.join('/') + '/' + uri + extraAction.join('&') + salt
    )
    .digest('hex')
}
