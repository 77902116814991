const required = require('validity-required')
const schemata = require('schemata')
const createPromoSchema = require('./promo-schema')
const fileSchema = require('../s3/file-schema')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () => {
  const schema = schemata({
    name: 'Make',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      name: {
        type: String,
        validators: [required]
      },
      category: {
        type: String,
        validators: [required]
      },
      shortName: {
        type: String,
        validators: [required]
      },
      brandName: {
        type: String,
        validators: [required]
      },
      title: {
        type: String
      },
      description: {
        type: String
      },
      slug: {
        type: String,
        validators: [required]
      },
      thumbnailImages: {
        type: Object,
        defaultValue: () => [],
        resolve: make => resolveImages(make.thumbnailImages),
        resolveType: Array
      },
      backgroundImages: {
        type: Object,
        defaultValue: () => [],
        resolve: make => resolveImages(make.backgroundImages),
        resolveType: Array
      },
      lifestyleImages: {
        type: Object,
        defaultValue: () => [],
        resolve: make => resolveImages(make.lifestyleImages),
        resolveType: Array
      },
      heroImages: {
        type: Object,
        defaultValue: () => [],
        resolve: make => resolveImages(make.heroImages),
        resolveType: Array
      },
      order: {
        type: Number
      },
      promos: {
        type: schemata.Array(createPromoSchema()),
        defaultValue: () => []
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      brandPromiseImages: {
        type: Object,
        defaultValue: () => [],
        resolve: make => resolveImages(make.brandPromiseImages),
        resolveType: Array
      },
      state: {
        type: String,
        options: ['Draft', 'Published'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      brandPromiseVideos: {
        type: schemata.Array(fileSchema('Brand Promise Video File')),
        defaultValue: () => []
      },
      servicingTitle: {
        type: String
      },
      servicingBody: {
        type: String
      },
      servicingCtaText: {
        type: String
      },
      servicingCtaLink: {
        type: String
      },
      facebookSocialLink: {
        type: String
      },
      instagramSocialLink: {
        type: String
      }
    }
  })

  return schema
}
