const join = require('path').join
const View = require('ventnor')
const modal = require('modal')
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"grid list-grid\"><div class=\"grid__item one-whole js-item-list\"></div></div>");;return buf.join("");
}

class ItemRepeater extends View {
  constructor(serviceLocator, config, currentItems, setConfig) {
    super(serviceLocator)
    this.FormView = config.formView
    this.ItemView = config.itemView
    this.ItemModel = config.itemModel
    this.itemNames = config.itemNames || { plural: 'Items', singular: 'Item' }
    this.setConfig = setConfig

    this.currentModels = currentItems.map((item, i) => {
      const model = new this.ItemModel(item)
      model.id = i
      return model
    })
    this.nextId = this.currentModels.length
  }

  render() {
    this.$el.append(
      template({
        itemNames: this.itemNames
      })
    )
    this.renderItems()
    return this
  }

  renderItems() {
    this.$el.find('.js-item-list').empty()
    this.currentModels.forEach(model => {
      const view = new this.ItemView(this.serviceLocator, model)
      view.on('editItem', this.handleEditItem.bind(this))
      view.on('removeItem', this.handleRemoveItem.bind(this))
      this.$el.find('.js-item-list').append(view.render().$el)
      this.attachView(view)
    })
  }

  handleNewItem(imageId, name) {
    const model = new this.ItemModel()
    model.set('imageId', imageId)
    model.set('name', name)
    const view = new this.FormView(this.serviceLocator, model, true)

    const itemModal = modal({
      title: `Add image for "${name}"`,
      className: 'wide',
      content: view.render().$el,
      buttons: []
    })

    const handleSave = () => {
      model.id = this.nextId
      this.nextId++
      this.currentModels.push(model)
      this.emit('updatedItem', this.currentModels)
      this.renderItems()
      itemModal.close()
    }

    view.on('save', () => handleSave())
    view.on('saveAndClose', () => {
      handleSave()
      this.emit('saveAndClose')
    })

    view.on('cancel', () => itemModal.close())
  }

  handleEditItem(model) {
    const view = new this.FormView(this.serviceLocator, model)
    const itemModal = modal({
      title: `Edit image for "${model.get('name')}"`,
      className: 'wide',
      content: view.render().$el,
      buttons: []
    })

    view.on('save', () => {
      this.renderItems()
      itemModal.close()
    })

    view.on('cancel', () => itemModal.close())
    itemModal.on('cancel', () => view.emit('cancel'))
  }

  handleRemoveItem(model) {
    this.currentModels = this.currentModels.filter(item => item.id !== model.id)
    this.emit('updatedItem', this.currentModels)
    this.renderItems()
  }

  getItems() {
    return this.currentModels.map(item => item.toJSON())
  }
}

module.exports = ItemRepeater
