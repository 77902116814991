const required = require('validity-required')
const isUrl = require('validity-url')
const schemata = require('schemata')
const validateIfSet = require('validity-validate-if-set')

module.exports = () => {
  const schema = schemata({
    name: 'Author',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      name: {
        type: String,
        validators: [required]
      },
      website: {
        type: String,
        validators: [validateIfSet(isUrl)]
      },
      biography: {
        type: String
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
