const initWidget = () => ({ imageWidget: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Image',
    itemView: require('./views/item'),
    description: 'Displays an Image'
  }

  ;['section', 'offerBody', 'articleBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('image', widget)
  )

  done()
}

module.exports = initWidget
