const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format, subtitle, title, undefined) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"></div><div class=\"multiline-title\"><h2>" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h2><small>" + (jade.escape((jade_interp = subtitle) == null ? '' : jade_interp)) + " </small>");
if ( data.externalId)
{
buf.push("- <a" + (jade.attr("href", "https://portal.autotrader.co.uk/portal/#/edit/" + (data.externalId) + "/overview", true, false)) + " target=\"_blank\">Edit on AutoTrader</a>");
}
buf.push("</div></div><div class=\"list-item-content\"><p>Failure Reasons:</p><dl><ul>");
// iterate data.failureReason
;(function(){
  var $$obj = data.failureReason;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

buf.push("<li>" + (jade.escape((jade_interp = key) == null ? '' : jade_interp)) + ": " + (jade.escape((jade_interp = err) == null ? '' : jade_interp)) + "</li>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

buf.push("<li>" + (jade.escape((jade_interp = key) == null ? '' : jade_interp)) + ": " + (jade.escape((jade_interp = err) == null ? '' : jade_interp)) + "</li>");
    }

  }
}).call(this);

buf.push("</ul></dl></div><div class=\"list-item-content\"><dl class=\"list--rows\"><dt>Added:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Action: </dt><dd>" + (jade.escape(null == (jade_interp = data.action) ? "" : jade_interp)) + "</dd>");
if ( data.autoTraderSearchId)
{
buf.push("<dt>Link: </dt><dd> <a" + (jade.attr("href", 'https://www.autotrader.co.uk/car-details/' + (data.autoTraderSearchId) + '', true, false)) + " target=\"_blank\">View on AutoTrader</a></dd>");
}
buf.push("</dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"subtitle" in locals_for_with?locals_for_with.subtitle:typeof subtitle!=="undefined"?subtitle:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, index, totalItems, dealers) {
    super(...arguments)

    this.dealers = dealers
  }

  get template() {
    return template
  }

  render() {
    const data = this.model.toJSON()
    if (data.dealership) {
      data.dealership = this.dealers.find(
        dealer => dealer._id == data.dealership
      )
    } else {
      data.dealership = {}
    }

    let title = ''
    let subtitle = data.dealership.location

    if (data.registration) {
      title += `${data.registration}`
    }

    if (data.vin) {
      if (title) {
        title += ' - '
      }
      title += data.vin
    }

    if (!title) {
      title += 'Unknown Vehicle'
    }

    this.$el.empty().append(
      this.template({
        data,
        title,
        subtitle,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems
      })
    )
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
