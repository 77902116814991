const FormView = require('../views/form')
const Model = require('../models/faq')
const createPublishFormViewController = require('../../base/controllers/publish-form-view-controller')

const createController = serviceLocator => {
  const initializeForm = createPublishFormViewController(
    serviceLocator,
    'faqService',
    'FAQ',
    'faqs',
    FormView
  )

  // Edit
  serviceLocator.router.route('faqs/:id/form', 'editFaq', id => {
    if (!serviceLocator.allow('faq', 'update')) return false

    serviceLocator.faqService.read(id, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const model = new Model(serviceLocator, data)

      initializeForm(model, null, false)
    })
  })

  // Create
  serviceLocator.router.route('faqs/form', 'createFaq', () => {
    if (!serviceLocator.allow('faq', 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    initializeForm(model, null, true)
  })
}

module.exports = createController
