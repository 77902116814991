module.exports = ListFilterView
const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}

function ListFilterView() {
  View.apply(this, arguments)
  this.$el.addClass('list-filters')
  this.$el.on('submit', 'form', this.handleSubmit.bind(this))
  this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
}

ListFilterView.prototype = Object.create(View.prototype)

// Compatibility with backbone admin
ListFilterView.prototype.trigger = View.prototype.emit

ListFilterView.prototype.updateDisplay = function(params) {
  if (params.filter.instance === null) {
    this.$el
      .find('[name=instance] option[value=_global]')
      .attr('selected', true)
  }
}

ListFilterView.prototype.handleSubmit = function(e) {
  if (e) e.preventDefault()

  const params = { filter: {}, sort: ['fullUrlPath', 'asc'] }

  this.emit('filter', params)
}

ListFilterView.prototype.handleClear = function(e) {
  e.preventDefault()
  this.handleSubmit()
}

ListFilterView.prototype.render = function() {
  this.$el.empty().append(template())
  this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
  return this
}
