import model from './models/model'
import editView from './views/form'
import itemView from './views/item'

const initWidget = () => ({ careersFormWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    itemView,
    editView,
    model,
    name: 'Careers Form',
    description: 'Display a form to apply for a career'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('careersForm', widget)
  )

  done()
}

module.exports = initWidget
