module.exports = function validateIf(validateFn, comparator, other) {
  function validate(key, keyDisplayName, object, cb) {
    if (comparator(object[other || key], object)) {
      return validateFn(key, keyDisplayName, object, cb)
    }

    return cb(null, undefined)
  }

  return validate
}
