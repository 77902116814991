const formController = require('./controllers/form')
const listController = require('./controllers/list')
const CareerService = require('./services/career')

const initAdmin = () => ({ careerAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.careerService = new CareerService(serviceLocator)
  formController(serviceLocator)
  listController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
