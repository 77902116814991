module.exports = initWidget

function initWidget() {
  return { featureCarousel: ['sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    name: 'Feature Carousel',
    description: 'Displays a carousel',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }
  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('featureCarousel', widget)
  )
  done()
}
