const View = require('../views/api-error')
const debug = require('../../../../admin/source/js/lib/debug')('custom sync')

module.exports = function() {
  debug('Setting Backbone.sync to parentSyncMethod')

  const view = new View()
  let lastTimeout
  const parentSyncMethod = window.Backbone.sync

  window.Backbone.sync = function(method, model, options) {
    debug('sync called')
    clearTimeout(lastTimeout)

    let currentError = function() {}
    let currentSuccess = function() {}

    if (options.error) {
      currentError = options.error
    }

    if (options.success) {
      currentSuccess = options.success
    }

    options.error = function(xhr, textStatus, errorThrown) {
      debug('sync error - xhr status ' + xhr.status)

      currentError(xhr, textStatus, errorThrown)

      // No status is returned if the API is inaccessible
      if (xhr.status === 0) {
        view.render()
        lastTimeout = setTimeout(function() {
          options.xhr = undefined
          parentSyncMethod(method, model, options)
          debug(method, model, options)
        }, 2000)
      }
    }

    options.success = function(xhr, textStatus, errorThrown) {
      debug('sync succeeded')

      currentSuccess(xhr, textStatus, errorThrown)
      view.remove()
    }

    parentSyncMethod(method, model, options)
  }
}
