const schemata = require('schemata')
const required = require('validity-required')

module.exports = () => {
  const schema = schemata({
    name: 'Prep Form Entry',
    properties: {
      _id: {
        type: String
      },
      collectionDealership: { type: String, validators: [required] },
      registration: { type: String, validators: [required] },
      autoTraderVehicleType: {
        type: String,
        validators: [required]
      },
      autoTraderMake: {
        type: Object
      },
      autoTraderModel: {
        type: Object
      },
      autoTraderGeneration: {
        type: Object
      },
      autoTraderDerivative: {
        type: Object
      },

      model: { type: String, validators: [required] },
      colour: { type: String },
      mileage: { type: Number },
      retailPrice: { type: String, validators: [required] },
      workGrade: { type: String, validators: [required] },
      returnVehicleTo: { type: String },
      notes: { type: String },
      userId: { type: String },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
