const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')
const requireOne = require('validity-require-one')

const extraProperties = {
  filter: {
    type: String,
    validators: [required]
  },
  location: {
    type: String,
    validators: [requireOne(['make', 'location'])]
  },
  make: {
    type: String,
    validators: [requireOne(['make', 'location'])]
  },
  header: {
    type: String
  },
  theme: { type: String },
  showLettering: { type: Boolean }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Used Vehicle Carousel', extraProperties),
  type: 'usedVehicleCarousel',
  defaults() {
    return this.schemata.makeDefault({
      type: this.type || 'usedVehicleCarousel'
    })
  },

  validate: validateDelegate
})
