module.exports = initWidget

function initWidget() {
  return { cookieListWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  var cookieListWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Cookie List',
    itemView: require('./views/item'),
    description: 'Cookie List'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('cookieList', cookieListWidget)
  )

  done()
}
