const initWidget = () => ({ heroWidget: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Hero',
    itemView: require('./views/item'),
    description: 'Display hero image with description'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('hero', widget)
  )

  done()
}

module.exports = initWidget
