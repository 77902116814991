const schemata = require('schemata')
const required = require('validity-required')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () =>
  schemata({
    name: 'Dimension',
    properties: {
      heading: { type: String, validators: [required] },
      subheading: { type: String },
      description: { type: String, validators: [required] },
      options: { type: String },
      brochureUrl: { type: String },
      configurationUrl: { type: String },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: dimension => resolveImages(dimension.images),
        resolveType: Array
      }
    }
  })
