const FormView = require('../views/form')
const Model = require('../models/role')
const notify = require('../../notification/foreground')

const createController = serviceLocator => {
  // Edit
  serviceLocator.router.route('roles/:id/form', 'editRole', id => {
    if (!serviceLocator.allow('role', 'update')) return false

    serviceLocator.roleService.read(id, (err, role) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const form = new FormView(
        serviceLocator,
        new Model(serviceLocator, role),
        false
      ).render()
      serviceLocator.router.render(form, 'Edit Role')

      form.on('back', back)

      form.on('save', () => {
        saveExisting(id, form, err => {
          if (err) return
          notify('Saved', 'save')
        })
      })

      form.on('saveAndClose', () => {
        saveExisting(id, form, err => {
          if (err) return
          notify('Saved', 'save')
          serviceLocator.router.navigate('roles', { trigger: true })
        })
      })
    })
  })

  // Create
  serviceLocator.router.route('roles/form', 'createRole', () => {
    if (!serviceLocator.allow('role', 'create')) return false

    const model = new Model(serviceLocator)
    const form = new FormView(serviceLocator, model, true).render()

    model.set(model.schemata.makeDefault())

    serviceLocator.router.render(form, 'New List')

    form.on('back', back)

    form.on('save', () => {
      saveNew(form, (err, saved) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('roles/' + saved._id + '/form', {
          trigger: true
        })
      })
    })

    form.on('saveAndClose', () => {
      saveNew(form, err => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate('roles', { trigger: true })
      })
    })
  })

  const saveExisting = (id, form, cb) => {
    serviceLocator.roleService.update(id, form.model.toJSON(), (err, role) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      serviceLocator.loadAcl()
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, role)
    })
  }

  const saveNew = (form, cb) => {
    serviceLocator.roleService.create(form.model.toJSON(), (err, role) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      serviceLocator.loadAcl()
      form.clearUnsavedChanges()
      cb(null, role)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('roles', { trigger: true })
  }
}

module.exports = createController
