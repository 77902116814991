const CrudService = require('../../../../admin/source/js/lib/crud-service')

class ImageSetService extends CrudService {
  get name() {
    return 'ImageSetService'
  }
  get urlRoot() {
    return '/image-sets'
  }
}

module.exports = ImageSetService
