const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed) {
buf.push("<div class=\"asset-picker-controls\"><span class=\"js-status\">No items selected</span>");
if ( allowed('asset', 'create'))
{
buf.push("<p class=\"label label--notice label--large\">Drop files anywhere to upload</p><span>or</span><button class=\"btn btn--action js-upload\">Choose Files</button><input type=\"file\" value=\"Choose File\" multiple=\"multiple\" class=\"control control--file js-file-input asset-file-input\"/>");
}
buf.push("</div><!-- .js-filters--><div class=\"asset-picker-grid\"><div class=\"js-in-progress\"></div><div class=\"js-list-items grid\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined));;return buf.join("");
}
const InProgressView = require('./in-progress')
const createFileUploader = require('../lib/file-uploader')
const AssetCollection = require('../collections/asset')
const debug = require('../../../../admin/source/js/lib/debug')(
  'asset picker view'
)
const GenericListView = require('../../../../admin/source/js/lib/generic/list-view')
const ListFilterView = require('./list-filter')
const ListItemView = require('./list-item')
const uploadUiDelegate = require('../lib/upload-ui-delegate')
const bindAll = require('lodash.bindall')

module.exports = GenericListView.extend({
  events: {
    'click .js-upload': 'showFileInput',
    'click .js-more': 'loadMore'
  },

  template: template,
  itemContainer: '.js-list-items',

  createListItemView(model) {
    var view = new ListItemView({
      model: model,
      perRow: 6,
      serviceLocator: this.options.serviceLocator
    })

    if (this.selectedCollection.get(model.id)) {
      view.select()
    }

    return view
  },

  initialize(options) {
    this.options = options
    // Ensure all instance methods of this view are
    // executed in the context 'this' for convenience
    bindAll(this)

    // Create an empty collection to hold in-progress uploads
    this.inProgressCollection = new AssetCollection()
    // Listen to the in progress collection for finished uploads
    this.listenTo(this.inProgressCollection, 'uploadEnd', this.onUploadEnd)

    this.listenTo(this.collection, 'filter', this.maintainOrder)

    this.on('remove', this.onRemove)

    this.listenTo(this.collection, 'change', this.assetUpdated, this)

    GenericListView.prototype.initialize.apply(this, arguments)
  },

  initializeUploader() {
    this.fileUploader = createFileUploader(this.$el, this.options.type)
    this.fileUploader.on('add', this.addUpload.bind(this))

    uploadUiDelegate(this)
  },

  /*
   * Add a new model created by the uploader
   * to the in progress collection.
   */
  addUpload(model) {
    debug('Got model for a new upload', model)
    this.inProgressCollection.add(model)
  },

  /*
   * Show the file input dialog.
   */
  showFileInput() {
    this.$('.js-file-input').click()
  },

  /*
   * Tidy up events and external plugins for when
   * the view is to be disposed of.
   */
  onRemove() {
    debug('removing fileupload behaviour')

    // Remove the fileupload behaviour
    this.fileUploader.trigger('destroy')
  },

  clearSelection() {
    this.trigger('clearSelection')
  },

  addToSelection() {
    this.trigger('addToSelection')
  },

  onSelectionChange() {
    var count = this.selectedCollection.length
    if (count === 0) return this.$('.js-status').text('No items selected')
    this.$('.js-status').text(
      count + (count === 1 ? ' item' : ' items') + ' selected'
    )
  },

  /*
   * Fixes a bug where if an asset is edited after upload/selected, the
   * model that is given to the article is outdated.
   */
  assetUpdated(model) {
    if (this.selectedCollection.get(model)) {
      this.selectedCollection.remove(model)
      this.selectedCollection.add(model)
    }
  },

  /*
   * Handle a completed upload.
   */
  onUploadEnd(uploadedModel) {
    const onSuccess = () => {
      debug('Adding model to asset collection', uploadedModel)
      this.collection.applyFilter()
      // Bit weird listening to 'filter' here, but applyFilters() doesn't have a
      // callback (and it does a silent fetch()) and the app needs to know when
      // the new model has been fetch'd
      this.collection.once('filter', () => {
        var model = this.collection.get(uploadedModel.id)

        // this will add the uploaded image to the collection if the model doesn't exist
        var added = this.collection.unshift(model || uploadedModel)

        added.trigger('select', added)
      })
    }
    uploadedModel.save(null, { success: onSuccess })
  },

  loadMore() {
    this.collection.loadMore()
  },

  render() {
    this.$el
      .empty()
      .append(template({ allowed: this.options.serviceLocator.allowed }))

    const inProgressView = new InProgressView({
      collection: this.inProgressCollection
    })
    const listFilterView = new ListFilterView({ layout: 'condensed' })

    this.attachView(inProgressView)
    this.attachView(listFilterView)

    listFilterView.on('filter', params => {
      this.collection.applyFilter(params)
    })

    this.$('.js-in-progress').append(inProgressView.$el)
    this.$('.js-filters').append(listFilterView.$el)

    if (this.options.serviceLocator.allowed('asset', 'create')) {
      this.initializeUploader()
    }

    return this
  }
})
