const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const extraProperties = {
  model: {
    type: String,
    validators: [required]
  },
  description: {
    type: String
  },
  header: {
    type: String
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Model Banner', extraProperties),
  type: 'modelBanner',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'modelBanner' })
  },

  validate: validateDelegate
})
