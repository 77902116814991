const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')
const length = require('validity-length')

const extraProperties = {
  title: {
    type: String,
    validators: [required]
  },
  items: {
    type: Array,
    defaultValue: () => [],
    validators: [length(1, 4)]
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Automotive Cards Widget', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'automotiveCards' })
  },
  validate: validateDelegate
})
