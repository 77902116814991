const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, modelNames, name) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
if ( model.get('visible'))
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This widget is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><div class=\"grid\"><div class=\"grid__item\"><dl><dt>Models:</dt><dd>" + (jade.escape(null == (jade_interp = modelNames) ? "" : jade_interp)) + "</dd></dl></div></div></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"modelNames" in locals_for_with?locals_for_with.modelNames:typeof modelNames!=="undefined"?modelNames:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const EditBaseItemView = require('../../../widget/views/item/edit-base')

module.exports = EditBaseItemView.extend({
  template: template,
  render: function() {
    const modelIds = this.model.get('models')
    if (!modelIds) return this

    this.options.serviceLocator.modelService.find(
      '',
      { _id: { $in: modelIds } },
      {},
      [],
      (error, models) => {
        if (error) {
          this.options.serviceLocator.logger.error(error)
          return this
        }
        const modelNames = models.results.map(({ name }) => name)
        this.extraProperties.modelNames = modelNames.toString()
        EditBaseItemView.prototype.render.apply(this, arguments)
      }
    )
  }
})
