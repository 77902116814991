const FormView = require('./views/form')
const Model = require('./models/body-type')
const baseFormController = require('../base/controllers/form')
const listController = require('./controllers/list')
const BodyTypeService = require('./services/body-type')

const initAdmin = () => ({ bodyTypeAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.bodyTypeService = new BodyTypeService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'body type',
    plural: 'body types',
    path: 'body-types',
    service: serviceLocator.bodyTypeService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  listController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
