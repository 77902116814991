const {
  findCategory
} = require('../components/service/used-vehicle/lib/category-matcher')

const strip = value => (value || '').replace(/[^\w]/gi, '')

const buildPath = vehicle =>
  `/${findCategory(vehicle.category).type}s/${
    vehicle.saleType === 'new' ? 'new-in-stock' : 'used'
  }/${vehicle.brand}/${strip(vehicle.model)}/`.toLowerCase() +
  strip(vehicle.registration || vehicle.vin || vehicle._id).toLowerCase()

module.exports = buildPath
