const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"]('area', 'Type', 'The type of complaint', data.area, true, 'text');
jade_mixins["inputField"]('email', 'Email', `If no email is provided, the default email will be used`, data.email, false, 'email');
buf.push("</form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template
  }

  render() {
    super.render()
    return this
  }
}

module.exports = ItemRepeaterFormView
