const initWidget = () => ({
  homepageHeroWidget: ['widget', 'sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Homepage Hero',
    itemView: require('./views/item'),
    description: 'Display vehicle search'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('homepageHero', widget)
  )

  done()
}

module.exports = initWidget
