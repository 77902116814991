module.exports = initAdmin
const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const RoleService = require('./services/role')

function initAdmin() {
  return { roleAdmin: init }
}

function init(serviceLocator, done) {
  serviceLocator.roleService = new RoleService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}
