const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const extraProperties = {
  career: {
    type: String,
    validators: [required]
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Careers Form', extraProperties),

  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'careersForm' })
  },

  validate: validateDelegate
})
