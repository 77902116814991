const escapeRegExp = require('escape-string-regexp')

module.exports = save => (key, errorProperty, object, callback) => {
  let email
  try {
    email = escapeRegExp(object[key])
  } catch (e) {
    return callback(null, 'Email must be a string')
  }
  save.findOne(
    {
      [key]: new RegExp(['^', email, '$'].join(''), 'i')
    },
    (error, found) => {
      if (error) return callback(error)
      callback(
        null,
        found && found._id.toString() !== object._id
          ? object[key] + ' already in use'
          : undefined
      )
    }
  )
}
