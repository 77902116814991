const schemata = require('schemata')
const required = require('validity-required')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () =>
  schemata({
    name: 'Promo',
    properties: {
      description: { type: String, validators: [required] },
      linkUrl: { type: String },
      liveDate: { type: Date },
      expiryDate: { type: Date },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: dimension => resolveImages(dimension.images),
        resolveType: Array
      }
    }
  })
