const Model = require('merstone')

const createGetRevisions = (serviceLocator, collection, paginationModel) => (
  entityId,
  type
) => {
  serviceLocator.revisionService.listRevisionsForEntity(
    entityId,
    type,
    (err, res) => {
      if (err)
        return serviceLocator.logger.error(err, 'Could not load revisions')
      collection.reset(res.map(revision => new Model(serviceLocator, revision)))
      paginationModel.set('totalItems', collection.models.length)
      paginationModel.set('showing', collection.models.length)
    }
  )
}

module.exports = createGetRevisions
