const BaseModel = require('cf-base-model')
const schemata = require('schemata')
const required = require('validity-required')

const properties = {
  heading: {
    type: String,
    validators: [required]
  },
  type: {
    type: String
  },
  usedVehicle: {
    type: Array
  },
  makes: {
    type: Array
  },
  models: {
    type: Array
  },
  selectedLocations: {
    type: Array
  },
  offerCategories: {
    type: Array
  }
}

module.exports = BaseModel.extend({
  schemata: schemata({ name: 'Item', properties }),

  defaults() {
    return this.schemata.makeDefault()
  },
  validate(cb) {
    this.schemata.validate(this.attributes, (ignoreErr, errors) => {
      return cb(Object.keys(errors).length > 0 ? errors : undefined)
    })
  }
})
