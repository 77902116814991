module.exports = (dealership, vehicle) => {
  if (!dealership || !dealership.autoTraderUrl) {
    return ''
  }

  let url = `${dealership.autoTraderUrl}/stock?advanced=true`
  url += `&onesearchad=Used&onesearchad=Nearly New&advertising-location=at_cars&advertising-location=at_profile_cars`

  // add dealership ID
  url += `&dealer=${dealership.autoTraderFeedId}`

  // add make and model
  url += `&make=${vehicle.brand.toUpperCase()}`
  url += `&model=${vehicle.model.toUpperCase()}`

  // add the colour
  url += `&colour=${vehicle.colour}`

  // add vehicle price
  url += `&price-from=${vehicle.value - 1}&price-to=${vehicle.value + 1}`

  return url
}
