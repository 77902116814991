const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  tagName: 'label',

  initialize(options) {
    this.options = options
    if (this.model.get('allowMultipleSelection')) {
      this.options.inputType = 'checkbox'
    } else {
      this.options.inputType = 'radio'
    }

    // Check if the properties dictate that this should be an automatically
    // checked context.
    if (this.model.get('autoChecked')) {
      this.options.checked = true
      this.options.disabled = true
    }

    this.render()
  },

  template: template,

  render: function() {
    this.$el.empty().append(
      this.template({
        data: this.model.toJSON(),
        inputType: this.options.inputType,
        checked: this.options.checked,
        disabled: this.options.disabled
      })
    )

    return this
  }
})
