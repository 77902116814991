const schemata = require('schemata')
const dateBeforePropertyValidator = require('validity-date-before-property')()
const hat = require('hat')

module.exports = (name = 'Base Widget', properties) =>
  schemata({
    name,
    properties: {
      ...properties,
      id: {
        type: String,
        defaultValue: () => hat()
      },
      type: {
        type: String
      },
      className: {
        type: String
      },
      visible: {
        type: Boolean,
        defaultValue: true
      },
      liveDate: {
        type: Date,
        validators: {
          all: [dateBeforePropertyValidator]
        }
      },
      expiryDate: {
        type: Date
      },
      tiers: {
        type: Array
      },
      displayOptions: {
        type: Array,
        defaultValue: () => ['desktop', 'tablet', 'mobile'],
        validators: [
          (key, keyDisplayName, object, cb) =>
            cb(
              null,
              object[key].length < 1
                ? 'At least one display option must be selected'
                : null
            )
        ]
      },
      essential: {
        type: Boolean,
        defaultValue: true
      },
      anchorLink: {
        type: String,
        validators: [
          (prop, name, object) => {
            if (!object.anchorLink) return null
            const re = /^[A-Za-z]+[\w\-:.]*$/
            if (!re.test(object.anchorLink)) {
              return `This must be a valid anchor ID`
            }
          }
        ]
      }
    }
  })
