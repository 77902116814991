const DynavidAssetService = require('./services/dynavid-asset')

const initAdmin = () => ({ dynavidAssetAdmin: init })

const init = (serviceLocator, done) => {
  serviceLocator.dynavidAssetService = new DynavidAssetService(serviceLocator)
  done()
}

module.exports = initAdmin
