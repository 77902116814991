const BaseFormView = require('../../base/views/form')
const join = require('path').join
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, faqTypes, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div class=\"grid\"><div class=\"grid__item one-whole\"><div data-field=\"title\" class=\"form-row\"><label><span class=\"form-label-text\">Title<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"title\"" + (jade.attr("value", data.title, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/><div class=\"js-error\"></div></label></div><div data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Type<abbr title=\"This field is required\">*</abbr></span><select name=\"type\" class=\"control control--choice form-field\"><option value=\"\"" + (jade.attr("selected", data.type === '', true, false)) + ">-- Please select a Type --</option>");
// iterate faqTypes
;(function(){
  var $$obj = faqTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var faqType = $$obj[$index];

buf.push("<option" + (jade.attr("value", faqType.type, true, false)) + (jade.attr("selected", faqType.type === data.type, true, false)) + ">" + (jade.escape(null == (jade_interp = faqType.text) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var faqType = $$obj[$index];

buf.push("<option" + (jade.attr("value", faqType.type, true, false)) + (jade.attr("selected", faqType.type === data.type, true, false)) + ">" + (jade.escape(null == (jade_interp = faqType.text) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select><div class=\"js-error\"></div></label></div></div></div></div></div><div id=\"section-tags\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Tags</h2><p>Identifies labels for the FAQ to allow advanced filtering options in a list.</p></div><div class=\"panel-content\"><div class=\"js-tag-selector\"></div></div></div><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Body</h2></div><div class=\"panel-content\"><div class=\"form-row form-row-full-width\"><textarea name=\"body\" rows=\"4\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.body) ? "" : jade_interp)) + "</textarea><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"faqTypes" in locals_for_with?locals_for_with.faqTypes:typeof faqTypes!=="undefined"?faqTypes:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const RichTextEditorInstanceManager = require('../../../../admin/source/js/lib/rich-text-editor-instance-manager')
const TimePicker = require('anytime')
const moment = require('moment')
const faqTypes = require('../../../service/faq/types').all
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
const ToolbarView = require('../../toolbar/views/form-publish-toolbar')
const TagSelector = require('./faq-tag-selector')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account
    this.dateTimePickers = []
    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )
    this.richTextEditorInstanceManager = new RichTextEditorInstanceManager()
    this.on('remove', () => {
      this.dateTimePickers.forEach(picker => {
        picker.destroy()
      })
      this.richTextEditorInstanceManager.destroy()
    })

    this.tagControls = new TagSelector(this.serviceLocator, this.model)
  }

  setupToolbar() {
    this.toolbar = new ToolbarView(this.serviceLocator, this.model, this, 'faq')
    this.toolbar.on('back', this.emit.bind(this, 'back'))
    this.toolbar.on('save', this.emit.bind(this, 'save'))
    this.toolbar.on('saveAndClose', this.emit.bind(this, 'saveAndClose'))
    this.toolbar.on('publish', this.emit.bind(this, 'publish'))
    this.toolbar.on('draft', this.emit.bind(this, 'draft'))
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New FAQ' : 'Edit FAQ',
        faqTypes: faqTypes,
        data: this.model.toJSON(),
        format: this.serviceLocator.format,
        allowed: this.serviceLocator.allow
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.on('afterAppend', this.renderRichTextEditors.bind(this))
    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(this.initDateTimePickers)
    this.renderDatepickers()

    this.$el
      .find('.js-tag-selector')
      .empty()
      .append(this.tagControls.render().$el)

    return this
  }

  renderRichTextEditors() {
    const config = {
      height: 200,
      toolbarGroups: [
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        {
          name: 'editing',
          groups: ['find', 'selection', 'spellchecker', 'editing']
        },
        { name: 'links', groups: ['links'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list'] },
        { name: 'document', groups: ['mode', 'document', 'doctools'] }
      ],
      removeButtons:
        'Underline,Subscript,Superscript,Styles,Format,About,' +
        'Blockquote,Outdent,Indent,Maximize,' +
        'Image,Table,HorizontalRule,SpecialChar,Anchor'
    }
    this.$el.find('.js-text-editor').each((i, el) => {
      this.richTextEditorInstanceManager.create(el, config)
    })
  }

  renderDatepickers() {
    // Instantiate a datepicker for each date/time input
    this.$el.find('.js-timepicker').each((i, el) => {
      const picker = new TimePicker({
        input: el,
        format: this.serviceLocator.config.formats.timezone,
        timezone: this.serviceLocator.config.timezone
      })
      picker.on('change', value => {
        if (value) value = moment(value).toDate()
        this.model.set(el.name, value)
      })
      picker.render()
      this.timePickers.push(picker)
    })
  }
}

module.exports = FormView
