module.exports={
  "repositories": "'repositories' (plural) Not supported. Please pick one as the 'repository' field"
  ,"missingRepository": "No repository field."
  ,"brokenGitUrl": "Probably broken git url: %s"
  ,"nonObjectScripts": "scripts must be an object"
  ,"nonStringScript": "script values must be string commands"
  ,"nonArrayFiles": "Invalid 'files' member"
  ,"invalidFilename": "Invalid filename in 'files' list: %s"
  ,"nonArrayBundleDependencies": "Invalid 'bundleDependencies' list. Must be array of package names"
  ,"nonStringBundleDependency": "Invalid bundleDependencies member: %s"
  ,"nonDependencyBundleDependency": "Non-dependency in bundleDependencies: %s"
  ,"nonObjectDependencies": "%s field must be an object"
  ,"nonStringDependency": "Invalid dependency: %s %s"
  ,"deprecatedArrayDependencies": "specifying %s as array is deprecated"
  ,"deprecatedModules": "modules field is deprecated"
  ,"nonArrayKeywords": "keywords should be an array of strings"
  ,"nonStringKeyword": "keywords should be an array of strings"
  ,"conflictingName": "%s is also the name of a node core module."
  ,"nonStringDescription": "'description' field should be a string"
  ,"missingDescription": "No description"
  ,"missingReadme": "No README data"
  ,"missingLicense": "No license field."
  ,"nonEmailUrlBugsString": "Bug string field must be url, email, or {email,url}"
  ,"nonUrlBugsUrlField": "bugs.url field must be a string url. Deleted."
  ,"nonEmailBugsEmailField": "bugs.email field must be a string email. Deleted."
  ,"emptyNormalizedBugs": "Normalized value of bugs field is an empty object. Deleted."
  ,"nonUrlHomepage": "homepage field must be a string url. Deleted."
  ,"invalidLicense": "license should be a valid SPDX license expression"
  ,"typo": "%s should probably be %s."
}
