const compileJade = require('browjadify-compile')
const join = require('path').join
const templates = {
  'drop-area': function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"widget-drop-area js-widgets\"></div>");;return buf.join("");
},
  'beancan-area': function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"widget-beancan-area\"><div class=\"js-widgets\"></div></div>");;return buf.join("");
}
}
const WidgetOptionListView = require('./widget-option-list')
const debug = require('debug')('widget area view')
const extend = require('lodash.assign')
const defaults = { widgetItemViews: [], receiveDrops: true, delayRender: false }

module.exports = window.Backbone.View.extend({
  events: {},

  initialize(options) {
    debug('init')
    this.views = {}
    this.options = extend({}, defaults, options)
    this.template = this.options.receiveDrops
      ? templates['drop-area']
      : templates['beancan-area']

    this.selected = []

    if (options.delayRender) return

    this.beginRender()
  },

  beginRender() {
    this.render()
    this.addListeners()
    this.appendWidgets()
  },

  addListeners() {
    this.model.on(
      'add',
      (widgetArea, widget, options) => {
        debug('model add', widget)
        this.appendWidget(widget, true, options)
      },
      this
    )

    this.model.on('fullChange', (widgetArea, widgets) => {
      Object.keys(this.views).forEach(id => {
        this.views[id].remove()
      })
      this.views = []
      widgets.forEach(widget => this.appendWidget(widget, true))
    })
  },

  appendWidgets() {
    this.model.get('widgets').forEach(widget => {
      this.appendWidget(widget)
    })
  },

  handleDrop: function(event, ui) {
    let type = $(ui.item).data('type')
    debug('handle widget drop', type)

    const factory = this.model.abstractWidgetFactory(type)
    const Model = factory.model
    const model = new Model({ type: type })

    ui.item.attr('id', 'placeholder-' + model.cid)
    this.model.add(model)
  },

  removeView(id) {
    delete this.views[id]
  },

  handleSelect(model) {
    this.selected.push(model)
  },

  handleDeselect(model) {
    const index = this.selected.indexOf(model)

    if (index > -1) {
      this.selected.splice(index, 1)
    }
  },

  appendWidget(model, init, options) {
    debug('append widget', this, model)

    options = options || {}

    const factory = this.model.abstractWidgetFactory(model.get('type'))
    let WidgetItemView = factory.itemView.extend({ factory })
    const widgetItemOptions = extend({}, this.options.widgetItemOptions, {
      model: model,
      widgetArea: this,
      serviceLocator: this.options.serviceLocator,
      allowSelection: this.options.allowSelection
    })

    const select = this.handleSelect.bind(this, model)
    const deSelect = this.handleDeselect.bind(this, model)

    if (this.options.allowSelection) {
      model.on('select', select)
      model.on('deSelect', deSelect)
    }

    this.trigger('addListeners', model)

    let widgetItemView

    // This allows you to override an edit template without having to create a new widget
    if (
      this.options.widgetItemOptions &&
      this.options.widgetItemOptions.editView
    ) {
      WidgetItemView = WidgetItemView.extend({
        editView: this.options.widgetItemOptions.editView
      })
    }

    widgetItemView = new WidgetItemView(widgetItemOptions)

    this.views[model.id] = widgetItemView

    // Make sure each widget knows which widget area it belongs to
    widgetItemView.widgetArea = this

    const removeListeners = () => {
      model.off('select', select)
      model.off('deSelect', deSelect)
      this.trigger('removeListeners', model)
    }

    const removeView = () => {
      debug('view remove')
      widgetItemView.remove()
      removeListeners()

      this.removeView(model.id)
    }
    const removeModel = () => {
      debug('model remove')
      widgetItemView.remove()
      removeListeners()

      this.renderWidgetOptionLists()
    }
    widgetItemView.listenTo(this, 'remove', removeView)
    model.on('remove', removeModel)

    this.appendWidgetView(widgetItemView, options.at)

    if (init) {
      widgetItemView.trigger('init', null, init)
    }
  },

  removeWidgetOptionLists() {
    const $container = this.$('.js-widgets')
    $container.find('.js-widget-option-list').remove()
    this.widgetOptionLists.map(list => list.remove())
  },

  renderWidgetOptionLists() {
    const { receiveDrops, collection } = this.options
    if (!receiveDrops || !collection) return

    this.removeWidgetOptionLists()

    const $container = this.$('.js-widgets')
    const widgets = $container.find('.js-widget')

    // Add first
    const widgetOptionList = new WidgetOptionListView({ collection })
    widgetOptionList.prependTo($container)
    widgetOptionList.on('add', this.handleWidgetAdd.bind(this))
    this.widgetOptionLists.push(widgetOptionList)

    // Add after each widget (if any)
    widgets.each((i, widget) => {
      const optionView = new WidgetOptionListView({ collection })
      optionView.insertAfter(widget)
      optionView.on('add', this.handleWidgetAdd.bind(this))
      this.widgetOptionLists.push(optionView)
    })
  },

  handleReorder() {
    const order = []
    this.$('.js-widget').map((index, el) => {
      order.push($(el).attr('data-cid'))
    })
    this.model.setOrder(order)
  },

  setupSortable: function() {
    this.$('.js-widgets')
      .sortable({
        handle: '.js-sort-handle',
        placeholder:
          'js-widget widget-grid__item ui-sortable-placeholder-custom',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer',
        connectWith: this.options.receiveDrops ? '.js-widgets' : '',
        stop: function() {
          this.model.trigger('reload')
        }.bind(this)
      })
      .off('sortupdate')
      .on(
        'sortupdate',
        function(event, ui) {
          if (!ui.item.hasClass('js-widget')) {
            this.handleDrop(event, ui)
          }
          var order = []
          this.$('.js-widget').map(function(index, el) {
            order.push($(el).attr('data-cid'))
          })
          this.model.setOrder(order)
        }.bind(this)
      )
  },

  appendWidgetView(widgetItemView, at) {
    const $widgetArea = this.$('.js-widgets')
    const $widgets = $widgetArea.children()
    const $placeholder = $widgetArea.find(
      '#placeholder-' + widgetItemView.model.cid
    )

    if ($placeholder.length > 0) {
      $placeholder.replaceWith(widgetItemView.$el)
    } else {
      if (typeof at === 'number') {
        if ($widgets.length && $widgets.length > at) {
          $widgets.eq(at).before(widgetItemView.$el)
        } else {
          $widgetArea.append(widgetItemView.$el)
        }
      } else {
        $widgetArea.append(widgetItemView.$el)
      }
    }

    this.options.widgetItemViews.push(widgetItemView)
    this.setupSortable()
  },
  remove() {
    window.Backbone.View.prototype.remove.call(this)
    this.trigger('remove')
  },
  render() {
    this.$el.empty().append(this.template())
    this.setupSortable()
    return this
  }
})
