const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, models, name, usedVehicles) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
if ( model.get('visible'))
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This widget is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\"><dl>");
if ( model.get('header'))
{
buf.push("<dt>Header:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('header')) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('filter') === 'models')
{
buf.push("<dt>Models:</dt><dd>" + (jade.escape(null == (jade_interp = models) ? "" : jade_interp)) + "</dd>");
}
if ( model.get('filter') === 'usedVehicles')
{
buf.push("<dt>Used Vehicles:</dt><dd>" + (jade.escape(null == (jade_interp = usedVehicles) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"models" in locals_for_with?locals_for_with.models:typeof models!=="undefined"?models:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"usedVehicles" in locals_for_with?locals_for_with.usedVehicles:typeof usedVehicles!=="undefined"?usedVehicles:undefined));;return buf.join("");
}
const EditBaseItemView = require('../../../widget/views/item/edit-base')

module.exports = EditBaseItemView.extend({
  template: template,
  render: function() {
    const filter = this.model.get('filter')
    if (!filter) {
      EditBaseItemView.prototype.render.apply(this, arguments)
      return this
    }

    const service =
      filter === 'models'
        ? this.options.serviceLocator.modelService
        : this.options.serviceLocator.usedVehicleService
    const ids =
      filter === 'models'
        ? this.model.get('models')
        : this.model.get('usedVehicles')

    service.find('', { _id: { $in: ids } }, {}, [], (error, values) => {
      if (error) {
        this.options.serviceLocator.logger.error(error)
        return this
      }

      const itemFilter = filter === 'models' ? 'name' : 'registration'
      const vehicles = values.results.map(item => item[itemFilter])
      this.extraProperties[filter] = vehicles.toString()
      EditBaseItemView.prototype.render.apply(this, arguments)
    })
  }
})
