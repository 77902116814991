const initWidget = () => ({
  newsStripWidget: ['widget', 'sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'News Strip',
    itemView: require('./views/item'),
    description: ''
  }

  ;['section'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('newsStrip', widget)
  )

  done()
}

module.exports = initWidget
