const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')

const extraProperties = {
  title: {
    type: String,
    validators: [required]
  },
  text: {
    type: String
  },
  backgroundImages: {
    type: Array,
    defaultValue: () => []
  },
  heroImages: {
    type: Array,
    defaultValue: () => []
  },
  darkBackground: {
    type: Boolean
  }
}

module.exports = () => createSchema('Hero', extraProperties)
