const schemata = require('schemata')

module.exports = () =>
  schemata({
    name: 'Meta',
    properties: {
      key: {
        type: String
      },
      value: {
        type: String
      }
    }
  })
