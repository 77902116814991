const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (person, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('firstName', 'First Name', '', person.firstName, true, 'text');
jade_mixins["inputField"]('lastName', 'Last Name', '', person.lastName, true, 'text');
jade_mixins["inputField"]('emailAddress', 'Email Address', '', person.emailAddress, true, 'text');
buf.push("</div></div></form></div></div>");}.call(this,"person" in locals_for_with?locals_for_with.person:typeof person!=="undefined"?person:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New User' : 'Edit User',
        person: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }
}

module.exports = FormView
