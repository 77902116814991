"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isPrimitive = require('is-primitive');

var clone = require('lodash.clonedeep');

var SchemataArray = require('./lib/array');

var hasTag = require('./lib/has-tag');

var isSchemata = require('./lib/is-schemata');

var isSchemataArray = require('./lib/is-array');

var getType = require('./lib/type-getter');

var castArray = require('./lib/casters/array');

var castBoolean = require('./lib/casters/boolean');

var castDate = require('./lib/casters/date');

var castNumber = require('./lib/casters/number');

var castObject = require('./lib/casters/object');

var castString = require('./lib/casters/string');

var _require = require('./lib/validate'),
    validate = _require.validate,
    validateRecursive = _require.validateRecursive;

var convertCamelcaseToHuman = require('./lib/camelcase-to-human-converter');
/**
 * Casts a value to a given type.
 *
 * For booleans and integers; undefined, '', and null will all be cast to null
 * For array they will be converted to []
 * For object they will be converted to {}
 *
 * Throws error if type is undefined.
 *
 */


var castProperty = function castProperty(type, value, key, entityObject) {
  if (type === undefined) throw new Error('Missing type'); // First check whether the type of this property is
  // a sub-schema, or an array of sub-schemas

  var subSchema = getType(type, entityObject);

  if (isSchemata(subSchema)) {
    return value !== null ? subSchema.cast(value) : null;
  }

  if (isSchemataArray(type)) {
    if (!value) return null;
    if (!Array.isArray(value)) value = [value];
    return value.map(function (v) {
      return type.arraySchema.cast(v);
    });
  } // If the { type: x } is a primitive constructor, use
  // cast the value based on which constructor is found
  // JSHint doesn't like switch statements!

  /* jshint maxcomplexity: 13 */


  switch (type) {
    case Boolean:
      return castBoolean(value);

    case Number:
      return castNumber(value);

    case String:
      return castString(value);

    case Object:
      return castObject(value);

    case Date:
      return castDate(value);

    case Array:
      return castArray(value);

    default:
      return value;
  }
};

var createSchemata = function createSchemata() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      name = _ref.name,
      description = _ref.description,
      properties = _ref.properties;

  if (name === undefined) throw new Error('name is required');
  var internalSchema = clone(properties || {});
  Object.keys(internalSchema).forEach(function (k) {
    if (!properties[k].defaultValue) return;
    if (typeof properties[k].defaultValue === 'function') return;
    if (isPrimitive(properties[k].defaultValue)) return;
    throw new Error("The defaultValue for the schema property \"".concat(k, "\" must be either a primitive value or a function"));
  });
  return {
    getName: function getName() {
      return name;
    },
    getDescription: function getDescription() {
      return description;
    },
    getProperties: function getProperties() {
      return clone(internalSchema);
    },

    /*
     * Returns an object with properties defined in schema but with empty values.
     *
     * The empty value will depend on the type:
     * - Array = []
     * - Object = {}
     * - String = null
     * - Boolean = null
     * - Number = null
     */
    makeBlank: function makeBlank() {
      var newEntity = {};
      Object.keys(internalSchema).forEach(function (key) {
        var type = getType(internalSchema[key].type);

        if (_typeof(type) === 'object') {
          // If the type is a schemata instance use its makeBlank() function
          if (isSchemata(type)) {
            newEntity[key] = type.makeBlank();
            return null;
          } // If the type is a schemata array, create an empty array


          if (isSchemataArray(type)) {
            newEntity[key] = [];
            return null;
          }

          throw new Error("Invalid property type on '".concat(key, "'"));
        }

        switch (type) {
          case Object:
            newEntity[key] = {};
            return null;

          case Array:
            newEntity[key] = [];
            return null;

          default:
            newEntity[key] = null;
        }
      });
      return newEntity;
    },

    /*
     * Returns a new object with properties and default values from the schema definition.
     * If existingEntity is passed then extends it with the default properties.
     */
    makeDefault: function makeDefault(existingEntity) {
      var newEntity = this.makeBlank();
      if (!existingEntity) existingEntity = {};
      Object.keys(internalSchema).forEach(function (key) {
        var property = internalSchema[key];
        var existingValue = existingEntity[key];
        var type = getType(property.type, existingEntity); // If an existingEntity is passed then use its value
        // If it doesn't have that property then the default will be used.
        // If an existingEntity is a schemata instance it's own makeDefault() will
        // also be called so that partial sub-objects can be used.

        if (existingEntity !== undefined && existingEntity[key] !== undefined) {
          newEntity[key] = isSchemata(type) ? type.makeDefault(existingValue) : existingEntity[key];
          return;
        }

        switch (_typeof(property.defaultValue)) {
          case 'undefined':
            // If the property is a schemata instance use its makeDefault() function
            if (isSchemata(type)) {
              newEntity[key] = type.makeDefault(existingValue);
              return;
            } // In the absence of a defaultValue property the makeBlank() value is used


            return;

          case 'function':
            // In the case of a defaultValue() function, run it to create the default
            // value. This is important when using mutable values like Object and Array
            // which would be a reference to the schema's property if it were set as
            // property.defaultValue = Object|Array|Date
            newEntity[key] = property.defaultValue();
            return;

          default:
            // If defaultValue is a primitive value use it as-is
            newEntity[key] = property.defaultValue;
        }
      });
      return newEntity;
    },

    /*
     * Takes an object and strips out properties not in the schema. If a tag is given
     * then only properties with that tag will remain.
     */
    stripUnknownProperties: function stripUnknownProperties(entityObject, tag, ignoreTagForSubSchemas) {
      /* jshint maxcomplexity: 10 */
      var newEntity = {};
      Object.keys(entityObject).forEach(function (key) {
        var property = internalSchema[key];
        var subSchemaTag; // If the schema doesn't have this property, or if the property is in
        // the schema but doesn't have the given tag, don't keep it

        if (typeof property === 'undefined' || !hasTag(internalSchema, key, tag)) return;
        var type = getType(property.type, entityObject); // If the property is null, leave it alone

        if (entityObject[key] === null) {
          newEntity[key] = null;
          return;
        } // If the type is a schemata instance use its stripUnknownProperties() function


        if (isSchemata(type)) {
          subSchemaTag = ignoreTagForSubSchemas ? undefined : tag;
          newEntity[key] = type.stripUnknownProperties(entityObject[key], subSchemaTag, ignoreTagForSubSchemas);
          return;
        } // If this property is of a primitive type, keep it as is


        if (_typeof(property.type) !== 'object') {
          newEntity[key] = entityObject[key];
          return;
        } // If the type is a schemata array, call stripUnknownProperties() on each item in the array


        if (isSchemataArray(property.type)) {
          // The array can't be processed if it's not an array
          if (!Array.isArray(entityObject[key])) return; // Create a new array to copy items over to

          newEntity[key] = [];
          subSchemaTag = ignoreTagForSubSchemas ? undefined : tag;
          entityObject[key].forEach(function (item, index) {
            newEntity[key][index] = property.type.arraySchema.stripUnknownProperties(item, subSchemaTag, ignoreTagForSubSchemas);
          });
        }
      });
      return newEntity;
    },

    /*
     * Casts all the properties in the given entityObject that are defined in the schema.
     * If tag is provided then only properties that are in the schema and have the given tag will be cast.
     */
    cast: function cast(entityObject, tag) {
      var newEntity = {};
      Object.keys(entityObject).forEach(function (key) {
        // Copy all properties
        newEntity[key] = entityObject[key]; // Only cast properties in the schema and tagged, if tag is provided

        if (internalSchema[key] !== undefined && internalSchema[key].type && hasTag(internalSchema, key, tag)) {
          newEntity[key] = castProperty(internalSchema[key].type, entityObject[key], key, entityObject);
        }
      });
      return newEntity;
    },
    validate: validate(internalSchema),
    validateRecursive: validateRecursive(internalSchema),

    /*
     * Returns the human readable name for a particular property.
     */
    propertyName: function propertyName(property) {
      if (internalSchema[property] === undefined) throw new RangeError("No property '".concat(property, "' in schema"));
      return internalSchema[property].name === undefined ? convertCamelcaseToHuman(property) : internalSchema[property].name;
    }
  };
};

createSchemata.Array = SchemataArray;
createSchemata.castProperty = castProperty;
module.exports = createSchemata;