module.exports={
  "vehicle": {
    "ownershipCondition": "Used",
    "registration": "AB12CDE",
    "vin": "WAUZXXXXXXXXX12345",
    "make": "Audi",
    "model": "A4",
    "generation": "Saloon (2011 - 2016) B8 Facelift",
    "derivative": "2.0 TDI ultra S line Saloon 4dr Diesel Manual (109 g/km, 161 bhp)",
    "derivativeId": "09ab072a937c3594b5e8c64251bde2eb",
    "vehicleType": "Car",
    "trim": "S line",
    "bodyType": "Saloon",
    "fuelType": "Diesel",
    "cabType": null,
    "transmissionType": "Manual",
    "wheelbaseType": null,
    "roofHeightType": null,
    "drivetrain": "Front Wheel Drive",
    "seats": 5,
    "doors": 4,
    "co2EmissionGPKM": 109,
    "topSpeedMPH": 140,
    "zeroToSixtyMPHSeconds": 8.3,
    "badgeEngineSizeLitres": 2.0,
    "engineCapacityCC": 1968,
    "enginePowerBHP": 161,
    "fuelCapacityLitres": 63.0,
    "emissionClass": "Euro 6",
    "fuelEconomyNEDCExtraUrbanMPG": 74.3,
    "fuelEconomyNEDCUrbanMPG": 56.5,
    "fuelEconomyNEDCCombinedMPG": 67.3,
    "fuelEconomyWLTPLowMPG": null,
    "fuelEconomyWLTPMediumMPG": null,
    "fuelEconomyWLTPHighMPG": null,
    "fuelEconomyWLTPExtraHighMPG": null,
    "fuelEconomyWLTPCombinedMPG": null,
    "bootSpaceSeatsUpLitres": 480.0,
    "insuranceGroup": "29",
    "insuranceSecurityCode": "E",
    "firstRegistrationDate": "2015-09-15",
    "colour": "Black",
    "style": null,
    "subStyle": null,
    "lengthMM": 4701,
    "heightMM": 1427,
    "widthMM": 2040,
    "payloadLengthMM": null,
    "payloadWidthMM": null,
    "payloadHeightMM": null,
    "payloadWeightKG": null,
    "minimumKerbWeightKG": 1540,
    "grossVehicleWeightKG": null,
    "engineNumber": "CNHC064654",
    "fuelDelivery": "Direct Injection",
    "gears": 6,
    "valves": 16,
    "startStop": false,
    "enginePowerPS": null,
    "engineTorqueNM": 400,
    "engineTorqueLBFT": 294.99,
    "bootSpaceSeatsDownLitres": 962.0,
    "batteryChargeTime": null,
    "batteryQuickChargeTime": null,
    "batteryRangeMiles": null,
    "batteryCapacityKWH": null,
    "batteryUsableCapacityKWH": null,
    "wheelbaseMM": 2808,
    "grossCombinedWeightKG": null,
    "grossTrainWeightKG": null,
    "boreMM": 81,
    "strokeMM": 96,
    "cylinders": 4,
    "cylinderArrangement": "Inline",
    "engineMake": "Audi",
    "valveGear": "Double Overhead Camshaft",
    "axles": 2,
    "countryOfOrigin": "Germany",
    "driveType": "4X2",
    "payloadVolumeCubicMetres": null,
    "oem": {
      "make": null,
      "model": null,
      "derivative": null,
      "bodyType": null,
      "transmissionType": null,
      "drivetrain": null,
      "wheelbaseType": null,
      "roofHeightType": null,
      "engineType": "TDI",
      "engineTechnology": "ultra",
      "engineMarketing": null,
      "editionDescription": null,
      "colour": null
    }
  },
  "motTests": [
    {
      "completedDate": "2018-07-23T10:19:33Z",
      "expiryDate": "2019-08-21",
      "testResult": "Passed",
      "odometerValue": 70642,
      "odometerUnit": "Miles",
      "motTestNumber": "1234567890",
      "rfrAndComments": [
        {
          "type": "Advisory",
          "text": "Offside Front Inner Tyre worn close to legal limit/worn on edge (5.2.3 (e))",
          "dangerous": false
        }
      ]
    },
    {
      "completedDate": "2017-08-11T14:04:28Z",
      "expiryDate": "2018-08-21",
      "testResult": "Passed",
      "odometerValue": 59101,
      "odometerUnit": "Miles",
      "motTestNumber": "803160337856",
      "rfrAndComments": []
    }
  ],
  "features": [
    {
      "name": "3 - Spoke Leather Steering Wheel",
      "type": "Standard",
      "category": "Comfort",
      "basicPrice": 0.0,
      "vatPrice": 0.0
    },
    {
      "name": "6.5in Colour Display Screen",
      "type": "Standard",
      "category": "Comfort",
      "basicPrice": 0.0,
      "vatPrice": 0.0
    },
    {
      "name": "Active Cruise Control with Stop and Go Function",
      "type": "Optional",
      "category": "Comfort",
      "basicPrice": 1108.33,
      "vatPrice": 221.67
    }
  ],
  "check": {
    "insuranceWriteoffCategory": null,
    "scrapped": false,
    "stolen": false,
    "imported": false,
    "exported": false,
    "previousOwners": 3,
    "keeperChanges": [
      {
        "dateOfLastKeeper": "2016-08-30"
      },
      {
        "dateOfLastKeeper": "2013-11-02"
      },
      {
        "dateOfLastKeeper": "2012-07-25"
      }
    ],
    "v5cs": [
      {
        "issuedDate": "2017-04-13"
      }
    ],
    "highRisk": false,
    "privateFinance": true,
    "tradeFinance": false,
    "mileageDiscrepancy": false,
    "registrationChanged": false,
    "colourChanged": false,
    "dvlaVehicle": {
      "make": "BMW",
      "model": "420D GRAN COUPE M SPORT A",
      "bodyType": "COUPE",
      "fuelType": "DIESEL",
      "transmissionType": null,
      "engineCapacityCC": 1995,
      "colour": "GREY",
      "co2EmissionsGKM": 114,
      "dateScrapped": null,
      "dateExported": null
    },
    "policeStolenMarker": {
      "recordedDate": null,
      "policeForce": null,
      "telephoneNumber": null
    },
    "financeAgreements": [
      {
        "agreementId": "1234ABC",
        "company": "ABS123/0001",
        "telephoneNumber": "UNKNOWN",
        "startDate": "2019-04-13",
        "term": 36,
        "type": "CONTRACT HIRE"
      },
      {
        "agreementId": "5678DEF",
        "company": "DEF456/0001",
        "telephoneNumber": "01234 567 890",
        "startDate": "2016-10-20",
        "term": 36,
        "type": "CONTRACT HIRE"
      }
    ],
    "plateChanges": [],
    "colourChanges": [],
    "odometerReadings": [
      {
        "performed": "2019-01-18",
        "source": "RMI MIL'GE",
        "odometerReadingMiles": 16615
      }
    ],
    "highRiskMarkers": [],
    "previousSearches": [
      {
        "performed": "2020-03-20T09:38:50Z",
        "typeOfBusiness": "MOTOR TRADE & OTHER"
      },
      {
        "performed": "2019-03-20T09:38:50Z",
        "typeOfBusiness": "AUCTION CO."
      },
      {
        "performed": "2018-03-20T09:38:50Z",
        "typeOfBusiness": "INSURER"
      }
    ],
    "insuranceHistory": []
  },
  "valuations": {
    "retail": {
      "amountGBP": 10143,
      "amountExcludingVatGBP": 8114
    },
    "trade": {
      "amountGBP": 8038
    },
    "partExchange": {
      "amountGBP": 7830
    },
    "private": {
      "amountGBP": 8860
    }
  },
  "vehicleMetrics": {
    "retail": {
      "supply": {
        "value": 1.84
      },
      "demand": {
        "value": 1.88
      },
      "marketCondition": {
        "value": 0.02
      },
      "rating": {
        "value": 49.48
      },
      "daysToSell": {
        "value": 50.6
      }
    }
  }
}
