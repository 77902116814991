const colors = [
  {
    name: 'Citygate Asphalt',
    backgroundColor: '#1F2C36',
    textColor: '#FFFFFF'
  },
  {
    name: 'Citygate Petrol',
    backgroundColor: '#035D67',
    textColor: '#FFFFFF'
  },
  {
    name: 'Citygate Light Grey',
    backgroundColor: '#E6E2E7',
    textColor: '#000000'
  },
  {
    name: 'White',
    backgroundColor: '#FFFFFF',
    textColor: '#000000'
  },
  {
    name: 'VW',
    backgroundColor: '#00B0F0',
    textColor: '#FFFFFF'
  },
  {
    name: 'VWCV',
    backgroundColor: '#001E50',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA',
    backgroundColor: '#95572B',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA Petrol',
    backgroundColor: '#003E51',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA Oxygen White',
    backgroundColor: '#F8F8F8',
    textColor: '#000000'
  },
  {
    name: 'SEAT Orange',
    backgroundColor: '#EA5D1A',
    textColor: '#FFFFFF'
  },
  {
    name: 'SEAT Grey',
    backgroundColor: '#DEDAD4',
    textColor: '#000000'
  },
  {
    name: 'Kia',
    backgroundColor: '#193041',
    textColor: '#FFFFFF'
  },
  {
    name: 'SKODA Green',
    backgroundColor: '#4BA82E',
    textColor: '#FFFFFF'
  },
  {
    name: 'Go Electric',
    backgroundColor: '#97F5FB',
    textColor: '#0D0F0D'
  }
]

module.exports = {
  colors,
  findColor: backgroundColor => {
    return colors.find(color => color.backgroundColor === backgroundColor)
  }
}
