const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"js-item list-item\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a class=\"btn btn--small js-remove-item\">Remove</a></div></div><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = data.heading) ? "" : jade_interp)) + "</a></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}

class ItemRepeaterItemView extends BaseItemRepeaterItemView {
  constructor(serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
  }
}

module.exports = ItemRepeaterItemView
