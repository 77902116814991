const createSchema = require('../../../../service/widget/model/base-widget')
const BaseModel = require('cf-base-model')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

const extraProperties = {
  faqTags: {
    type: Array,
    defaultValue: () => []
  },
  title: {
    type: String
  },
  text: {
    type: String
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('FAQ Tags Selector', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'faqTags' })
  },
  validate: validateDelegate
})
