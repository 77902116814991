module.exports = ComparisonView
const View = require('ventnor')
const modal = require('modal')
const RawView = require('./raw')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (format, model, undefined) {
buf.push("<p>Comparing: " + (jade.escape((jade_interp = format(model.oldRevision.createdDate, 'calendar')) == null ? '' : jade_interp)) + " by " + (jade.escape((jade_interp = model.oldRevision.author) == null ? '' : jade_interp)) + " with " + (jade.escape((jade_interp = format(model.newRevision.createdDate, 'calendar')) == null ? '' : jade_interp)) + " by " + (jade.escape((jade_interp = model.newRevision.author) == null ? '' : jade_interp)) + "</p><button type=\"button\"" + (jade.attr("data-revision-id", model.oldRevision._id, true, false)) + " class=\"btn btn--small js-restore-revision\">Restore " + (jade.escape((jade_interp = format(model.oldRevision.createdDate, 'calendar')) == null ? '' : jade_interp)) + " by " + (jade.escape((jade_interp = model.oldRevision.author) == null ? '' : jade_interp)) + "</button><table class=\"table--striped\"><tr><th>Field</th><th>Old</th><th>New</th><th>Action</th></tr>");
// iterate model.differences
;(function(){
  var $$obj = model.differences;
  if ('number' == typeof $$obj.length) {

    for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
      var item = $$obj[index];

if ( item.old || item.new)
{
buf.push("<tr><td>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.old) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.new) ? "" : jade_interp)) + "</td><td>");
if ( item.raw)
{
buf.push("<button type=\"button\"" + (jade.attr("data-index", index, true, false)) + " class=\"btn btn--small js-view-raw\">View Raw</button>");
}
buf.push("</td></tr>");
}
    }

  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;      var item = $$obj[index];

if ( item.old || item.new)
{
buf.push("<tr><td>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.old) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.new) ? "" : jade_interp)) + "</td><td>");
if ( item.raw)
{
buf.push("<button type=\"button\"" + (jade.attr("data-index", index, true, false)) + " class=\"btn btn--small js-view-raw\">View Raw</button>");
}
buf.push("</td></tr>");
}
    }

  }
}).call(this);

buf.push("</table>");}.call(this,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

function ComparisonView(serviceLocator, model) {
  View.apply(this, arguments)
  this.serviceLocator = serviceLocator
  this.model = model
  this.$el.on('click', '.js-restore-revision', this.restoreRevision.bind(this))
  this.$el.on('click', '.js-view-raw', this.viewRaw.bind(this))
}

ComparisonView.prototype = Object.create(View.prototype)

ComparisonView.prototype.restoreRevision = function(e) {
  const revisionId = $(e.currentTarget).data('revisionId')
  let index = null

  $('[data-revision-id="' + revisionId + '"]').each(function(i, el) {
    if ($(el).data('index')) {
      index = $(el).data('index')
    }
  })

  this.emit('restoreRevision', revisionId, index)
}

ComparisonView.prototype.viewRaw = function(e) {
  const itemIndex = $(e.currentTarget).data('index')
  const view = new RawView(
    this.serviceLocator,
    this.model.differences[itemIndex]
  ).render()

  modal({
    title: 'Raw Differences',
    className: 'wide',
    content: view.$el,
    buttons: [{ text: 'Close', className: 'btn', keyCodes: [27] }]
  })
}

ComparisonView.prototype.render = function() {
  this.$el.append(
    template({ model: this.model, format: this.serviceLocator.format })
  )
  return this
}
