module.exports = initWidget

function initWidget() {
  return { htmlWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  var htmlWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'HTML',
    itemView: require('./views/item'),
    description: 'Block of HTML'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('html', htmlWidget)
  )

  done()
}
