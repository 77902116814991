const platforms = {
  youtube: {
    name: 'YouTube Video',
    link: id => `https://www.youtube.com/watch?v=${id}`,
    embedUrl: id => `https://www.youtube.com/embed/${id}`
  },

  getVideoUrls(video = {}) {
    const { provider, id } = video
    const platform = platforms[provider]

    if (!platform) return

    return { url: platform.link(id), embedUrl: platform.embedUrl(id) }
  }
}

module.exports = platforms
