const CrudService = require('../../../../admin/source/js/lib/crud-service')

class UsedVehicleService extends CrudService {
  get name() {
    return 'NewVehicleImportService'
  }
  get urlRoot() {
    return '/new-vehicle-import'
  }
}

module.exports = UsedVehicleService
