const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/redirect')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const RedirectService = require('./services/redirect')

const initAdmin = () => ({ redirectAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.redirectService = new RedirectService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'redirect',
    plural: 'redirects',
    path: 'redirects',
    service: serviceLocator.redirectService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
