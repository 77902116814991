const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/person')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const PersonService = require('./services/person')

const initAdmin = () => ({ personAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.personService = new PersonService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'person',
    plural: 'persons',
    path: 'persons',
    service: serviceLocator.personService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
