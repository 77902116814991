module.exports = initAdmin
const createController = require('./controllers/controller')

function initAdmin() {
  return { menuBar: init }
}

function init(serviceLocator, done) {
  createController(serviceLocator)
  done()
}
