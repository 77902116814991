const debug = require('../debug')('generic list view')
const BaseView = require('cf-base-view')
const listDeleteDelegate = require('../list-delete-delegate')
const listSelectDelegate = require('../list-select-delegate')
const interfaceCheck = require('../interface-check')

module.exports = BaseView.extend({
  events: {
    'click .js-toolbar .js-new': 'handleNew',
    'click .js-toolbar .js-delete': 'handleDelete'
  },

  itemContainer: '.js-items',

  initialize(options) {
    this.options = options
    // Ensure the implemented class has the correct interface
    ;['createListItemView', 'template'].forEach(interfaceCheck.bind(this))

    this.render()
    this.listenTo(this.collection, 'add', this.addItem, this)
    this.listenTo(this.collection, 'change', this.updateItem, this)
    this.listenTo(this.collection, 'remove', this.removeItem, this)
    this.listenTo(this.collection, 'filter', this.updatePagination)

    this.selectedCollection = listSelectDelegate.call(this)

    this.listenTo(this.selectedCollection, 'add', this.onSelectionChange)
    this.listenTo(this.selectedCollection, 'remove', this.onSelectionChange)
    this.listenTo(this.selectedCollection, 'reset', this.onSelectionChange)
  },

  addItem(model) {
    debug('add list item', model)
    const $items = this.$(this.itemContainer)
    const newItem = this.createListItemView(model)
    this.attachView(newItem)
    newItem.on('edit', this.trigger.bind(this, 'edit', model))
    newItem.on('delete', this.trigger.bind(this, 'delete', model))

    this.trigger('newListItem', newItem, model)
    $items.append(newItem.$el)
  },

  updateItem(model) {
    debug('update list item', model)
    this.getViewByModel(model)
  },

  removeItem(model) {
    debug('remove list item', model)
    var view = this.getViewByModel(model)
    if (view) view.remove()
  },

  handleNew() {
    debug('new')
    this.trigger('new')
  },

  handleDelete() {
    debug('delete')
    listDeleteDelegate.call(this, this.selectedCollection)
  },

  render() {
    this.$el.empty().append(
      this.template({
        displayName: this.options.displayName,
        allowed:
          this.options.serviceLocator && this.options.serviceLocator.allowed
      })
    )
    return this
  },

  maintainOrder() {
    debug('Maintaining order')
    let cursor = null
    this.collection.each(function(model) {
      var view = this.getViewByModel(model)
      if (cursor) {
        cursor.after(view.$el)
      } else {
        this.$(this.itemContainer).prepend(view.$el)
      }
      cursor = view.$el
    }, this)
  },

  updatePagination() {
    debug('Updating pagination')
    if (
      this.collection.currentPage * this.collection.pageSize <
      this.collection.totalItems
    ) {
      this.$('.js-more').show()
    } else {
      this.$('.js-more').hide()
    }
    this.$('.js-item-count').text(this.collection.length)
    this.$('.js-total-item-count').text(this.collection.totalItems)
  }
})
