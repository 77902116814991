const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (item, undefined) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--shortHeadline\" data-field=\"shortHeadline\" class=\"form-row\"><label><span class=\"form-label-text\">Text<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"shortHeadline\"" + (jade.attr("value", item.shortHeadline, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--link\" data-field=\"link\" class=\"form-row\"><label><span class=\"form-label-text\">Link<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", item.link, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row form-row-boolean\"><span class=\"form-label-text\">Display Type</span><div class=\"form-field\"><ul><li><label><input type=\"radio\" name=\"type\" value=\"\" class=\"control control--boolean\"/><span>Default</span></label></li>");
// iterate { twitter: 'Twitter', facebook: 'Facebook', instagram: 'Instagram' }
;(function(){
  var $$obj = { twitter: 'Twitter', facebook: 'Facebook', instagram: 'Instagram' };
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var text = $$obj[key];

buf.push("<li><label><input type=\"radio\" name=\"type\"" + (jade.attr("value", key, true, false)) + (jade.attr("checked", item.type == key, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = text) ? "" : jade_interp)) + "</span></label></li>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var text = $$obj[key];

buf.push("<li><label><input type=\"radio\" name=\"type\"" + (jade.attr("value", key, true, false)) + (jade.attr("checked", item.type == key, true, false)) + " class=\"control control--boolean\"/><span>" + (jade.escape(null == (jade_interp = text) ? "" : jade_interp)) + "</span></label></li>");
    }

  }
}).call(this);

buf.push("</ul></div><div class=\"js-error\"></div></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
    // Compatibility with the form errors delegate
    this.$ = selector => this.$el.find(selector)
    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors
  }

  save() {
    this.clearErrors()
    this.model.validate((err, errors) => {
      if (err) return alert(err.message)
      if (Object.keys(errors).length) {
        this.showErrors(errors)
        return this.emit('error')
      }
      this.emit('save')
    })
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Item' : 'Edit Item',
        item: this.model.toJSON()
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.$el.find('.js-save-item').on('click', () => this.save())
    this.$el.find('.js-cancel-item').on('click', () => this.emit('cancel'))

    return this
  }
}

module.exports = FormView
