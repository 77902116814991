const notify = require('../../notification/foreground')

const baseFormController = ({
  serviceLocator,
  service,
  path,
  singular,
  Model,
  FormView
}) => {
  // Create
  serviceLocator.router.route(`${path}/form`, `create${singular}`, () => {
    if (!serviceLocator.allow(singular, 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    const form = new FormView(serviceLocator, model, true).render()

    serviceLocator.router.render(form, `Vehicle Collection Form`)

    form.on('saveAndReset', cb =>
      saveNew(form, err => {
        if (err) return cb(err)

        form.emit('saved')
        notify('Saved', 'save')

        model.set(model.schemata.makeDefault())
        form.render()

        return cb()
      })
    )
  })

  const saveNew = (form, cb) => {
    service.create(form.model.toJSON(), (err, entity) => {
      if (err) {
        form.showErrors(err.errors || [err])
        return cb(err)
      }
      form.clearUnsavedChanges()
      cb(null, entity)
    })
  }
}

module.exports = baseFormController
