const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')
const validateInteger = require('validity-integer')
const validateIfSet = require('validity-validate-if-set')
const validateIfPropertySet = require('validity-validate-if-property-set')

const extraProperties = {
  lists: {
    type: Array,
    validators: [required]
  },
  heading: { type: String },
  subheading: { type: String },
  emptyMessage: { type: String },
  dedupe: { type: Boolean, defaultValue: true },
  limit: {
    type: Number,
    validators: [validateIfSet(validateInteger)]
  },
  callToActionText: {
    type: String,
    validators: [validateIfPropertySet('callToActionLink', required)]
  },
  callToActionLink: { type: String },
  showArticleProperties: {
    type: Array,
    defaultValue: () => ['attributes', 'sell']
  }
}

module.exports = createSchema('List', extraProperties)
