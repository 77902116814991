const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, format, subtitle, title) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\"></div><div class=\"multiline-title\"><h2>" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h2><small>" + (jade.escape((jade_interp = subtitle) == null ? '' : jade_interp)) + " </small>");
if ( data.vehicle)
{
buf.push("" + (jade.escape((jade_interp = ' - ') == null ? '' : jade_interp)) + "<a" + (jade.attr("href", "/vehicles/" + (data.vehicle) + "/form", true, false)) + " target=\"_blank\">Edit on Auto.Works</a>");
}
if ( data.link)
{
buf.push("" + (jade.escape((jade_interp = ' - ') == null ? '' : jade_interp)) + " <a" + (jade.attr("href", data.link, true, false)) + " target=\"_blank\">View on Modix</a>");
}
buf.push("</div></div><div class=\"list-item-content\"><p>Failure Reasons:</p><ul class=\"list--unstyled\">");
if ( data.stats.vehicleNameMismatch)
{
buf.push("<li class=\"feature\"><div><div><strong>Vehicle Name Mismatch</strong></div><div class=\"footnote\"><pre>Modix Feed: " + (jade.escape((jade_interp = data.stats.names.scraped) == null ? '' : jade_interp)) + " \nAuto.Works: " + (jade.escape((jade_interp = data.stats.names.vehicle) == null ? '' : jade_interp)) + "</pre></div></div></li>");
}
if ( data.stats.priceMismatch)
{
buf.push("<li class=\"feature\"><div><div><strong>Price Mismatch</strong></div><div class=\"footnote\"><pre>Modix Feed: " + (jade.escape((jade_interp = data.stats.prices.scraped) == null ? '' : jade_interp)) + " \nAuto.Works: " + (jade.escape((jade_interp = data.stats.prices.vehicle) == null ? '' : jade_interp)) + "</pre></div></div></li>");
}
if ( data.stats.missingImages)
{
buf.push("<li class=\"feature\"><div><div><strong>Missing Images</strong></div><div class=\"footnote\">Expected 20 images, found " + (jade.escape((jade_interp = data.images || 0) == null ? '' : jade_interp)) + "</div></div></li>");
}
if ( data.stats.vehicleNotFound        )
{
buf.push("<li class=\"feature\"><div><div><strong>Vehicle Not Found</strong></div><div class=\"footnote\">Vehicle is on Modix but not in Auto.Works</div></div></li>");
}
if ( data.stats.missingOnSource)
{
buf.push("<li class=\"feature\"><div><div><strong>Missing on Modix</strong></div><div class=\"footnote\">Vehicle is on Auto.Works but not on Modix</div></div></li>");
}
buf.push("</ul></div><div class=\"list-item-content\"><dl class=\"list--rows\"><dt>Found:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Last Checked:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.lastUpdatedDate, 'calendar')) ? "" : jade_interp)) + "</dd><dt>Source: </dt><dd>" + (jade.escape(null == (jade_interp = data.source) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"subtitle" in locals_for_with?locals_for_with.subtitle:typeof subtitle!=="undefined"?subtitle:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, index, totalItems, dealers) {
    super(...arguments)

    this.dealers = dealers
  }

  get template() {
    return template
  }

  render() {
    const data = this.model.toJSON()
    if (data.dealership) {
      data.dealership = this.dealers.find(
        dealer => dealer._id === data.dealership
      )
    } else {
      data.dealership = {}
    }

    let title = data.title
    let subtitle = data.dealership.location

    if (data.registration) {
      title += ` (${data.registration})`
    }

    if (!title) {
      title += 'Unknown Vehicle'
    }

    this.$el.empty().append(
      this.template({
        data,
        title,
        subtitle,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems
      })
    )
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
