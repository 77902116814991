module.exports = images => {
  return (
    (images &&
      images.widgets &&
      images.widgets.map(image => {
        const crop = image.crops && image.crops[0]

        if (!crop) {
          return
        }

        return crop.src
      })) ||
    []
  )
}
