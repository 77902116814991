module.exports=[
  {
    "id": 1183,
    "range": "Alhambra"
  },
  {
    "id": 1184,
    "range": "Altea"
  },
  {
    "id": 2909,
    "range": "Arona"
  },
  {
    "id": 2299,
    "range": "Ateca"
  },
  {
    "id": 1481,
    "range": "Exeo"
  },
  {
    "id": 2932,
    "range": "Ibiza"
  },
  {
    "id": 2749,
    "range": "Ibiza 3 Door SC"
  },
  {
    "id": 2750,
    "range": "Ibiza 5 Door"
  },
  {
    "id": 2753,
    "range": "Leon 5 Door"
  },
  {
    "id": 2754,
    "range": "Leon Estate"
  },
  {
    "id": 3636,
    "range": "Leon Estate e-Hybrid"
  },
  {
    "id": 2752,
    "range": "Leon SC"
  },
  {
    "id": 3635,
    "range": "Leon e-Hybrid"
  },
  {
    "id": 2744,
    "range": "Mii 5 Door"
  },
  {
    "id": 3234,
    "range": "Mii electric"
  },
  {
    "id": 3084,
    "range": "Tarraco"
  },
  {
    "id": 1193,
    "range": "Toledo"
  }
]
