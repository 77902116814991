const schemata = require('schemata')

module.exports = () => {
  const schema = schemata({
    name: 'CDK Data',
    properties: {
      _id: {
        type: String
      },
      name: { type: String },
      type: { type: String },
      userId: { type: String },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
