const compileJade = require('browjadify-compile')
const { join } = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (compact, data, format) {
buf.push("<div class=\"list-item-small-margin\"><div class=\"list-item-header-small-padding\"><div class=\"list-item-actions\"><label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label></div><a href=\"#base\" class=\"js-edit multiline-title\"><span>" + (jade.escape((jade_interp = data.value || '<blank entry -- spacer item>') == null ? '' : jade_interp)) + "</span></a></div>");
if ( !compact)
{
buf.push("<div class=\"list-item-content\"><dl><dt>Category:</dt><dd>" + (jade.escape(null == (jade_interp = data.category) ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div>");
}
buf.push("</div>");}.call(this,"compact" in locals_for_with?locals_for_with.compact:typeof compact!=="undefined"?compact:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const pickerTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
if ( data.value)
{
buf.push("<div class=\"list-item-small-margin\"><div class=\"list-item-header-small-padding\"><div class=\"list-item-actions\"><div class=\"list-item-select\"><input" + (jade.attr("name", "checkbox" + data._id, true, false)) + (jade.attr("id", "checkbox" + data._id, true, false)) + " type=\"checkbox\" class=\"js-select\"/></div></div><div" + (jade.attr("for", "checkbox" + data._id, true, false)) + " class=\"js-edit multiline-title\"><strong>" + (jade.escape((jade_interp = data.value) == null ? '' : jade_interp)) + "</strong></div></div></div>");
}}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const BaseListItemView = require('../../base/views/list-item')

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, index, totalItems, compact) {
    super(...arguments)

    this.compact = compact

    if (compact) {
      this.$el.on('click', '.list-item-small-margin', e => this.toggleCheck(e))
      this.$el.on('click', '.js-select', e => this.toggleCheck(e))
    }
  }

  get template() {
    return this.compact ? pickerTemplate : template
  }

  toggleCheck() {
    const check = this.$el.find('.js-select')[0]
    check.checked = !check.checked

    this.model.emit(check.checked ? 'select' : 'deSelect', this.model)
  }

  render() {
    this.$el.empty().append(
      this.template({
        data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        index: this.index,
        totalItems: this.totalItems,
        compact: this.compact
      })
    )
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
