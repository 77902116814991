module.exports = initWidget

function initWidget() {
  return { offerDetailsTable: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  var textWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Offer Details Table',
    itemView: require('./views/item'),
    description:
      'Adds a table to the site which displays the details of an offer'
  }

  ;['section', 'offerBody', 'articleBody', 'dealershipBody'].forEach(type =>
    serviceLocator.widgetFactories
      .get(type)
      .register('offerDetailsTable', textWidget)
  )

  done()
}
