const createFetch = require('../../../../admin/source/js/lib/authed-fetch')
const config = window.config

module.exports = () => {
  const fetch = createFetch(config.apiUrl)

  return async id => {
    const req = await fetch('POST', '/plate-recogniser/process-image', { id })

    return await req.json()
  }
}
