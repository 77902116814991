const availableTagTypes = require('../../../service/faq/tag-config.json')
const TagCheckbox = require('../../tag/views/tag-checkbox')
const TagSelect = require('../../tag/views/tag-select')
const View = require('ventnor')
const join = require('path').join
const compileJade = require('browjadify-compile')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (availableTagTypes, undefined) {
// iterate availableTagTypes
;(function(){
  var $$obj = availableTagTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var tagType = $$obj[$index];

if ( tagType.inputStyle === 'textbox')
{
buf.push("<div class=\"form-row\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['form-field','js-tags-' + tagType.type.toLowerCase()], [null,true])) + "></div></div>");
}
else
{
buf.push("<div class=\"form-row form-row-boolean\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['form-field','js-tags-' + tagType.type.toLowerCase()], [null,true])) + "></div></div>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var tagType = $$obj[$index];

if ( tagType.inputStyle === 'textbox')
{
buf.push("<div class=\"form-row\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['form-field','js-tags-' + tagType.type.toLowerCase()], [null,true])) + "></div></div>");
}
else
{
buf.push("<div class=\"form-row form-row-boolean\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['form-field','js-tags-' + tagType.type.toLowerCase()], [null,true])) + "></div></div>");
}
    }

  }
}).call(this);
}.call(this,"availableTagTypes" in locals_for_with?locals_for_with.availableTagTypes:typeof availableTagTypes!=="undefined"?availableTagTypes:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class TagSelector extends View {
  constructor(serviceLocator, model, fieldName, readOnly) {
    super(serviceLocator)

    this.model = model

    this.fieldName = fieldName || 'tags'
    this.readOnly = readOnly

    this.setUpTagControls()
  }

  setUpTagControls() {
    this.tagControls = []

    availableTagTypes.forEach(tagType => {
      let tagControl
      let readOnly
      switch (tagType.inputStyle) {
        case 'checkbox':
          tagControl = new TagCheckbox(
            this.serviceLocator,
            this.model.get(this.fieldName),
            tagType.type
          )
          tagControl.loadTags()
          break
        case 'textbox':
          readOnly =
            this.readOnly ||
            tagType.readOnly ||
            !this.serviceLocator.allowed('tag', 'create')

          tagControl = new TagSelect(
            this.serviceLocator,
            this.model.get(this.fieldName),
            tagType.type,
            readOnly
          )
          tagControl.loadTags()
          break
      }
      tagControl.on('change', this.updateTags.bind(this))
      this.tagControls.push(tagControl)
      this.attachView(tagControl)
    })
  }

  /*
   * Any time a tag input changes, update the model
   */
  updateTags() {
    let tags = []
    this.tagControls.forEach(tagControl => {
      tags = tags.concat(tagControl.tags.toJSON())
    })
    return this.model.set(this.fieldName, tags)
  }

  renderTagControls() {
    this.tagControls.forEach(tagControl => {
      this.$el
        .find('.js-tags-' + tagControl.tagType.toLowerCase())
        .append(tagControl.render().$el)
    })
  }

  render() {
    this.$el.append(
      template({
        availableTagTypes
      })
    )

    this.renderTagControls()

    return this
  }
}
module.exports = TagSelector
