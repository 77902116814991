const BaseModel = require('cf-base-model')
const schemata = require('schemata')
const required = require('validity-required')
const ImageAreaModel = require('../../../../asset/models/image-area')
const urlOrPathValidator = require('../../../../../service/redirect/lib/url-or-path-validator')

const properties = {
  title: { type: String, validators: [required] },
  backgroundColor: { type: String },
  textColor: { type: String },
  link: { type: String, validators: [required, urlOrPathValidator] },
  images: {
    type: Array,
    validators: [required],
    defaultValue: () => []
  }
}

module.exports = BaseModel.extend({
  schemata: schemata({ name: 'Item', properties }),

  defaults() {
    return this.schemata.makeDefault()
  },
  validate(cb) {
    this.schemata.validate(this.attributes, (ignoreErr, errors) => {
      return cb(Object.keys(errors).length > 0 ? errors : undefined)
    })
  },
  initialize: function() {
    ;['images'].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.attributes[imageType])

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }
})
