const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model, name, platforms) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\">");
if ( model.get('liveDate') || model.get('expiryDate'))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", "<strong>Live:</strong> " + ( model.get('liveDate') ? model.get('liveDate') : 'Not Set' ) + "<br><strong>Expires:</strong> " + ( model.get('expiryDate') ? model.get('expiryDate') : 'Not Set' ) + "", true, false)) + " class=\"label label--large label--inverse js-tooltip-trigger\"><i class=\"icon icon--clock\"></i></span>");
}
if ( model.get('visible'))
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Visible&lt;/strong&gt;&lt;br&gt;This widget is visible on the site.\" class=\"label label--large label--standard js-tooltip-trigger\"><i class=\"icon icon--published\"></i></span>");
}
else
{
buf.push("<span data-toggle=\"tooltip\" title=\"&lt;strong&gt;Hidden&lt;/strong&gt;&lt;br&gt;This widget will not appear on the site.\" class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--draft\"></i></span>");
}
buf.push("</div><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a data-text-expand=\"Expand\" data-text-collapse=\"Collapse\" class=\"js-expander-toggle\">Collapse</a></li><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content expander-content\">");
var platform = platforms[model.get('provider')]
if ( !platform)
{
buf.push("<p>Video </p>");
}
else
{
buf.push("<div class=\"grid\"><div class=\"grid__item one-third\"><iframe width=\"100%\"" + (jade.attr("src", platform.embedUrl(model.get('videoId')), true, false)) + "></iframe></div><div class=\"grid__item two-thirds\"><dl><dt>Video Provider:</dt><dd>" + (jade.escape(null == (jade_interp = platform.name) ? "" : jade_interp)) + "</dd><dt>Video ID:</dt><dd>" + (jade.escape(null == (jade_interp = model.get('videoId')) ? "" : jade_interp)) + "</dd><dt>Link to Video:</dt>");
var link = platform.link(model.get('videoId'))
buf.push("<dd><a" + (jade.attr("href", link, true, false)) + " target=\"_blank\">" + (jade.escape(null == (jade_interp = link) ? "" : jade_interp)) + "</a></dd></dl></div></div>");
}
buf.push("</div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"platforms" in locals_for_with?locals_for_with.platforms:typeof platforms!=="undefined"?platforms:undefined));;return buf.join("");
}
const EditBaseItemView = require('../../../widget/views/item/edit-base')
const platforms = require('../../../../common/video/platforms')

module.exports = EditBaseItemView.extend({
  template: template,
  extraProperties: platforms,
  render: function() {
    EditBaseItemView.prototype.render.apply(this)
    var $vimeoPlaceholder = this.$('.js-vimeo-thumbnail')
    if ($vimeoPlaceholder) {
      $.ajax({
        url: 'http://vimeo.com/api/oembed.json',
        data: { url: 'http://vimeo.com/' + this.model.get('videoId') },
        success: function(data) {
          /* jshint camelcase: false */
          var url = data.thumbnail_url
          if (url) $vimeoPlaceholder.attr('src', url)
        }
      })
    }
    return this
  }
})
