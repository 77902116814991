module.exports={
	"assert": true,
	"assert/strict": ">= 15",
	"async_hooks": ">= 8",
	"buffer_ieee754": "< 0.9.7",
	"buffer": true,
	"child_process": true,
	"cluster": true,
	"console": true,
	"constants": true,
	"crypto": true,
	"_debug_agent": ">= 1 && < 8",
	"_debugger": "< 8",
	"dgram": true,
	"diagnostics_channel": ">= 15.1",
	"dns": true,
	"dns/promises": ">= 15",
	"domain": ">= 0.7.12",
	"events": true,
	"freelist": "< 6",
	"fs": true,
	"fs/promises": [">= 10 && < 10.1", ">= 14"],
	"_http_agent": ">= 0.11.1",
	"_http_client": ">= 0.11.1",
	"_http_common": ">= 0.11.1",
	"_http_incoming": ">= 0.11.1",
	"_http_outgoing": ">= 0.11.1",
	"_http_server": ">= 0.11.1",
	"http": true,
	"http2": ">= 8.8",
	"https": true,
	"inspector": ">= 8.0.0",
	"_linklist": "< 8",
	"module": true,
	"net": true,
	"node-inspect/lib/_inspect": ">= 7.6.0 && < 12",
	"node-inspect/lib/internal/inspect_client": ">= 7.6.0 && < 12",
	"node-inspect/lib/internal/inspect_repl": ">= 7.6.0 && < 12",
	"os": true,
	"path": true,
	"path/posix": ">= 15.3",
	"path/win32": ">= 15.3",
	"perf_hooks": ">= 8.5",
	"process": ">= 1",
	"punycode": true,
	"querystring": true,
	"readline": true,
	"repl": true,
	"smalloc": ">= 0.11.5 && < 3",
	"_stream_duplex": ">= 0.9.4",
	"_stream_transform": ">= 0.9.4",
	"_stream_wrap": ">= 1.4.1",
	"_stream_passthrough": ">= 0.9.4",
	"_stream_readable": ">= 0.9.4",
	"_stream_writable": ">= 0.9.4",
	"stream": true,
	"stream/promises": ">= 15",
	"string_decoder": true,
	"sys": [">= 0.6 && < 0.7", ">= 0.8"],
	"timers": true,
	"timers/promises": ">= 15",
	"_tls_common": ">= 0.11.13",
	"_tls_legacy": ">= 0.11.3 && < 10",
	"_tls_wrap": ">= 0.11.3",
	"tls": true,
	"trace_events": ">= 10",
	"tty": true,
	"url": true,
	"util": true,
	"util/types": ">= 15.3",
	"v8/tools/arguments": ">= 10 && < 12",
	"v8/tools/codemap": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8/tools/consarray": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8/tools/csvparser": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8/tools/logreader": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8/tools/profile_view": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8/tools/splaytree": [">= 4.4.0 && < 5", ">= 5.2.0 && < 12"],
	"v8": ">= 1",
	"vm": true,
	"wasi": ">= 13.4 && < 13.5",
	"worker_threads": ">= 11.7",
	"zlib": true
}
