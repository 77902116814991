const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed js-fixed-sidebar\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Service Centre&quot; ] or [ Service -Centre ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"lastName\">A-Z</option><option value=\"-lastName\">Z-A</option><option value=\"-score\">Relevance</option></select></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Start Date</span><span class=\"datepicker\"><input type=\"text\" name=\"startDate\" readonly=\"readonly\" class=\"control control--text form-field js-date-time-picker\"/></span></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">End Date</span><span class=\"datepicker\"><input type=\"text\" name=\"endDate\" readonly=\"readonly\" class=\"control control--text form-field js-date-time-picker\"/></span></label></div><div class=\"form-row form-row-full-width js-dealership-select\"><label><span class=\"form-label-text\">Dealership</span><div class=\"js-dealership\"></div></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
const BaseSelect = require('../../base/views/select')
const moment = require('moment')

class ListFilterView extends View {
  constructor(...args) {
    super(...args)

    const { L, LT } = window.config.locale.longDateFormat

    this.initDateTimePickers = dateTimePickers(`${L} ${LT}`, null)

    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
  }

  trigger(...args) {
    return this.emit(...args)
  }

  updateDisplay(params) {
    this.$el.find('form [name=keywords]').val(params.keywords)

    const backMap = {
      'lastName,asc': 'lastName',
      'lastName,desc': '-lastName',
      'score,desc': '-score'
    }

    if (Array.isArray(params.sort)) {
      this.$el
        .find(
          '[name=sort] option[value=' + backMap[params.sort.join(',')] + ']'
        )
        .attr('selected', true)
    }

    if (params.filter) {
      this.renderDealershipSelect(params.filter.locations)

      if (params.filter.createdDate) {
        const { $gte, $lte } = params.filter.createdDate
        this.startDate.update(moment($gte).toDate())
        this.endDate.update(moment($lte).toDate())
      }
    }
  }

  handleSubmit(e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      lastName: ['lastName', 'asc'],
      '-lastName': ['lastName', 'desc'],
      '-score': ['score', 'desc']
    }
    const keywords = this.$el.find('form [name=keywords]').val()

    params.sort = map[this.$el.find('form [name=sort]').val()]

    if (this.currentDealership && this.currentDealership !== 'all') {
      params.filter.locations = this.currentDealership
    }

    if (keywords.length) params.keywords = keywords

    if (this.startDate.value) {
      if (!params.filter.createdDate) {
        params.filter.createdDate = {}
      }
      params.filter.createdDate = { $gte: this.startDate.value.toDate() }
    }

    if (this.endDate.value) {
      if (!params.filter.createdDate) {
        params.filter.createdDate = {}
      }

      params.filter.createdDate = { $lte: this.endDate.value.toDate() }
    }

    this.emit('filter', params)
  }

  handleClear(e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.$el.find('form [name=sort]').val('lastName')
    this.startDate.update(null)
    this.endDate.update(null)
    this.renderDealershipSelect()

    this.handleSubmit()
  }

  render() {
    this.$el.empty().append(template({}))

    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(value => {
        this[value.name] = this.initDateTimePickers(value)
      })

    this.renderDealershipSelect()

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  renderDealershipSelect(selected) {
    const options = {
      service: 'dealershipService',
      limitToOneValue: true,
      displayAllOption: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName'
    }

    if (selected && selected.$in) {
      options.selected = 'all'
    } else if (selected) {
      options.selected = selected
    } else {
      options.selected = 'all'
    }

    this.currentDealership = options.selected
    const dealershipSelect = new BaseSelect(this.serviceLocator, options)

    this.$el
      .find('.js-dealership')
      .empty()
      .append(dealershipSelect.render().$el)

    dealershipSelect.on(
      'change',
      dealership => (this.currentDealership = dealership)
    )
    this.attachView(dealershipSelect)
  }
}

module.exports = ListFilterView
