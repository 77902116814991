const {
  findCategory
} = require('../../../service/used-vehicle/lib/category-matcher')

module.exports = function generateAutoTraderLink(vehicle) {
  const category = findCategory(vehicle.category).type || 'car'
  return `https://www.autotrader.co.uk/${category}-details/${
    vehicle.autoTraderSearchId
  }`
}
