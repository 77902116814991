const Collection = require('chale')
const MerstoneModel = require('merstone')
const async = require('async')
const pageSize = 100
const defaultCurrentParams = {
  keywords: '',
  filter: {},
  sort: ['createdDate', 'desc']
}

const ListController = ({
  serviceLocator,
  service,
  plural,
  basePath,
  path,
  singular,
  Model,
  ListView,
  currentParams = defaultCurrentParams,
  customListViewInitiator
}) => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new MerstoneModel(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  const pagination = { page: currentPage, pageSize: pageSize }
  const base = basePath || path
  // Whenever an entiry is updated, reset the model with its new attributes
  service.on('update', function(id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  service.on('partialUpdate', function(id, attributes) {
    var model = collection.get(id)
    if (model) model.reset({ ...model.attributes, ...attributes })
    service.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  service.on('create', function() {
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    service.cachedFind.clear()
    getEntities(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route(`${path}(/)`, `list${plural}`, function() {
    if (!serviceLocator.allow(singular, 'discover')) return false

    var list = new ListView(serviceLocator, collection, paginationModel)

    list.loadData(() => {
      list.render()

      if (customListViewInitiator)
        customListViewInitiator(list, serviceLocator.router)

      getEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.displayFilterParams(currentParams)

    list.on('createNew', function() {
      if (!serviceLocator.allow(singular, 'create')) return false
      serviceLocator.router.navigate(`${base}/form`, {
        trigger: true
      })
    })

    list.on('edit', function(id) {
      if (!serviceLocator.allow(singular, 'update')) return false
      serviceLocator.router.navigate(`${base}/${id}/form`, {
        trigger: true
      })
    })

    list.on('duplicate', model => {
      console.log(11111, model)
      // if (!serviceLocator.allow(singular, 'duplicate')) return false
      serviceLocator.router.navigate(
        `${base}/${model.id}/duplicate`,
        { trigger: true }
      )
    })

    list.on('delete', function(ids) {
      if (!serviceLocator.allow(singular, 'delete')) return false
      async.each(ids, deleteOne, function(err) {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })

      function deleteOne(id, cb) {
        service.delete(id, function(err) {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
    })

    list.on('filter', function(params) {
      currentParams = params
      currentPage = 1
      var pagination = { page: currentPage, pageSize: pageSize }
      getEntities(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function() {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', model => {
      serviceLocator.router.navigate(`${base}/` + model.id + '/revisions', {
        trigger: true
      })
    })

    const pageTitle = `${plural.charAt(0).toUpperCase()}${plural.slice(1)}`

    serviceLocator.router.render(list, pageTitle)
  })

  const getEntities = (keywords, filter, sort, pagination) => {
    service.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err)
        return serviceLocator.logger.error(err, `Could not load ${plural}`)
      collection.reset(
        res.results.map(entity => new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendEntities = (keywords, filter, sort, pagination) => {
    service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(entity =>
        collection.add(new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }
}

module.exports = ListController
