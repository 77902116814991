const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (setConfig, undefined) {
buf.push("<div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Images</h2></div><div class=\"panel-content\"><div id=\"field--imageId\" data-field=\"imageId\" class=\"form-row\"><label><span class=\"form-label-text\">Add Image for</span><select name=\"imageId\" class=\"control control--choice form-field js-image-type-select\">");
// iterate setConfig
;(function(){
  var $$obj = setConfig;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<option" + (jade.attr("id", item.imageId, true, false)) + (jade.attr("value", item.imageId, true, false)) + (jade.attr("disabled", item.disabled, true, false)) + ">" + (jade.escape((jade_interp = item.name) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.required ? '(required)': '(optional)') == null ? '' : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<option" + (jade.attr("id", item.imageId, true, false)) + (jade.attr("value", item.imageId, true, false)) + (jade.attr("disabled", item.disabled, true, false)) + ">" + (jade.escape((jade_interp = item.name) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.required ? '(required)': '(optional)') == null ? '' : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("<option>Additional Image (optional)</option></select></label></div><div class=\"form-row\"><label><input value=\"Add Image\" class=\"btn js-add-image\"/></label></div><div class=\"js-uploaded-images-list-item\"></div></div></div>");}.call(this,"setConfig" in locals_for_with?locals_for_with.setConfig:typeof setConfig!=="undefined"?setConfig:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const ItemRepeater = require('./item-repeater')
const { options } = require('yargs')
const repeaterConfig = {
  formView: require('./item-form'),
  itemView: require('./item'),
  itemModel: require('../../models/items')
}
const uuid = require('uuid')

class UploadedImagesList extends View {
  constructor(serviceLocator, model, imageSetConfig) {
    super(serviceLocator)
    this.uploadedImages = model.get('images')
    this.imageSetConfig = imageSetConfig
  }

  embellishSetConfigImage(imageSet) {
    const imageUploaded = this.uploadedImages.find(
      i => i.imageId === imageSet.imageId
    )
    imageSet.disabled = !!imageUploaded
    return imageSet
  }

  updateSetConfigSelect() {
    // enable / Disable the images in the setConfig list when they are added / removed from the image set.
    this.$el.find('.js-image-type-select option').each((index, option) => {
      if (options.value !== '-1') {
        const imageUploadedForOption = this.uploadedImages.find(
          uploadedImage => uploadedImage.get('imageId') === option.value
        )
        option.disabled = !!imageUploadedForOption
        option.selected = false
      }
    })
  }

  renderUploadedImages() {
    const itemRepeater = new ItemRepeater(
      this.serviceLocator,
      repeaterConfig,
      this.uploadedImages,
      this.imageSetConfig.setConfig
    )
    this.itemRepeater = itemRepeater
    this.itemRepeater.on('updatedItem', items => {
      this.uploadedImages = items
      this.emit('uploadedImages', this.uploadedImages)
      this.updateSetConfigSelect()
    })
    this.$el.find('.js-add-image').on('click', e => {
      if (e) e.preventDefault()
      const imageId = this.$el.find('.js-image-type-select').val()
      let setConfigImage = this.imageSetConfig.setConfig.find(
        i => i.imageId === imageId
      )
      if (imageId && !setConfigImage) {
        const id = uuid.v4()
        setConfigImage = {
          imageId: id.toString(),
          name: 'Additional Image'
        }
      }
      if (setConfigImage) {
        this.itemRepeater.handleNewItem(
          setConfigImage.imageId,
          setConfigImage.name
        )
      }
    })
    this.$el
      .find('.js-uploaded-images-list-item')
      .empty()
      .append(itemRepeater.render().$el)
  }

  render() {
    this.$el.empty().append(
      template({
        allowed: this.serviceLocator.allowed,
        setConfig: this.imageSetConfig
          ? this.imageSetConfig.setConfig.map(
              this.embellishSetConfigImage.bind(this)
            )
          : []
      })
    )
    this.renderUploadedImages()
    return this
  }
}

module.exports = UploadedImagesList
