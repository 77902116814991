const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (config, data, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--path\" data-field=\"path\" class=\"form-row\"><label><span class=\"form-label-text\">Path<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"path\"" + (jade.attr("value", data.path, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Your chosen URL path. This will be accessed via " + (jade.escape((jade_interp = config.url) == null ? '' : jade_interp)) + "&lt;Path&gt;.</p></div></div><div id=\"field--redirectUrl\" data-field=\"redirectUrl\" class=\"form-row\"><label><span class=\"form-label-text\">Redirect URL<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"redirectUrl\"" + (jade.attr("value", data.redirectUrl, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>The destination for your URL. This can be either relative (/my-url) or absolute (http://www.google.com).</p></div></div></div></div></form></div></div>");}.call(this,"config" in locals_for_with?locals_for_with.config:typeof config!=="undefined"?config:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Redirect' : 'Edit Redirect',
        data: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }
}

module.exports = FormView
