const FormView = require('../views/form')
const Model = require('../models/make')
const createPublishFormViewController = require('../../base/controllers/publish-form-view-controller')

const createController = serviceLocator => {
  const initializeForm = createPublishFormViewController(
    serviceLocator,
    'makeService',
    'Make',
    'makes',
    FormView
  )

  // Edit
  serviceLocator.router.route('makes/:id/form', 'editMake', id => {
    if (!serviceLocator.allow('make', 'update')) return false

    serviceLocator.makeService.read(id, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const model = new Model(serviceLocator, data)

      initializeForm(model, null, false)
    })
  })

  // Create
  serviceLocator.router.route('makes/form', 'createMake', () => {
    if (!serviceLocator.allow('make', 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    initializeForm(model, null, true)
  })
}

module.exports = createController
