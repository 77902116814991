import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({
  usedVehicleCarouselWidget: ['sectionAdmin', 'makeAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Used Vehicle Carousel',
    description:
      'Display a carousel containing used vehicles of a specified make.'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('usedVehicleCarousel', widget)
  )

  done()
}

module.exports = initWidget
