const View = require('ventnor')
const join = require('path').join
const compileJade = require('browjadify-compile')
const container = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"section-exteriorImages\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Exterior 360 Images</h2></div><div class=\"panel-content\"><div data-type=\"exteriorImages\" class=\"js-notification-area\"></div><h3 class=\"form-row js-preview-title\">Previewing Vehicle</h3><div class=\"form-row\"><img style=\"max-height: 400px;\" class=\"image-centering image-contain js-image\"/></div><div class=\"form-row form-row-actions\"><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-previous\">Previous </button><button type=\"button\" class=\"btn js-pause\">Play</button><button type=\"button\" class=\"btn js-next\">Next<button type=\"button\" class=\"btn js-remove\">Remove</button></button></div></div></div><div class=\"form-row form-row-actions\"><a data-context=\"exteriorImages\" class=\"btn btn--success js-blur-all\">Blur Plates on All Images</a><a data-context=\"exteriorImages\" class=\"btn btn--success js-crop-current\">Crop Current</a></div></div></div>");;return buf.join("");
}
const CropDialog = require('./crop-dialog')
const modal = require('modal')
const createExteriorPreview = require('./lib/exterior-previews')
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')
const blurImages = require('./lib/bulk-blur-delegate')

class ExteriorImageArea extends View {
  constructor(serviceLocator, model) {
    super(serviceLocator)
    this.model = model
    const exteriorImagesExist =
      this.model.get('exteriorImages') &&
      this.model.get('exteriorImages').length
    Object.assign(this, createExteriorPreview(this.getImageUrl.bind(this)))

    this.$el.on('click', '.js-remove', this.removeImages.bind(this))
    this.$el.on('click', '.js-previous', this.setImage.bind(this, -1))
    this.$el.on('click', '.js-next', this.setImage.bind(this, +1))
    this.$el.on('click', '.js-pause', this.togglePaused.bind(this))
    this.$el.on('click', '.js-crop-current', this.crop.bind(this))
    this.$el.on('click', '.js-blur-all', this.blur.bind(this))

    this.model.on('change:exteriorImages', () =>
      this.initialise360(this.model.get('exteriorImages'), 250)
    )

    exteriorImagesExist
      ? this.$el.find('.js-remove').show()
      : this.$el.find('.js-remove').hide()
  }

  blur() {
    let images = this.model.exteriorImages.get('widgets') || []
    blurImages.call(this, images, 'exteriorImages')
  }

  crop() {
    this.cropDialog = new CropDialog(
      this.serviceLocator,
      this.model.get('exteriorImages'),
      this.index,
      false,
      this.model.get('brand')
    ).render()

    this.cropDialog.on('crop', body => {
      this.emit('crop', body)
    })

    this.cropDialog.on('remove', body => {
      this.emit('remove', body)
    })

    modal({
      title: 'Crop Image',
      content: this.cropDialog.$el,
      className: 'wide',
      buttons: [
        {
          text: 'Finish',
          className: 'btn',
          keyCodes: [13]
        }
      ]
    })
  }

  render() {
    this.$el.append(container())

    this.initialise360(this.model.get('exteriorImages'), 500)

    return this
  }

  getImageUrl(item) {
    if (!item || !item.binaryUri) return ''

    const id = item.crops ? item.crops[0].src : item.binaryUri

    const { url, salt } = this.serviceLocator.config.darkroom
    const builder = createDarkroomUrlBuilder(url, salt)
    const imageUrl = builder()
      .resource(id)
      .width(600)
      .url()

    return imageUrl
  }
}

module.exports = ExteriorImageArea
