const moment = require('moment')
const { isVan, isCar } = require('../../used-vehicle/lib/category-matcher')
export const fetch = require('@zeit/fetch-cached-dns')(require('node-fetch'))
export const apiUrl =
  'https://services.codeweavers.net/api/finance/calculatefordisplay'
export const apiKey = 'q6QFIGDg667F5L8kTl'
export const lenderMap = {
  'Volkswagen Financial Services (UK) Limited': 'volkswagen',
  'ŠKODA Financial Services Limited': 'volkswagen',
  'Seat Financial Services (UK) Limited': 'volkswagen',
  'Black Horse': 'blackhorse'
}

const buildRequest = (vehicle, annualMileage, termLength, deposit, type) => {
  let category = ''

  if (isVan(vehicle.category)) {
    category = 'lcv'
  } else if (isCar(vehicle.category)) {
    category = 'car'
  }

  return {
    Credentials: {
      ApiKey: apiKey
    },
    VehicleRequests: [
      {
        Id: '1',
        Parameters: {
          Term: termLength,
          CashDeposit: deposit
            ? deposit
            : vehicle.value * (type === 'PCP' ? 0.1 : 0.2),
          DepositType: 'Amount',
          AnnualMileage: annualMileage,
          OrganisationIdentifier: {
            Value: vehicle.dealerKey,
            Type: 'AssociatedDealerKey'
          }
        },
        PhysicalVehicle: {
          Type: category,
          OnTheRoadPrice: vehicle.value,
          Mileage: vehicle.mileage,
          Status: 'PREOWNED',
          Registration: {
            RegistrationNumber: vehicle.registration,
            DateRegisteredWithDvla: vehicle.registrationDate
          }
        }
      }
    ]
  }
}

const getTerms = async termsUrl => {
  try {
    const response = await fetch(termsUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'X-CW-ApiKey': apiKey
      }
    })
    return (await response.json()).TermsAndConditions
  } catch (e) {
    return
  }
}

const formatQuote = ({
  Finance: { Quote: quote, Product: product },
  Blocks: blocks
}) => {
  const adminFee = quote.Fees.find(fee => fee.Type === 'Admin')
  const optionFee = quote.Fees.find(fee => fee.Type === 'Option')
  const firstPayment = quote.Payments[0]
  const finalPayment = quote.Payments[quote.Payments.length - 1]
  const totalChargeData =
    blocks[0] &&
    blocks[0].Details.find(detail => detail.Key === 'TotalChargeForCredit')
  const terms =
    quote.QuoteActions && getTerms(quote.QuoteActions.TermsAndConditions)

  return {
    monthlyPayment: quote.RegularPayment,
    carPrice: quote.TotalPrice,
    depositAmount: quote.Deposit && quote.Deposit.Cash,
    adminFee: adminFee ? adminFee.Amount : 0,
    balanceToFinance: quote.Balance,
    firstPayment: firstPayment ? firstPayment.Amount : 0,
    numberOfPayments: quote.Term,
    finalPayment: finalPayment ? finalPayment.Amount : 0,
    amountPayable: quote.TotalAmountPayable,
    interestCharges: totalChargeData.Value,
    interestApr: quote.Apr,
    interestRate: quote.RateOfInterest,
    optionFee: optionFee ? optionFee.Amount : 0,
    lender: lenderMap[product.Lender],
    lenderDisplayName: product.Lender,
    terms: terms || undefined
  }
}

const financeCalculator = serviceLocator => async ({
  type,
  vehicle,
  annualMileage,
  termLength,
  depositAmount,
  depositPercentage,
  fetchMaxDeposit = true
}) => {
  serviceLocator.logger.info(
    `Calculating ${type} finance information for ${vehicle.registration}`
  )

  const vehicleAge = moment().year() - vehicle.year
  let { value } = vehicle
  let deposit = depositAmount

  const dealership = await serviceLocator.dealershipService.cachedRead(
    vehicle.dealership
  )
  vehicle.dealerKey = dealership.codeweaversDealerKey

  if (
    fetchMaxDeposit &&
    (vehicle.category === 'van' || vehicle.category === 'lifestyle') &&
    vehicle.vatCategory === '+ VAT'
  )
    value = vehicle.value * 1.2

  if (depositPercentage) deposit = value * depositPercentage

  if (type === 'PCP' && (vehicleAge > 4 || vehicle.mileage > 60000)) {
    serviceLocator.logger.info(
      `Unable to calculate finance (${type}) for vehicle: ${
        vehicle.registration
      }`,
      'Vehicle is either older than 4 years old or has done over 60k miles'
    )
    return {
      financeCalculatorError:
        'Vehicle is either older than 4 years old or has done over 60k miles'
    }
  }

  let maxDepositResult = null
  const vehicleData = { ...vehicle, value }

  if (fetchMaxDeposit) {
    const maxDepositCalculator = financeCalculator(serviceLocator)
    maxDepositResult = await maxDepositCalculator({
      type: type,
      vehicle: vehicleData,
      annualMileage,
      termLength,
      depositAmount: 100000,
      fetchMaxDeposit: false
    })
  }

  const payload = buildRequest(
    vehicleData,
    annualMileage,
    termLength,
    deposit,
    type
  )
  // console.dir(payload, { depth: null, colors: true })
  // console.log(`curl --header "Content-Type: application/json" --request POST --data  '${JSON.stringify(payload)}' https://services.codeweavers.net/public/v2/JsonFinance/Calculate`)
  const response = await fetch(apiUrl, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })

  const financeData = await response.json()

  if (response.status !== 200) {
    const err = new Error('Invalid lookup response')
    err.status = response.status
    err.data = financeData
    err.text = financeData && financeData.Error && financeData.Error.UserMessage

    if (err.text) {
      return { financeCalculatorError: err.text }
    }

    serviceLocator.logger.warn(
      `Finance Calculator: Invalid lookup response received: ${
        response.status
      }`,
      financeData,
      err.text
    )
    throw err
  }

  const getFinanceProduct = () => {
    const vehicleResults = financeData.Vehicles && financeData.Vehicles[0]

    if (!vehicleResults) return {}
    const financeProductResults = vehicleResults.FinanceQuotations
    if (!financeProductResults) return {}

    if (financeProductResults.HasError) {
      serviceLocator.logger.info(
        `Unable to calculate finance (${type})`,
        financeProductResults.Error
      )
      return { error: financeProductResults.Error }
    }

    const financeProduct = financeProductResults.find(
      finance => finance.Finance && finance.Finance.Key === type
    )

    if (financeProduct.HasError) {
      serviceLocator.logger.info(
        `Unable to calculate finance (${type}) for vehicle: ${
          vehicleData.registration
        }`,
        financeProduct.Error
      )
      return { error: financeProduct.Error }
    }

    return financeProduct
  }

  const financeProduct = getFinanceProduct()

  if (!Object.keys(financeProduct).length) return {}
  if (financeProduct.error) {
    const { TechnicalMessage } = financeProduct.error || {}
    return { financeCalculatorError: TechnicalMessage }
  }

  const result = formatQuote(financeProduct)

  if (maxDepositResult && maxDepositResult.depositAmount) {
    const maxDepositBasedOnQuote = maxDepositResult.depositAmount
    let maxDepositAmount = maxDepositBasedOnQuote

    if (type === 'PCP') {
      const maxDepositBasedOnValue = vehicleData.value * 0.3
      maxDepositAmount =
        maxDepositBasedOnQuote > maxDepositBasedOnValue
          ? maxDepositBasedOnValue
          : maxDepositBasedOnQuote
    }

    result.maxDepositAmount = maxDepositAmount
  }
  return result
}

module.exports = financeCalculator
