const CrudService = require('../../../../admin/source/js/lib/crud-service')

class AuthorService extends CrudService {
  get name() {
    return 'AuthorService'
  }
  get urlRoot() {
    return '/authors'
  }
}

module.exports = AuthorService
