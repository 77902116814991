const formController = require('./controllers/form')
const listController = require('./controllers/list')
const MakeService = require('./services/make')
const createRevisionController = require('../revision/controllers/controller')

const initAdmin = () => ({ makeAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.makeService = new MakeService(serviceLocator)
  formController(serviceLocator)
  listController(serviceLocator)
  createRevisionController(
    serviceLocator,
    serviceLocator.makeService,
    'make',
    'makes/:id/revisions',
    'makes',
    'name'
  )
  cb(null)
}

module.exports = initAdmin
