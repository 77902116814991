const CrudService = require('../../../../admin/source/js/lib/crud-service')

class BodyTypeService extends CrudService {
  get name() {
    return 'BodyTypeService'
  }
  get urlRoot() {
    return '/body-types'
  }

  embellishBodyTypesWithMakes(bodyTypes, callback) {
    let makeIds = []
    bodyTypes.forEach(bodyType => {
      if (bodyType.makes.length > 0) makeIds = makeIds.concat(bodyType.makes)
    })

    this.serviceLocator.makeService.find(
      '',
      { _id: { $in: makeIds } },
      [],
      { page: 1, pageSize: 500 },
      (error, makes) => {
        if (error) return callback(error)

        bodyTypes.map(bodyType => {
          const makeNames = []
          bodyType.makes.forEach(make => {
            const foundMake = makes.results.find(
              makeResult => makeResult._id === make
            )
            if (foundMake) makeNames.push(foundMake.name)
          })
          bodyType.__makes = makeNames.toString()
        })

        return callback(null, bodyTypes)
      }
    )
  }
}

module.exports = BodyTypeService
