const initWidget = () => ({
  promoCarouselWidget: ['widget', 'sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Promo Carousel',
    itemView: require('./views/item'),
    description: 'Displays a carousel of images'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('promoCarousel', widget)
  )

  done()
}

module.exports = initWidget
