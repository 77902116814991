import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  recentlyViewedVehiclesWidget: ['sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'Recently Viewed Vehicles',
    description: 'Display recently viewed vehicles'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('recentlyViewedVehicles', widget)
  )

  done()
}

module.exports = initWidget
