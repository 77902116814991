import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({
  fullRangeWidget: ['sectionAdmin', 'makeAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Full Range',
    description: 'Display all models of a specified make.'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('fullRange', widget)
  )

  done()
}

module.exports = initWidget
