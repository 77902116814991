module.exports = initAdmin
const createController = require('./controllers/controller')

function initAdmin() {
  return { indexAdmin: init }
}

function init(serviceLocator, done) {
  createController(serviceLocator)
  done()
}
