const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed) {
buf.push("<div class=\"page-content\"></div><div class=\"centering\"><div class=\"grid\"><div class=\"grid__item desktop-one-half\">");
if ( allowed('autoTrader', 'bulk-query'))
{
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>AutoTrader Bulk Query</h2></div><div class=\"panel-content\"><p><button type=\"button\" class=\"btn btn--small js-autotrader-bulk-query\">Bulk Query AutoTrader</button></p></div></div>");
}
buf.push("</div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined));;return buf.join("");
}
const modal = require('modal')
const AutotraderQueryModalView = require('./modal')

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-autotrader-bulk-query': 'handleBulkAutotraderQuery'
  },
  handleBulkAutotraderQuery(e) {
    e.preventDefault()
    const autotraderQueryModalView = new AutotraderQueryModalView(
      this.options.serviceLocator
    )

    const autotraderQueryModal = modal({
      title: 'AutoTrader Bulk Query',
      content: autotraderQueryModalView.render().$el,
      className: 'wide',
      buttons: []
    }).on('close cancel', () => {
      autotraderQueryModalView.remove()
    })
    autotraderQueryModalView.on('close', () => {
      autotraderQueryModal.close()
      autotraderQueryModalView.remove()
    })
  },
  initialize(options) {
    this.options = options
    this.render()
  },
  render() {
    this.$el.empty().append(
      template({
        allowed: this.options.serviceLocator.allowed
      })
    )
    return this
  }
})
