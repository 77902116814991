const createFormController = require('./controllers/form')
const createListController = require('./controllers/list')
const TagService = require('./services/tag')

const initAdmin = () => ({ tagAdmin: init })

const init = (serviceLocator, done) => {
  serviceLocator.tagTypes = ['General', 'System']
  serviceLocator.tagService = new TagService(serviceLocator)
  createFormController(serviceLocator)
  createListController(serviceLocator)
  done()
}

module.exports = initAdmin
