const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const dialogContainer = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (imageUrl, make, versionPath) {
buf.push("<div style=\"display: none\" class=\"notification notification--info js-notification\">Cropping Image...</div><div class=\"grid\"><div class=\"grid__item three-quarters\"><div class=\"form-row\"><img" + (jade.attr("src", imageUrl, true, false)) + " crossorigin=\"anonymous\" class=\"image-centering image-contain js-image\"/></div><div class=\"form-row form-row-actions\"><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-previous\">Previous </button><button type=\"button\" class=\"btn js-pause\">Pause</button><button type=\"button\" class=\"btn js-next\">Next</button></div></div></div><div class=\"form-row form-row-actions\"><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-out\">Zoom Out </button><button type=\"button\" class=\"btn js-in\">Zoom In</button></div><div class=\"btn-group\"><button type=\"button\" class=\"btn js-crop\">Crop and Save</button></div>");
if ( (['volkswagen', 'seat', 'skoda']).includes(make))
{
buf.push("<div class=\"btn-group\"><a" + (jade.attr("href", versionPath('/assets/files/volkswagen-guidelines.pdf'), true, false)) + " target=\"_blank\" class=\"btn\">Guidelines</a></div>");
}
buf.push("</div></div></div><div class=\"grid__item one-quarter\"><h2>Saved Images</h2><div class=\"images-area js-images-cropped\"></div></div></div>");}.call(this,"imageUrl" in locals_for_with?locals_for_with.imageUrl:typeof imageUrl!=="undefined"?imageUrl:undefined,"make" in locals_for_with?locals_for_with.make:typeof make!=="undefined"?make:undefined,"versionPath" in locals_for_with?locals_for_with.versionPath:typeof versionPath!=="undefined"?versionPath:undefined));;return buf.join("");
}
const createExteriorPreview = require('./lib/exterior-previews')
const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')
const Cropper = require('cropperjs')
const imageAreaTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, imageContext, name) {
buf.push("<div" + (jade.attr("id", data.id, true, false)) + " style=\"display: none;\" class=\"widget-grid__item\"><div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-actions\"><div class=\"control-group\"><div class=\"btn-group\"><button" + (jade.attr("data-id", data.id, true, false)) + (jade.attr("data-context", imageContext, true, false)) + " class=\"text-btn label label--large js-remove\"><i class=\"icon icon--cross\"></i></button></div></div></div><h2>" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</h2></div><div class=\"list-item-content expander-content\"><div class=\"image-select-preview js-image-select-preview\"><a class=\"js-edit\"><img" + (jade.attr("src", data.url, true, false)) + " class=\"js-preview-image\"/></a></div></div></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"imageContext" in locals_for_with?locals_for_with.imageContext:typeof imageContext!=="undefined"?imageContext:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const Model = require('merstone')
const versionator = require('versionator')
const mappedVersion = versionator.createMapped(
  require('../../../../admin/static-file-map.json')
)
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')
const config = window.config
const axios = require('axios')
const FormData = require('form-data')

class CropDialogView extends View {
  constructor(serviceLocator, images, index, autoplay, make = '') {
    super(serviceLocator)

    this.urlBuilder = createDarkroomUrlBuilder(
      config.darkroom.url,
      config.darkroom.salt
    )

    this.authedRequest = createAuthedRequester(
      serviceLocator.config.apiUrl
    ).bind(this)

    this.images = images && images.widgets ? images.widgets : images
    this.index = index
    this.make = make.toLowerCase()

    this.previewController = createExteriorPreview(this.getImageUrl.bind(this))
    Object.assign(this, this.previewController)

    this.$el.on('click', '.js-out', this.zoom.bind(this, 'out'))
    this.$el.on('click', '.js-in', this.zoom.bind(this, 'in'))

    this.$el.on('click', '.js-previous', this.changeImage.bind(this, -1))
    this.$el.on('click', '.js-next', this.changeImage.bind(this, +1))
    this.$el.on('click', '.js-pause', this.toggleCrop.bind(this))
    this.$el.on('click', '.js-crop', this.cropImage.bind(this))
    this.$el.on('click', '.js-remove', this.removeImage.bind(this))

    this.paused = this.index !== undefined && !autoplay
    this.imageUrl = this.paused
      ? this.getImageUrl(this.images[index % this.images.length])
      : ''

    this.imageSet = new Model(serviceLocator, {
      current: []
    })
  }

  toggleCrop() {
    this.togglePaused()

    if (this.paused) {
      this.initializeCrop()
    } else {
      this.cropper.destroy()
      this.cropper = null
    }

    this.$el.find('.dropdown-toggle').attr('disabled', !this.paused)
  }

  changeImage(inc) {
    this.setImage(inc)

    if (this.cropper) {
      this.cropper.replace(this.getImage(), true)
    } else {
      this.toggleCrop()
    }
  }

  getImageUrl(item) {
    return (
      this.urlBuilder()
        .resource(item.binaryUri)
        .url() +
      '?' +
      Date.now()
    )
  }

  initializeCrop() {
    const image = this.$el.find('.js-image').get(0)

    this.cropper = new Cropper(image, {
      dragMode: 'move',
      cropBoxMovable: false,
      cropBoxResizable: false,
      aspectRatio: 3 / 2,
      autoCropArea: 1,
      viewMode: 3
    })
  }

  zoom(direction) {
    if (!this.cropper) return

    this.cropper.zoom(direction === 'in' ? 0.1 : -0.1)
  }

  getCrop(cb) {
    this.cropper.getCroppedCanvas().toBlob(cb)
  }

  cropImage(e) {
    this.$el.find('.js-notification').show()
    const target = 'images'
    const name = this.$el.find(e.currentTarget).text()
    const croppedImageData = this.cropper.getData(true)

    this.getCrop(image => {
      const form = new FormData()
      form.append('image', image)
      form.append('height', croppedImageData.height)
      form.append('width', croppedImageData.width)

      axios
        .post(config.apiUrl + '/assets/upload', form, {
          headers: {
            'x-darkroom-key': config.darkroom.key
          }
        })
        .then(res => {
          const data = res.data
          data.url = this.getImageUrl(data)
          data.name = data.binaryUri // otherwise the name is 'blob'

          this.$el.find('.js-notification').hide()

          this.emit('crop', { data, target })

          this.$el
            .find('.js-images-cropped')
            .append(imageAreaTemplate({ data, imageContext: target, name }))

          this.$el.find(`#${data.id}`).fadeIn(1000)
        })
    })
  }

  removeImage(e) {
    e.preventDefault()
    const id = e.currentTarget.dataset.id
    const target = e.currentTarget.dataset.context

    this.emit('remove', { id, target })

    this.$el.find(`#${id}`).fadeOut(1000)
    this.$el.find(`#${id}`).remove()
  }

  render() {
    this.$el.append(
      dialogContainer({
        imageUrl: this.imageUrl,
        make: this.make,
        versionPath: mappedVersion.versionPath
      })
    )

    this.$el.find('.js-pause').text(this.paused ? 'Play' : 'Pause')
    this.$el.find('.dropdown-toggle').attr('disabled', !this.paused)

    if (this.paused) {
      this.initializeCrop()
    } else {
      this.initialise360(this.images, 250, this.index)
    }

    return this
  }
}

module.exports = CropDialogView
