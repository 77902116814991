import editView from './views/form'
import itemView from './views/item'
import model from './models/model'

const initWidget = () => ({
  faqTags: ['widget', 'sectionAdmin', 'faqAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'FAQs',
    description: 'Displays a selection of tagged FAQs.'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('faqTags', widget)
  )

  done()
}

module.exports = initWidget
