var validateColor = require('validate-color').default

const renderSwatches = function() {
  const $swatches = this.$el.find('.js-swatch')

  $swatches.each(function(index, swatch) {
    const $swatch = $(swatch)
    const $input = $swatch.find('.js-swatch-input')
    let color1 = ''

    const onColorChange = function(e) {
      const $target = $(e.target)
      const color = $target.val()
      const $swatchPreview = $(this).find('.js-swatch-preview')

      if (validateColor(color)) {
        $target.removeClass('is-invalid')
        color1 = $target.val()
      } else {
        if (color !== '' || e.target.getAttribute('required'))
          $target.addClass('is-invalid')
        color1 = ''
      }

      const colorSwatch = color1 !== '' ? color1 : '#000'

      $swatchPreview.css('background-color', colorSwatch)
    }

    $swatch.on('input', $input, onColorChange)

    $input.trigger('input')
  })
}

module.exports = renderSwatches
