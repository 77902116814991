const schemata = require('schemata')
const required = require('validity-required')

const properties = {
  headline: { type: String, validators: [required] },
  description: { type: String },
  callToActionText: { type: String },
  callToActionLink: { type: String }
}

module.exports = schemata({ name: 'News Strip Item', properties })
