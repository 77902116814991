const schemata = require('schemata')
const required = require('validity-required')
const resolveImages = require('../../../lib/image-resolver')
const validateIfSet = require('validity-validate-if-property-set')

const specFields = [
  'doors',
  'engineSize',
  'batterySize',
  'batteryUnits',
  'range',
  'rapidCharging',
  'transmission',
  'efficiency',
  'performance'
]

const validateSpecFieldsSet = (prop, name, object) => {
  const fields = specFields.filter(
    field => object.hasOwnProperty(field) && object[field]
  )

  if (
    fields.length % 2 === 1 &&
    (!fields.includes('transmission') &&
      !fields.includes('engineSize') &&
      !fields.includes('batterySize') &&
      !fields.includes('batteryUnits'))
  ) {
    return 'Ensure an even number of fields is set'
  }

  if (
    fields.length % 2 === 0 &&
    (fields.includes('batterySize') || fields.includes('batteryUnits'))
  ) {
    return 'Ensure an odd number of fields is set (including Battery Size and Battery Units)'
  }

  if (
    fields.length % 2 === 0 &&
    (fields.includes('transmission') || fields.includes('engineSize'))
  ) {
    return 'Ensure an odd number of these fields is set (including Transmission and Engine Size)'
  }
}

module.exports = () =>
  schemata({
    name: 'Specification',
    properties: {
      name: { type: String, validators: [required] },
      label: { type: String, validators: [required] },
      price: { type: String },
      description: { type: String },
      doors: { type: Number, validators: [validateSpecFieldsSet] },

      // The following four are required if one of the pair is set
      engineSize: {
        type: Number,
        validators: [
          validateSpecFieldsSet,
          validateIfSet('transmission', required)
        ]
      },
      transmission: {
        type: String,
        validators: [
          validateSpecFieldsSet,
          validateIfSet('engineSize', required)
        ]
      },

      batterySize: {
        type: Number,
        validators: [
          validateSpecFieldsSet,
          validateIfSet('batteryUnits', required)
        ]
      },
      batteryUnits: {
        type: String,
        validators: [
          validateSpecFieldsSet,
          validateIfSet('batterySize', required)
        ]
      },

      efficiency: { type: Number, validators: [validateSpecFieldsSet] },
      performance: { type: Number, validators: [validateSpecFieldsSet] },
      range: { type: Number, validators: [validateSpecFieldsSet] },
      rapidCharging: { type: Number, validators: [validateSpecFieldsSet] },

      brochureUrl: { type: String },
      images: {
        type: Array,
        defaultValue: () => [],
        resolve: spec => resolveImages(spec.images),
        resolveType: Array
      }
    }
  })
