const createListController = require('./controllers/list')
const createFormController = require('./controllers/form')
const createRevisionController = require('../revision/controllers/controller')
const ArticleService = require('./services/article')
const createWidgetFactory = require('../widget/lib/base-factory')
const createRegistry = require('regg')

const initAdmin = () => ({ articleAdmin: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.articleService = new ArticleService(serviceLocator)

  serviceLocator.widgetFactories.register('articleBody', createWidgetFactory())
  serviceLocator.widgetFactories.register(
    'relatedArticles',
    createWidgetFactory()
  )

  serviceLocator.articleTypeFactory = createRegistry()

  // Add convenience function to the article type factory to list their display names
  serviceLocator.articleTypeFactory.listNames = () =>
    serviceLocator.articleTypeFactory.list().map(type => {
      const articleType = serviceLocator.articleTypeFactory.get(type)
      return { name: articleType.name, type: type, hidden: articleType.hidden }
    })

  createListController(serviceLocator)
  createFormController(serviceLocator)
  createRevisionController(
    serviceLocator,
    serviceLocator.articleService,
    'article',
    'articles/:id/revisions',
    'articles',
    'shortHeadline'
  )

  cb(null)
}

module.exports = initAdmin
