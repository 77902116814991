const CrudService = require('../../../../admin/source/js/lib/crud-service')

class CaseStudyService extends CrudService {
  get name() {
    return 'CaseStudyService'
  }
  get urlRoot() {
    return '/caseStudies'
  }
}

module.exports = CaseStudyService
