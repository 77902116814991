const schemata = require('schemata')
const newsStripItemSchema = require('./news-strip-item-schema')
const uuid = require('uuid')

const properties = {
  _id: {
    type: String
  },
  items: {
    type: schemata.Array(newsStripItemSchema),
    defaultValue: () => []
  },
  dismissId: {
    type: String,
    defaultValue: () => uuid.v4()
  }
}

module.exports = schemata({ name: 'News Strip', properties })
