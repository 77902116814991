const ListView = require('./views/upload-only')
const Model = require('./models/idealfile-upload')
const baseListController = require('../base/controllers/list')
const IDealFileUploadService = require('./services/idealfile-upload')

const initAdmin = () => ({ idealfileAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.idealfileUploadService = new IDealFileUploadService(
    serviceLocator
  )

  const controllerOptions = {
    serviceLocator,
    singular: 'idealfileUpload',
    plural: 'idealfileUploads',
    path: 'idealfile-upload',
    service: serviceLocator.idealfileUploadService,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['createdDate', 'desc']
    },
    Model
  }
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
