import model from './models/model'

const initWidget = () => ({
  careersPage: ['sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    model: model,
    name: 'Careers Page',
    description: 'Display the Careers page'
  }

  ;['section'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('careersPage', widget)
  )

  done()
}

module.exports = initWidget
