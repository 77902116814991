const validateDelegate = require('../../../../admin/source/js/lib/validate-delegate')()
const BaseModel = require('cf-base-model')
const ImageAreaModel = require('../../asset/models/image-area')

module.exports = schema =>
  BaseModel.extend({
    schemata: schema,
    defaults() {
      return this.schemata.makeDefault({ type: this.type || 'ItemModel' })
    },
    validate: validateDelegate,
    initialize: function() {
      ;['images'].forEach(imageType => {
        this[imageType] = new ImageAreaModel(this.attributes[imageType])

        this[imageType].on('add remove change', () => {
          this.set(imageType, this[imageType].toJSON())
        })
      })
    }
  })
