const BaseModel = require('cf-base-model')
const createSchema = require('./schema')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: createSchema,
  type: 'list',
  displayName: 'List',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'list' })
  },
  validate: validateDelegate
})
