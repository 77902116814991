const BaseListView = require('../../base/views/list')
const ListItemView = require('./list-item')
const FilterView = require('./list-filter')
const compileJade = require('browjadify-compile')
const { join } = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn js-back\">Back</button></div></div></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>AutoTrader import failures</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div data-layout=\"detail\" class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");;return buf.join("");
}

class ListView extends BaseListView {
  constructor() {
    super(...arguments)
    this.$el.on('click', '.js-back', () => {
      history.back()
    })
  }

  get name() {
    return {
      singular: 'Import Failure',
      plural: 'Import Failures',
      resource: 'importFailure'
    }
  }

  get template() {
    return template
  }

  get ListItemView() {
    return ListItemView
  }

  get FilterView() {
    return FilterView
  }

  addListItem(model, index) {
    const listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      index,
      this.collection.length,
      this.dealers
    )
    this.attachView(listItem)
    try {
      this.$el.find('.js-items').append(listItem.render().$el)
    } catch (e) {
      this.serviceLocator.logger.error(e, 'Error rendering list item')
    }

    return listItem
  }

  loadData(cb) {
    this.serviceLocator.dealershipService.cachedFind(
      '',
      {},
      [],
      { page: 1, pageSize: 500 },
      (err, data) => {
        if (err) {
          alert(err.message)
          return cb(err)
        }

        this.dealers = data.results

        cb()
      }
    )
  }
}

module.exports = ListView
