const compileJade = require('browjadify-compile')
const join = require('path').join
const debug = require('debug')('widget option list view')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (moreWidgets, primaryWidgets, undefined) {
buf.push("<div class=\"js-primary-widgets\">");
// iterate primaryWidgets
;(function(){
  var $$obj = primaryWidgets;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var widget = $$obj[$index];

buf.push("<div" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item\"><i class=\"icon icon--plus\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var widget = $$obj[$index];

buf.push("<div" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item\"><i class=\"icon icon--plus\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</div>");
    }

  }
}).call(this);

buf.push("<div class=\"js-more-widgets more-widgets-list hidden\">");
// iterate moreWidgets
;(function(){
  var $$obj = moreWidgets;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var widget = $$obj[$index];

buf.push("<div" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item\"><i class=\"icon icon--plus\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var widget = $$obj[$index];

buf.push("<div" + (jade.attr("data-type", widget.get('type'), true, false)) + " class=\"widget-list-item js-widget-list-item\"><i class=\"icon icon--plus\"></i>" + (jade.escape((jade_interp = widget.get('name')) == null ? '' : jade_interp)) + "</div>");
    }

  }
}).call(this);

buf.push("</div><div class=\"btn btn--success js-toggle-more-widgets\"><i class=\"icon icon--plus\"></i><span class=\"label--more\"> More</span><span class=\"label--less hidden\"> Less</span></div></div>");}.call(this,"moreWidgets" in locals_for_with?locals_for_with.moreWidgets:typeof moreWidgets!=="undefined"?moreWidgets:undefined,"primaryWidgets" in locals_for_with?locals_for_with.primaryWidgets:typeof primaryWidgets!=="undefined"?primaryWidgets:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const placeholderTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (type) {
buf.push("<div" + (jade.attr("data-type", type, true, false)) + " class=\"js-widget-placeholder js-widget\"></div>");}.call(this,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return buf.join("");
}
const primaryWidgets = ['text', 'inlineImage', 'video', 'quote']

module.exports = window.Backbone.View.extend({
  events: {
    'click .js-widget-list-item': 'handleAdd',
    'click .js-toggle-more-widgets': 'handleToggleMoreWidgets'
  },

  initialize({ collection }) {
    this.collection = collection
    this.render()
  },

  className: 'widget-list js-widget-option-list',

  handleAdd(e) {
    const { target } = e
    const { dataset } = target
    const { type } = dataset

    if (!type) {
      debug('handleAdd ignored, no data-type')
      return
    }

    this.addPlaceholder(type)
    debug('handleAdd', { type })
    this.trigger('add', type)
  },

  handleToggleMoreWidgets() {
    this.$('.label--more').toggleClass('hidden')
    this.$('.label--less').toggleClass('hidden')
    this.$('.js-more-widgets').toggleClass('hidden')
  },

  addPlaceholder(type) {
    // If these cases aren't handled properly, the placeholder is inserted inside the <div> of a js-widget
    if (['insertBefore', 'insertAfter'].includes(this.actionReference)) {
      return $(placeholderTemplate({ type }))[this.actionReference](
        this.$target
      )
    }
    return this.$target[this.actionReference](placeholderTemplate({ type }))
  },

  removeFromView($view) {
    $view.find(this.el).remove()
  },

  prependTo($target) {
    debug('prepending view to target')
    this.$target = $target
    this.actionReference = 'prepend'
    $target.prepend(this.el)
    this.delegateEvents()
  },

  appendTo($target) {
    debug('appending view to target')
    this.$target = $target
    this.actionReference = 'append'
    $target.append(this.el)
    this.delegateEvents()
  },

  insertBefore($target) {
    debug('inserting view before target')
    this.$target = $target
    this.actionReference = 'insertBefore'
    this.$el.insertBefore($target)
    this.delegateEvents()
  },

  insertAfter($target) {
    debug('inserting view after target')
    this.$target = $target
    this.actionReference = 'insertAfter'
    this.$el.insertAfter($target)
    this.delegateEvents()
  },

  getWidgetsList(type) {
    const widgets = this.collection.toArray()

    return type === 'primary'
      ? widgets
          .filter(widget => primaryWidgets.includes(widget.get('type')))
          .sort(
            (widgetA, widgetB) =>
              primaryWidgets.indexOf(widgetA.get('type')) -
              primaryWidgets.indexOf(widgetB.get('type'))
          )
      : widgets.filter(widget => !primaryWidgets.includes(widget.get('type')))
  },

  render() {
    this.$el.html(
      template({
        primaryWidgets: this.getWidgetsList('primary'),
        moreWidgets: this.getWidgetsList('secondary')
      })
    )
    return this
  }
})
