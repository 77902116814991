const Model = require('merstone')
const createSchema = require('../../../service/account/schema')
const ImageAreaModel = require('../../asset/models/image-area')

class AccountModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schemata = createSchema()
    this.attributes = this.schemata.cast(attributes || {})
    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schemata.cast(attrs))
    })
    ;[
      'brandPromiseImages',
      'autoTraderImages',
      'autoTraderPlaceholderImages'
    ].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.get(imageType))

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = AccountModel
