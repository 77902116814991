const CrudService = require('../../../../admin/source/js/lib/crud-service')

class StandardDataServoce extends CrudService {
  get name() {
    return 'StandardDataService'
  }
  get urlRoot() {
    return '/standard-data'
  }

  getDistinct(key, query, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/distinct-entries/${key}`,
      query,
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }
}

module.exports = StandardDataServoce
