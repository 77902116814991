const BaseFormView = require('../../base/views/form')
const BaseSelect = require('../../base/views/select')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--value\" data-field=\"value\" class=\"form-row\"><label><span class=\"form-label-text\">Value</span><input type=\"text\" name=\"value\"" + (jade.attr("value", data.value, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--category\" data-field=\"category\" class=\"form-row\"><label><span class=\"form-label-text\">Category<abbr title=\"This field is required\">*</abbr></span><select name=\"category\" class=\"control control--choice form-field\"><option value=\"\">-- Select a Category --</option><option value=\"general\"" + (jade.attr("selected", data.category === 'general', true, false)) + ">General</option><option value=\"interior\"" + (jade.attr("selected", data.category === 'interior', true, false)) + ">Interior</option><option value=\"exterior\"" + (jade.attr("selected", data.category === 'exterior', true, false)) + ">Exterior</option><option value=\"safety\"" + (jade.attr("selected", data.category === 'safety', true, false)) + ">Safety</option><option value=\"technical\"" + (jade.attr("selected", data.category === 'technical', true, false)) + ">Technical</option></select></label><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const DistinctSelect = require('../../used-vehicle/views/distinct-select')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew
          ? 'New Standard Data Item'
          : 'Edit Standard Data Item',
        data: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    this.renderDealershipSelect()
    this.renderLabelSelect()

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }

  renderLabelSelect() {
    const category = this.model.get('category')
    const select = new DistinctSelect(
      this.serviceLocator,
      {
        service: 'standardDataService',
        singular: 'Label',
        plural: 'Labels',
        method: 'POST',
        apiRoute: `/standard-data/distinct-entries/label`,
        selected: this.model.get('label'),
        query: category ? { category } : {},
        selectizeConfig: { create: true }
      },
      'label'
    )

    this.$el.find(`.js-label`).append(select.render().$el)

    this.$el.on('click', `.js-add-label`, () => {
      this.$el.find(`.js-label`).empty().append(`
        <input type="text" class="control control--text form-field" placeholder="Enter the new label" name=label />
      `)
    })

    select.on('change', value => this.model.set('label', value))
    this.attachView(select)
  }

  renderDealershipSelect() {
    const dealershipSelect = new BaseSelect(this.serviceLocator, {
      service: 'dealershipService',
      selected: this.model.get('dealerships'),
      displayAllOption: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName',
      filter: { disableVehicleAllocating: false }
    })

    this.$el.find('.js-dealerships').append(dealershipSelect.render().$el)
    dealershipSelect.on('change', dealerships =>
      this.model.set('dealerships', dealerships)
    )
    this.attachView(dealershipSelect)
  }
}

module.exports = FormView
