const createDviImageSubmitter = require('../../lib/dvi-image-submitter')
const submitImages = createDviImageSubmitter()

module.exports = async function(vehicleId, images, type) {
  const className = `.js-notification-area[data-type=${type}]`

  this.$el.find(className).empty().append(`
    <div class="notification">
      Processing ${images.length} images...
    </div>
  `)

  try {
    const imageIds = images.map(image => image.get('binaryUri'))

    const result = await submitImages(vehicleId, imageIds)

    images.forEach(image => {
      image.set('dviStatus', result.status)
      image.set('dviFailReason', result.failReason)
    })

    this.emit('save')

    if (result.status === 'failed') {
      return this.$el.find(className).empty().append(`
        <div class="notification">
          ${result.failReason}
        </div>
      `)
    }
  } catch (err) {
    return this.$el.find(className).empty().append(`
      <div class="notification">
        ${err.message}
      </div>
    `)
  }

  return this.$el.find(className).empty().append(`
    <div class="notification">
      Sent ${images.length} images to DVI
    </div>
  `)
}
