const schemata = require('schemata')

const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

const rowSchema = () =>
  schemata({
    name: 'Row',
    properties: {
      label: {
        type: String
      },
      value: {
        type: String
      }
    }
  })

const extraProperties = {
  items: {
    type: schemata.Array(rowSchema())
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Text', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'text' })
  },
  validate: validateDelegate
})
