const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name) {
buf.push("<span class=\"name\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</span><div class=\"js-error error\"></div><div class=\"js-clear clear\">Clear</div><div class=\"progress-text js-progress-text\"></div><div class=\"progress\"><div class=\"progress__bar progress__bar--notice progress__bar--striped is-active js-progress-bar\"></div></div>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const bytes = require('bytes')
const bindAll = require('lodash.bindall')

module.exports = window.Backbone.View.extend({
  className: 'in-progress-item',

  events: { 'click .js-clear': 'handleClear' },

  initialize() {
    bindAll(this)
    this.listenTo(this.model, 'uploadProgress', this.onProgress)
    this.listenTo(this.model, 'uploadEnd', this.onEnd)
    this.listenTo(this.model, 'uploadError', this.onError)
    this.listenTo(this.model, 'clear', this.onClear)
    this.render()
  },

  render() {
    this.$el.append(template(this.model.toJSON()))
  },

  onProgress(p) {
    this.$('.js-progress-bar').css({ width: p + '%' })
    const total = this.model.get('size')
    const uploaded = (p / 100) * total
    this.$('.js-progress-text').text(
      bytes(uploaded) + ' of ' + bytes(total) + ' uploaded'
    )
  },

  onEnd() {
    this.$('.js-progress-bar')
      .removeClass('progress__bar--notice')
      .removeClass('is-active')
      .addClass('progress__bar--success')
    this.$('.js-progress-text').text('Done')
    this.model.isClearable = true
    this.$('.js-clear').show()
  },

  onError(message) {
    this.$('.js-error').append(message)
    this.$('.js-progress-bar')
      .removeClass('progress__bar--notice')
      .removeClass('is-active')
      .addClass('progress__bar--error')
      .css({ width: '100%' })
    this.model.isClearable = true
    this.$('.js-clear').show()
  },

  onClear() {
    this.remove()
  },

  handleClear() {
    this.model.trigger('clear', this.model)
  }
})
