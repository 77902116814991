const View = require('ventnor')
const faqTypes = require('../../../service/faq/types')

class FaqSelectView extends View {
  constructor(serviceLocator, value) {
    super(serviceLocator, value)
    this.value = value
    this.faqs = faqTypes.all
    this.$el = $('<select/>')
    this.$el.attr('name', 'faq')
    this.$el.addClass('control control--choice')
    this.$el.on('change', () => {
      this.emit('change', this.$el.val())
    })
    this.notSetLabel = '-- Select a Type --'
  }

  render() {
    this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
    this.populate()
    return this
  }

  populate() {
    this.faqs.forEach(faq => {
      this.$el.append(
        $('<option/>', {
          text: faq.text,
          value: faq.type,
          selected: this.type === faq.type
        })
      )
    })
    return this
  }
}

module.exports = FaqSelectView
