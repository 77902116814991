const Model = require('merstone')
const createSchema = require('../../../service/article/schema')
const WidgetAreaModel = require('../../widget/models/widget-area')
const ImageAreaModel = require('../../asset/models/image-area')

class ArticleModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)
    this.schemata = createSchema()
    this.attributes = this.schemata.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schemata.cast(attrs))
    })

    this.body = new WidgetAreaModel(this.get('body'), {
      abstractWidgetFactory: serviceLocator.widgetFactories.get('articleBody')
    })

    this.relatedWidgets = new WidgetAreaModel(this.get('relatedWidgets'), {
      abstractWidgetFactory: serviceLocator.widgetFactories.get(
        'relatedArticles'
      )
    })

    this.body.on('add remove change', () => {
      this.set('body', this.body.toJSON())
    })

    this.relatedWidgets.on('add remove change', () => {
      this.set('relatedWidgets', this.relatedWidgets.toJSON())
    })

    this.images = new ImageAreaModel(this.get('images'))

    this.images.on('add remove change', () => {
      this.set('images', this.images.toJSON())
    })
  }

  makeDefault(cb) {
    const serviceLocator = this.serviceLocator

    serviceLocator.accountService.read(
      serviceLocator.session.account,
      (err, account) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        this.set(this.schemata.makeDefault())
        cb(null, this, account)
      }
    )
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  isInDate() {
    const now = new Date()
    // No schedule settings
    if (!this.get('liveDate') && !this.get('expiryDate')) return true
    // Live date in the past, no expiry date
    if (now - this.get('liveDate') > 0 && !this.get('expiryDate')) return true
    // Live date in the past, expiry date in the future
    if (now - this.get('liveDate') >= 0 && this.get('expiryDate') - now > 0)
      return true
    // No live date, expiry date in the future
    if (!this.get('liveDate') && this.get('expiryDate') - now > 0) return true
    // Otherwise not in date
    return false
  }

  getVisibilityState() {
    switch (this.get('state')) {
      case 'Draft':
        return 'draft'
      case 'Archived':
        return 'archived'
      case 'Published':
        if (this.isInDate()) {
          return 'published'
        } else {
          return 'publishedHidden'
        }
    }
  }

  getScheduleState() {
    const parseDate = dateString => {
      if (!dateString) return null
      const date = new Date(dateString)
      return isNaN(date.getTime()) ? null : new Date(date)
    }

    const now = new Date()
    const threeDays = 3 * 24 * 60 * 60 * 1000
    const liveDate = parseDate(this.get('liveDate'))
    const expiryDate = parseDate(this.get('expiryDate'))

    // No schedule info
    if (!liveDate && !expiryDate) return 'notScheduled'

    // expiryDate in past
    if (expiryDate && now - expiryDate > 0) return 'expired'

    //
    // From here on in, expiryDate is either not set or in future:
    //

    // liveDate in future, expiryDate not set or in future
    if (liveDate - now > 0 && (!expiryDate || liveDate - now > 0)) {
      if (this.get('state') !== 'Published') {
        // Draft, Archived states
        if (liveDate - now < threeDays) return 'scheduledLiveSoonNotPublished'
        // Published
        return 'scheduledFuture'
      }

      return 'scheduledFuture'
    }

    // liveDate in past, expiryDate not set or in future
    if (now - liveDate > 0) {
      if (this.get('state') !== 'Published') {
        // Not published
        return 'scheduledLiveNowNotPublished'
      } else if (!expiryDate) {
        // Published, no expiryDate
        return 'scheduledInDateWillNotExpire'
      } else {
        // Published, expiryDate in future
        // Different state for expiring soon (less than 3 days)
        return expiryDate - now > threeDays
          ? 'scheduledInDateWillExpire'
          : 'scheduledInDateExpiringSoon'
      }
    }
  }
}

module.exports = ArticleModel
