const modal = require('modal')
const BaseWidgetView = require('../../widget/views/form/base')

module.exports = (name, template, FormView) =>
  BaseWidgetView.extend({
    events: Object.assign({}, BaseWidgetView.prototype.events, {
      'click .js-edit': 'handleEdit',
      'click .js-item-remove': 'handleRemove',
      'click .js-item-duplicate': 'handleDuplicate'
    }),

    initialize: function(model, serviceLocator) {
      this.model = model
      this.serviceLocator = serviceLocator
      this.render()
    },

    handleDuplicate: function(e) {
      e.preventDefault()
      this.trigger('duplicate', this.model)
    },

    handleEdit: function(e) {
      e.preventDefault()
      const itemFormView = new FormView(this.model, this.serviceLocator)
      this.modal = modal({
        title: `Edit ${name}`,
        className: 'wide',
        buttons: [],
        content: itemFormView.$el,
        clickOutsideToClose: false
      })
      itemFormView.on(
        'save',
        function() {
          this.modal.close()
          this.trigger('re-render')
        }.bind(this)
      )
      itemFormView.on(
        'cancel',
        function() {
          this.modal.close()
        }.bind(this)
      )
    },

    handleRemove: function(e) {
      e.preventDefault()
      modal({
        content: 'Are you sure you want to delete this item?',
        buttons: [
          { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
          { text: 'Delete', event: 'confirm', className: 'btn btn--action' }
        ]
      }).on(
        'confirm',
        function() {
          this.trigger('remove', this.model.id)
        }.bind(this)
      )
    },

    render: function() {
      const data = this.model.toJSON()
      this.$el.empty().append(template({ data: data }))
      this.$el.addClass('js-selected-item list-item')
      this.$el.data('id', this.model.id)
    }
  })
