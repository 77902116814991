const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><div class=\"list-item-actions\">");
jade_mixins["stateMap"](null, data.visibility);
if ( allowed('faq', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
if ( allowed('faq', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/admin/faqs/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = data.title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.dateCreated, 'calendar')) ? "" : jade_interp)) + "</dd></dl>");
if ( data.tags && data.tags.length > 0)
{
buf.push("<dl><dt>Tags:</dt><dd>" + (jade.escape(null == (jade_interp = data.tags.map(tag => tag.tag).join(', ')) ? "" : jade_interp)) + "</dd></dl>");
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const stateMap = require('../../article/state-map')

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, instances, accounts) {
    super(serviceLocator, model, instances, accounts)
    this.accounts = accounts
  }

  render() {
    const data = this.model.toJSON()

    const getVisibilityState = stateMap.visibility.get(
      this.model.getVisibilityState()
    )
    data.visibility = getVisibilityState()

    this.$el.empty().append(
      template({
        data: data,
        account: this.serviceLocator.session.account,
        allowed: this.serviceLocator.allowed,
        config: this.serviceLocator.config,
        format: this.serviceLocator.format
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListItemView
