const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button class=\"btn js-cancel\">Cancel</button></div><div class=\"control-group\"><button class=\"btn btn-primary js-save\">Save</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"notification notification--notice js-order-info\"> \nDrag " + (jade.escape((jade_interp = name.plural) == null ? '' : jade_interp)) + " into the desired order then click Save</div><div class=\"dd\"><ol class=\"js-items dd-list\"></ol></div></div></div>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const View = require('ventnor')
const ListItemView = require('./reorder-list-item')
const debug = require('../../../../admin/source/js/lib/debug')(
  'section reorder view'
)
const async = require('async')

class ReorderView extends View {
  constructor(serviceLocator, collection) {
    super(...arguments)

    this.collection = collection
    this.$el.on('click', '.js-save', this.handleSaveOrder.bind(this))
    this.$el.on('click', '.js-cancel', this.handleCancelOrder.bind(this))

    this.listenTo(collection, 'add', this.addItem.bind(this))
    this.listenTo(collection, 'change', this.updateItem.bind(this))
    this.listenTo(collection, 'remove', this.removeItem.bind(this))
    this.listenTo(collection, 'reset', this.reset.bind(this))

    // Compatibility with backbone admin
    this.trigger = super.emit
  }

  get name() {
    return {
      singular: 'Standard Data Item',
      plural: 'Standard Data Items',
      resource: 'standardData'
    }
  }

  addItem(model) {
    debug('add item', this, model)
    // Ignore root items
    if (model.get('root')) {
      return true
    }

    if (this.$el.find('[data-id=' + model.get('_id') + ']').length !== 0) {
      return
    }

    let $parent = this.$el.find('.js-items')
    const parentId = model.get('parent')
    const a = $('<ol class="dd-list">')
    const newItem = this.createItemView(this.serviceLocator, model).render()
    if (parentId) {
      $parent = this.$el.find('.js-items [data-id=' + parentId + ']')
      if ($parent.find('ol').length > 0) {
        $parent = $parent.find('ol')
      } else {
        $parent.append(a)
        $parent = a
      }
    }

    $parent.append(
      $('<li class="dd-item compact" data-id=' + model.get('_id') + '>').append(
        newItem.$el
      )
    )
  }

  createItemView(serviceLocator, model) {
    var newItem = new ListItemView(serviceLocator, model)
    this.attachView(newItem)
    return newItem
  }

  updateItem(model) {
    debug('update', model)

    // Ignore root items
    if (model.get('root')) {
      return true
    }

    const $current = this.$('[data-id=' + model.id + '] > div')
    const newItem = this.createItemView(model)

    if ($current.length !== 0) {
      $current.replaceWith(newItem.$el)
    } else {
      this.emit('updateCollection')
    }
  }

  removeItem(model) {
    debug('remove', model)

    // Ignore root items
    if (model.get('root')) {
      return true
    }

    this.$('[data-id=' + model.id + ']').remove()
  }

  handleSaveOrder() {
    let orderCounter = 1
    const self = this
    const newOrder = this.$el.find('.dd').nestable('serialize')

    const successCallback = () => {
      self.emit('save')
    }

    const processChildren = (parent, item, cb) => {
      async.each(
        item,
        (childItem, done) => {
          var model = self.collection.get(childItem.id)
          debug('setting ' + childItem.id + ' to parent ' + parent)
          model.set('slug', model.get('slug'))
          model.set('parent', parent)
          model.set('order', orderCounter++)
          this.emit('update', model, () => {
            if (childItem.children) {
              processChildren(childItem.id, childItem.children, done)
            } else {
              done()
            }
          })
        },
        cb
      )
    }

    processChildren(null, newOrder, successCallback)
  }

  handleCancelOrder() {
    this.emit('cancel')
  }

  handleNew() {
    this.emit('new')
  }

  setupNestable() {
    $('.dd').nestable({
      expandBtnHTML: '',
      collapseBtnHTML: '',
      maxDepth: 1
    })
  }

  render() {
    this.$el.empty().append(
      template({
        title: this.title,
        name: this.name,
        allowed: window.serviceLocator.allowed
      })
    )

    this.collection.forEach(faq => {
      this.addItem(faq)
    })

    return this
  }

  reset() {
    this.render()
    this.setupNestable()
  }

  get template() {
    return ListItemView
  }
}

module.exports = ReorderView
