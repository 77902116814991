import model from './models/model'
import editView from './views/form'
import itemView from './views/item'

const initWidget = () => ({ listWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'List',
    description: 'List'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('list', widget)
  )

  done()
}

module.exports = initWidget
