const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const validity = require('validity')

const isNotEmpty = value => value && value.length > 0
const nonEmptyValidator = validity.createValidatorAllowingFailureMessageOverride(
  validity.booleanToCallback(isNotEmpty),
  'At least one value is required'
)

const extraProperties = {
  header: {
    type: String
  },
  models: {
    type: Array,
    validators: [
      validateIfPropertyEquals('filter', 'models', nonEmptyValidator)
    ]
  },
  usedVehicles: {
    type: Array,
    validators: [
      validateIfPropertyEquals('filter', 'usedVehicles', nonEmptyValidator)
    ]
  },
  filter: {
    type: String,
    validators: [required]
  }
}

module.exports = () => createSchema('Vehicle Carousel Widget', extraProperties)
