const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

const createController = serviceLocator => {
  serviceLocator.router.route('customer/download/:id', 'download', id => {
    if (!serviceLocator.allow('customer', 'read')) return false

    window.location = createSignedApiUrl(
      `/customer/download-file/${id}`,
      window.localStorage.apiKey,
      window.localStorage.apiId,
      window.config.apiUrl
    )

    serviceLocator.router.navigate('customer/', { trigger: true })
  })
}

module.exports = createController
