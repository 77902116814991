const initWidget = () => ({
  imageButtonGroupWidget: ['widget', 'sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Image Button Group',
    itemView: require('./views/item'),
    description: ''
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('imageButtonGroup', widget)
  )

  done()
}

module.exports = initWidget
