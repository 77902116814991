const schemata = require('schemata')
const required = require('validity-required')

module.exports = () => {
  return schemata({
    name: 'Standard Data',
    properties: {
      _id: {
        type: String
      },
      value: {
        type: String
      },
      category: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      order: {
        type: Number
      }
    }
  })
}
