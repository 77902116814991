const BaseSelect = require('../../base/views/select')
const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')

const defaultSelectizeConfig = {
  delimiter: ',',
  create: true,
  preload: true
}

class ApiSelect extends BaseSelect {
  constructor(serviceLocator, options, field, limitOne = true) {
    const newOptions = {
      ...options,
      selectizeConfig: Object.assign(
        {},
        options.selectizeConfig,
        defaultSelectizeConfig
      ),
      limitToOneValue: limitOne
    }

    super(serviceLocator, newOptions)

    this.getData = this.getData.bind(this)
    this.field = field
    this.apiRoute = options.apiRoute
    this.authedRequest = createAuthedRequester(
      serviceLocator.config.apiUrl
    ).bind(this)
    this.noFilterOnChange = options.noFilterOnChange
    this.idProperty = options.idProperty
    this.nameProperty = options.nameProperty || 'name'

    this.mapResult =
      options.mapResult ||
      (res => ({
        value: res,
        text: res
      }))
    this.initialData
  }

  initializeSelectize() {
    const allOptionIndex = this.selected.indexOf('all')
    const allSelected = [...this.selected]
    if (allOptionIndex > -1) this.selected.splice(allOptionIndex, 1)
    this.getData(null, (err, data) => {
      if (err) return alert(`Cannot find existing ${this.singular}`)

      // This ensure they stay in the same order
      allSelected.forEach(value => {
        if (!value) return

        let text = value

        if (this.idProperty) {
          const dataItem = data.find(datum => datum[this.idProperty] === value)
          text = dataItem[this.nameProperty]
        }

        this.el.selectize.addOption({
          value,
          text
        })
        // Select the added option
        this.el.selectize.addItem(value)
      })
      this.el.selectize.on('change', this.updateSelection.bind(this))
      this.initialised = true
      this.emit('initialised')
    })
  }

  load(query, cb) {
    this.getData(query, (err, data) => {
      if (err) return cb(err)
      const list = data.map(this.mapResult)

      cb(list)
    })
  }

  getData(query, cb) {
    if (this.noFilterOnChange && this.initialData)
      return cb(null, this.initialData)

    this.query = query
    this.authedRequest('GET', this.apiRoute, query, (err, res, body) => {
      if (err) return cb(err)
      if (!this.initialData) this.initialData = body

      cb(null, body)
    })
  }
}

module.exports = ApiSelect
