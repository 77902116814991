const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/review-entity')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const ReviewEntityService = require('./services/review-entity')

const initAdmin = () => ({ reviewEntityAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.reviewEntityService = new ReviewEntityService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'review entity',
    plural: 'review entities',
    path: 'review-entities',
    service: serviceLocator.reviewEntityService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
