const schemata = require('schemata')
const pick = require('lodash.pick')

const createChannels = channels =>
  schemata({
    name: 'Channels',
    properties: pick(
      {
        email: {
          type: Boolean
        },
        sms: {
          type: Boolean
        },
        phone: {
          type: Boolean
        },
        electoralRegister: {
          type: Boolean
        },
        dvla: {
          type: Boolean
        },
        vwfs: {
          type: Boolean
        },
        experian: {
          type: Boolean
        }
      },
      channels
    )
  })

const createPermissions = (
  types = ['marketing'],
  channels = [
    'email',
    'sms',
    'phone',
    'dvla',
    'electoralRegister',
    'vwfs',
    'experian'
  ]
) => {
  return schemata({
    name: 'Permissions',
    properties: pick(
      {
        marketing: {
          type: createChannels(channels)
        }
      },
      types
    )
  })
}

module.exports = createPermissions
