const imageSchema = require('../../../admin/asset/schema/image')()
const imageConfig = require('../../../../lib/image-config')

const convertImages = asset => {
  const convertedAsset = {
    ...imageSchema.makeDefault({ type: 'image' }),
    ...asset
  }

  convertedAsset.selectedContexts = [imageConfig.contexts[0].name]

  convertedAsset.crops = [
    {
      name: 'Free',
      aspectRatio: '*',
      coordinates: {
        x1: 0,
        y1: 0,
        x2: asset.width,
        y2: asset.height,
        w: asset.width,
        h: asset.height
      },
      src: asset.binaryUri
    }
  ]

  return convertedAsset
}

module.exports = convertImages
