const FormView = require('./views/form')
const Model = require('./models/prep-form-entry')
const baseFormController = require('./controllers/form')
const PrepFormEntryService = require('./services/prep-form-entry')

const initAdmin = () => ({ prepFormAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.prepFormEntryService = new PrepFormEntryService(serviceLocator)

  const controllerOptions = {
    serviceLocator,
    singular: 'prepFormEntry',
    plural: 'prepFormEntries',
    path: 'prep-form',
    listPath: '',
    service: serviceLocator.prepFormEntryService,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['createdDate', 'desc']
    },
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  cb(null)
}

module.exports = initAdmin
