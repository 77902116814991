module.exports = initAdmin
const RevisionService = require('./services/revision')

function initAdmin() {
  return { revisionAdmin: init }
}

function init(serviceLocator, done) {
  serviceLocator.revisionService = new RevisionService(serviceLocator)
  done()
}
