const AssetModel = require('../models/asset')
const BaseCollection = require('../../../../admin/source/js/lib/base-collection')
const config = window.config

module.exports = BaseCollection.extend({
  model: AssetModel,
  pageSize: 20,

  url: config.apiUrl + '/assets',

  getByType(/* assetType */) {
    var filterType = {}
    // We only have images and this is going to cause slower queries
    // var filterType = { type: assetType }
    // if (assetType === 'image') {
    //   filterType = { type: { $in: ['image', null] } }
    // }
    this.applyFilter({ filter: filterType })
  }
})
