const notify = require('../../notification/foreground')
const qs = require('querystring')

const formController = ({
  serviceLocator,
  service,
  path,
  listPath,
  singular,
  Model,
  FormView
}) => {
  const basePath = listPath || path
  // Edit
  serviceLocator.router.route(`${path}/view-form`, `edit${singular}`, () => {
    if (!location.search) return back()
    const query = qs.parse(location.search.replace('?', ''))

    if (!query.reg) {
      return back()
    }

    serviceLocator.usedVehicleService.find(
      '',
      { registration: query.reg },
      [],
      { page: 1, pageSize: 500 },
      (err, res) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        const [vehicle] = res.results

        if (!vehicle) {
          return serviceLocator.router.navigate(`${path}/form`, {
            trigger: true
          })
        }

        serviceLocator.router.navigate(`${path}/${vehicle._id}/form`, {
          trigger: true
        })
      }
    )
  })

  serviceLocator.router.route(
    `${path}/:id/form`,
    `edit${singular}`,
    async id => {
      if (!serviceLocator.allow(singular, 'update')) return false

      const autotraderData = await new Promise(resolve => {
        serviceLocator.usedVehicleService.getAutoTraderData(id, (err, data) => {
          if (err) {
            serviceLocator.logger.error(err)
            resolve(null)
          }

          resolve(data)
        })
      })

      service.read(id, (err, entity) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)

        const search = qs.stringify({ reg: entity.registration })

        serviceLocator.router.navigate(`${path}/${id}/form?${search}`, {
          trigger: false,
          replace: true
        })

        const form = new FormView(
          serviceLocator,
          new Model(serviceLocator, entity),
          false,
          autotraderData
        ).render()

        serviceLocator.router.render(form, `Edit ${singular}`)

        form.on('back', back)

        form.on('save', () =>
          saveExisting(id, form, err => {
            if (err) return
            notify('Saved', 'save')
          })
        )

        form.on('imported', saved => {
          notify('Saved', 'save')
          form.hasUnsavedChanges = () => false
          serviceLocator.router.navigate(`${path}/` + saved._id + '/form', {
            trigger: true,
            replace: true
          })
        })

        form.on('saveAndClose', () =>
          saveExisting(id, form, err => {
            if (err) return
            notify('Saved', 'save')
            back()
          })
        )

        form.on('publish', () =>
          save(form, false, id, 'published', 'Published', () => back())
        )

        form.on('archive', () =>
          save(form, false, id, 'archived', 'Archived', () => back())
        )

        form.on('draft', () =>
          save(form, false, id, 'draft', 'Draft', () => back())
        )
      })
    }
  )

  serviceLocator.router.route(
    `${path}/:id/duplicate`,
    `duplicate${singular}`,
    async id => {
      if (!serviceLocator.allow(singular, 'duplicate')) return false

      service.read(id, (err, entity) => {
        if (err) {
          return serviceLocator.router.trigger('notFound', err.message)
        }

        ;[
          '_id',
          'id',
          'vin',
          'state',
          'registration',
          'autoTraderStockId',
          'autoTraderSearchId',
          'previousAutoTraderStockId',
          'needsIccUpdate',
          'createdDate',
          'expiredDate',
          'reservedDate',
          'previewId'
        ].forEach(field => delete entity[field])

        entity.dataOrigin = 'duplicate'

        const form = new FormView(
          serviceLocator,
          new Model(serviceLocator, entity),
          true
        ).render()

        serviceLocator.router.render(form, `New ${singular}`)
        setupSaveNewForm(form)
      })
    }
  )

  // Create
  serviceLocator.router.route(`${path}/form`, `edit${singular}`, () => {
    if (!serviceLocator.allow(singular, 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    const form = new FormView(serviceLocator, model, true).render()
    const pageTitle = `${singular.charAt(0).toUpperCase()}${singular.slice(1)}`

    serviceLocator.router.render(form, `New ${pageTitle}`)
    setupSaveNewForm(form)
  })

  const setupSaveNewForm = form => {
    form.on('back', back)

    form.on('save', () =>
      saveNew(form, (err, saved) => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate(`${path}/` + saved._id + '/form', {
          trigger: true,
          replace: true
        })
      })
    )

    form.on('imported', saved => {
      notify('Saved', 'save')
      form.hasUnsavedChanges = () => false
      serviceLocator.router.navigate(`${path}/` + saved._id + '/form', {
        trigger: true,
        replace: true
      })
    })

    form.on('saveAndClose', () =>
      saveNew(form, err => {
        if (err) return
        notify('Saved', 'save')
        serviceLocator.router.navigate(basePath, { trigger: true })
      })
    )

    form.on('publish', () =>
      save(form, true, null, 'published', 'Published', () => back())
    )

    form.on('archive', () =>
      save(form, true, null, 'archived', 'Archived', () => back())
    )

    form.on('draft', () =>
      save(form, true, null, 'draft', 'Draft', () => back())
    )
  }

  const save = (form, isNew, vehicleId, icon, state, cb) => {
    const previousState = form.model.get('state') || 'Draft'
    const service = serviceLocator.usedVehicleService
    let saveFn
    let id

    if (state) form.model.set('state', state)

    if (state === 'Archived') {
      form.model.set('expiredDate', new Date())
    } else if (state === 'Published') {
      form.model.set('publishedDate', new Date())
    }

    if (isNew && !form.model.get('_id')) {
      saveFn = service.create.bind(service, form.model.toJSON())
    } else {
      id = vehicleId || form.model.get('_id')
      saveFn = service.update.bind(service, id, form.model.toJSON())
    }

    afterSave(form, saveFn, (err, entity) => {
      if (err) return form.model.set('state', previousState)
      notify(state || 'Saved', icon)
      if (typeof cb === 'function') cb(null, entity)
    })
  }

  const afterSave = (form, saveFn, cb) => {
    saveFn((err, data) => {
      if (err) {
        form.showErrors(err.errors)
        return cb(err)
      }
      form.model.reset(data)
      form.clearUnsavedChanges()
      form.clearErrors()
      cb(null, data)
    })
  }

  const saveExisting = (id, form, cb) => save(form, false, id, 'save', null, cb)
  const saveNew = (form, cb) => save(form, true, null, 'save', null, cb)

  const back = () => history.back()
}

module.exports = formController
