const required = require('validity-required')
const schemata = require('schemata')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () => {
  const schema = schemata({
    name: 'CaseStudy',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      heading: {
        type: String,
        validators: [required]
      },
      subHeading: {
        type: String
      },
      sell: {
        type: String
      },
      images: {
        type: Object,
        defaultValue: () => [],
        resolveType: Array,
        resolve: async caseStudy => resolveImages(caseStudy.images)
      },
      logo: {
        type: Object,
        defaultValue: () => [],
        resolveType: Array,
        resolve: async caseStudy => resolveImages(caseStudy.logo)
      },
      quotation: {
        type: String
      },
      quotationAuthor: {
        type: String
      },
      body: {
        type: String,
        validators: [required]
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
