module.exports = ListView
const BaseListView = require('../../base/views/list')
const ListItemView = require('./list-item')
const ComparisonView = require('./comparison')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn--action js-compare\">Compare Selected</button></div></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.title + ' ' + name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\"><table class=\"table--striped js-items\"><tr> <th>#</th><th>Old</th><th>New</th><th>Date</th><th>Author</th><th>Note</th><th>Action</th></tr></table></div></div></div></div>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const modal = require('modal')

function ListView(
  serviceLocator,
  collection,
  paginationModel,
  type,
  entityId,
  title
) {
  this.type = type
  this.entityId = entityId
  this.name.title = title
  BaseListView.apply(this, arguments)
  this.$el.on('change', 'input[type=radio]', this.updateRadioButtons.bind(this))
  this.$el.on('click', '.js-compare', this.getComparisonData.bind(this))
  this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
}

ListView.prototype = Object.create(BaseListView.prototype)

ListView.prototype.name = {
  singular: 'Revision',
  plural: 'Revisions',
  resource: 'revision'
}

ListView.prototype.ListItemView = ListItemView

ListView.prototype.template = template

ListView.prototype.addListItem = function(model, index) {
  var listItem = new this.ListItemView(
    this.serviceLocator,
    model,
    index,
    this.collection.length
  )
  this.listenTo(listItem, 'restoreRevision', this.restoreRevision.bind(this))
  this.attachView(listItem)
  this.$el.find('.js-items').append(listItem.render().$el)
}

ListView.prototype.updateRadioButtons = function(e) {
  const $radioButtons = this.$el.find('input[type=radio]')
  const $current = e ? $(e.target) : null
  const newRevisionId = this.$el.find('.js-new:checked').data('index')
  const oldRevisionId = this.$el.find('.js-old:checked').data('index')
  let $context

  $radioButtons.removeAttr('disabled')

  this.$el
    .find('.js-old')
    .slice(0, newRevisionId)
    .hide()
  this.$el
    .find('.js-old')
    .slice(newRevisionId)
    .show()

  if (oldRevisionId >= newRevisionId - 1) {
    // Check the next radio button down on the list
    this.$el
      .find('.js-old')
      .eq(newRevisionId)
      .attr('checked', 'checked')
  }

  $context = this.$el

  // Loop through all radio buttons and disable as appropriate
  $radioButtons.each(function() {
    if ($(this).is(':checked')) {
      $context
        .find('input[value=' + $(this).attr('value') + ']')
        .not($(this))
        .attr('disabled', 'disabled')
    }
  })

  if ($current) {
    // Disable the opposite radio button for this event
    this.$el
      .find('input[value=' + $current.attr('value') + ']')
      .not($current)
      .attr('disabled', 'disabled')
  }
}

ListView.prototype.getComparisonData = function() {
  const oldId = this.$el.find('.js-old:checked').val()
  const newId = this.$el.find('.js-new:checked').val()
  this.serviceLocator.revisionService.compareRevisions(
    this.entityId,
    this.type,
    oldId,
    newId,
    function(error, data) {
      if (error)
        return this.serviceLocator.logger.error(
          error,
          'Could not load revisions'
        )
      this.showComparison(data)
    }.bind(this)
  )
}

ListView.prototype.showComparison = function(data) {
  const view = new ComparisonView(this.serviceLocator, data).render()
  view.on('restoreRevision', this.restoreRevision.bind(this))
  modal({
    title: 'Compare Revisions',
    className: 'wide',
    content: view.$el,
    buttons: [{ text: 'Close', className: 'btn', keyCodes: [27] }]
  })
}

ListView.prototype.restoreRevision = function(revisionId, index) {
  modal({
    title: 'Restore',
    content: 'Are you sure you want to restore this revision?',
    buttons: [
      { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
      { text: 'Restore', event: 'confirm', className: 'btn btn--action' }
    ]
  }).on(
    'confirm',
    function() {
      this.serviceLocator.revisionService.restoreRevision(
        this.entityId,
        this.type,
        revisionId,
        index,
        function(error, data) {
          if (error)
            return this.serviceLocator.logger.error(
              error,
              'Could not restore revision'
            )
          if (data) window.location.reload()
        }.bind(this)
      )
    }.bind(this)
  )
}
