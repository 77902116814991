const CrudService = require('../../../../admin/source/js/lib/crud-service')

class DealershipPushService extends CrudService {
  get name() {
    return 'DealershipPushService'
  }
  get urlRoot() {
    return '/dealerships/push'
  }
}

module.exports = DealershipPushService
