const config = window.config
const limiter = require('p-limit')

module.exports = async function(images, type) {
  let processed = 0,
    errors = 0

  const className = `.js-notification-area[data-type=${type}]`

  const limit = limiter(config.plateRecogniserRateLimit)

  this.$el.find(className).empty().append(`
    <div class="notification">
      Processing ${images.length} images...
    </div>
  `)

  const processImage = async image =>
    limit(async () => {
      try {
        await image.blurImage()
        processed += 1
        this.$el.find(className).empty().append(`
          <div class="notification">
            Processed ${processed} of ${images.length}
          </div>
        `)
      } catch (e) {
        errors += 1
      }
    })

  await Promise.all(images.map(processImage))

  if (errors > 1) {
    return this.$el.find(className).empty().append(`
      <div class="notification">
        Processed ${processed} of ${images.length} (${errors} failures)
      </div>
    `)
  }

  return this.$el.find(className).empty().append(`
    <div class="notification">
      Processed all ${images.length} images
    </div>
  `)
}
