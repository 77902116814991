const Model = require('merstone')
const createSchema = require('../../../service/offer/schema')
const WidgetAreaModel = require('../../widget/models/widget-area')
const ImageAreaModel = require('../../asset/models/image-area')

class OfferModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schemata = createSchema()
    this.attributes = this.schemata.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schemata.cast(attrs))
    })

    this.body = new WidgetAreaModel(this.get('body'), {
      abstractWidgetFactory: serviceLocator.widgetFactories.get('offerBody')
    })

    this.body.on('add remove change', () => {
      this.set('body', this.body.toJSON())
    })
    ;['images', 'promoImages'].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.attributes[imageType])

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }

  getVisibilityState() {
    switch (this.get('state')) {
      case 'Draft':
        return 'draft'
      case 'Published':
        return 'published'
    }
  }
}

module.exports = OfferModel
