const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

const extraProperties = {
  location: {
    type: String,
    validators: []
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Careers List', extraProperties),
  type: 'careersList',
  defaults() {
    return this.schemata.makeDefault({
      type: this.type || 'usedVehicleCarousel'
    })
  },

  validate: validateDelegate
})
