const View = require('ventnor')
const ApiSelect = require('../../base/views/api-select')
const Model = require('merstone')
const join = require('path').join
const compileJade = require('browjadify-compile')
const container = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Auto.Works Image Set Importer</h2><p>Replace this vehicle&rsquo;s images with an existing Image Set from Auto.Works Studio ICC or the Auto.Works App</p></div><div class=\"panel-content\"><div id=\"field--icc-images\" class=\"form-row form-row-full-width\"><div class=\"js-icc-select\"></div></div><div class=\"form-row form-row-full-width\"><a class=\"btn js-lookup\">Lookup Vehicle</a></div><div style=\"display: none\" class=\"js-images-preview\"><h3 class=\"form-row js-preview-title\">Previewing Vehicle</h3><div class=\"form-row\"><img style=\"max-height: 400px;\" class=\"image-contain js-image\"/></div><div class=\"form-row form-row-actions\"><input value=\"Add Images\" data-context=\"exteriorImages\" class=\"btn btn--success js-confirm\"/></div></div><div style=\"display: none\" class=\"notification notification--info js-notification\">Uploading Images...</div></div></div>");;return buf.join("");
}
const createDarkroomUrlBuilder = require('@clocklimited/darkroom-url-builder')

const optionTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (getImage, text, thumb) {
buf.push("<div class=\"icc-image-item\"><img" + (jade.attr("src", getImage(thumb), true, false)) + " loading=\"lazy\"/><div>" + (jade.escape((jade_interp = text) == null ? '' : jade_interp)) + "</div></div>");}.call(this,"getImage" in locals_for_with?locals_for_with.getImage:typeof getImage!=="undefined"?getImage:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined,"thumb" in locals_for_with?locals_for_with.thumb:typeof thumb!=="undefined"?thumb:undefined));;return buf.join("");
}

const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')
const createExteriorPreview = require('./lib/exterior-previews')

class ICCImageArea extends View {
  constructor(serviceLocator, model) {
    super(serviceLocator)
    this.vehicle = model
    this.model = new Model()

    this.$el.on('click', '.js-confirm', this.applyToModel.bind(this))

    this.model.on('change:selected', this.renderPreview.bind(this))

    this.authedRequest = createAuthedRequester(
      serviceLocator.config.apiUrl
    ).bind(this)

    Object.assign(this, createExteriorPreview(this.getImageUrl.bind(this)))

    this.$el.on('click', '.js-previous', this.setImage.bind(this, -1))
    this.$el.on('click', '.js-next', this.setImage.bind(this, +1))
    this.$el.on('click', '.js-pause', this.togglePaused.bind(this))

    this.$el.on('click', '.js-lookup', this.findVehicle.bind(this))
  }

  applyToModel() {
    const set = this.model.get('iccImageSet')

    this.$el.find('.js-images-preview').hide()
    this.$el
      .find('.js-notification')
      .text('Uploading...')
      .show()

    this.authedRequest(
      'POST',
      '/icc-images/process-images',
      set,
      (err, res, body) => {
        if (err) return alert(`Cannot process images for ${set}`)

        this.$el.find('.js-notification').hide()

        this.emit('receiveImages', body)
      }
    )
  }

  render() {
    this.$el.append(container())

    this.renderSelect()

    const imgs = this.vehicle.get('exteriorImages') || []

    if (imgs.length === 0) {
      this.findVehicle()
    }

    return this
  }

  renderPreview(selected) {
    this.$el.find('.js-images-preview').show()
    const [reg, vin] = selected.split(':')
    const reqUrl = `/icc-images/find-images/`
    this.authedRequest(
      'POST',
      reqUrl,
      {
        registration: reg,
        vin: vin
      },
      (err, res, body) => {
        if (!body || res.statusCode !== 200)
          return this.serviceLocator.logger.error(
            `No image returned from find-images call for ${reg}:${vin}`
          )
        if (err) return alert(`Cannot find images for ${reg}:${vin}`)

        this.model.set('iccImageSet', body)
        body.height = 300

        this.initialise360([body], 500)
        this.togglePaused()
      }
    )
  }

  findVehicle() {
    this.$el
      .find('.js-notification')
      .text('Finding images...')
      .show()

    const vehicle = this.vehicle.get('registration') || this.vehicle.get('vin')

    if (!vehicle) {
      return
    }

    const reqUrl = `/icc-images/find-images/`
    this.authedRequest(
      'POST',
      reqUrl,
      {
        registration: this.vehicle.get('registration'),
        vin: this.vehicle.get('vin')
      },
      (err, res, body) => {
        this.$el.find('.js-notification').hide()

        if (err) return alert(`Cannot find image set for ${vehicle}`)

        if (body && (body.reg || body.vin)) {
          this.$el.find('.js-icc-title').text(`Found images for ${vehicle}`)
          this.model.set('selected', `${body.reg}:${body.vin}`)
          this.model.set('iccImageSet', body)
        }
      }
    )
  }

  getImageUrl(item) {
    const resource = item.preview && item.preview.binaryUri
    if (!resource) return ''

    const { url, salt } = this.serviceLocator.config.darkroom
    const builder = createDarkroomUrlBuilder(url, salt)
    const height = item.height ? 400 : 50
    const imageUrl = builder()
      .resource(resource)
      .height(height)
      .filename('preview.jpg')
      .url()

    return imageUrl
  }

  renderSelect() {
    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto.Works Image Set',
      plural: 'Auto.Works Image Sets',
      apiRoute: '/icc-images/list-sets',
      mapResult: res => ({
        text: res.name,
        value: `${res.reg}:${res.vin}`,
        getImage: this.getImageUrl.bind(this, res)
      }),
      selectizeConfig: {
        render: { option: optionTemplate, item: optionTemplate }
      }
    })

    this.$el.find(`.js-icc-select`).append(select.render().$el)
    select.on('change', item => {
      this.model.set('selected', item)
    })
    this.attachView(select)
  }
}

module.exports = ICCImageArea
