const CrudService = require('../../../../admin/source/js/lib/crud-service')

class OfferCategoryService extends CrudService {
  get name() {
    return 'OfferCategoryService'
  }
  get urlRoot() {
    return '/offer-categories'
  }
}

module.exports = OfferCategoryService
