const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('heading', 'Heading', null, data.heading, true, 'text');
jade_mixins["inputField"]('subHeading', 'Sub-Heading', null, data.subHeading, false, 'text');
jade_mixins["inputField"]('sell', 'Sell', null, data.sell, false, 'text');
jade_mixins["inputField"]('quotation', 'Quotation', null, data.quotation, false, 'text');
jade_mixins["inputField"]('quotationAuthor', 'Quotation Author', null, data.quotationAuthor, false, 'text');
buf.push("</div></div><div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Body</h2></div><div class=\"panel-content\"><div id=\"field--body\" data-field=\"body\" class=\"form-row form-row-full-width\"><label><span class=\"vhidden\"></span><textarea name=\"body\" rows=\"5\" class=\"control control--text control--multiline js-text-editor form-field\">" + (jade.escape(null == (jade_interp = data.body) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div><div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Header Image</h2></div><div class=\"panel-content\"><div id=\"field--images\" class=\"form-row\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div><div class=\"form-row form-row-actions\"><input type=\"submit\" value=\"Choose Image\" data-context=\"images\" class=\"btn btn-success js-image-add\"/></div></div></div><div class=\"panel-header\"><h2>Logo Image</h2></div><div class=\"panel-content\"><div id=\"field--logo\" class=\"form-row\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div><div class=\"form-row form-row-actions\"><input type=\"submit\" value=\"Choose Image\" data-context=\"logo\" class=\"btn btn-success js-image-add\"/></div></div></div></div></form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../lib/multi-image-view')
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
const RichTextEditorInstanceManager = require('../../../../admin/source/js/lib/rich-text-editor-instance-manager')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account
    this.$el.on('click', '.js-image-add', e => this.handleAddImages(e))

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )

    Object.assign(this, createMultiImageViewFunctions())
    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )

    this.on('remove', () => {
      this.richTextEditorInstanceManager.destroy()
    })

    this.richTextEditorInstanceManager = new RichTextEditorInstanceManager()
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        data: this.model.toJSON()
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.renderImageWidgetArea('images')
    this.renderImageWidgetArea('logo')

    this.on('afterAppend', () => this.renderRichTextEditors())

    return this
  }

  renderRichTextEditors() {
    this.$el
      .find('.js-text-editor')
      .each((i, el) =>
        this.richTextEditorInstanceManager.create(el, { height: 200 })
      )
  }
}

module.exports = FormView
