import model from './models/model'
import editView from './views/form'
import itemView from './views/item'

const initWidget = () => ({ kubNavigationWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    itemView,
    editView,
    model,
    name: 'Sub-Navigation',
    description: 'Display the sub-nav'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('subNavigation', widget)
  )

  done()
}

module.exports = initWidget
