const BasicToolbarView = require('./form-toolbar')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, singular) {
buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div><div class=\"control-group\">");
if ( data.state === 'Published' && !allowed(singular, 'publish'))
{
buf.push("<span class=\"label label--large\">You don't have permission to alter Published content.</span>");
}
else
{
buf.push("<div class=\"btn-group\"><button type=\"button\" class=\"btn js-btn-save\">Save</button><a data-toggle=\"dropdown\" href=\"#\" class=\"btn dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a href=\"#\" class=\"js-btn-save-and-close\">Save and Close</a></li></ul></div>");
if ( allowed(singular, 'publish'))
{
if ( data.state === 'Published')
{
buf.push("<button type=\"button\" class=\"btn btn--warning js-btn-draft\">Revert to Draft</button>");
}
else
{
buf.push("<button type=\"button\" class=\"btn btn--action js-btn-publish\">Publish</button>");
}
}
}
buf.push("</div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"singular" in locals_for_with?locals_for_with.singular:typeof singular!=="undefined"?singular:undefined));;return buf.join("");
}
const modal = require('modal')

class ToolbarView extends BasicToolbarView {
  constructor(serviceLocator, model, parentView, singular) {
    super(...arguments)
    this.$el.on('click', '.js-btn-publish', this.emit.bind(this, 'publish'))
    this.$el.on('click', '.js-btn-draft', this.confirmDraft.bind(this))
    this.singular = singular
  }

  get template() {
    return template
  }

  confirmDraft() {
    modal({
      title: 'Revert to Draft',
      content: `This ${
        this.singular
      } will be removed from public view. Are you sure?`,
      buttons: [
        {
          text: 'Remain Published',
          event: 'cancel',
          className: 'btn',
          keyCodes: [27]
        },
        {
          text: 'Revert to Draft',
          event: 'confirm',
          className: 'btn btn--warning'
        }
      ]
    }).on('confirm', this.emit.bind(this, 'draft'))
  }

  render() {
    this.$el.empty().append(
      this.template({
        data: this.model.toJSON(),
        allowed: this.serviceLocator.allowed,
        singular: this.singular
      })
    )
    return this
  }
}

module.exports = ToolbarView
