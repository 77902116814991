const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format, imageCountSummary, imageSetConfig) {
buf.push("<div class=\"list-item list list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrl + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('imageSet', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("</div>");
var title = `${data.registration} - ${data.make} ${data.model} (${imageSetConfig.make} ${imageSetConfig.model})`
if ( allowed('imageSet', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/image-sets/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Images:</dt><dd>" + (jade.escape(null == (jade_interp = imageCountSummary.required.uploaded) ? "" : jade_interp)) + " / " + (jade.escape((jade_interp = imageCountSummary.required.config) == null ? '' : jade_interp)) + "</dd><dt>Optional Images:</dt><dd>" + (jade.escape(null == (jade_interp = imageCountSummary.optional.uploaded) ? "" : jade_interp)) + " / " + (jade.escape((jade_interp = imageCountSummary.optional.config) == null ? '' : jade_interp)) + "</dd><dt>Additional Images:</dt><dd>" + (jade.escape(null == (jade_interp = imageCountSummary.additional) ? "" : jade_interp)) + "</dd><br/><dt>Status:</dt><dd>" + (jade.escape(null == (jade_interp = data.status) ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"imageCountSummary" in locals_for_with?locals_for_with.imageCountSummary:typeof imageCountSummary!=="undefined"?imageCountSummary:undefined,"imageSetConfig" in locals_for_with?locals_for_with.imageSetConfig:typeof imageSetConfig!=="undefined"?imageSetConfig:undefined));;return buf.join("");
}
const getImageUrl = require('../../lib/default-image-url-generator')
const ImageModel = require('../models/items')

class ListItemView extends BaseListItemView {
  constructor(serviceLocator, model, imageSetConfig) {
    super(serviceLocator, model)
    this.imageSetConfig = imageSetConfig
  }

  serialize() {
    const data = this.model.toJSON()
    const images = this.model.get('images')
    const firstImageModel = new ImageModel(images ? images[0] : {})
    data.previewImageUrl = getImageUrl({
      model: firstImageModel,
      width: 80,
      height: 80,
      mode: 'pad'
    })
    return data
  }

  getImageCountSummary(imageSetConfig, modelImages) {
    const summary = {
      required: {
        config: 0,
        uploaded: 0
      },
      optional: {
        config: 0,
        uploaded: 0
      },
      additional: 0
    }
    imageSetConfig.setConfig.forEach(setConfig => {
      const uploadedImage = modelImages.find(
        i => i.imageId === setConfig.imageId
      )
      if (setConfig.required) {
        if (uploadedImage) {
          summary.required.uploaded++
        }
        summary.required.config++
      } else {
        if (uploadedImage) {
          summary.optional.uploaded++
        }
        summary.optional.config++
      }
    })

    modelImages.forEach(image => {
      if (image.name === 'Additional Image') {
        summary.additional++
      }
    })
    return summary
  }

  render() {
    const data = this.serialize()

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format,
        imageSetConfig: this.imageSetConfig,
        imageCountSummary: this.getImageCountSummary(
          this.imageSetConfig,
          data.images
        )
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
