module.exports=[
  {
    "id": 1628,
    "range": "Citigo"
  },
  {
    "id": 3299,
    "range": "Citigoe iV"
  },
  {
    "id": 4196,
    "range": "ELROQ"
  },
  {
    "id": 4186,
    "range": "ENYAQ"
  },
  {
    "id": 3924,
    "range": "ENYAQ Coupé iV"
  },
  {
    "id": 3392,
    "range": "ENYAQ iV"
  },
  {
    "id": 1198,
    "range": "Fabia"
  },
  {
    "id": 2218,
    "range": "Fabia Estate"
  },
  {
    "id": 3226,
    "range": "Kamiq"
  },
  {
    "id": 2746,
    "range": "Karoq"
  },
  {
    "id": 2702,
    "range": "Kodiaq"
  },
  {
    "id": 1202,
    "range": "Octavia"
  },
  {
    "id": 2219,
    "range": "Octavia Estate"
  },
  {
    "id": 1710,
    "range": "Rapid"
  },
  {
    "id": 2683,
    "range": "Rapid Spaceback"
  },
  {
    "id": 1203,
    "range": "Roomster"
  },
  {
    "id": 3116,
    "range": "Scala"
  },
  {
    "id": 1204,
    "range": "Superb"
  },
  {
    "id": 2217,
    "range": "Superb Estate"
  },
  {
    "id": 1495,
    "range": "Yeti"
  }
]
