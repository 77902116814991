const View = require('ventnor')
const Pagination = require('../../base/views/pagination')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"grid grid--reverse\"><div class=\"grid__item one-whole\"><div class=\"list-container\"><div class=\"controls\"><form action=\"#\" method=\"get\" class=\"js-filter\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Service Centre&quot; ] or [ Service -Centre ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div></form></div><div style=\"max-height: 450px; overflow:auto\" class=\"grid list-grid js-items\"></div></div><div class=\"pagination-small-spacing\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" style=\"margin:10px\" class=\"btn js-more\">Load more</button><button type=\"button\" style=\"margin:10px\" class=\"btn js-all\">Load all</button></div></div></div>");;return buf.join("");
}
const Collection = require('chale')
const ListItemView = require('./list-item')
const Model = require('merstone')
const modal = require('modal')
const StandardDataModel = require('../models/standard-data')

class ListView extends View {
  constructor(serviceLocator, category) {
    super(...arguments)
    View.apply(this, arguments)
    Object.assign(this, Pagination.prototype)

    this.paginationModel = new Model(serviceLocator, {
      totalItems: 0,
      showing: 0
    })

    this.currentPage = 1

    this.currentParams = {
      filter: category ? { category: { $in: [category, 'general'] } } : {},
      sort: ['custom', [['category', 'desc'], ['order', 'asc']]]
    }

    Pagination.call(this, this.paginationModel)
    this.collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
    this.selectedCollection = new Collection(serviceLocator)
    this.service = serviceLocator.standardDataService

    this.listenTo(this.collection, 'add', this.addListItem.bind(this))
    this.listenTo(this.collection, 'remove', this.removeListItem.bind(this))
    this.listenTo(this.collection, 'reset', this.resetListItems.bind(this))
    this.listenTo(this.collection, 'model:select', this.select.bind(this))
    this.listenTo(this.collection, 'model:deSelect', this.deSelect.bind(this))
    this.$el.on('click', '.js-more', this.loadMore.bind(this))
    this.$el.on('click', '.js-all', this.loadAll.bind(this))
    this.$el.on('submit', '.js-filter', this.getEntities.bind(this))

    this.getEntities()
  }

  getEntities() {
    const { filter, sort } = this.currentParams
    this.currentPage = 1
    const pagination = { page: this.currentPage, pageSize: 100 }

    const keywords = this.$el.find('form [name=keywords]').val()

    this.service.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err) {
        return this.serviceLocator.logger.error(
          err,
          `Could not load standard data`
        )
      }

      this.collection.reset(
        res.results.map(
          entity => new StandardDataModel(this.serviceLocator, entity)
        )
      )
      this.paginationModel.set('totalItems', res.totalItems)
      this.paginationModel.set('showing', this.collection.models.length)
    })
  }

  appendEntities() {
    const { keywords, filter, sort } = this.currentParams
    const pagination = { page: this.currentPage, pageSize: 100 }

    this.service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)

      res.results.forEach(entity =>
        this.collection.add(new StandardDataModel(this.serviceLocator, entity))
      )
      this.paginationModel.set('totalItems', res.totalItems)
      this.paginationModel.set('showing', this.collection.models.length)
    })
  }

  loadMore() {
    this.currentPage++
    this.appendEntities()
  }

  loadAll() {
    const { keywords, filter, sort } = this.currentParams

    this.service.find(
      keywords,
      filter,
      sort,
      { page: 1, pageSize: 999999999 },
      (err, res) => {
        if (err) return alert(err.message)

        res.results.forEach(entity =>
          this.collection.add(
            new StandardDataModel(this.serviceLocator, entity)
          )
        )
        this.paginationModel.set('totalItems', res.totalItems)
        this.paginationModel.set('showing', this.collection.models.length)
      }
    )
  }

  addListItem(model, index) {
    var listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      index,
      this.collection.length,
      true
    )

    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)

    if (this.modal) {
      this.modal.centre()
    }

    return listItem
  }

  resetListItems(models, previousModels) {
    previousModels.forEach(this.removeListItem.bind(this))
    models.forEach(this.addListItem.bind(this))
  }

  removeListItem(model) {
    var view = this.getViewByModel(model)
    if (!view) return
    view.remove()
  }

  onSelectionChange() {
    var count = this.selectedCollection.models.length
    this.$el
      .find('.js-selection-count')
      .text(count + (count === 1 ? ' item' : ' items'))
  }

  select(model) {
    this.selectedCollection.add(model)
  }

  deSelect(model) {
    this.selectedCollection.remove(model.id)
  }

  selectAll() {
    this.collection.forEach(function(model) {
      model.emit('select', model)
    })
  }

  selectNone() {
    var models = this.selectedCollection.models.slice(0)
    models.forEach(function(model) {
      model.emit('deSelect', model)
    })
  }

  render() {
    // Render the template
    this.$el.append(
      this.template({ name: this.name, allowed: this.serviceLocator.allowed })
    )
    this.collection.models.forEach(this.addListItem.bind(this))

    this.updatePagination()

    this.modal = modal({
      title: 'Choose Features',
      content: this.$el,
      buttons: [
        {
          text: 'Add',
          event: 'add',
          className: 'btn btn--success',
          keyCodes: [27]
        },
        { text: 'Cancel', event: 'cancel', className: 'btn' }
      ],
      className: 'wide'
    })

    this.modal.on('add', this.emit.bind(this, 'add'))

    return this
  }
}

ListView.prototype.name = {
  singular: 'Standard Data Item',
  plural: 'Standard Data Items',
  resource: 'standardData'
}

ListView.prototype.ListItemView = ListItemView

ListView.prototype.template = template

// Compatibility with backbone admin
ListView.prototype.trigger = View.prototype.emit

module.exports = ListView
