const required = require('validity-required')
const schemata = require('schemata')

module.exports = () => {
  const schema = schemata({
    name: 'OfferCategory',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      name: {
        type: String,
        validators: [required]
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
