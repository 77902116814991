const BaseListView = require('../../revision/views/revision-list-view')
const ListItemView = require('./list-item')
const FilterView = require('./list-filter')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\">");
if ( (allowed(name.resource, 'delete')))
{
buf.push("<div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( (allowed(name.resource, 'create')))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New " + (jade.escape((jade_interp = name.singular) == null ? '' : jade_interp)) + "</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}

class ListView extends BaseListView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return {
      singular: 'Administrator',
      plural: 'Administrators',
      resource: 'administrator'
    }
  }

  get template() {
    return template
  }

  addListItem(model) {
    const listItem = new this.ListItemView(this.serviceLocator, model)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(listItem, 'reset2fa', this.emit.bind(this, 'reset2fa', model))
    this.listenTo(
      listItem,
      'showRevisions',
      this.emit.bind(this, 'showRevisions', model)
    )
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }
}

module.exports = ListView
