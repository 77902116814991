import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({ fbPixelWidget: ['widget', 'sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Facebook Pixel',
    description: 'Inserts a Facebook Pixel to the page'
  }

  ;['section', 'offerBody', 'articleBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('fbPixel', widget)
  )

  done()
}

module.exports = initWidget
