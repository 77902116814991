const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

const extraProperties = {
  items: {
    type: Array,
    defaultValue: () => []
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Feature Carousel Widget', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'featureCarousel' })
  },
  validate: validateDelegate
})
