const Model = require('merstone')
const createSchema = require('../../../service/administrator/schema')
const schemata = require('schemata')
const omit = require('lodash.omit')

class AdministratorModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    let schema
    if (
      serviceLocator.config &&
      serviceLocator.config.enabledCmsFeatures &&
      serviceLocator.config.enabledCmsFeatures.includes('OAuthEnabled')
    ) {
      schema = createSchema(null, serviceLocator.config, {
        isMicrosoftAccount: {
          type: Boolean,
          defaultValue: true,
          tag: ['update']
        },
        microsoftAccountId: {
          type: String,
          tag: ['update']
        }
      })
    } else {
      schema = createSchema(null, serviceLocator.config)
    }

    this.schemata = schemata({
      name: 'Administrator',
      properties: omit(
        schema.getProperties(),
        'password',
        'passwordSalt',
        'key',
        'keyExpiry'
      )
    })

    this.attributes = this.schemata.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => cb(null, this.schemata.cast(attrs)))
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    super.reset(attrs)
  }
}

module.exports = AdministratorModel
