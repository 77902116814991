const schemata = require('schemata')

const fileSchema = name =>
  schemata({
    name,
    properties: {
      filename: { type: String },
      mimetype: { type: String },
      fileBase64: { type: String }
    }
  })

module.exports = fileSchema
