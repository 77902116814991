const createFetch = require('../../../../admin/source/js/lib/authed-fetch')
const config = window.config

const createDviImageSubmitter = () => {
  const fetch = createFetch(config.apiUrl)

  return async (vehicleId, imageIds) => {
    const req = await fetch('POST', '/dvi-request/create-request', {
      vehicleId,
      imageIds
    })

    return await req.json()
  }
}

module.exports = createDviImageSubmitter
