import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Unmissable Special Offers'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('unmissableSpecialOffers', widget)
  )

  done()
}

module.exports = () => ({
  unmissableSpecialOffersWidget: ['sectionAdmin', 'makeAdmin', init]
})
