module.exports = LayoutEditorView
const compileJade = require('browjadify-compile')
const sortBy = require('lodash.sortby')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (config, layoutDescriptor, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><div class=\"js-error-summary\"></div>");
if ( !layoutDescriptor.isBuiltIn)
{
buf.push("<div class=\"panel panel-styled\"><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", layoutDescriptor.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--description\" data-field=\"description\" class=\"form-row\"><label><span class=\"form-label-text\">Description</span><input type=\"text\" name=\"description\"" + (jade.attr("value", layoutDescriptor.description, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div></div></div>");
}
buf.push("<div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"panel panel-styled panel-fixed js-fixed-sidebar\"><div class=\"panel-header\"><h2>Widgets</h2></div><div class=\"panel-content\"><p>Drop into the dashed areas to build up the page.</p><form action=\"\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text vhidden\">Search</span><input type=\"text\" placeholder=\"Filter Widgets\" autocomplete=\"off\" class=\"control control--text form-field js-widget-filter\"/></label></div></form><ul class=\"widget-list js-widget-list\"></ul></div></div></div><div class=\"grid__item three-quarters\"><div class=\"js-rows\"></div><hr/><div class=\"row-layouts\"><p>Add additional rows to build up the layout:</p>");
if ( !config.rowLayouts || config.rowLayouts.indexOf('3') !== -1)
{
buf.push("<button data-row-layout=\"3\" class=\"text-btn row-layouts__button js-add-row\"><i class=\"row-icon row-icon--3\"></i>3 Column</button>");
}
buf.push("</div></div></div></div></div>");}.call(this,"config" in locals_for_with?locals_for_with.config:typeof config!=="undefined"?config:undefined,"layoutDescriptor" in locals_for_with?locals_for_with.layoutDescriptor:typeof layoutDescriptor!=="undefined"?layoutDescriptor:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const errorTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (errors, undefined) {
jade_mixins["displayError"] = jade_interp = function(key, error){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( typeof error === 'object')
{
// iterate error
;(function(){
  var $$obj = error;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var err = $$obj[key];

jade_mixins["displayError"](key, err);
    }

  }
}).call(this);

}
else
{
buf.push("<li><a" + (jade.attr("href", ('#field--' + key), true, false)) + ">" + (jade.escape(null == (jade_interp = error) ? "" : jade_interp)) + "</a></li>");
}
};
buf.push("<div class=\"form-error notification notification--error\"><strong>Oops! Something isn’t right.</strong><p>Please check and try again.</p><ul>");
// iterate errors
;(function(){
  var $$obj = errors;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var error = $$obj[key];

jade_mixins["displayError"](key, error);
    }

  }
}).call(this);

buf.push("</ul></div>");}.call(this,"errors" in locals_for_with?locals_for_with.errors:typeof errors!=="undefined"?errors:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const WidgetListView = require('../../widget/views/widget-list')
const modal = require('modal')
const widgetExpander = require('../../widget/lib/widget-expander-toggle')(
  function() {}
)
const fixedSidebar = require('../../../../admin/source/js/lib/fixed-sidebar')
const View = require('ventnor')
const LayoutModel = require('../../widget/models/layout')
const ToolbarView = require('./toolbar')
const WidgetRowView = require('./widget-row')

function LayoutEditorView(serviceLocator, layoutDescriptor) {
  View.apply(this, arguments)

  if (!serviceLocator.widgetFactories.get(layoutDescriptor.type)) {
    throw new Error(
      'Unsupported layout type "' + layoutDescriptor.get('type') + '"'
    )
  }
  this.widgetFactory = serviceLocator.widgetFactories.get(layoutDescriptor.type)

  this.layoutDescriptor = layoutDescriptor
  this.layout = new LayoutModel(layoutDescriptor.layout, {
    serviceLocator: this.serviceLocator,
    abstractWidgetFactory: this.widgetFactory
  })

  this.initialModel = this.layout.toJSON()

  this.setupToolbar()

  this.$el.attr('id', this.cid)

  this.$el.on('click', '.js-add-row', this.handleAddRow.bind(this))
  this.$el.on('keyup', '.js-widget-filter', this.handleWidgetFilter.bind(this))

  this.$el.on('change', '[name=name]', this.updateName.bind(this))
  this.$el.on('change', '[name=description]', this.updateDescription.bind(this))

  this.rowCount = 0

  this.listenTo(this.layout, 'addRow', this.renderRow.bind(this))

  this.listenTo(
    this.layout,
    'removeRow',
    function(rowIndex) {
      this.$el.find('[data-index=' + rowIndex + ']').remove()
    }.bind(this)
  )

  this.on(
    'afterAppend',
    function() {
      fixedSidebar(this, this.$el.find('.js-fixed-sidebar'))
    }.bind(this)
  )

  this.$ = this.$el.find.bind(this.$el)
}

LayoutEditorView.prototype = Object.create(View.prototype)

/*
 * View.prototype implements this but because it's listening to
 * backbone views, it needs to be compatible with both those too
 */
LayoutEditorView.prototype.stopListening = function() {
  this._listeners.forEach(function(listener) {
    if (typeof listener.obj.removeListener === 'function') {
      listener.obj.removeListener(listener.event, listener.fn)
    } else {
      listener.obj.off(listener.event, listener.fn)
    }
  })
}

LayoutEditorView.prototype.setupToolbar = function() {
  this.toolbar = new ToolbarView(
    this.serviceLocator,
    this.layoutDescriptor,
    this
  )

  // Back to list
  this.toolbar.on('back', this.remove.bind(this))

  // Save
  this.toolbar.on(
    'save',
    this.save.bind(
      this,
      function(err) {
        if (err) {
          return modal({
            title: 'Error Saving Layout',
            content: err.message,
            buttons: [{ text: 'Dismiss', className: 'btn' }]
          })
        }
        this.toolbar.showSaveNotification()
      }.bind(this)
    )
  )

  // Save and close
  this.toolbar.on(
    'saveAndClose',
    this.save.bind(
      this,
      function(err) {
        if (err) {
          return modal({
            title: 'Error Saving Layout',
            content: err.message,
            buttons: [{ text: 'Dismiss', className: 'btn' }]
          })
        }
        this.remove()
      }.bind(this)
    )
  )

  this.toolbar.on('expandAll', widgetExpander.handleExpandAll.bind(this))
  this.toolbar.on('collapseAll', widgetExpander.handleCollapseAll.bind(this))
}

LayoutEditorView.prototype.renderRow = function(row) {
  const widgetRow = new WidgetRowView(this.serviceLocator, row, this.layout)
  this.attachView(widgetRow)
  this.$el.find('.js-rows').append(widgetRow.render().$el)
}

LayoutEditorView.prototype.handleAddRow = function(e) {
  this.layout.addRow($(e.currentTarget).data('row-layout'))
}

LayoutEditorView.prototype.handleRemoveRow = function(index) {
  modal({
    content: 'Do you want to delete this row and containing widgets?',
    buttons: [
      { text: 'Cancel', event: 'cancel', className: 'btn', keyCodes: [27] },
      { text: 'Delete', event: 'confirm', className: 'btn btn--error' }
    ]
  }).on(
    'confirm',
    function() {
      this.layout.removeRow(index)
    }.bind(this)
  )
}

LayoutEditorView.prototype.remove = function() {
  this.layout.stopListening()
  View.prototype.remove.call(this)
}

LayoutEditorView.prototype.save = function(cb) {
  if (!this.validate()) return
  this.serviceLocator.layoutService.update(
    this.layoutDescriptor,
    this.layout.toJSON(),
    function(err, data) {
      if (err) return cb(err)
      this.initialModel = this.layout.toJSON()
      this.emit('save')
      cb(null, data)
    }.bind(this)
  )
}

LayoutEditorView.prototype.handleWidgetFilter = function() {
  var filterText = this.$widgetFilter.val().toUpperCase()
  this.$widgetList.find('li').each(
    function(index, widget) {
      const widgetName = widget.innerHTML.toUpperCase()
      const offset = widgetName.search(filterText)
      if (offset > -1) {
        this.$el.find(widget).show()
      } else {
        this.$el.find(widget).hide()
      }
    }.bind(this)
  )
}

LayoutEditorView.prototype.render = function() {
  this.$el.empty().append(
    template({
      title: this.layoutDescriptor.isBuiltIn
        ? this.layoutDescriptor.name + ': ' + this.layoutDescriptor.parent.name
        : 'Custom Layout' + ': ' + this.layoutDescriptor.parent.name,
      layout: this.layout.toJSON(),
      layoutDescriptor: this.layoutDescriptor
    })
  )

  this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

  const widgetListView = new WidgetListView({
    collection: new window.Backbone.Collection(
      sortBy(this.widgetFactory.toArray(), 'name')
    ),
    widgetDropArea: '#' + this.cid + ' .js-widgets'
  })

  this.$widgetList = this.$el.find('.js-widget-list')
  this.$widgetFilter = this.$el.find('.js-widget-filter')
  this.$widgetList.append(widgetListView.$el)

  Object.keys(this.layout.layout).forEach(type => {
    this.renderRow(this.layout.layout[type])
  })

  this.setupSortable()

  return this
}

LayoutEditorView.prototype.setupSortable = function() {
  this.$el.find('.js-rows').sortable({
    axis: 'y',
    helper: 'clone',
    cursor: 'move',
    handle: '.js-row-drag-handle',
    update: () => {
      const layout = {}

      this.$el.find('.js-widget-row').each((i, el) => {
        const $el = $(el)
        const index = $el.data('index')
        layout[i] = this.layout.layout[index]
        layout[i].id = i
        $el.data('index', i)
      })

      this.layout.layout = layout
    }
  })
}

LayoutEditorView.prototype.validate = function() {
  /* jshint maxcomplexity:7 */

  // Only the article layout type can be invalid
  if (this.layoutDescriptor.type !== 'article') return true

  const errors = {}
  const $errors = this.$el.find('.js-error-summary').empty()

  if (!this.layoutDescriptor.isBuiltIn) {
    if (!this.layoutDescriptor.name) {
      errors.layoutName = 'A name must be provided for this layout'
    }
  }

  if (Object.keys(errors).length === 0) return true

  $errors.html(errorTemplate({ errors: errors }))
  return false
}

LayoutEditorView.prototype.updateName = function() {
  this.layoutDescriptor.name = this.$el.find('[name=name]').val()
}

LayoutEditorView.prototype.updateDescription = function() {
  this.layoutDescriptor.description = this.$el.find('[name=description]').val()
}
