const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (bodyType, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", bodyType.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--makes\" data-field=\"makes\" class=\"form-row\"><label><span class=\"form-label-text\">Makes</span><div class=\"form-field js-makes\"></div></label><div class=\"js-error\"></div></div></div></div></form></div></div>");}.call(this,"bodyType" in locals_for_with?locals_for_with.bodyType:typeof bodyType!=="undefined"?bodyType:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const BaseSelect = require('../../base/views/select')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
  }

  renderMakeSelect() {
    const vehicleMakeSelectView = new BaseSelect(this.serviceLocator, {
      service: 'makeService',
      selected: this.model.get('makes'),
      limitToOneValue: false,
      singular: 'make',
      plural: 'makes',
      textProperty: 'name',
      sortProperty: 'name'
    })

    this.$el.find('.js-makes').append(vehicleMakeSelectView.render().$el)
    vehicleMakeSelectView.on('change', makes => {
      return this.model.set('makes', makes)
    })
    this.attachView(vehicleMakeSelectView)
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Body Type' : 'Edit Body Type',
        bodyType: this.model.toJSON(),
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.renderMakeSelect()

    return this
  }
}

module.exports = FormView
