const createSchema = require('../../../../service/widget/model/base-widget')

const extraProperties = {
  text: {
    type: String
  },
  images: {
    type: Array,
    defaultValue: () => []
  }
}

module.exports = () => createSchema('Image', extraProperties)
