const schemata = require('schemata')
const required = require('validity-required')
const isEmail = require('validity-email')
const moment = require('moment')
const duplicateEmailValidator = require('./lib/duplicate-email-validator')
const generateSalt = require('./lib/salt-generator')
const emailCitygateValidator = (prop, name, object) => {
  const email = (object[prop] || '').toLowerCase()
  if (
    email.includes('@citygate.co.uk') &&
    !object.isMicrosoftAccount &&
    !object._id
  ) {
    return 'Microsoft Login must be enabled when creating Citygate administrators'
  }

  if (!email.includes('@citygate.co.uk') && object.isMicrosoftAccount) {
    return 'Microsoft Login must be disabled for non-Citygate emails'
  }
}

module.exports = (save, config, extraSchema = {}) =>
  schemata({
    name: 'Administrator',
    properties: {
      _id: {
        type: String,
        tag: ['update']
      },
      firstName: {
        type: String,
        validators: {
          all: [required]
        },
        tag: ['update']
      },
      lastName: {
        type: String,
        validators: {
          all: [required]
        },
        tag: ['update']
      },
      emailAddress: {
        type: String,
        validators: {
          all: [
            required,
            isEmail,
            duplicateEmailValidator(save),
            emailCitygateValidator
          ]
        },
        tag: ['update']
      },
      password: {
        type: String,
        validators: {
          all: []
        }
      },
      passwordSalt: {
        type: String,
        defaultValue: generateSalt
      },
      passwordResetDate: {
        type: Date,
        defaultValue: function() {
          var numDaysBetweenPasswordResets =
            config.passwordResetPolicy.numDaysBetweenReset
          return moment()
            .add(numDaysBetweenPasswordResets, 'days')
            .toDate()
        }
      },
      previousPasswords: {
        type: Array,
        defaultValue: function() {
          return []
        }
      },
      key: {
        type: String
      },
      keyExpiry: {
        type: Date
      },
      twoFaEnabled: {
        type: Boolean,
        defaultValue: true,
        tag: ['update']
      },
      twoFaKey: {
        type: String,
        tag: ['update']
      },
      twoFaChallengeDates: {
        type: Object,
        tag: ['update'],
        defaultValue: function() {
          return {}
        }
      },
      roles: {
        type: Array,
        tag: ['update']
      },
      account: {
        type: String,
        tag: ['update']
      },
      failedLoginAttempts: {
        type: Number,
        defaultValue: 0
      },
      nextAllowedLoginAttemptDate: {
        type: Date
      },
      createdDate: {
        type: Date,
        validators: {
          all: []
        },
        defaultValue: () => new Date()
      },
      viewDealerships: {
        type: Array,
        defaultValue: () => [],
        tag: ['update']
      },
      pushDealerships: {
        type: Array,
        defaultValue: () => [],
        tag: ['update']
      },
      editDealerships: {
        type: Array,
        defaultValue: () => [],
        tag: ['update']
      },
      primaryDealership: {
        type: String,
        defaultValue: () => [],
        tag: ['update']
      },
      ...extraSchema
    }
  })
