const required = require('validity-required')
const schemata = require('schemata')

module.exports = () => {
  const schema = schemata({
    name: 'ScrapedVehicle',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      vehicle: {
        type: String
      },
      dealership: {
        type: String
      },
      source: {
        type: String
      },
      live: {
        type: Boolean
      },
      registration: {
        type: String,
        validators: [required]
      },
      title: {
        type: String,
        validators: [required]
      },
      price: {
        type: Number,
        validators: [required]
      },
      link: {
        type: String
      },
      images: {
        type: Number
      },
      viewing: {
        type: Number
      },
      stats: {
        type: Object,
        defaultValue: () => ({})
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      lastUpdatedDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
