module.exports = initWidget

function initWidget() {
  return { vehicleFinanceExampleWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Vehicle Finance Example',
    itemView: require('./views/item'),
    description: 'Personal Contract Purchase (PCP) representative example'
  }

  ;['section'].forEach(type =>
    serviceLocator.widgetFactories
      .get(type)
      .register('vehicleFinanceExample', widget)
  )

  done()
}
