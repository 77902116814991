const listController = require('../base/controllers/list')
const NewVehicleImportService = require('./services/scraped-vehicle')
const Model = require('./models/scraped-vehicle')
const ListView = require('./views/list')
const qs = require('querystring')

const initAdmin = () => ({ scrapedVehicleAdmin: ['usedVehicleAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.scrapedVehicleService = new NewVehicleImportService(
    serviceLocator
  )

  const currentParams = {
    keywords: '',
    filter: { stats: { $gt: {} }, live: true },
    sort: ['createdDate', 'desc']
  }

  const controllerOptions = {
    serviceLocator,
    singular: 'scrapedVehicle',
    plural: 'Vehicles',
    basePath: 'vehicles/feed-failures',
    service: serviceLocator.scrapedVehicleService,
    currentParams,
    Model
  }

  listController(
    Object.assign({}, controllerOptions, {
      ListView,
      path: 'vehicles/feed-failures/list',
      customListViewInitiator(list) {
        const query = qs.parse(location.search.replace('?', ''))

        if (query.dealership) {
          const params = {
            ...currentParams,
            filter: { ...currentParams.filter, dealership: query.dealership }
          }
          list.emit('filter', params)
          list.displayFilterParams(params)
        }
      }
    })
  )

  cb(null)
}

module.exports = initAdmin
