const listController = require('../base/controllers/list')
const NewVehicleImportService = require('./services/new-vehicle-import')
const Model = require('./models/new-vehicle-import')
const ListView = require('./views/list')

const initAdmin = () => ({ newImport: ['usedVehicleAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.newVehicleImportService = new NewVehicleImportService(
    serviceLocator
  )

  const currentParams = {
    keywords: '',
    filter: { successful: false },
    sort: ['createdDate', 'desc']
  }

  const controllerOptions = {
    serviceLocator,
    singular: 'usedVehicle',
    plural: 'Vehicles',
    basePath: 'vehicles/import-failure',
    service: serviceLocator.newVehicleImportService,
    currentParams,
    Model
  }

  listController(
    Object.assign({}, controllerOptions, {
      ListView,
      path: 'vehicles/import-failure/list'
    })
  )

  cb(null)
}

module.exports = initAdmin
