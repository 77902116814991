const debug = require('./lib/debug')('global router')
const modal = require('modal')
const uppercaseFirstLetter = require('./lib/uppercase-first-letter')

module.exports = window.Backbone.Router.extend({
  initialize: function(config) {
    this.config = config
    this.$root = $('.js-container')
    this.previousRoute = ''
    this.on(
      'route',
      function() {
        this.previousRoute = window.location.pathname
      }.bind(this)
    )
  },
  render: function(view, defaultTitle) {
    debug('render', view, this.view)
    if (window.ga) window.ga('send', 'pageview')

    const {
      client = '',
      env = 'development',
      title = defaultTitle || ''
    } = this.config

    const envLabel =
      env === 'production' ? '' : `[${uppercaseFirstLetter(env)}]`
    const clientLabel = client ? ` - ${uppercaseFirstLetter(client)}` : ''

    // Set the document title
    document.title = `${title} Portal ${envLabel}${clientLabel}`

    if (typeof this.view !== 'undefined') {
      debug('removing view', this.view)
      // this.view.$el.detach()
      this.view.remove()
    }

    this.view = view
    this.view.trigger('beforeAppend')
    this.$root.append(this.view.$el)
    this.view.trigger('afterAppend')

    if (this.view && typeof view.hasUnsavedChanges === 'function') {
      window.onbeforeunload = function() {
        if (
          !this.view ||
          (this.view.unsavedChanges === undefined &&
            this.view.hasUnsavedChanges === undefined)
        ) {
          // do nothing
        } else if (this.view.unsavedChanges === false) {
          // do nothing
        } else if (!this.view.hasUnsavedChanges()) {
          // do nothing
        } else {
          return 'You have made unsaved changes. Would you still like to leave this page?'
        }
      }.bind(this)
    }
  },
  route: function(route, name, callback) {
    this.constructor.__super__.route.call(this, route, name, function() {
      var returnRoute = this.previousRoute
      if (
        !this.view ||
        (this.view.unsavedChanges === undefined &&
          this.view.hasUnsavedChanges === undefined)
      ) {
        callback.apply(this, arguments)
      } else if (this.view.unsavedChanges === false) {
        callback.apply(this, arguments)
      } else if (!this.view.hasUnsavedChanges()) {
        callback.apply(this, arguments)
      } else {
        process.nextTick(
          function() {
            modal({
              title: 'You have unsaved changes',
              content:
                'Would you like to continue editing, or discard these changes?',
              buttons: [
                {
                  text: 'Continue editing',
                  event: 'continue',
                  className: 'btn btn--success',
                  keyCodes: [27]
                },
                { text: 'Discard changes', event: 'discard', className: 'btn' }
              ]
            })
              .on(
                'discard',
                function() {
                  callback.apply(this, arguments)
                }.bind(this)
              )

              .on('return', function() {
                window.Backbone.history.navigate(returnRoute, {
                  trigger: false
                })
              })
          }.bind(this)
        )
      }
    })
  }
})
