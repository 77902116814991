const CrudService = require('../../../../admin/source/js/lib/crud-service')

class RedirectService extends CrudService {
  get name() {
    return 'RedirectService'
  }
  get urlRoot() {
    return '/redirects'
  }
}

module.exports = RedirectService
