const CrudService = require('../../../../admin/source/js/lib/crud-service')

class ListService extends CrudService {
  get name() {
    return 'ListService'
  }
  get urlRoot() {
    return '/lists'
  }
}

module.exports = ListService
