import BaseModel from 'cf-base-model'
import createSchema from './schema'
import validateDelegate from '../../../../../admin/source/js/lib/validate-delegate'
import ImageAreaModel from '../../../asset/models/image-area'

module.exports = BaseModel.extend({
  schemata: createSchema(),
  type: 'newsStrip',
  defaults() {
    return this.schemata.makeDefault({ type: 'newsStrip' })
  },
  validate: validateDelegate(),
  initialize: function() {
    ;['images'].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.attributes[imageType])

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }
})
