const CrudService = require('../../../../admin/source/js/lib/crud-service')

class MakeService extends CrudService {
  get name() {
    return 'MakeService'
  }
  get urlRoot() {
    return '/makes'
  }

  embellishModelsWithMake(models, callback) {
    const vehicleMakeIds = models.map(({ make }) => make)
    this.serviceLocator.makeService.find(
      '',
      { _id: { $in: vehicleMakeIds } },
      [],
      { page: 1, pageSize: 500 },
      (error, vehicleMakes) => {
        if (error) return callback(error)

        models.forEach(model => {
          const foundMake = vehicleMakes.results.find(
            make => make._id === model.make
          )
          model.__make = foundMake ? foundMake.name : null
        })

        return callback(null, models)
      }
    )
  }
}

module.exports = MakeService
