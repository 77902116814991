/*
VW - #00B0F0
VWCV - #001E50
CUPRA Copper - #95572B
CUPRA Petrol - #003E51
CUPRA Oxygen White - #F8F8F8
SEAT Orange #EA5D1A
SEAT Grey - #DEDAD4
Kia - rgb(19, 30, 41)
SKODA Green - #4BA82E
Citygate Asphalt - #1F2C36
Citygate Petrol - #035D67
Citygate Light Grey - #E6E2E7 
*/

const colors = [
  {
    name: 'White',
    backgroundColor: '',
    textColor: ''
  },
  {
    name: 'VW',
    backgroundColor: '#00B0F0',
    textColor: '#FFFFFF'
  },
  {
    name: 'VWCV',
    backgroundColor: '#001E50',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA',
    backgroundColor: '#95572B',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA Petrol',
    backgroundColor: '#003E51',
    textColor: '#FFFFFF'
  },
  {
    name: 'CUPRA Oxygen White',
    backgroundColor: '#F8F8F8',
    textColor: '#000000'
  },
  {
    name: 'SEAT Orange',
    backgroundColor: '#EA5D1A',
    textColor: '#FFFFFF'
  },
  {
    name: 'SEAT Grey',
    backgroundColor: '#DEDAD4',
    textColor: '#000000'
  },
  {
    name: 'Kia',
    backgroundColor: '#193041',
    textColor: '#FFFFFF'
  },
  {
    name: 'SKODA Green',
    backgroundColor: '#4BA82E',
    textColor: '#FFFFFF'
  },
  {
    name: 'Citygate Asphalt',
    backgroundColor: '#1F2C36',
    textColor: '#FFFFFF'
  },
  {
    name: 'Citygate Petrol',
    backgroundColor: '#035D67',
    textColor: '#FFFFFF'
  },
  {
    name: 'Citygate Light Grey',
    backgroundColor: '#E6E2E7',
    textColor: '#000000'
  }
]

module.exports = {
  colors,
  findColor: backgroundColor => {
    return colors.find(color => color.backgroundColor === backgroundColor)
  }
}
