module.exports = listSelectDelegate

var debug = require('./debug')('list select delegate')

/*
 * Facilitates the selecting on items in a list view.
 * Call listSelectDelegate with the context of the view,
 * e.g listSelectDelegate.call(this)
 * This function returns a collection that will maintain an
 * active set of the currently selected items.
 *
 * One assumption is made about the list view, which is
 * that list items are stored at `this.collection`.
 *
 * Usage:
 *
 * model.trigger('select', model)
 *   Adds a model to the selection. Model must exist in `this.collection`.
 *
 * model.trigger('deSelect', model)
 *   Removes a model from the selection.
 *
 * listView.trigger('clearSelection')
 *   Empties the selected collection.
 *
 * listView.trigger('addToSelection')
 *   Adds all of the models in `this.collection` to the selected collection.
 *
 * Listen to 'add' and 'remove' events if you want to keep track of
 * the size of the collection.
 */
function listSelectDelegate() {
  // A plain collection just to store the selected models in
  var selectedCollection = new window.Backbone.Collection()

  /*
   * Triggers a 'select' event on all of the models
   * in `this.collection`, causing them to be added
   * to the selected collection.
   */
  function addToSelection() {
    this.collection.each(function(model) {
      model.trigger('select', model)
    })
  }

  /*
   * Triggers a 'deSelect' event on all of the models in
   * the selected collection, causing them to be removed.
   */
  function clearSelection() {
    // Need to clone the array of models so that the
    // each loop completes.
    var models = selectedCollection.clone()
    models.forEach(function(model) {
      model.trigger('deSelect', model)
    })
  }

  // Hook up the functions to events on the view
  this.on('addToSelection', addToSelection)
  this.on('clearSelection', clearSelection)

  /*
   * Handle a 'select' event that contains a model, adding it to
   * the selected collection.
   */
  function select(model) {
    selectedCollection.add(model)
    debug('selecting', model, selectedCollection)
  }

  /*
   * Handle a 'deSelect' event that contains a model
   * removing it from the selected collection.
   */
  function deSelect(model) {
    selectedCollection.remove(model)
    debug('deselecting', model, selectedCollection)
  }

  // Wire up the functions to events on the collections
  this.listenTo(this.collection, 'select', select)
  this.listenTo(selectedCollection, 'deSelect', deSelect)

  return selectedCollection
}
