const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"field--headline\" data-field=\"headline\" class=\"form-row\"><label><span class=\"form-label-text\">Headline<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"headline\"" + (jade.attr("value", data.headline, true, false)) + " class=\"control control--text form-field\"/></label></div><div id=\"field--description\" data-field=\"description\" class=\"form-row\"><label><span class=\"form-label-text\">Headline</span><input type=\"text\" name=\"description\"" + (jade.attr("value", data.description, true, false)) + " class=\"control control--text form-field\"/></label></div><div id=\"field--callToActionText\" data-field=\"callToActionText\" class=\"form-row\"><label><span class=\"form-label-text\">Call to Action Text</span><input type=\"text\" name=\"callToActionText\"" + (jade.attr("value", data.callToActionText, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"form-row-description form-copy\"><p>Text on link button</p></div></div><div id=\"field--callToActionLink\" data-field=\"callToActionLink\" class=\"form-row\"><label><span class=\"form-label-text\">Call to Action Link</span><input type=\"text\" name=\"callToActionLink\"" + (jade.attr("value", data.callToActionLink, true, false)) + " class=\"control control--text form-field\"/></label><div class=\"form-row-description form-copy\"><p>Link for button. Internal links should start with “/” and external with “http”.</p></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../../../lib/multi-image-view')
const RichTextInstanceManager = require('../../../../../../admin/source/js/lib/rich-text-editor-instance-manager')

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template
    Object.assign(this, createMultiImageViewFunctions())
    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))
    this.richTextEditorManager = new RichTextInstanceManager()
    this.on(
      'save',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )
    this.on(
      'cancel',
      this.richTextEditorManager.destroy.bind(this.richTextEditorManager)
    )
  }

  render() {
    super.render()
    this.$el.find('.js-text-editor').each((index, value) => {
      this.richTextEditorManager.create(value, { height: 300 })
    })
    return this
  }
}

module.exports = ItemRepeaterFormView
