const moment = require('moment')
const currencyFormatter = require('../../../../admin/source/js/lib/currency-formatter')

const camelToTitle = function(camelCaseString) {
  // https://stackoverflow.com/a/35953318
  var result = camelCaseString // "__ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser_456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/(_)+/g, ' ') // " ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser 456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/([a-z])([A-Z][a-z])/g, '$1 $2') // " To Get YourGEDIn TimeASong About The26ABCs IsOf The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times IsNot AsEasy As123ForC3POOrR2D2Or2R2D"
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2') // " To Get YourGEDIn TimeASong About The26ABCs Is Of The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2') // " To Get Your GEDIn Time ASong About The26ABCs Is Of The Essence But APersonal IDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2') // " To Get Your GEDIn Time A Song About The26ABCs Is Of The Essence But A Personal ID Card For User456In Room26A ContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2') // " To Get Your GEDIn Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3POOr R2D2Or 2R2D"

    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2') // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"
    .replace(/([0-9])([A-Z][a-z]+)/g, '$1 $2') // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC 26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"

    // Note: the next two regexes use {2,} instead of + to add space on phrases like Room26A and 26ABCs but not on phrases like R2D2 and C3PO"
    .replace(/([A-Z]{2,})([0-9]{2,})/g, '$1 $2') // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .replace(/([0-9]{2,})([A-Z]{2,})/g, '$1 $2') // " To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .trim() // "To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const formatPairs = obj => {
  const formattedObj = {}
  Object.entries(obj).map(item => {
    formattedObj[camelToTitle(item[0])] = item[1] || '-'
  })
  return formattedObj
}

const formatVehicle = data => {
  // Destructuring is verbose, but allows the `...other` to catch any new
  // fields which appear in the API that we haven't accounted for yet.

  if (!data) return {}

  const {
    ownershipCondition,
    registration,
    vin,
    make,
    model,
    generation,
    derivative,
    derivativeId,
    vehicleType,
    trim,

    bodyType,
    fuelType,
    cabType,
    transmissionType,
    wheelbaseType,
    roofHeightType,
    drivetrain,
    seats,
    doors,
    colour,
    style,
    subStyle,
    gears,
    topSpeedMPH,
    startStop,
    countryOfOrigin,
    driveType,
    axles,

    co2EmissionGPKM,
    zeroToSixtyMPHSeconds,
    badgeEngineSizeLitres,
    engineCapacityCC,
    enginePowerBHP,
    fuelCapacityLitres,
    emissionClass,
    fuelEconomyNEDCExtraUrbanMPG,
    fuelEconomyNEDCUrbanMPG,
    fuelEconomyNEDCCombinedMPG,
    fuelEconomyWLTPLowMPG,
    fuelEconomyWLTPMediumMPG,
    fuelEconomyWLTPHighMPG,
    fuelEconomyWLTPExtraHighMPG,
    fuelEconomyWLTPCombinedMPG,
    engineNumber,
    fuelDelivery,
    valves,
    enginePowerPS,
    engineTorqueNM,
    engineTorqueLBFT,
    cylinders,
    cylinderArrangement,
    engineMake,
    valveGear,

    insuranceGroup,
    insuranceSecurityCode,
    firstRegistrationDate,

    lengthMM,
    heightMM,
    widthMM,
    payloadLengthMM,
    payloadWidthMM,
    payloadHeightMM,
    payloadWeightKG,
    minimumKerbWeightKG,
    grossVehicleWeightKG,
    bootSpaceSeatsUpLitres,
    bootSpaceSeatsDownLitres,
    wheelbaseMM,
    grossCombinedWeightKG,
    grossTrainWeightKG,
    boreMM,
    strokeMM,
    payloadVolumeCubicMetres,

    batteryChargeTime,
    batteryQuickChargeTime,
    batteryRangeMiles,
    batteryCapacityKWH,
    batteryUsableCapacityKWH,

    oem,
    ...other
  } = data

  const primary = {
    registration,
    make,
    model,
    derivative
  }
  const summary = {
    ownershipCondition,
    registration,
    vin,
    make,
    model,
    generation,
    derivative,
    derivativeId,
    vehicleType,
    trim
  }
  const summaryExtended = {
    bodyType,
    fuelType,
    cabType,
    transmissionType,
    wheelbaseType,
    roofHeightType,
    drivetrain,
    seats,
    doors,
    colour,
    style,
    subStyle,
    gears,
    topSpeedMPH,
    startStop,
    countryOfOrigin,
    driveType
  }
  const engine = {
    co2EmissionGPKM,
    zeroToSixtyMPHSeconds,
    badgeEngineSizeLitres,
    engineCapacityCC,
    enginePowerBHP,
    fuelCapacityLitres,
    emissionClass,
    fuelEconomyNEDCExtraUrbanMPG,
    fuelEconomyNEDCUrbanMPG,
    fuelEconomyNEDCCombinedMPG,
    fuelEconomyWLTPLowMPG,
    fuelEconomyWLTPMediumMPG,
    fuelEconomyWLTPHighMPG,
    fuelEconomyWLTPExtraHighMPG,
    fuelEconomyWLTPCombinedMPG,
    engineNumber,
    fuelDelivery,
    valves,
    enginePowerPS,
    engineTorqueNM,
    engineTorqueLBFT,
    cylinders,
    cylinderArrangement,
    engineMake,
    valveGear,
    axles
  }
  const measurements = {
    lengthMM,
    heightMM,
    widthMM,
    wheelbaseMM,
    payloadLengthMM,
    payloadWidthMM,
    payloadHeightMM,
    payloadVolumeCubicMetres,
    payloadWeightKG,
    bootSpaceSeatsUpLitres,
    bootSpaceSeatsDownLitres,
    minimumKerbWeightKG,
    grossVehicleWeightKG,
    grossCombinedWeightKG,
    grossTrainWeightKG,
    boreMM,
    strokeMM
  }

  const insurance = {
    insuranceGroup,
    insuranceSecurityCode,
    firstRegistrationDate
  }

  const battery = {
    batteryChargeTime,
    batteryQuickChargeTime,
    batteryRangeMiles,
    batteryCapacityKWH,
    batteryUsableCapacityKWH
  }

  return {
    raw: data,
    primary: primary,
    summary: formatPairs(summary),
    summaryExtended: formatPairs(summaryExtended),
    engine: formatPairs(engine),
    measurements: formatPairs(measurements),
    insurance: formatPairs(insurance),
    battery: formatPairs(battery),
    oem: formatPairs(oem),
    other: formatPairs(other)
  }
}

const formatMotTests = data => {
  if (!data) return {}

  return {
    raw: data,
    formatted: data.map(item => ({
      ...item,
      completedDate: moment(item.completedDate).format('DD MMMM YYYY'),
      expiryDate: moment(item.expiryDate).format('DD MMMM YYYY')
    }))
  }
}

const formatFeatures = data => {
  if (!data) return {}

  const groups = {
    Comfort: [],
    Exterior: [],
    'Safety and Security': [],
    Other: []
  }

  data.map(item => {
    groups[item.category].push(item)
  })

  return {
    raw: data,
    formatted: groups
  }
}

const formatCheck = data => {
  if (!data) return {}

  const {
    keeperChanges,
    v5cs,
    dvlaVehicle,
    policeStolenMarker,
    financeAgreements,
    plateChanges,
    colourChanges,
    odometerReadings,
    highRiskMarkers,
    previousSearches,
    ...other
  } = data

  const formatDates = arr => {
    return arr.map(item => moment(item).format('DD MMMM YYYY'))
  }

  const formatPreviousSearches = searches => {
    return searches.map(search => ({
      Date: moment(search.performed).format('DD MMMM YYYY'),
      'Business Type': search.typeOfBusiness
    }))
  }

  return {
    raw: data,
    keeperChanges: formatDates(
      keeperChanges.map(item => item.dateOfLastKeeper)
    ),
    v5cs: formatDates(v5cs.map(item => item.dateOfLastKeeper)),
    dvlaVehicle: formatPairs(dvlaVehicle),
    policeStolenMarker: formatPairs(policeStolenMarker),
    financeAgreements: financeAgreements.map(obj => formatPairs(obj)),
    plateChanges, // TODO: need example data to see how to format this
    colourChanges, // TODO: need example data to see how to format this
    odometerReadings: odometerReadings.map(obj => formatPairs(obj)),
    highRiskMarkers, // TODO: need example data to see how to format this
    previousSearches: formatPreviousSearches(previousSearches),
    other: formatPairs(other)
  }
}

const formatValuations = data => {
  if (!data) return {}

  const formatted = {}

  Object.entries(formatPairs(data)).map(item => {
    const temp = {}
    Object.entries(item[1]).map(value => {
      if (['amountGBP', 'amountExcludingVatGBP'].includes(value[0])) {
        temp[value[0]] = currencyFormatter({
          value: value[1] * 100,
          format: 'short'
        })
      } else {
        temp[value[0]] = value[1]
      }
    })
    formatted[item[0]] = temp
  })

  return {
    raw: data,
    formatted: formatted
  }
}

const formatMetrics = data => {
  if (!data) return {}

  return {
    raw: data
  }
}

module.exports = function autotraderFormatter(data) {
  return {
    vehicle: formatVehicle(data.vehicle),
    motTests: formatMotTests(data.motTests),
    features: formatFeatures(data.features),
    check: formatCheck(data.check),
    valuations: formatValuations(data.valuations),
    vehicleMetrics: formatMetrics(data.vehicleMetrics)
  }
}
