/*
 * Sets a default target='_blank' to links
 */
window.CKEDITOR.on('dialogDefinition', function(ev) {
  try {
    const dialogName = ev.data.name
    const dialogDefinition = ev.data.definition
    const informationTab = dialogDefinition.getContents('target')
    const targetField = informationTab.get('linkTargetType')

    if (dialogName === 'link') {
      targetField.default = '_blank'
    }
  } catch (e) {
    // suppress error
  }
})
