const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')
const ImageAreaModel = require('../../../asset/models/image-area')

const extraProperties = {
  videoId: {
    type: String,
    validators: [required]
  },
  provider: {
    type: String,
    validators: [required]
  },
  title: {
    type: String
  },
  coverImages: {
    type: Array,
    defaultValue: () => []
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Video', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'video' })
  },
  validate: validateDelegate,
  initialize: function() {
    ;['coverImages'].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.attributes[imageType])

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }
})
