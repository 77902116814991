const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')

class ListView extends BaseListView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return {
      singular: 'Dealership',
      plural: 'Dealerships',
      resource: 'dealership'
    }
  }

  addListItem(model) {
    const listItem = new this.ListItemView(this.serviceLocator, model)
    this.listenTo(
      listItem,
      'showRevisions',
      this.emit.bind(this, 'showRevisions', model)
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }
}

module.exports = ListView
