const BaseListItemView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const async = require('async')

const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, name) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\">");
if ( (allowed(name.resource, 'delete')))
{
buf.push("<div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-delete\">Delete Selected</button></div>");
}
if ( (allowed(name.resource, 'create')))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-upload\">Upload Customers</button>");
}
buf.push("</div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}
const createAuthedHeaders = require('../../../../admin/source/js/lib/authed-headers')

// Import the plugins
const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')

class ListView extends BaseListItemView {
  constructor() {
    super(...arguments)

    this.$el.on('click', '.js-upload', this.uploadDialog.bind(this))
  }

  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get template() {
    return template
  }

  uploadDialog() {
    const url = '/customer/upload-file'

    const uppy = Uppy()
      .use(Dashboard, {
        closeAfterFinish: true
      })

      .use(XHRUpload, {
        endpoint: this.serviceLocator.config.apiUrl + url,
        headers: () => {
          const headers = createAuthedHeaders('POST', url, null, {
            headers: {},
            isForm: true
          })
          return headers
        },
        formData: true,
        getResponseError(responseText) {
          if (responseText === '') {
            return new Error('Failed to upload')
          }
          return new Error(JSON.parse(responseText).error)
        }
      })

    uppy.on('complete', () => {
      this.$el.find('.js-controls').append(`
        <div class="notification notification--info">
          File uploaded, please check your emails for a download link.
        </div>
      `)
      this.serviceLocator.customerService.emit('create')
    })

    uppy.getPlugin('Dashboard').openModal()
  }

  get name() {
    return {
      singular: 'Customer',
      plural: 'Customers',
      resource: 'customer'
    }
  }

  loadData(cb) {
    async.parallel(
      {
        admins: this.serviceLocator.administratorService.find.bind(
          this.serviceLocator.administratorService,
          '',
          {},
          [],
          {}
        ),
        dealers: this.serviceLocator.dealershipService.find.bind(
          this.serviceLocator.dealershipService,
          '',
          {},
          [],
          {}
        )
      },
      (err, data) => {
        if (err) {
          alert(err.message)
          return cb(err)
        }

        this.admins = data.admins.results
        this.dealers = data.dealers.results

        cb()
      }
    )
  }

  addListItem(model, index) {
    var listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      index,
      this.collection.length,
      this.admins,
      this.dealers
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
    return listItem
  }
}

module.exports = ListView
