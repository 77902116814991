import editView from './views/form'
import model from './models/model'
import itemView from './views/item'

const initWidget = () => ({
  modelBannerWidget: ['sectionAdmin', 'modelAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView: editView,
    model: model,
    itemView: itemView,
    name: 'Model Banner',
    description: 'Display a vehicle model in a banner'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('modelBanner', widget)
  )

  done()
}

module.exports = initWidget
