const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (format, history, mini, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Reservation History - " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\"><table class=\"table--striped js-items\"><tr> <th>Date</th><th>Old Status</th><th>New Status</th><th>Author</th></tr>");
if ( history.length === 0)
{
buf.push("<tr><td colspan=\"4\"> <span>No reservation history for this vehicle was recorded</span></td></tr>");
}
// iterate (mini ? history.splice(0, 1) : history)
;(function(){
  var $$obj = (mini ? history.splice(0, 1) : history);
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.oldReservedDate ? 'Reserved' : 'Unreserved') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.newReservedDate ? 'Reserved' : 'Unreserved') ? "" : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = item.author) == null ? '' : jade_interp)) + "<br/>" + (jade.escape((jade_interp = item.authorEmail) == null ? '' : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.oldReservedDate ? 'Reserved' : 'Unreserved') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.newReservedDate ? 'Reserved' : 'Unreserved') ? "" : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = item.author) == null ? '' : jade_interp)) + "<br/>" + (jade.escape((jade_interp = item.authorEmail) == null ? '' : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div></div></div>");}.call(this,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"history" in locals_for_with?locals_for_with.history:typeof history!=="undefined"?history:undefined,"mini" in locals_for_with?locals_for_with.mini:typeof mini!=="undefined"?mini:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})

class ReservationHistory extends View {
  constructor(serviceLocator, model, reservationHistory = [], mini) {
    super(...arguments)

    this.model = model
    this.reservationHistory = reservationHistory

    this.trigger = this.emit.bind(this)
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
    this.mini = mini
  }

  render() {
    const title = `${this.model.brand} ${this.model.model} - ${
      this.model.registration
    }`

    this.$el.empty().append(
      template({
        history: this.reservationHistory,
        format: this.serviceLocator.format,
        title,
        formatter,
        id: this.model._id,
        mini: this.mini
      })
    )
    return this
  }
}

module.exports = ReservationHistory
