const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/bodyshop-submission')
const bodyshopController = require('./controllers/controller')
const BodyshopSubmissionService = require('./services/bodyshop-submission')

const initAdmin = () => ({ bodyshopSubmissionAdmin: [init] })

const currentParams = {
  keywords: '',
  filter: {},
  sort: ['createdDate', 'desc']
}

const init = (serviceLocator, cb) => {
  serviceLocator.bodyshopSubmissionService = new BodyshopSubmissionService(
    serviceLocator
  )

  const controllerOptions = {
    serviceLocator,
    singular: 'bodyshopSubmission',
    plural: 'bodyshopSubmissions',
    path: 'bodyshop-submissions',
    currentParams,
    service: serviceLocator.bodyshopSubmissionService,
    Model
  }
  bodyshopController(
    Object.assign({}, controllerOptions, { FormView, ListView })
  )
  cb()
}

module.exports = initAdmin
