import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  allDealershipsWidget: ['sectionAdmin', 'articleAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    model,
    itemView,
    name: 'All Dealerships',
    description: 'Shows all dealerships'
  }

  ;['section', 'article'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('allDealerships', widget)
  )

  done()
}

module.exports = initWidget
