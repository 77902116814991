const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    const formatted = message
      .replace('{name}', name)
      .replace('{value}', object[key])

    cb(error, valid ? formatted : undefined)
  })
}

module.exports = customValidityMessage
