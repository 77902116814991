module.exports = logger

var log = []

function logger(label) {
  function contains(searchTerm, subject) {
    return subject.some(function(item) {
      try {
        return item && JSON.stringify(item).indexOf(searchTerm) !== -1
      } catch (e) {
        return false
      }
    })
  }

  function allowOutput() {
    return (
      ((typeof global !== 'undefined' && global.debug) ||
        (typeof window !== 'undefined' && window.debug)) &&
      (typeof window.debug.filter === 'undefined' ||
        contains(window.debug.filter, Array.prototype.slice.call(arguments)))
    )
  }

  // Wraps IE9's native console.log as a javascript primitive
  // eslint-disable-next-line no-console
  var cout = Function.prototype.bind.call(console.log, console)

  function debug() {
    if (typeof console !== 'undefined' && allowOutput.apply(null, arguments)) {
      var msg = Array.prototype.slice.call(arguments)
      msg.unshift(label + ' »')
      cout.apply(console, msg)
      log.push(msg)
    }
  }

  debug.showLog = function() {
    if (typeof console !== 'undefined' && allowOutput()) {
      cout.apply(console, log)
    }
    return log
  }

  return debug
}
