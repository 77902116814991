const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model) {
buf.push("<div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"></div><div class=\"control-group\"><div class=\"btn-group\"><button type=\"button\" class=\"btn btn--small js-edit\">Edit</button><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-remove\">Delete</a></li></ul></div></div></div></div><div class=\"list-item-content expander-content\"><div class=\"image-select-preview\"><a" + (jade.attr("href", model.buildOriginalUrl(), true, false)) + " class=\"button-file-type\">");
var fileParts = model.get('name').split('.')
var fileType = fileParts[fileParts.length -1]
buf.push("<i" + (jade.cls(['file-icon','file-icon--' + fileType], [null,true])) + ">" + (jade.escape(null == (jade_interp = fileType) ? "" : jade_interp)) + "</i><span>" + (jade.escape(null == (jade_interp = model.get('name')) ? "" : jade_interp)) + "</span></a></div></div></div></div>");}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
}
const BaseItemView = require('../../../widget/views/item/edit-base')
const debug = require('../../../../../admin/source/js/lib/debug')(
  'edit base widget item view'
)

module.exports = BaseItemView.extend({
  template,
  initialize() {
    BaseItemView.prototype.initialize.apply(this, arguments)
    // Prevent edit overlay being fired when adding files.
    this.off('init')
  },
  render() {
    var result = BaseItemView.prototype.render.call(this)
    debug('Adding binaries', this.options.widgetArea)
    return result
  }
})
