const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"dd-handle-icon\"></div><div class=\"dd-item-content\"><p>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</p><p>" + (jade.escape(null == (jade_interp = data.description) ? "" : jade_interp)) + "</p></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

module.exports = window.Backbone.View.extend({
  className: 'dd-handle',
  initialize: function() {
    this.render()
  },
  render: function() {
    this.$el.empty().append(template({ data: this.model.toJSON() }))
    return this
  }
})
