const schemata = require('schemata')
const required = require('validity-required')
const uuid = require('uuid')
const createImageUrlBuilder = require('cf-image-url-builder')

const imageSetImagesSchema = schemata({
  name: 'ImageSetConfigImage',
  properties: {
    url: {
      type: String
    }
  }
})

module.exports = serviceLocator =>
  schemata({
    name: 'SetConfig',
    properties: {
      imageId: {
        type: String,
        validators: [required],
        defaultValue: () => {
          return uuid.v4()
        }
      },
      name: {
        type: String,
        validators: [required]
      },
      required: {
        type: Boolean,
        defaultValue: false
      },
      isPlaceholder: {
        resolveType: Boolean,
        resolve: setConfig => {
          /*
            if "setConfig.isPlaceholder" is undefined, it's safe to assume the image set for this specific vehicle does not exist
            thus all the images are placeholders (because we are creating a brand new image set).
          */
          const fromSource = setConfig && setConfig.isPlaceholder
          const isPlaceholder =
            (typeof fromSource).toString() === 'undefined' ||
            fromSource === null
              ? true
              : setConfig.isPlaceholder

          return isPlaceholder
        }
      },
      images: {
        type: Object,
        defaultValue: () => [],
        resolveType: schemata.Array(imageSetImagesSchema),
        resolve: setConfig => {
          if (!serviceLocator) return []
          if (
            !setConfig.images ||
            !setConfig.images.widgets ||
            !setConfig.images.widgets.length
          )
            return []

          const urlBuilder = createImageUrlBuilder(
            serviceLocator.config.darkroom.url,
            serviceLocator.config.darkroom.salt,
            setConfig.images.widgets
          )

          let images = [...setConfig.images.widgets]

          images = urlBuilder.getImages('All').map(image => ({
            url: image
              .crop('Free')
              .constrain(200)
              .url()
          }))

          if (!images || images.length <= 0) return []

          return images
        }
      }
    }
  })
