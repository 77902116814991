const createSchema = require('../../../../service/widget/model/base-widget')

const extraProperties = {
  title: {
    type: String
  },
  heading: {
    type: String
  },
  text: { type: String },
  theme: { type: String }
}

module.exports = () => createSchema('Heading Widget', extraProperties)
