const AutotraderView = require('../views/view')

module.exports = serviceLocator => {
  const { router } = serviceLocator

  router.route('autotrader', 'autotrader', () => {
    if (!serviceLocator.allowed('autoTrader', 'read')) {
      return false
    }
    const autotraderView = new AutotraderView({ serviceLocator })
    router.render(autotraderView)
  })
}
