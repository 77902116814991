module.exports = createController
const ListView = require('../views/list')
const Collection = require('chale')
const SectionModel = require('../models/section')
const Model = require('merstone')
const async = require('async')
const pageSize = 150

function createController(serviceLocator) {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = { keywords: '', filter: {}, sort: ['fullUrlPath', 'asc'] }

  // Whenever a section is updated, reset the model with its new attributes
  serviceLocator.sectionService.on('update', function(id, attrs) {
    var model = collection.get(id)
    if (model) model.reset(attrs)
    serviceLocator.sectionService.cachedFind.clear()
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.sectionService.on('create', refresh)

  // Update when any account changes
  serviceLocator.accountService.on('create', refresh)
  serviceLocator.accountService.on('update', refresh)

  serviceLocator.router.route('sections(/)', 'listSections', function() {
    if (!serviceLocator.allow('section', 'discover')) return false

    getSections(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      { page: currentPage, pageSize: pageSize }
    )

    async.parallel({ accounts: getAccounts }, function(err, res) {
      if (err)
        return this.serviceLocator.logger.error(err, 'Could not load accounts')

      var list = new ListView(
        serviceLocator,
        collection,
        paginationModel,
        res.accounts
      ).render()

      list.displayFilterParams(currentParams)

      list.on('createNew', function() {
        if (!serviceLocator.allow('section', 'create')) return false
        serviceLocator.router.navigate('sections/form', { trigger: true })
      })

      list.on('edit', function(id) {
        if (!serviceLocator.allow('section', 'update')) return false
        serviceLocator.router.navigate('sections/' + id + '/form', {
          trigger: true
        })
      })

      list.on('manageLayouts', function(id) {
        if (!serviceLocator.allow('section', 'manageLayouts')) return false
        serviceLocator.router.navigate('layouts/' + id + '/manager', {
          trigger: true
        })
      })

      list.on('reorder', function() {
        serviceLocator.router.navigate('sections/reorder', { trigger: true })
      })

      list.on('delete', function(id) {
        if (!serviceLocator.allow('section', 'delete')) return false
        serviceLocator.sectionService.delete(id, function(err) {
          if (err) return alert(err.message)
          collection.remove(id)
          paginationModel.set(
            'totalItems',
            paginationModel.get('totalItems') - 1
          )
          paginationModel.set('showing', collection.models.length)
        })
      })

      list.on('filter', function(params) {
        currentParams = params
        var pagination = { page: currentPage, pageSize: pageSize }
        currentPage = 1
        getSections(params.keywords, params.filter, params.sort, pagination)
      })

      list.on('loadMore', function() {
        currentPage += 1
        var pagination = { page: currentPage, pageSize: pageSize }
        appendSections(
          currentParams.keywords,
          currentParams.filter,
          currentParams.sort,
          pagination
        )
      })

      serviceLocator.router.render(list, 'Sections')
    })
  })

  function getSections(keywords, filter, sort, pagination) {
    serviceLocator.sectionService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      function(err, res) {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load sections')
        collection.reset(
          res.results.map(function(section) {
            return new SectionModel(serviceLocator, section)
          })
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function appendSections(keywords, filter, sort, pagination) {
    serviceLocator.sectionService.find(
      keywords,
      filter,
      sort,
      pagination,
      function(err, res) {
        if (err) return alert(err.message)
        res.results.forEach(function(section) {
          collection.add(new SectionModel(serviceLocator, section))
        })
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  function getAccounts(cb) {
    serviceLocator.accountService.find(
      '',
      {},
      ['name'],
      { pageSize: 1000 },
      function(err, res) {
        if (err) return cb(err)
        return cb(null, res.results)
      }
    )
  }

  function refresh() {
    serviceLocator.sectionService.cachedFind.clear()
    currentPage = 1
    var pagination = { page: currentPage, pageSize: pageSize }
    getSections(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  }
}
