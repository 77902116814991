const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')
const requireUrl = require('validity-url')

const extraProperties = {
  title: {
    type: String,
    validators: [required]
  },
  subTitle: {
    type: String
  },
  videoUrl: {
    type: String,
    validators: [required, requireUrl]
  },
  images: {
    type: Array,
    defaultValue: () => []
  }
}

module.exports = () => createSchema('FloatyPromo', extraProperties)
