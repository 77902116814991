const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
if ( allowed('caseStudy', 'delete'))
{
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label>");
}
buf.push("<div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a></div></div>");
var title = `${data.heading}${data.subheading ? ` - ${data.subheading}` : ''}`
if ( allowed('caseStudy', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/caseStudies/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Heading:</dt><dd>" + (jade.escape(null == (jade_interp = data.heading) ? "" : jade_interp)) + "</dd><dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const getImageUrl = require('../../lib/default-image-url-generator')

class ListItemView extends BaseListItemView {
  serialize() {
    const data = this.model.toJSON()
    data.previewImageUrlSmall = getImageUrl({
      model: this.model,
      width: 80,
      height: 80,
      mode: 'pad'
    })

    return data
  }

  render() {
    const data = this.serialize()

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
