const ListView = require('../views/list')
const Collection = require('chale')
const OfferModel = require('../models/offer')
const Model = require('merstone')
const async = require('async')
const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['createdDate', 'desc']
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever a model is updated, reset the model with its new attributes
  serviceLocator.offerService.on('update', (id, attrs) => {
    const offer = collection.get(id)
    if (offer) offer.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.offerService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getOffers(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('offers(/)', 'listOffers', () => {
    if (!serviceLocator.allow('offer', 'discover')) return false

    getOffers(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('offer', 'create')) return false
      serviceLocator.router.navigate('offers/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('offer', 'update')) return false
      serviceLocator.router.navigate('offers/' + id + '/form', {
        trigger: true
      })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('offer', 'delete')) return false
      const deleteOne = (id, cb) => {
        serviceLocator.offerService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })
    })

    list.on('preview', id => {
      serviceLocator.accountService.read(
        serviceLocator.session.account,
        (err, account) => {
          const domainUrl = serviceLocator.accountService.createUrl(account)
          const previewPath = '/offer#id=' + id
          const previewUrl = domainUrl + previewPath
          window.open(previewUrl, '_preview')
        }
      )
    })

    list.on('filter', params => {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      getOffers(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendOffers(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', offer => {
      if (!serviceLocator.allow('offer', 'showRevisions')) return false
      serviceLocator.router.navigate('offers/' + offer.id + '/revisions', {
        trigger: true
      })
    })

    list.on('duplicate', offer => {
      if (!serviceLocator.allow('offer', 'duplicate')) return false
      serviceLocator.router.navigate('offers/' + offer.id + '/duplicate', {
        trigger: true
      })
    })

    serviceLocator.router.render(list, 'Offers')
  })

  const getOffers = (keywords, filter, sort, pagination) => {
    serviceLocator.offerService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load offers')
        collection.reset(
          res.results.map(offer => new OfferModel(serviceLocator, offer))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  const appendOffers = (keywords, filter, sort, pagination) => {
    serviceLocator.offerService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        res.results.forEach(offer =>
          collection.add(new OfferModel(serviceLocator, offer))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}

module.exports = createController
