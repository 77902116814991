module.exports = initWidget

function initWidget() {
  return { heroCarousel: ['sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    name: 'Hero Carousel',
    description: 'Displays a carousel',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }
  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories.get(factory).register('heroCarousel', widget)
  )
  done()
}
