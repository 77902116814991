module.exports = initWidget

function initWidget() {
  return { textWidget: ['widget', 'sectionAdmin', init] }
}

function init(serviceLocator, done) {
  var textWidget = {
    editView: require('./views/form'),
    model: require('./models/model'),
    name: 'Text',
    itemView: require('./views/item'),
    description: 'Rich Text Block'
  }

  ;['section', 'offerBody', 'articleBody', 'dealershipBody'].forEach(type =>
    serviceLocator.widgetFactories.get(type).register('text', textWidget)
  )

  done()
}
