const View = require('ventnor')

class OfferSelectView extends View {
  constructor(serviceLocator, selectedOffers, limitToOneValue) {
    if (!Array.isArray(selectedOffers)) selectedOffers = [selectedOffers]
    super(serviceLocator, selectedOffers)

    this.$el = $(limitToOneValue ? `<select />` : '<select multiple />')
    this.$el.attr('name', 'offers')
    this.$el.attr(
      'placeholder',
      limitToOneValue ? 'Choose an offer' : 'Choose one or more offers'
    )
    this.$el.addClass('control control--choice')
    if (!limitToOneValue) this.$el.addClass('control--multiline')

    this.el = this.$el[0]
    this.selectedOffers = selectedOffers || []
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.offerService.find(
      '',
      { _id: { $in: this.selectedOffers } },
      [],
      pagination,
      (err, res) => {
        if (err) return alert('Cannot find existing offer')
        const index = res.results.reduce((index, offer) => {
          index[offer._id] = offer
          return index
        }, {})
        // This ensure they stay in the same order
        this.selectedOffers.forEach(id => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({
            value: id,
            text: index[id] ? index[id].heading : id
          })
          // Select the added option
          this.el.selectize.addItem(id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  updateSelection() {
    this.selectedOffers = this.el.selectize.getValue()
    this.emit('change', this.selectedOffers)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.offerService.cachedFind(
      query,
      {},
      ['heading', 'asc'],
      pagination,
      (err, data) => {
        if (err) return cb(err)
        cb(
          data.results.map(offer => {
            return { value: offer._id, text: offer.heading }
          })
        )
      }
    )
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        delimiter: ',',
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        preload: true
      })
    }, 0)
    return this
  }
}

module.exports = OfferSelectView
