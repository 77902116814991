const View = require('ventnor')
const Pagination = require('../../base/views/pagination')
const Collection = require('chale')
const Model = require('merstone')
const ArticleModel = require('../models/article')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"js-filters\"></div><div class=\"list-container\"><div class=\"list-grid js-items\"></div></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div>");;return buf.join("");
}
const ListItemView = require('./browser-list-item')
const ListFilterView = require('./list-filter')

class BrowserView extends View {
  constructor(serviceLocator) {
    super(serviceLocator)
    Object.assign(this, Pagination.prototype)

    Pagination.call(
      this,
      new Model(serviceLocator, { totalItems: 0, showing: 0 })
    )

    this.collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
    this.currentPage = 1
    this.pageSize = 100
    this.currentParams = {
      keywords: '',
      filter: {},
      sort: ['dateCreated', 'desc']
    }

    this.listenTo(this.collection, 'add', this.addListItem.bind(this))
    this.listenTo(this.collection, 'remove', this.removeListItem.bind(this))
    this.listenTo(this.collection, 'reset', this.resetListItems.bind(this))

    this.$el.on('click', '.js-more', this.loadMore.bind(this))

    this.createFilterView()

    this.getArticles(
      this.currentParams.keywords,
      this.currentParams.filter,
      this.currentParams.sort,
      { page: this.currentPage, pageSize: this.pageSize }
    )
  }

  createFilterView() {
    this.filters = new ListFilterView(this.serviceLocator, this, 'condensed')
    this.filters.on('filter', this.filter.bind(this))
    this.attachView(this.filters)
  }

  addListItem(model) {
    var listItem = new ListItemView(this.serviceLocator, model)
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }

  resetListItems(models, previousModels) {
    previousModels.forEach(this.removeListItem.bind(this))
    models.forEach(this.addListItem.bind(this))
  }

  removeListItem(model) {
    var view = this.getViewByModelId(model.id)
    if (!view) return
    view.remove()
  }

  render() {
    this.$el.append(template())
    this.$el.find('.js-filters').append(this.filters.render().$el)
    this.updatePagination()
    return this
  }

  getArticles(keywords, filter, sort, pagination) {
    this.serviceLocator.articleService.find(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        this.collection.reset(
          res.results.map(
            article => new ArticleModel(this.serviceLocator, article)
          )
        )
        this.updatePaginationModel(
          res.totalItems,
          this.collection.models.length
        )
      }
    )
  }

  updatePaginationModel(total, collectionTotal) {
    this.paginationModel.set('totalItems', total)
    this.paginationModel.set('showing', collectionTotal)
  }

  appendArticles(keywords, filter, sort, pagination) {
    this.serviceLocator.articleService.find(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        res.results.forEach(article =>
          this.collection.add(new ArticleModel(this.serviceLocator, article))
        )
        this.updatePaginationModel(
          res.totalItems,
          this.collection.models.length
        )
      }
    )
  }

  filter(params) {
    this.currentParams = params
    var pagination = { page: this.currentPage, pageSize: this.pageSize }
    this.currentPage = 1
    this.getArticles(params.keywords, params.filter, params.sort, pagination)
  }

  loadMore() {
    this.currentPage += 1
    var pagination = { page: this.currentPage, pageSize: this.pageSize }
    this.appendArticles(
      this.currentParams.keywords,
      this.currentParams.filter,
      this.currentParams.sort,
      pagination
    )
  }
}

module.exports = BrowserView
