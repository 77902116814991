const CrudService = require('../../../../admin/source/js/lib/crud-service')
const async = require('async')
const buildPath = require('../../../service/model/lib/path-builder')

class ModelService extends CrudService {
  get name() {
    return 'ModelService'
  }
  get urlRoot() {
    return '/models'
  }

  embellishModelsWithUrlPath(modelsToEmbellish, callback) {
    let modelIds = modelsToEmbellish.map(({ _id }) => _id)
    modelIds = modelIds
      .concat(modelsToEmbellish.map(({ parentModel }) => parentModel))
      .filter(parent => parent)
    const makeIds = modelsToEmbellish.map(({ make }) => make)
    const pagination = { page: 1, pageSize: 500 }

    const tasks = {
      models: this.serviceLocator.modelService.find.bind(
        this.serviceLocator.modelService,
        '',
        { _id: { $in: modelIds } },
        [],
        pagination
      ),
      makes: this.serviceLocator.makeService.find.bind(
        this.serviceLocator.makeService,
        '',
        { _id: { $in: makeIds } },
        [],
        pagination
      )
    }

    async.parallel(tasks, (error, results) => {
      if (error) return callback(error)

      modelsToEmbellish.map(model => {
        const currentModelMake = results.makes.results.find(
          make => make._id === model.make
        )
        const parentModel = results.models.results.find(
          m => m._id === model.parentModel
        )
        model.__urlPath = buildPath(currentModelMake, model, parentModel)
      })

      return callback(null, modelsToEmbellish)
    })
  }
}

module.exports = ModelService
