const ListView = require('./views/list')
const FormView = require('./views/form')
const Model = require('./models/customer')
const baseListController = require('../base/controllers/list')
const baseFormController = require('../base/controllers/form')
const downloadController = require('./controllers/download')
const CustomerService = require('./services/customer')

const initAdmin = () => ({ customerAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.customerService = new CustomerService(serviceLocator)

  const controllerOptions = {
    serviceLocator,
    singular: 'customer',
    plural: 'customers',
    path: 'customer',
    service: serviceLocator.customerService,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['lastName', 'asc']
    },
    Model
  }
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  downloadController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
