const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, images, other, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div>");
jade_mixins["textField"] = jade_interp = function(property, label, value){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"text-row\"><label><strong class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</strong><span" + (jade.cls(['form-field','form-label-field',`js-${property}`], [null,null,true])) + ">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</span></label></div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["textField"]('title', 'Title', data.title);
jade_mixins["textField"]('firstName', 'First Name', data.firstName);
jade_mixins["textField"]('lastName', 'Last Name', data.lastName);
jade_mixins["textField"]('email', 'Email Address', data.email);
jade_mixins["textField"]('phone', 'Phone Number', data.phone);
jade_mixins["textField"]('postcode', 'Postcode', data.postcode);
buf.push("</div></div>");
jade_mixins["textField"] = jade_interp = function(property, label, value){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"text-row\"><label><strong class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</strong><span" + (jade.cls(['form-field','form-label-field',`js-${property}`], [null,null,true])) + ">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</span></label></div>");
};
buf.push("<div id=\"section-vehicle\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Vehicle Details</h2></div><div class=\"panel-content\">");
jade_mixins["textField"]('make', 'Make', data.make);
jade_mixins["textField"]('model', 'Model', data.model);
jade_mixins["textField"]('registration', 'Registration', data.registration);
jade_mixins["textField"]('damageDetails', 'Damage Details', data.damageDetails || "None Given");
buf.push("</div></div>");
if ( images.length)
{
buf.push("<div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Uploaded Images</h2><p>To view in a larger size, click on an image to download it</p></div><div class=\"panel-content widget-beancan-area\">");
// iterate images
;(function(){
  var $$obj = images;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var file = $$obj[key];

buf.push("<div" + (jade.attr("data-id", key, true, false)) + " class=\"widget-grid__item\"><div class=\"widget\"><div class=\"list-item-content expander-content\"><a" + (jade.attr("href", file.url, true, false)) + " target=\"_blank\"" + (jade.attr("data-filename", file.name, true, false)) + " class=\"js-link\"><div" + (jade.attr("style", 'background-image: url("' + (file.url) + '");', true, false)) + " class=\"image-select-preview\"></div></a></div></div></div>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var file = $$obj[key];

buf.push("<div" + (jade.attr("data-id", key, true, false)) + " class=\"widget-grid__item\"><div class=\"widget\"><div class=\"list-item-content expander-content\"><a" + (jade.attr("href", file.url, true, false)) + " target=\"_blank\"" + (jade.attr("data-filename", file.name, true, false)) + " class=\"js-link\"><div" + (jade.attr("style", 'background-image: url("' + (file.url) + '");', true, false)) + " class=\"image-select-preview\"></div></a></div></div></div>");
    }

  }
}).call(this);

buf.push("</div></div>");
}
if ( other.length)
{
buf.push("<div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Videos</h2></div><div class=\"panel-content\">");
// iterate other
;(function(){
  var $$obj = other;
  if ('number' == typeof $$obj.length) {

    for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
      var file = $$obj[key];

buf.push("<video" + (jade.attr("src", file.url, true, false)) + " controls=\"controls\"></video><p><a" + (jade.attr("href", file.url, true, false)) + " target=\"_blank\"" + (jade.attr("data-filename", file.name, true, false)) + " class=\"js-link\">Download</a></p>");
    }

  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;      var file = $$obj[key];

buf.push("<video" + (jade.attr("src", file.url, true, false)) + " controls=\"controls\"></video><p><a" + (jade.attr("href", file.url, true, false)) + " target=\"_blank\"" + (jade.attr("data-filename", file.name, true, false)) + " class=\"js-link\">Download</a></p>");
    }

  }
}).call(this);

buf.push("</div></div>");
}
buf.push("</form></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"images" in locals_for_with?locals_for_with.images:typeof images!=="undefined"?images:undefined,"other" in locals_for_with?locals_for_with.other:typeof other!=="undefined"?other:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const ToolbarView = require('./form/toolbar')

const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

class FormView extends BaseFormView {
  setupToolbar() {
    this.toolbar = new ToolbarView(this.serviceLocator, this.model, this)
    this.toolbar.on('back', this.emit.bind(this, 'back'))
    this.$el.on('click', '.js-link', this.clickLink.bind(this))
  }

  getFile(filename) {
    const url = createSignedApiUrl(
      `/bodyshop-submissions/fetch-file/${this.model.get(
        '_id'
      )}/${encodeURIComponent(filename)}`,
      window.localStorage.apiKey,
      window.localStorage.apiId,
      this.serviceLocator.config.apiUrl
    )

    return url
  }

  getImages() {
    return this.model
      .get('files')
      .filter(file => file.mimetype.includes('image'))
      .map(file => ({
        url: this.getFile(file.filename),
        name: file.filename
      }))
  }

  getOtherFiles() {
    return this.model
      .get('files')
      .filter(file => !file.mimetype.includes('image'))
      .map(file => ({
        url: this.getFile(file.filename),
        name: file.filename
      }))
  }

  clickLink(e) {
    e.preventDefault()
    const file = e.currentTarget.dataset.filename
    window.open(this.getFile(file), '_blank')
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: 'Viewing Bodyshop Submission',
        data: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format,
        images: this.getImages(),
        other: this.getOtherFiles()
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }
}

module.exports = FormView
