import getFinance from '../../finance-calculator/codeweavers/finance-calculator'

export const defaultFinance = {
  monthlyPayment: null,
  carPrice: null,
  depositAmount: null,
  adminFee: null,
  balanceToFinance: null,
  firstPayment: null,
  numberOfPayments: null,
  finalPayment: null,
  amountPayable: null,
  interestCharges: null,
  interestApr: null,
  interestRate: null,
  optionFee: null,
  lender: null
}

export const financeProperties = Object.keys(defaultFinance)

export default serviceLocator => async vehicle => {
  let hpFinance = null
  let pcpFinance = null

  if (vehicle.saleType === 'new') {
    return vehicle
  }

  const defaultTermLength = { hp: 48, pcp: 42 }
  const defaultDepositPercentage = { hp: 0.2, pcp: 0.1 }

  try {
    hpFinance = await getFinance(serviceLocator)({
      type: 'HP',
      vehicle,
      annualMileage: 1000,
      termLength: defaultTermLength.hp,
      depositPercentage: defaultDepositPercentage.hp
    })

    pcpFinance = await getFinance(serviceLocator)({
      type: 'PCP',
      vehicle,
      annualMileage: 8000,
      termLength: defaultTermLength.pcp,
      depositPercentage: defaultDepositPercentage.pcp
    })
  } catch (e) {
    serviceLocator.logger.error(
      e,
      `Failed to fetch finance for ${vehicle.registration}`
    )
  }

  return {
    hpFinance: { ...defaultFinance, ...hpFinance },
    pcpFinance: { ...defaultFinance, ...pcpFinance }
  }
}
