const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/offer-category')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const OfferCategoryService = require('./services/offer-category')

const initAdmin = () => ({ offerCategoryAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.offerCategoryService = new OfferCategoryService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'offer category',
    plural: 'offer categories',
    path: 'offer-categories',
    service: serviceLocator.offerCategoryService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
