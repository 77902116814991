"use strict";

/*
 * Array helper to assist creation of nested array schemas
 */
var ArrayType = function ArrayType(schema) {
  return {
    arraySchema: typeof schema === 'function' ? schema() : schema
  };
};

module.exports = ArrayType;