const FormView = require('../views/form')
const Model = require('../models/image-set-config')
const createPublishFormViewController = require('../../base/controllers/publish-form-view-controller')
const async = require('async')

const createController = serviceLocator => {
  const initializeForm = createPublishFormViewController(
    serviceLocator,
    'imageSetConfigService',
    'Image Set Config',
    'image-set-configs',
    FormView
  )

  // Edit
  serviceLocator.router.route('image-set-configs/:id/form', 'editImageSetConfig', id => {
    if (!serviceLocator.allow('imageSetConfig', 'update')) return false

    serviceLocator.imageSetConfigService.read(id, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const model = new Model(serviceLocator, data)

      initializeForm(model, null, false)
    })
  })

  // Create
  serviceLocator.router.route('image-set-configs/form', 'createImageSetConfig', () => {
    if (!serviceLocator.allow('imageSetConfig', 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    initializeForm(model, null, true)
  })

  // Duplicate
  serviceLocator.router.route(
    'image-set-configs/:id/duplicate',
    'duplicateImageSetConfig',
    id => {
      if (!serviceLocator.allow('imageSetConfig', 'update')) return false

      const accountId = serviceLocator.session.account
      if (!accountId)
        return alert(
          'Session must be associated with an account id to create/edit image set configs'
        )

      async.parallel(
        {
          imageSetConfig: serviceLocator.imageSetConfigService.read.bind(
            serviceLocator.imageSetConfigService,
            id
          ),
          account: serviceLocator.accountService.read.bind(
            serviceLocator.accountService,
            accountId
          )
        },
        (err, data) => {
          if (err) return serviceLocator.router.trigger('notFound', err.message)
          const imageSetConfigModel = new Model(serviceLocator)
          const imageSetConfigData = data.imageSetConfig
          // Clear ID so it's new
          delete imageSetConfigData._id
          delete imageSetConfigData.createdDate
          imageSetConfigModel.set(imageSetConfigModel.schemata.makeDefault(imageSetConfigData))
          initializeForm(imageSetConfigModel, data.account, true)
        }
      )
    }
  )
}

module.exports = createController
