const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const extraProperties = {
  pixelId: {
    type: String,
    validators: [required]
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Facebook Pixel', extraProperties),
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'fbPixel' })
  },
  validate: validateDelegate
})
