const FormView = require('../views/form')
const Model = require('../models/model')
const async = require('async')
const createPublishFormViewController = require('../../base/controllers/publish-form-view-controller')

const createController = serviceLocator => {
  const initializeForm = createPublishFormViewController(
    serviceLocator,
    'modelService',
    'Model',
    'models',
    FormView
  )
  // Edit
  serviceLocator.router.route('models/:id/form', 'editModel', id => {
    if (!serviceLocator.allow('model', 'update')) return false

    serviceLocator.modelService.read(id, (err, data) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const model = new Model(serviceLocator, data)

      initializeForm(model, null, false)
    })
  })

  // Duplicate
  serviceLocator.router.route('models/:id/duplicate', 'duplicateModel', id => {
    if (!serviceLocator.allow('model', 'update')) return false

    const accountId = serviceLocator.session.account
    if (!accountId)
      return alert(
        'Session must be associated with an account id to create/edit models'
      )

    async.parallel(
      {
        model: serviceLocator.modelService.read.bind(
          serviceLocator.modelService,
          id
        ),
        account: serviceLocator.accountService.read.bind(
          serviceLocator.accountService,
          accountId
        )
      },
      (err, data) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        const model = new Model(serviceLocator)
        const modelData = data.model
        delete modelData._id
        delete modelData.slug
        delete modelData.createdDate

        model.set(model.schemata.makeDefault(modelData))

        initializeForm(model, null, true)
      }
    )
  })

  // Create
  serviceLocator.router.route('models/form', 'createModel', () => {
    if (!serviceLocator.allow('model', 'create')) return false

    const model = new Model(serviceLocator)
    model.set(model.schemata.makeDefault())

    initializeForm(model, null, true)
  })
}

module.exports = createController
