import createSignedApiUrl from '../../../../admin/source/js/lib/create-signed-api-url'

module.exports = serviceLocator => {
  $(document).on('click', '.js-newsletter-export', () => {
    window.location = createSignedApiUrl(
      '/newsletter/export',
      window.localStorage.apiKey,
      window.localStorage.apiId,
      serviceLocator.config.apiUrl
    )
  })
}
