const CrudService = require('../../../../admin/source/js/lib/crud-service')

class OfferService extends CrudService {
  get name() {
    return 'OfferService'
  }
  get urlRoot() {
    return '/offers'
  }
}

module.exports = OfferService
