const createController = require('./controllers/controller')

const initAdmin = () => ({ netlifyAdmin: [init] })

const init = (serviceLocator, cb) => {
  createController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
