module.exports = function() {
  // Use this to configure ck-editor
  return {
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing']
      },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'others', groups: ['others'] },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
      },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'about', groups: ['about'] }
    ],

    removeButtons: 'Underline,Subscript,Superscript,Scayt,Image,Styles',
    format_tags: 'h2;h3;p',
    stylesSet: 'custom_styles',
    removeDialogTabs: 'image:advanced;link:advanced',
    disableNativeSpellChecker: false,
    height: 500,
    scayt_sLang: 'en_GB',
    allowedContent:
      'div h1 h2 h3 h4 h5 h6 ol p pre ul li strong em{text-align}; a[href,name]; img[!src,alt]{width,height};'
  }
}
