const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/colour')
const baseFormController = require('./controllers/form')
const baseListController = require('./controllers/list')
const ColourService = require('./services/colour')

const initAdmin = () => ({ colourAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.colourService = new ColourService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'colour',
    plural: 'colourService',
    path: 'colours',
    service: serviceLocator.colourService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
