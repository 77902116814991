const CrudService = require('../../../../admin/source/js/lib/crud-service')

class CustomerService extends CrudService {
  get name() {
    return 'CustomerService'
  }
  get urlRoot() {
    return '/customer'
  }
}

module.exports = CustomerService
