module.exports=[
	"AGPL-1.0",
	"AGPL-3.0",
	"BSD-2-Clause-FreeBSD",
	"BSD-2-Clause-NetBSD",
	"GFDL-1.1",
	"GFDL-1.2",
	"GFDL-1.3",
	"GPL-1.0",
	"GPL-2.0",
	"GPL-2.0-with-GCC-exception",
	"GPL-2.0-with-autoconf-exception",
	"GPL-2.0-with-bison-exception",
	"GPL-2.0-with-classpath-exception",
	"GPL-2.0-with-font-exception",
	"GPL-3.0",
	"GPL-3.0-with-GCC-exception",
	"GPL-3.0-with-autoconf-exception",
	"LGPL-2.0",
	"LGPL-2.1",
	"LGPL-3.0",
	"Nunit",
	"StandardML-NJ",
	"eCos-2.0",
	"wxWindows"
]
