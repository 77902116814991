const schemata = require('schemata')
const featureSetSchema = require('./feature-set-schema')()
const required = require('validity-required')
const createUniqueValidator = require('validity-unique-property')
const customValidityMessage = require('../../../lib/validators/custom-validator-message')

module.exports = ({ find } = {}) => {
  const uniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: ['account'] }),
    'This {name} is already in use'
  )

  return schemata({
    name: 'Features',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: [required, uniqueValidator]
      },
      account: {
        type: String,
        validators: [required]
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      interior: {
        type: schemata.Array(featureSetSchema)
      },
      exterior: {
        type: schemata.Array(featureSetSchema)
      },
      technical: {
        type: schemata.Array(featureSetSchema)
      },
      safety: {
        type: schemata.Array(featureSetSchema)
      }
    }
  })
}
