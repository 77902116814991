const schema = require('schemata')
const baseSchema = require('../../../service/widget/model/base-widget')()
const assetSchemata = require('../../../service/asset/schema')()

module.exports = () =>
  schema({
    name: 'Image',
    properties: {
      selectedContexts: {
        type: Array
      },
      ...baseSchema.getProperties(),
      ...assetSchemata.getProperties()
    }
  })
