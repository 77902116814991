const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')

const extraProperties = {
  images: {
    type: Object,
    defaultValue: () => []
  },
  title: {
    type: String,
    validators: { all: [required] }
  },
  sell: {
    type: String,
    validators: { all: [required] }
  },
  callToActionText: {
    type: String,
    validators: { all: [required] }
  },
  callToActionLink: {
    type: String,
    validators: { all: [required] }
  }
}

module.exports = () => createSchema('Sell Your Car Widget', extraProperties)
