const required = require('validity-required')
const schemata = require('schemata')
const createDateBeforePropertyValidator = require('validity-date-before-property')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const createDefaultSectionLayout = require('./lib/default-section-layout')
const createDefaultArticleLayout = require('./lib/default-article-layout')

module.exports = () =>
  schemata({
    name: 'Section',
    properties: {
      _id: {
        type: String,
        tag: ['root']
      },
      // Is this section the root section of the site. If so then slug is not editable
      root: {
        type: Boolean,
        defaultValue: false,
        tag: ['root']
      },
      systemType: {
        type: String,
        defaultValue: null,
        tag: ['root']
      },
      name: {
        type: String,
        validators: [required],
        tag: ['root']
      },
      slug: {
        type: String,
        validators: [validateIfPropertyEquals('root', false, required)]
      },
      className: {
        type: String,
        tag: ['root']
      },
      classNameSectionOnly: {
        type: String,
        tag: ['root']
      },
      fullUrlPath: {
        type: String
      },
      parent: {
        type: String,
        tag: ['root']
      },
      order: {
        type: Number,
        tag: ['root']
      },
      calltracksId: {
        type: String
      },
      calltracksNumber: { type: String },
      layouts: {
        type: Object,
        tag: ['root'],
        defaultValue: () => ({
          section: createDefaultSectionLayout(),
          article: createDefaultArticleLayout()
        }),
        resolve: async section => {
          return JSON.stringify(section.layouts)
        },
        resolveType: String
      },
      visible: {
        type: Boolean,
        tag: ['root'],
        defaultValue: false,
        validators: [required]
      },
      displayInNav: {
        type: Boolean,
        defaultValue: false
      },
      displayInSecondaryNav: {
        type: Boolean,
        defaultValue: false
      },
      liveDate: {
        type: Date,
        tag: ['root'],
        validators: createDateBeforePropertyValidator()
      },
      expiryDate: {
        type: Date,
        tag: ['root']
      },
      metaTitle: {
        type: String
      },
      metaDescription: {
        type: String
      },
      shareTitle: {
        type: String
      },
      shareDescription: {
        type: String
      },
      liveChatProviderId: {
        type: String,
        tag: ['root']
      },
      discoverable: {
        type: Boolean,
        defaultValue: true
      },
      account: {
        type: String,
        validators: [required],
        tag: ['root']
      },
      created: {
        type: Date,
        tag: ['root']
      }
    }
  })
