const View = require('ventnor')
const Collection = require('chale')
const Model = require('merstone')
const find = require('lodash.find')

class TagSelect extends View {
  constructor(serviceLocator, initialTags, tagType, isReadOnly) {
    if (!isReadOnly && !tagType) throw new Error('Tag type must be supplied')
    initialTags = initialTags || []
    super(serviceLocator, initialTags)
    this.tagType = tagType || null
    this.isReadOnly = !!isReadOnly
    this.$el = $('<select multiple/>')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose some tags')
    this.tags = new Collection(
      serviceLocator,
      initialTags
        .filter(tag => {
          return !tagType || tag.type === tagType
        })
        .map(tag => new Model(serviceLocator, tag))
    )
    this.on('remove', () => {
      this.el.selectize.destroy()
    })
  }

  loadTags() {
    return this
  }

  create(input) {
    const value = JSON.stringify({ tag: input, type: this.tagType, meta: [] })
    return { value: value, text: input }
  }

  onAdd(value) {
    const tag = JSON.parse(value)
    this.tags.add(new Model(this.serviceLocator, tag))
    this.emit('change')
  }

  onRemove(value) {
    const tag = JSON.parse(value)
    const toDelete = find(this.tags.models, existing => {
      return (
        existing.get('type') === tag.type && existing.get('tag') === tag.tag
      )
    })
    if (toDelete) this.tags.remove(toDelete.cid)
    this.emit('change')
  }

  addSelectizeHandlers() {
    this.el.selectize.on('item_add', this.onAdd.bind(this))
    this.el.selectize.on('item_remove', this.onRemove.bind(this))
  }

  initializeSelectize() {
    this.tags.models.forEach(item => {
      const value = JSON.stringify({
        tag: item.get('tag'),
        type: item.get('type'),
        meta: item.get('meta')
      })
      // The item needs to be added to the list
      // of selectize options in order to be selected
      this.el.selectize.addOption({ value: value, text: item.get('tag') })
      // Select the added option
      this.el.selectize.addItem(value)
    })
    this.addSelectizeHandlers()
  }

  load(query, cb) {
    const filter = this.tagType ? { type: this.tagType } : {}
    const order = ['tag', 'asc']
    const pagination = { page: 1, pageSize: 500 }

    this.serviceLocator.tagService.find(
      query,
      filter,
      order,
      pagination,
      (err, res) => {
        if (err)
          return this.serviceLocator.logger.error(err, 'Error loading tags')
        cb(
          res.results.map(tag => ({
            value: JSON.stringify({
              tag: tag.tag,
              type: tag.type,
              meta: tag.meta
            }),
            text: tag.tag
          }))
        )
      }
    )
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        delimiter: ',',
        persist: false,
        create: this.isReadOnly ? false : this.create.bind(this),
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this)
      })
    }, 100)
    return this
  }
}

module.exports = TagSelect
