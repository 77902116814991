const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const additionalDetailsContainerTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["lineUpPanel"] = jade_interp = function(type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"section-details\"" + (jade.attr("data-field", type + 'Details', true, false)) + " class=\"panel panel-styled js-details-panel\"><div class=\"panel-header panel-header--justified\"><h2>" + (jade.escape(null == (jade_interp = (type.charAt(0).toUpperCase() + type.slice(1)) + ' Details') ? "" : jade_interp)) + "</h2><div class=\"control-group\"><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-clear-items\">Clear Items</button></div></div><div class=\"panel-content\"><div" + (jade.attr("id", 'field--' + type + 'details', true, false)) + " class=\"form-row is-hidden\"><div class=\"js-error\"></div><div" + (jade.cls(['js-' + type + '-details'], [true])) + "></div></div><button type=\"button\"" + (jade.attr("data-type", type, true, false)) + " class=\"btn js-add-item\">Add Item</button><p></p></div></div>");
};
jade_mixins["lineUpPanel"]('safety');
jade_mixins["lineUpPanel"]('environment');
jade_mixins["lineUpPanel"]('interior');
jade_mixins["lineUpPanel"]('exterior');
jade_mixins["lineUpPanel"]('technical');;return buf.join("");
}
const detailsItemTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (index, label, type, value) {
buf.push("<div class=\"grid\"><div" + (jade.attr("id", 'field--item-label-' + index, true, false)) + (jade.attr("data-field", 'item-label-' + index, true, false)) + " class=\"grid__item three-sevenths form-row\"><label><span class=\"form-label-text\">Label</span><input type=\"text\" name=\"label\"" + (jade.attr("value", label, true, false)) + (jade.attr("data-index", index, true, false)) + " class=\"control control--text form-field\"/></label></div><div" + (jade.attr("id", 'field--item-value-' + index, true, false)) + (jade.attr("data-field", 'item-value-' + index, true, false)) + " class=\"grid__item three-sevenths form-row\"><label><span class=\"form-label-text\">Value</span><input type=\"text\" name=\"value\"" + (jade.attr("value", value, true, false)) + (jade.attr("data-index", index, true, false)) + " class=\"control control--text form-field\"/></label></div><div" + (jade.attr("id", 'field--item-remove-' + index, true, false)) + (jade.attr("data-field", 'item-remove-' + index, true, false)) + " class=\"grid__item one-seventh form-row\"><div class=\"list-item-actions\"><div class=\"btn-group\"><a" + (jade.attr("data-index", index, true, false)) + (jade.attr("data-type", type, true, false)) + " class=\"btn js-remove-item\"><i class=\"icon icon--cross--dark\"></i></a></div></div></div></div>");}.call(this,"index" in locals_for_with?locals_for_with.index:typeof index!=="undefined"?index:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined));;return buf.join("");
}

class AdditionalDetailsView extends View {
  constructor(serviceLocator, model) {
    super(serviceLocator)
    this.model = model
    this.itemCount = {
      safety: 0,
      environment: 0,
      interior: 0,
      exterior: 0,
      technical: 0
    }
    this.propertyNames = {
      safety: 'safety',
      environment: 'environment',
      interior: 'interior',
      exterior: 'exterior',
      technical: 'technical'
    }
  }

  render() {
    this.$el.append(additionalDetailsContainerTemplate())

    Object.keys(this.propertyNames).map(propName => this.renderItems(propName))

    this.$el
      .find('.js-clear-items')
      .on('click', this.handleClearItems.bind(this))
    this.$el.find('.js-add-item').on('click', this.handleAddNewItem.bind(this))

    return this
  }

  handleRemoveItem(e) {
    e.preventDefault()
    const index = $(e.currentTarget).data('index')
    const type = $(e.currentTarget).data('type')
    const items = this.model.get(type)
    items.splice(index, 1)
    this.model.set(type, items)
    this.renderItems(type)
  }

  handleClearItems(e) {
    e.preventDefault()
    const detailsType = $(e.currentTarget).data('type')
    this.clearItems(detailsType)
  }

  renderItems(type) {
    this.itemCount[type] = 0
    this.$el.find('.js-' + type + '-details').empty()
    const currentItems = this.model.get(this.propertyNames[type]) || []

    for (let i = 0; i < currentItems.length; i++) {
      const item = currentItems[i]
      const itemLabel = item ? item.label : null
      const itemValue = item ? item.value : null
      this.addItem(itemLabel, itemValue, type)
    }
  }

  addItem(itemLabel, itemValue, type) {
    const index = this.itemCount[type]++

    this.$el.find('.js-' + type + '-details').append(
      detailsItemTemplate({
        label: itemLabel,
        value: itemValue,
        index,
        type
      })
    )

    this.$el.find('.js-' + type + '-details').on('change', e => {
      const index = e.target.dataset.index
      const key = e.target.name
      const values = this.model.get(type)
      const currentValue = values[index]
      const itemObject = {}
      itemObject[key] = e.target.value

      values[index] = Object.assign({}, currentValue, itemObject)

      this.model.set(type, values)
    })

    this.$el
      .find('#field--item-remove-' + index + ' .js-remove-item')
      .on('click', e => {
        this.handleRemoveItem(e)
      })
  }

  handleAddNewItem(e) {
    e.preventDefault()
    const $clickedEl = $(e.currentTarget)
    const type = $clickedEl.data('type')
    const items = this.model.get(type)
    items.push({ label: '', value: '' })
    this.model.set(type, items)
    this.addItem(null, null, type)
  }

  clearItems(type) {
    this.model.set(this.propertyNames[type], [])
    this.renderItems(type)
  }
}

module.exports = AdditionalDetailsView
