const compileJade = require('browjadify-compile')
const BaseFormView = require('../../base/views/form')
const join = require('path')
const ApiSelect = require('../../base/views/api-select')
const BaseSelectView = require('../../base/views/select')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"page-content\"><div class=\"centering\"><header class=\"page-header\"><h1>Vehicle Collection Form</h1></header><div class=\"notification notification--success hidden js-success\"><p>Request submitted. View sheet <a href=\"https://docs.google.com/spreadsheets/d/1HzBOXc3ApcHctVmEpLuZYW9Q-3J5Unugb15KlX3SZtw/edit#gid=16157585\">here</a></p></div><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};













jade_mixins["selectField"] = jade_interp = function(property, label, required, description, showAdd){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><div" + (jade.cls(['form-field','js-' + property], [null,true])) + "></div></label>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
if ( showAdd && allowed('usedVehicleDropdowns', property))
{
buf.push("<div class=\"form-row-description form-copy\"><a" + (jade.cls(['btn','js-add-' + property], [null,true])) + ">Add " + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</a></div>");
}
buf.push("<div class=\"js-error\"></div></div>");
};




buf.push("<div id=\"section-locations\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Locations</h2></div><div class=\"panel-content\">");
jade_mixins["selectField"]('collectionDealership', 'Collection From', true);
jade_mixins["selectField"]('returnVehicleTo', 'Return Vehicle to (if known)', false);
buf.push("</div></div>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};













jade_mixins["selectField"] = jade_interp = function(property, label, required, description, showAdd){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><div" + (jade.cls(['form-field','js-' + property], [null,true])) + "></div></label>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
if ( showAdd && allowed('usedVehicleDropdowns', property))
{
buf.push("<div class=\"form-row-description form-copy\"><a" + (jade.cls(['btn','js-add-' + property], [null,true])) + ">Add " + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</a></div>");
}
buf.push("<div class=\"js-error\"></div></div>");
};




buf.push("<div id=\"section-vehicle-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Vehicle Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('registration', 'Registration', '', data.registration, true, 'text');
jade_mixins["selectField"]('autoTraderVehicleType', 'Vehicle Type', true);
jade_mixins["selectField"]('autoTraderMake', 'Make', true);
jade_mixins["selectField"]('autoTraderModel', 'Model', true);
jade_mixins["selectField"]('autoTraderGeneration', 'Generation', true);
jade_mixins["selectField"]('autoTraderDerivative', 'Derivative', true);
buf.push("<div id=\"field--model\" data-field=\"model\" class=\"form-row\"><div class=\"js-error\"></div></div>");
jade_mixins["inputField"]('retailPrice', 'Retail Price', '', data.retailPricer, true, 'number');
jade_mixins["inputField"]('colour', 'Colour', '', data.colour, false, 'text');
jade_mixins["inputField"]('mileage', 'Mileage', '', data.mileage, false, 'number');
buf.push("<div id=\"field--workGrade\" data-field=\"workGrade\" class=\"form-row\"><label><span class=\"form-label-text\">Work Grade<abbr title=\"This field is required\">*</abbr></span><select name=\"workGrade\" class=\"control control--choice form-field\"><option value=\"\">-- Select a Grade --</option><option value=\"Grade 1\"" + (jade.attr("selected", data.workGrade === 'Grade 1', true, false)) + ">Grade 1</option><option value=\"Grade 2\"" + (jade.attr("selected", data.workGrade === 'Grade 2', true, false)) + ">Grade 2</option><option value=\"Grade 3\"" + (jade.attr("selected", data.workGrade === 'Grade 3', true, false)) + ">Grade 3</option></select></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><ul><li> \nGrade 1 -- Requires smart repairs totalling no more than £200.<br/><small>Mechanical work should only be an MPI or small service.</small></li><li>Grade 2 -- Requires smart repairs totalling no more than £400.<br/><small>Mechanical work to include large service, Cambelt, break fluid change and disks and pads etc.</small></li><li> \nGrade 3 -- Requires Bodyshop estimate due to paint and panel damage <br/><small>* Regardless of mechanical costs, on the proviso that considered in margin?</small></li></ul></div></div><div id=\"field--notes\" data-field=\"notes\" class=\"form-row\"><label><span class=\"form-label-text\">Notes</span><textarea name=\"notes\" rows=\"5\" class=\"control control--text control--multiline form-field\">" + (jade.escape(null == (jade_interp = data.notes) ? "" : jade_interp)) + "</textarea></label><div class=\"js-error\"></div></div></div></div><button class=\"btn btn--action js-submit\">Submit</button></form></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class UploadView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )

    this.$el.on('click', '.js-submit', this.submit.bind(this))
  }

  submit(e) {
    e.preventDefault()

    const button = this.$el.find('.js-submit')

    button.text('Saving...').prop('disabled', true)
    this.emit('saveAndReset', err => {
      const successMessage = this.$el.find('.js-success')
      if (err) {
        successMessage.addClass('hidden')
        return button.text('Save').prop('disabled', false)
      }

      successMessage.removeClass('hidden')
      $('html, body').animate({ scrollTop: 0 }, 500, 'swing')
    })
  }

  setupToolbar() {
    return
  }

  get name() {
    return {
      singular: 'Vehicle Collection Form',
      plural: 'Vehicle Collection Form',
      resource: 'prepFormEntry'
    }
  }

  renderAutoTraderVehicleTypeSelect() {
    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto Trader Vehicle Type',
      plural: 'Auto Trader Vehicle Type',
      selected: this.model.get('autoTraderVehicleType'),
      apiRoute: '/autotrader/vehicle-types',
      mapResult: res => ({
        text: res.name,
        value: res.name
      }),
      create: false,
      noFilterOnChange: true
    })

    this.$el
      .find(`.js-autoTraderVehicleType`)
      .empty()
      .append(select.render().$el)
    select.on('change', item => {
      this.model.set('autoTraderVehicleType', item)
      this.model.set('autoTraderMake', '')
      this.model.set('autoTraderModel', '')
      this.model.set('autoTraderGeneration', '')
      this.model.set('autoTraderDerivative', '')
      this.renderAutoTraderMakeSelect()
    })
    this.attachView(select)

    if (this.model.get('autoTraderVehicleType')) {
      if (select.initialised) {
        this.renderAutoTraderMakeSelect()
      } else {
        select.on('initialised', () => {
          this.renderAutoTraderMakeSelect()
        })
      }
    } else {
      this.$el.find(`.js-autoTraderMake`).html()
    }
  }

  withToString(item) {
    if (Object.keys(item).length === 0) return null

    return Object.assign({}, item, {
      toString() {
        return JSON.stringify(item)
      }
    })
  }

  renderAutoTraderMakeSelect() {
    const vehicleType = this.model.get('autoTraderVehicleType')
    const current = this.withToString(this.model.get('autoTraderMake'))
    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto Trader Make',
      plural: 'Auto Trader Make',
      selected: current,
      idProperty: 'makeId',
      apiRoute: `/autotrader/makes/${vehicleType}`,
      mapResult: res => ({
        text: res.name,
        value: this.withToString({
          id: res.makeId,
          name: res.name
        })
      }),
      create: false,
      noFilterOnChange: true
    })

    this.$el
      .find(`.js-autoTraderMake`)
      .empty()
      .append(select.render().$el)
    select.on('change', item => {
      if (item) {
        item = JSON.parse(item)
        this.model.set('autoTraderMake', item)
        this.model.set('autoTraderModel', '')
        this.model.set('autoTraderGeneration', '')
        this.model.set('autoTraderDerivative', '')
        this.renderAutoTraderModelSelect()
      }
    })
    this.attachView(select)

    if (current) {
      if (select.initialised) {
        this.renderAutoTraderModelSelect()
      } else {
        select.on('initialised', () => {
          this.renderAutoTraderModelSelect()
        })
      }
    } else {
      this.$el.find('.js-autoTraderModel').empty()
      this.$el.find('.js-autoTraderGeneration').empty()
      this.$el.find('.js-autoTraderDerivative').empty()
    }
  }

  renderAutoTraderModelSelect() {
    const make = this.model.get('autoTraderMake')
    const current = this.withToString(this.model.get('autoTraderModel'))

    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto Trader Model',
      plural: 'Auto Trader Model',
      selected: current,
      idProperty: 'modelId',
      apiRoute: `/autotrader/models/${make.id}`,
      mapResult: res => ({
        text: res.name,
        value: this.withToString({
          id: res.modelId,
          name: res.name
        })
      }),
      create: false,
      noFilterOnChange: true
    })

    this.$el
      .find(`.js-autoTraderModel`)
      .empty()
      .append(select.render().$el)
    select.on('change', item => {
      if (item) {
        item = JSON.parse(item)
        this.model.set('autoTraderModel', item)
        this.model.set('autoTraderGeneration', '')
        this.model.set('autoTraderDerivative', '')
        this.renderAutoTraderGenerationSelect()
      }
    })
    this.attachView(select)

    if (current) {
      if (select.initialised) {
        this.renderAutoTraderGenerationSelect()
      } else {
        select.on('initialised', () => {
          this.renderAutoTraderGenerationSelect()
        })
      }
    } else {
      this.$el.find('.js-autoTraderGeneration').empty()
      this.$el.find('.js-autoTraderDerivative').empty()
    }
  }

  renderAutoTraderGenerationSelect() {
    const model = this.model.get('autoTraderModel')
    const current = this.withToString(this.model.get('autoTraderGeneration'))
    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto Trader Generation',
      plural: 'Auto Trader Generation',
      selected: current,
      idProperty: 'generationId',
      apiRoute: `/autotrader/generations/${model.id}`,
      mapResult: res => ({
        text: res.name,
        value: this.withToString({
          id: res.generationId,
          name: res.name
        })
      }),
      create: false,
      noFilterOnChange: true
    })

    this.$el
      .find(`.js-autoTraderGeneration`)
      .empty()
      .append(select.render().$el)
    select.on('change', item => {
      if (item) {
        item = JSON.parse(item)
        this.model.set('autoTraderGeneration', item)
        this.model.set('autoTraderDerivative', '')
        this.renderAutoTraderDerivativeSelect()
      }
    })
    this.attachView(select)

    if (current) {
      if (select.initialised) {
        this.renderAutoTraderDerivativeSelect()
      } else {
        select.on('initialised', () => {
          this.renderAutoTraderDerivativeSelect()
        })
      }
    } else {
      this.$el.find('.js-autoTraderDerivative').empty()
    }
  }

  renderAutoTraderDerivativeSelect() {
    const generation = this.model.get('autoTraderGeneration')
    const current = this.withToString(this.model.get('autoTraderDerivative'))

    const select = new ApiSelect(this.serviceLocator, {
      singular: 'Auto Trader Derivative',
      plural: 'Auto Trader Derivative',
      selected: current,
      idProperty: 'derivativeId',
      apiRoute: `/autotrader/derivatives/${generation.id}`,
      mapResult: res => ({
        text: res.name,
        value: {
          id: res.derivativeId,
          name: res.name,
          toString() {
            return JSON.stringify(this)
          }
        }
      }),
      create: false,
      noFilterOnChange: true
    })

    this.$el
      .find(`.js-autoTraderDerivative`)
      .empty()
      .append(select.render().$el)
    select.on('change', item => {
      if (item) {
        item = JSON.parse(item)
        const make = this.model.get('autoTraderMake')
        const model = this.model.get('autoTraderModel')
        this.model.set('autoTraderDerivative', item)
        this.model.set('model', `${make.name} ${model.name} ${item.name}`)
      }
    })
    this.attachView(select)
  }

  renderDealershipSelect() {
    const collectionDealership = new BaseSelectView(this.serviceLocator, {
      service: 'dealershipService',
      selected: this.model.get('collectionDealership'),
      limitToOneValue: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName',
      displayNoneOption: true
    })

    this.$el
      .find('.js-collectionDealership')
      .append(collectionDealership.render().$el)
    collectionDealership.on('change', dealership =>
      this.model.set('collectionDealership', dealership)
    )
    this.attachView(collectionDealership)

    const returnVehicleTo = new BaseSelectView(this.serviceLocator, {
      service: 'dealershipService',
      selected: this.model.get('returnVehicleTo'),
      limitToOneValue: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName',
      displayNoneOption: true
    })

    this.$el.find('.js-returnVehicleTo').append(returnVehicleTo.render().$el)
    returnVehicleTo.on('change', dealership =>
      this.model.set('returnVehicleTo', dealership)
    )
    this.attachView(returnVehicleTo)
  }

  render() {
    this.$el
      .empty()
      .append(template({ name: this.name, data: this.model.toJSON() }))

    this.renderAutoTraderVehicleTypeSelect()
    this.renderDealershipSelect()

    return this
  }
}

module.exports = UploadView
