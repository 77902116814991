const createController = require('./controllers/controller')
const createDownloadController = require('./controllers/download')

const init = (serviceLocator, cb) => {
  createController(serviceLocator)
  createDownloadController(serviceLocator)
  cb(null)
}

module.exports = () => ({ autotraderAdmin: [init] })
