const BaseSelect = require('../../base/views/select')

const defaultSelectizeConfig = {
  delimiter: ',',
  preload: true
}

class DistinctSelect extends BaseSelect {
  constructor(serviceLocator, options, field) {
    const newOptions = {
      ...options,
      selectizeConfig: {
        ...defaultSelectizeConfig,
        ...options.selectizeConfig
      },
      limitToOneValue: true
    }

    super(serviceLocator, newOptions)
    this.field = field
    this.query = options.query
  }

  initializeSelectize() {
    const allOptionIndex = this.selected.indexOf('all')
    const allSelected = [...this.selected]
    if (allOptionIndex > -1) this.selected.splice(allOptionIndex, 1)

    this.serviceLocator[this.service].getDistinct(
      this.field,
      this.query,
      err => {
        if (err) return alert(`Cannot find existing ${this.singular}`)

        // This ensure they stay in the same order
        allSelected.forEach(value => {
          this.el.selectize.addOption({
            value,
            text: value
          })
          // Select the added option
          this.el.selectize.addItem(value)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  load(query, cb) {
    this.serviceLocator[this.service].getDistinct(
      this.field,
      this.query,
      (err, data) => {
        if (err) return cb(err)
        const list = data.map(res => {
          return { value: res, text: res }
        })

        cb(list)
      }
    )
  }
}

module.exports = DistinctSelect
