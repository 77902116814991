const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"js-item list-item\"><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a class=\"btn btn--small js-remove-item\">Remove</a></div></div><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = data.heading) ? "" : jade_interp)) + "</a></div><div class=\"list-item-content\"><dl>");
if ( data.type)
{
buf.push("<dt>Type:</dt><dd>" + (jade.escape(null == (jade_interp = data.type) ? "" : jade_interp)) + "</dd>");
}
if ( data.categories)
{
buf.push("<dt>Categories:</dt><dd>" + (jade.escape(null == (jade_interp = data.categories) ? "" : jade_interp)) + "</dd>");
}
if ( data.makes)
{
buf.push("<dt>Makes:</dt><dd>" + (jade.escape(null == (jade_interp = data.makes) ? "" : jade_interp)) + "</dd>");
}
if ( data.models)
{
buf.push("<dt>Models:</dt><dd>" + (jade.escape(null == (jade_interp = data.models) ? "" : jade_interp)) + "</dd>");
}
if ( data.locations)
{
buf.push("<dt>Locations:</dt><dd>" + (jade.escape(null == (jade_interp = data.locations) ? "" : jade_interp)) + "</dd>");
}
buf.push("</dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}
const async = require('async')

class ItemRepeaterItemView extends BaseItemRepeaterItemView {
  constructor(serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
    this.serviceLocator = serviceLocator
  }

  render() {
    const serviceLocator = this.serviceLocator
    const data = this.model.toJSON()

    const getModels = cb => {
      const service = serviceLocator.modelService
      const ids = this.model.get('models')

      service.find('', { _id: { $in: ids } }, {}, [], (error, values) => {
        if (error) return cb(error)

        const vehicles = values.results.map(item => item.name)
        data.models = vehicles.join(', ')
        cb()
      })
    }

    const getMakes = cb => {
      const service = serviceLocator.makeService
      const ids = this.model.get('makes')

      service.find('', { _id: { $in: ids } }, {}, [], (error, values) => {
        if (error) return cb(error)

        const makes = values.results.map(item => item.name)
        data.makes = makes.join(', ')
        cb()
      })
    }

    const getLocations = cb => {
      serviceLocator.dealershipService.find(
        '',
        { _id: { $in: this.model.get('selectedLocations') } },
        {},
        [],
        (error, values) => {
          if (error) return cb(error)

          const locations = values.results.map(i => i.location)
          data.locations = locations.join(', ')
          cb()
        }
      )
    }

    const getOfferCategories = cb => {
      serviceLocator.offerCategoryService.find(
        '',
        { _id: { $in: this.model.get('offerCategories') } },
        {},
        [],
        (error, values) => {
          if (error) return cb(error)

          const categories = values.results.map(i => i.name)
          data.categories = categories.join(', ')
          cb()
        }
      )
    }

    async.parallel(
      [
        getModels.bind(this),
        getMakes.bind(this),
        getLocations.bind(this),
        getOfferCategories.bind(this)
      ],
      error => {
        if (error) serviceLocator.logger.error(error)

        this.$el.append(this.template({ data: data, id: this.model.id }))
      }
    )

    return this
  }
}

module.exports = ItemRepeaterItemView
