const View = require('ventnor')
const defaultSelectizeConfig = {
  delimiter: ',',
  create: false,
  preload: true
}

class BodyTypeSelectView extends View {
  constructor(
    serviceLocator,
    selected,
    make,
    selectizeConfig = defaultSelectizeConfig
  ) {
    if (!Array.isArray(selected)) selected = [selected]
    super(serviceLocator, selected)

    this.selectizeConfig = selectizeConfig
    this.$el = $('<select multiple />')
    this.$el.attr('name', 'body-types')
    this.$el.attr('placeholder', `Choose one or more body types`)
    this.$el.addClass('control control--choice')
    this.$el.addClass('control--multiline')

    this.el = this.$el[0]
    this.make = make
    this.selected = selected || []
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: 500 }

    this.serviceLocator.bodyTypeService.find(
      '',
      { _id: { $in: this.selected } },
      [],
      pagination,
      (err, res) => {
        if (err) return alert(`Cannot find existing body types`)
        const index = res.results.reduce((index, res) => {
          index[res._id] = res
          return index
        }, {})
        // This ensure they stay in the same order
        this.selected.forEach(id => {
          // The item needs to be added to the list
          // of selectize options in order to be selected
          this.el.selectize.addOption({
            value: id,
            text: index[id] ? index[id].name : id
          })
          // Select the added option
          this.el.selectize.addItem(id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.bodyTypeService.cachedFind(
      query,
      {},
      ['name', 'asc'],
      pagination,
      (err, data) => {
        if (err) return cb(err)
        const filteredBodyTypes = data.results.filter(
          bodyType => bodyType.makes.indexOf(this.make) > -1
        )

        cb(
          filteredBodyTypes.map(bodyType => {
            return { value: bodyType._id, text: bodyType.name }
          })
        )
      }
    )
  }

  render() {
    const selectizeSettings = Object.assign(
      {},
      defaultSelectizeConfig,
      this.selectizeConfig,
      {
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this)
      }
    )

    setTimeout(() => {
      this.$el.selectize(selectizeSettings)
    }, 0)
    return this
  }
}

module.exports = BodyTypeSelectView
