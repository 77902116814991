const schemata = require('schemata')

module.exports = () => {
  const schema = schemata({
    name: 'New Vehicle Import',
    properties: {
      _id: { type: String },
      externalId: { type: String },
      autoTraderSearchId: { type: String },
      registration: { type: String },
      vin: { type: String },
      action: { type: String },
      successful: { type: Boolean },
      failureReason: { type: Object },
      dealership: { type: String },
      autoTraderData: { type: Object },
      processedData: { type: Object },
      usedVehicleId: { type: String },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
