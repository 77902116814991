const createSchema = require('../../../../service/widget/model/base-widget')

const extraProperties = {
  items: {
    type: Array,
    defaultValue: () => []
  }
}

module.exports = () => createSchema('Button Group', extraProperties)
