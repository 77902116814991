const BasicToolbarView = require('../../../toolbar/views/form-toolbar')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div></div>");;return buf.join("");
}

class ToolbarView extends BasicToolbarView {
  get template() {
    return template
  }
}

module.exports = ToolbarView
