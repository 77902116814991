const required = require('validity-required')
const schemata = require('schemata')
const createSetConfigSchema = require('./set-config-schema')

module.exports = (serviceLocator, { imageSetConfigSave } = {}) => {
  const makeModelUniqueValidator = (key, msg, object, callback) => {
    imageSetConfigSave.find(
      { _id: { $ne: object._id }, make: object.make, model: object.model },
      (err, entity) => {
        if (err) return callback(err)
        callback(
          null,
          entity.length === 0
            ? undefined
            : 'An Image Set Config already exists for this Make and Model combination'
        )
      }
    )
  }

  const validateAtLeastOneItem = (key, msg, object, callback) => {
    callback(
      null,
      object[key].length > 0
        ? undefined
        : 'Please add at least one image to this config'
    )
  }

  const schema = schemata({
    name: 'ImageSetConfig',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      make: { type: String, validators: [required] },
      model: { type: String, validators: [required, makeModelUniqueValidator] },
      createdDate: { type: Date, defaultValue: () => new Date() },
      setConfig: {
        type: schemata.Array(createSetConfigSchema(serviceLocator)),
        defaultValue: () => [],
        validators: [validateAtLeastOneItem]
      }
    }
  })

  return schema
}
