module.exports = AccountSelectView
const View = require('ventnor')

function AccountSelectView(serviceLocator, value) {
  View.apply(this, arguments)
  this.$el = $('<select/>')
  this.$el.attr('name', 'account')
  this.$el.addClass('control control--choice')
  this.value = value
  this.$el.on(
    'change',
    function() {
      this.emit('change', this.$el.val())
    }.bind(this)
  )
  this.notSetLabel = '-- Select an account --'
}

AccountSelectView.prototype = Object.create(View.prototype)

AccountSelectView.prototype.render = function() {
  this.$el.append($('<option/>', { text: this.notSetLabel, value: '' }))
  return this
}

AccountSelectView.prototype.populate = function() {
  this.serviceLocator.accountService.find(
    '',
    {},
    ['name'],
    {},
    function(err, res) {
      if (err) return this.serviceLocator.logger.error(err)
      res.results.forEach(
        function(account) {
          this.$el.append(
            $('<option/>', {
              text: account.name,
              value: account._id,
              selected: this.value === account._id
            })
          )
        }.bind(this)
      )
    }.bind(this)
  )
  return this
}
