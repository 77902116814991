const Model = require('merstone')

class ListItemModel extends Model {
  constructor(serviceLocator, attrs, contentModel, overridesModel, ...args) {
    super(serviceLocator, attrs, ...args)
    this.contentModel = contentModel
    this.overridesModel = overridesModel
  }
}

module.exports = ListItemModel
