const required = require('validity-required')
const schemata = require('schemata')
const createDealershipSchema = require('../dealership/schema')
const resolveImages = require('../../../lib/image-resolver')

module.exports = ({ readDealership } = {}) =>
  schemata({
    name: 'Career',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      title: { type: String, validators: [required] },
      sell: { type: String, validators: [required] },
      dealership: {
        type: String,
        resolve:
          readDealership && (parent => readDealership(parent.dealership)),
        resolveType: readDealership && createDealershipSchema()
      },
      jobDescription: { type: String, validators: [required] },
      candidateDescription: { type: String },
      otherInfo: { type: String },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: async posting => resolveImages(posting.images),
        resolveType: Array
      },
      createdDate: { type: Date, defaultValue: () => new Date() },
      state: {
        type: String,
        options: ['Draft', 'Published'],
        defaultValue: 'Draft',
        validators: { all: [] }
      }
    }
  })
