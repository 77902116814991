const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/image-set-config')
const formController = require('./controllers/form')
const listController = require('./controllers/list')
const ImageSetConfigService = require('./services/image-set-config')
const createRevisionController = require('../revision/controllers/controller')

function initAdmin() {
  return { imageSetConfigAdmin: ['widget', init] }
}

function init(serviceLocator, cb) {
  serviceLocator.imageSetConfigService = new ImageSetConfigService(serviceLocator)

  const controllerOptions = {
    serviceLocator,
    singular: 'Image Set Config',
    plural: 'Image Set Configs',
    path: 'image-set-configs',
    service: serviceLocator.imageSetConfigService,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['createdDate', 'desc']
    },
    Model
  }
  formController(serviceLocator)
  listController(Object.assign({}, controllerOptions, { ListView }))
  createRevisionController(
    serviceLocator,
    serviceLocator.imageSetConfigService,
    'image-set-configs',
    'image-set-configs/:id/revisions',
    'image-set-configs',
    (data) => (`${data.make} ${data.model} - Image Set Config`)
  )
  cb(null)
}

module.exports = initAdmin
