const CrudService = require('../../../../admin/source/js/lib/crud-service')

class FeaturesService extends CrudService {
  get name() {
    return 'FeaturesService'
  }
  get urlRoot() {
    return '/features'
  }

  createFromVehicle(id, name, fields, cb) {
    this.authedRequest(
      'POST',
      `${this.urlRoot}/create-from-vehicle/${id}`,
      { name, fields },
      (err, res, body) => {
        if (err) return cb(err)
        if (res.statusCode >= 300)
          return this.handleError(res.statusCode, body, cb)
        cb(null, body)
      }
    )
  }
}

module.exports = FeaturesService
