const FaqService = require('./services/faq')
const ListView = require('./views/list')
const Model = require('./models/faq')
const formController = require('./controllers/form')
const baseListController = require('../base/controllers/list')

const initAdmin = () => ({ faqAdmin: init })

const init = (serviceLocator, done) => {
  serviceLocator.faqService = new FaqService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'faq',
    plural: 'faqs',
    path: 'faqs',
    service: serviceLocator.faqService,
    Model
  }
  formController(serviceLocator)
  baseListController(Object.assign({}, controllerOptions, { ListView }))

  done()
}

module.exports = initAdmin
