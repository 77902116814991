import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ newsletterSignUpWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'Newsletter Signup',
    description: 'Display newsletter signup form'
  }

  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('newsletterSignUp', widget)
  )

  done()
}

module.exports = initWidget
