const ListView = require('../views/list')
const Collection = require('chale')
const ArticleModel = require('../models/article')
const Model = require('merstone')
const async = require('async')
const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['dateCreated', 'desc']
  }
  let currentPage = 1

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.articleService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getArticles(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  // Whenever an article is updated, reset the model with its new attributes
  serviceLocator.articleService.on('update', (id, attrs) => {
    const model = collection.get(id)
    if (model) model.reset(attrs)
  })

  serviceLocator.router.route('articles(/)', 'listArticles', () => {
    if (!serviceLocator.allow('article', 'discover')) return false

    // Pre-populate the list's collection with a set of articles
    const pagination = { page: currentPage, pageSize: pageSize }
    serviceLocator.accountService.read(
      serviceLocator.session.account,
      (err, account) => {
        if (err) return console.error(err)
        const list = new ListView(
          serviceLocator,
          collection,
          paginationModel,
          account
        ).render()

        getArticles(
          currentParams.keywords,
          currentParams.filter,
          currentParams.sort,
          pagination
        )

        list.displayFilterParams(currentParams)

        list.on('createNew', () => {
          if (!serviceLocator.allow('article', 'create')) return false
          serviceLocator.router.navigate('articles/form', { trigger: true })
        })

        list.on('edit', id => {
          if (!serviceLocator.allow('article', 'update')) return false
          serviceLocator.router.navigate('articles/' + id + '/form', {
            trigger: true
          })
        })

        list.on('preview', id => {
          const domainUrl = serviceLocator.accountService.createUrl(account)
          const previewPath = '/article#id=' + id
          const previewUrl = domainUrl + previewPath
          window.open(previewUrl, '_preview')
        })

        list.on('duplicate', model => {
          if (!serviceLocator.allow('article', 'duplicate')) return false
          serviceLocator.router.navigate(
            'articles/' + model.id + '/duplicate',
            { trigger: true }
          )
        })

        list.on('delete', ids => {
          if (!serviceLocator.allow('article', 'delete')) return false

          const deleteOne = (id, cb) => {
            serviceLocator.articleService.delete(id, err => {
              if (err) return cb(err)
              collection.remove(id)
              cb()
            })
          }
          async.each(ids, deleteOne, err => {
            if (err) return alert(err.message)
            paginationModel.set(
              'totalItems',
              paginationModel.get('totalItems') - ids.length
            )
            paginationModel.set('showing', collection.models.length)
          })
        })

        list.on('filter', params => {
          currentParams = params
          const pagination = { page: currentPage, pageSize: pageSize }
          currentPage = 1
          getArticles(params.keywords, params.filter, params.sort, pagination)
        })

        list.on('loadMore', () => {
          currentPage += 1
          const pagination = { page: currentPage, pageSize: pageSize }
          appendArticles(
            currentParams.keywords,
            currentParams.filter,
            currentParams.sort,
            pagination
          )
        })

        list.on('showRevisions', model => {
          serviceLocator.router.navigate(
            'articles/' + model.id + '/revisions',
            { trigger: true }
          )
        })
        serviceLocator.router.render(list, 'Articles')
      }
    )
  })

  const getArticles = (keywords, filter, sort, pagination) => {
    serviceLocator.articleService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load articles')
        collection.reset(
          res.results.map(article => new ArticleModel(serviceLocator, article))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  const appendArticles = (keywords, filter, sort, pagination) => {
    serviceLocator.articleService.find(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        res.results.forEach(article =>
          collection.add(new ArticleModel(serviceLocator, article))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}

module.exports = createController
