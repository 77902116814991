const View = require('ventnor')
const join = require('path').join
const compileJade = require('browjadify-compile')
const container = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (field, type) {
buf.push("<div" + (jade.attr("id", 'field--' + field, true, false)) + (jade.attr("data-field", field, true, false)) + " class=\"form-row form-row-full-width\"><div class=\"js-asset-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div></div><input type=\"file\" name=\"file\" style=\"display: none;\" class=\"js-file-input\"/><div class=\"form-row form-row-actions\"><a class=\"btn btn--success js-add\">Add " + (jade.escape((jade_interp = type) == null ? '' : jade_interp)) + "</a></div>");}.call(this,"field" in locals_for_with?locals_for_with.field:typeof field!=="undefined"?field:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return buf.join("");
}
const item = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div" + (jade.attr("data-id", data.filename, true, false)) + " class=\"widget-wide__grid__item\"><div class=\"widget\"><div class=\"expander js-expander expander-expanded\"><div class=\"expander-header list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"control-group\"><div class=\"btn-group\"><button" + (jade.attr("data-id", data.filename, true, false)) + " class=\"text-btn label label--large js-remove\"><i class=\"icon icon--cross\"></i></button></div></div></div></div><div class=\"list-item-content expander-content\"><div class=\"image-select-preview js-image-select-preview\"><a class=\"js-edit\"><video" + (jade.attr("src", data.url, true, false)) + " controls=\"controls\"></video></a></div><div class=\"image-select-controls js-image-select-controls form-row js-contexts\"><a" + (jade.attr("href", data.url, true, false)) + " target=\"_blank\"" + (jade.attr("data-filename", data.filename, true, false)) + " class=\"js-link\">Download</a></div></div></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

class AssetView extends View {
  constructor(serviceLocator, model, field, type) {
    super(serviceLocator)
    this.model = model
    this.field = field
    this.type = type

    this.$el.on('click', '.js-add', this.chooseFile.bind(this))
    this.$el.on('click', '.js-link', this.clickLink.bind(this))
    this.$el.on('click', '.js-remove', this.removeFile.bind(this))
    this.$el.on('change', '.js-file-input', this.upload.bind(this))
  }

  chooseFile() {
    this.$el.find('.js-file-input').trigger('click')
  }

  clickLink(e) {
    e.preventDefault()
    const file = e.currentTarget.dataset.filename

    const url = this.serviceLocator.dynavidAssetService.getUrl(
      this.model.get('_id'),
      file
    )

    window.open(url, '_blank')
  }

  removeFile(e) {
    const id = e.currentTarget.dataset.id

    const files = this.model.get(this.field)
    const filtered = files.filter(file => file.filename !== id)
    this.model.set(this.field, filtered)
    this.renderItems()
  }

  upload() {
    this.$el.find('.js-error').text('Uploading...')

    const file = this.$el.find('.js-file-input')
    if (!file[0].files[0]) {
      return alert('Please select a file')
    }

    const formData = new FormData()

    formData.append('file', file[0].files[0])

    this.serviceLocator.dynavidAssetService
      .upload(this.type, this.model.get('_id'), formData)
      .then(data => {
        const items = this.model.get(this.field) || []

        items.push(data)

        this.model.set(this.field, items)
        this.$el.find('.js-error').text('')
        this.renderItems()
      })
      .catch(error => {
        this.$el.find('.js-error').text(error.message)
      })
  }

  renderItems() {
    const selector = `#field--${this.field} .js-asset-area .widget-beancan-area`
    this.$el.find(selector).empty()
    const assets = this.model.get(this.field) || []
    assets.map(asset => {
      const url = this.serviceLocator.dynavidAssetService.getUrl(
        this.model.get('_id'),
        asset.filename
      )
      this.$el.find(selector).append(
        item({
          data: Object.assign({}, asset, { url: url })
        })
      )
    })

    this.$el
      .find(selector)
      .sortable({
        handle: '.js-sort-handle',
        placeholder:
          'js-widget widget-wide__grid__item ui-sortable-placeholder-custom',
        forcePlaceholderSize: 'true',
        cursor: 'move',
        cursorAt: { left: 30, top: 30 },
        tolerance: 'pointer'
      })
      .off('sortupdate')
      .on('sortupdate', () => this.handleSort(selector))
  }

  handleSort(selector) {
    const files = this.model.get(this.field) || []

    var order = []
    this.$el.find(selector + ' .widget-wide__grid__item').map((index, el) => {
      const item = files.find(
        file => file.filename === this.$el.find(el).data('id')
      )
      order.push(item)
    })

    this.model.set(this.field, order)
  }

  render() {
    this.$el.append(
      container({
        field: this.field,
        type: this.type
      })
    )

    this.renderItems()

    return this
  }
}

module.exports = AssetView
