const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (person, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('firstName', 'First Name', '', person.firstName, true, 'text');
jade_mixins["inputField"]('lastName', 'Last Name', '', person.lastName, true, 'text');
jade_mixins["inputField"]('phone', 'Primary Phone Number', '', person.phone, false, 'text');
jade_mixins["inputField"]('email', 'Email Address', '', person.email, false, 'text', true);
buf.push("<div class=\"form-row js-dealership-select\"><label><span class=\"form-label-text\">Dealerships</span><div class=\"form-field js-dealership\"></div></label></div><div class=\"form-row\"><label><span class=\"form-label-text\">Tags</span><div class=\"form-field js-tags\"></div></label></div></div></div><div id=\"section-trustpilot\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>TrustPilot Links</h2></div><div class=\"panel-content\"><div class=\"js-trustpilot-links\"></div></div></div></form></div></div>");}.call(this,"person" in locals_for_with?locals_for_with.person:typeof person!=="undefined"?person:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const trustpilotItem = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (dealership, link) {
buf.push("<div class=\"list-item\"><div class=\"list-item-header\"><h2>" + (jade.escape(null == (jade_interp = dealership || 'No Location') ? "" : jade_interp)) + "</h2></div><div class=\"list-item-content\"><dl><dt>Link:</dt><dd><a" + (jade.attr("href", link, true, false)) + ">" + (jade.escape(null == (jade_interp = link) ? "" : jade_interp)) + "</a></dd></dl></div></div>");}.call(this,"dealership" in locals_for_with?locals_for_with.dealership:typeof dealership!=="undefined"?dealership:undefined,"link" in locals_for_with?locals_for_with.link:typeof link!=="undefined"?link:undefined));;return buf.join("");
}
const BaseSelect = require('../../base/views/select')
const async = require('async')
const TagSelect = require('../../tag/views/tag-select')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.on('afterAppend', () =>
      this.$el.find('input[type=text]:first').focus()
    )
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Customer' : 'Edit Customer',
        person: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))
    this.renderDealershipSelect()
    this.renderTrustpilot()
    this.setupTagControl()
    return this
  }

  renderTrustpilot() {
    const elem = this.$el.find('.js-trustpilot-links').empty()
    async.eachSeries(this.model.get('reviewLinks'), (item, cb) => {
      this.serviceLocator.dealershipService.read(
        item.location,
        (err, response) => {
          if (err) return cb(err)

          elem.append(
            trustpilotItem(
              Object.assign(item, { dealership: response.location })
            )
          )
          cb()
        }
      )
    })
  }

  setupTagControl() {
    const readOnly = !this.serviceLocator.allowed('tag', 'create')
    const tagControl = new TagSelect(
      this.serviceLocator,
      this.model.get('tags'),
      'Customer',
      readOnly
    )
    tagControl.loadTags()

    tagControl.on('change', tags => {
      this.model.set('tags', tags)
    })

    this.$el.find('.js-tags').append(tagControl.render().$el)

    this.attachView(tagControl)
  }

  renderDealershipSelect() {
    const options = {
      service: 'dealershipService',
      limitToOneValue: false,
      displayAllOption: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName',
      selected: this.model.get('locations')
    }
    const dealershipSelect = new BaseSelect(this.serviceLocator, options)

    this.$el
      .find('.js-dealership')
      .empty()
      .append(dealershipSelect.render().$el)

    dealershipSelect.on('change', locations =>
      this.model.set('locations', locations)
    )
    this.attachView(dealershipSelect)
  }
}

module.exports = FormView
