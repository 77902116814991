const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, offerTypes, undefined) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"restrict\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Restrict Offers</h2></div><div class=\"panel-content\"><div id=\"field--heading\" data-field=\"heading\" class=\"form-row\"><label><span class=\"form-label-text\">Heading<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"heading\"" + (jade.attr("value", data.heading, true, false)) + " class=\"control--text form-field\"/><div class=\"js-error\"></div></label><div class=\"form-row-description form-copy\"><p>Heading for this tab</p></div></div><div id=\"field--type\" data-field=\"type\" class=\"form-row\"><label><span class=\"form-label-text\">Type </span><select name=\"type\" class=\"control control--choice form-field js-type-select\"><option>-- Select a Type --</option>");
// iterate offerTypes
;(function(){
  var $$obj = offerTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var type = $$obj[$index];

buf.push("<option" + (jade.attr("value", type, true, false)) + (jade.attr("selected", (type === data.type ? 'selected' : null), true, false)) + ">" + (jade.escape(null == (jade_interp = type) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var type = $$obj[$index];

buf.push("<option" + (jade.attr("value", type, true, false)) + (jade.attr("selected", (type === data.type ? 'selected' : null), true, false)) + ">" + (jade.escape(null == (jade_interp = type) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label><div class=\"js-error\"></div></div><div id=\"field--offerCategories\" data-field=\"offerCategories\" class=\"form-row\"><label><span class=\"form-label-text\">Offer Categories</span><div class=\"js-categories-select form-field\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>An optional Category to display Offers from.</p></div></div><div id=\"field--usedVehicle\" data-field=\"usedVehicle\" class=\"form-row\"><label><span class=\"form-label-text\">Used Vehicle </span><div class=\"form-field js-used-vehicle\"></div></label><div class=\"js-error\"></div></div><div id=\"field--make\" data-field=\"make\" class=\"form-row\"><label><span class=\"form-label-text\">Make </span><div class=\"form-field js-make\"></div></label><div class=\"js-error\"></div></div><div id=\"field--model\" data-field=\"model\" class=\"form-row\"><label><span class=\"form-label-text\">Model </span><div class=\"form-field js-model\"></div></label><div class=\"js-error\"></div></div><div id=\"field--locations\" data-field=\"locations\" class=\"form-row\"><label><span class=\"form-label-text\">Locations</span><div class=\"js-locations-select form-field\"></div></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>An optional location to display Offers from.</p></div></div></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"offerTypes" in locals_for_with?locals_for_with.offerTypes:typeof offerTypes!=="undefined"?offerTypes:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const BaseSelect = require('../../../../base/views/select')
const offerTypes = require('../../../../../service/offer/offer-types')()

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(sl, ...args) {
    super(sl, ...args)
    this.serviceLocator = sl
    this.$el.on('change', '.js-type-select', e =>
      this.handleTypeChange(e.target.value)
    )
    this.template = template
  }

  render() {
    this.$el.append(this.template({ data: this.model.toJSON(), offerTypes }))

    this.renderOfferCategorySelect()
    this.renderLocationSelect()
    this.renderMakeSelect()
    this.renderModelSelect()
    this.renderUsedVehicleSelect()
    this.handleTypeChange(this.model.get('type'))
    return this
  }

  handleSave() {
    const type = this.model.get('type')

    this.model.set('makes', this.vehicleMakeSelectView.selected)
    this.model.set('models', this.vehicleModelSelectView.selected)
    this.model.set('selectedLocations', this.locationSelectView.selected)
    this.model.set('offerCategories', this.offerCategorySelectView.selected)
    this.model.set('usedVehicles', this.usedVehicleSelectView.selected)

    if (type === 'Used') {
      this.model.set('models', [])
    } else {
      this.model.set('usedVehicles', [])
    }

    super.handleSave()
  }

  handleTypeChange(type) {
    if (type === 'Used') {
      this.$el.find('#field--usedVehicle').show()
      this.$el.find('#field--model').hide()
    } else {
      this.$el.find('#field--usedVehicle').hide()
      this.$el.find('#field--model').show()
    }
  }

  renderMakeSelect() {
    this.vehicleMakeSelectView = new BaseSelect(this.serviceLocator, {
      service: 'makeService',
      selected: this.model.get('makes'),
      limitToOneValue: false,
      singular: 'make',
      plural: 'makes',
      textProperty: 'name',
      sortProperty: 'name'
    })

    this.$el.find('.js-make').append(this.vehicleMakeSelectView.render().$el)
    this.vehicleMakeSelectView.on('change', makes => {
      this.model.set('makes', makes)

      const filter = !makes.length ? {} : { make: { $in: makes } }
      this.vehicleModelSelectView.setFilter(filter)
    })
    this.attachView(this.vehicleMakeSelectView)
  }

  renderModelSelect() {
    const makes = this.model.get('makes')

    this.vehicleModelSelectView = new BaseSelect(this.serviceLocator, {
      service: 'modelService',
      selected: this.model.get('models'),
      limitToOneValue: false,
      singular: 'model',
      plural: 'models',
      textProperty: 'name',
      sortProperty: 'name',
      filter: makes.length ? { make: { $in: makes } } : {}
    })

    this.$el.find('.js-model').append(this.vehicleModelSelectView.render().$el)
    this.vehicleModelSelectView.on('change', model => {
      this.model.set('models', model)
    })
    this.attachView(this.vehicleModelSelectView)
  }

  renderLocationSelect() {
    this.locationSelectView = new BaseSelect(this.serviceLocator, {
      service: 'dealershipService',
      selected: this.model.get('selectedLocations'),
      limitToOneValue: false,
      singular: 'Location',
      plural: 'Locations',
      textProperty: 'location',
      sortProperty: 'location'
    })
    this.locationSelectView
      .render()
      .$el.appendTo(this.$el.find('.js-locations-select'))

    this.locationSelectView.on('change', locations => {
      this.model.set('selectedLocations', locations)
    })
  }

  renderOfferCategorySelect() {
    this.offerCategorySelectView = new BaseSelect(this.serviceLocator, {
      service: 'offerCategoryService',
      selected: this.model.get('offerCategories'),
      limitToOneValue: false,
      singular: 'Offer Category',
      plural: 'Offer Categories',
      textProperty: 'name',
      sortProperty: 'name'
    })
    this.offerCategorySelectView
      .render()
      .$el.appendTo(this.$el.find('.js-categories-select'))

    this.offerCategorySelectView.on('change', categories => {
      this.model.set('offerCategories', categories)
    })
  }

  renderUsedVehicleSelect() {
    const format = vehicle => {
      if (!vehicle) return ''
      const { brand, model, registration } = vehicle
      return `${brand} ${model} - ${registration}`
    }
    this.usedVehicleSelectView = new BaseSelect(this.serviceLocator, {
      service: 'usedVehicleService',
      selected: this.model.get('usedVehicles'),
      limitToOneValue: false,
      singular: 'used vehicle',
      plural: 'used vehicles',
      textProperty: format,
      sortProperty: 'registration'
    })

    this.usedVehicleSelectView
      .render()
      .$el.appendTo(this.$el.find('.js-used-vehicle'))

    this.usedVehicleSelectView.on('change', usedVehicle => {
      this.model.set('usedVehicles', usedVehicle)
    })
  }
}

module.exports = ItemRepeaterFormView
