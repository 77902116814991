import editView from './views/form'
import model from './models/model'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({
  primaryActionsWidget: ['sectionAdmin', init]
})

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'Primary Actions',
    description: ''
  }

  ;['section'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('primaryActions', widget)
  )

  done()
}

module.exports = initWidget
