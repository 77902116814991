import model from './models/model'
import editView from './views/form'
import itemView from '../../widget/views/item/edit-base'

const initWidget = () => ({ reviewsCarouselWidget: ['sectionAdmin', init] })

const init = (serviceLocator, done) => {
  const widget = {
    editView,
    model,
    itemView,
    name: 'Reviews Carousel',
    description: 'Display a Carousel of all Trustist reviews'
  }

  ;['section', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('reviewsCarousel', widget)
  )

  done()
}

module.exports = initWidget
