const required = require('validity-required')
const schemata = require('schemata')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const createUniqueValidator = require('validity-unique-property')

const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

module.exports = ({ find }) => {
  const uniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: ['type', 'parent', 'finish'] }),
    'This colour already exists'
  )

  const schema = schemata({
    name: 'Colour',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      name: {
        type: String,
        validators: [required, uniqueValidator]
      },
      type: {
        type: String
      },
      finish: {
        type: String
      },
      parent: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'manufacturer', required)]
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
