const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (autoTraderHistory, format, history, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Availability History - " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\"><table class=\"table--striped js-items\"><tr> <th>Date</th><th>Previous Availability</th><th>Current Availability</th><th>Author</th><th>Reason</th></tr>");
// iterate history
;(function(){
  var $$obj = history;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.wasAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.isAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.author) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.reason) ? "" : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.wasAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.isAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.author) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.reason) ? "" : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div><h2>AutoTrader History</h2><div class=\"panel panel-styled\"><div class=\"panel-content\"><table class=\"table--striped js-items\"><tr> <th>Date</th><th>Previous Availability</th><th>Current Availability</th><th>Author</th><th>Reason</th></tr>");
// iterate autoTraderHistory
;(function(){
  var $$obj = autoTraderHistory;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.wasAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.isAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.author) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.reason) ? "" : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.wasAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.isAvailable === true ? 'Yes' : 'No') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.author) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.reason) ? "" : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div></div></div>");}.call(this,"autoTraderHistory" in locals_for_with?locals_for_with.autoTraderHistory:typeof autoTraderHistory!=="undefined"?autoTraderHistory:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"history" in locals_for_with?locals_for_with.history:typeof history!=="undefined"?history:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class PricingHistory extends View {
  constructor(serviceLocator, model, history) {
    super(...arguments)

    this.model = model
    this.history = history

    this.trigger = this.emit.bind(this)
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
  }

  render() {
    const title = `${this.model.brand} ${this.model.model} - ${
      this.model.registration
    }`

    const history = this.history.filter(history => !history.autotraderChange)
    const autoTraderHistory = this.history.filter(
      history => !!history.autotraderChange
    )

    this.$el.empty().append(
      template({
        history,
        autoTraderHistory,
        format: this.serviceLocator.format,
        title
      })
    )
    return this
  }
}

module.exports = PricingHistory
