const View = require('../views/view')
const debug = require('../../../../admin/source/js/lib/debug')('index router')

module.exports = function(serviceLocator) {
  serviceLocator.hub.once('acl:load', function() {
    const view = new View({ serviceLocator: serviceLocator })
    const router = serviceLocator.router

    router.route('(/)', 'index', function() {
      debug('admin index route triggered')
      const aclRoles = window.localStorage.aclRoles
      if (
        (aclRoles && aclRoles.includes('Stock Data Admin')) ||
        aclRoles.includes('Stock Data Manager')
      ) {
        router.navigate('/vehicles', { trigger: true })
      } else {
        router.render(view)
      }
    })

    view.on('route', function(route) {
      serviceLocator.router.navigate(route, { trigger: true })
    })
  })
}
