const sectionSchema = require('./schema')
const schemata = require('schemata')
const pick = require('lodash.pick')

const properties = pick(
  sectionSchema().getProperties(),
  '_id',
  'name',
  'slug',
  'className',
  'displayInNav',
  'displayInSecondaryNav',
  'visible',
  'liveDate',
  'expiryDate',
  'parent',
  'fullUrlPath',
  'root'
)

module.exports = () => {
  return schemata({
    name: 'Lite Section',
    properties
  })
}
