const CrudService = require('../../../../admin/source/js/lib/crud-service')

class DealershipService extends CrudService {
  get name() {
    return 'DealershipService'
  }
  get urlRoot() {
    return '/dealerships'
  }
}

module.exports = DealershipService
