const BaseListItemView = require('../../base/views/list-item')
const compileJade = require('browjadify-compile')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data, format) {
jade_mixins["stateMap"] = jade_interp = function(schedule, visibility){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// iterate [schedule, visibility]
;(function(){
  var $$obj = [schedule, visibility];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var indicator = $$obj[$index];

if ( (indicator))
{
buf.push("<span data-toggle=\"tooltip\"" + (jade.attr("title", '<strong>' + (indicator.title) + '</strong><br>' + (indicator.description) + '', true, false)) + (jade.cls(['label','label--large','js-tooltip-trigger',indicator.labelClass], [null,null,null,true])) + "><i" + (jade.cls(['icon',indicator.iconClass], [null,true])) + "></i></span>");
}
    }

  }
}).call(this);

};
buf.push("<div class=\"list-item list-item-detail\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.previewImageUrlSmall + '");', true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\">");
jade_mixins["stateMap"](null, data.visibility);
if ( allowed('model', 'delete'))
{
}
if ( data.isPopular)
{
buf.push("<span class=\"label label--large label--notice\">Popular</span>");
}
buf.push("<label class=\"list-item-select\"><input type=\"checkbox\" name=\"name\" class=\"js-select\"/></label><div class=\"btn-group\"><a data-toggle=\"dropdown\" href=\"#\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\"><li><a class=\"js-duplicate\">Duplicate</a></li><li><a class=\"js-show-revisions\">Show Revisions</a></li></ul></div></div>");
var title = `${data.__make} ${data.name}`
if ( allowed('model', 'update'))
{
buf.push("<h2><a" + (jade.attr("href", '/models/' + (data._id) + '/form', true, false)) + " class=\"js-edit\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</a></h2>");
}
else
{
buf.push("<h2>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h2>");
}
buf.push("</div><div class=\"list-item-content\"><dl><dt>Slug:<dd><a" + (jade.attr("href", data.__fullUrl, true, false)) + " target=\"_blank\">" + (jade.escape((jade_interp = data.__urlPath) == null ? '' : jade_interp)) + "</a></dd></dt>");
if ( (data.bodyType))
{
buf.push("<dt>Body Type:</dt><dd>" + (jade.escape(null == (jade_interp = data.bodyType) ? "" : jade_interp)) + "</dd>");
}
buf.push("<dt>Created:</dt><dd>" + (jade.escape(null == (jade_interp = format(data.createdDate, 'calendar')) ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined));;return buf.join("");
}
const config = window.config
const stateMap = require('../../article/state-map')
const getImageUrl = require('../../lib/default-image-url-generator')

class ListItemView extends BaseListItemView {
  constructor(...args) {
    super(...args)
    this.$el.on(
      'click',
      '.js-show-revisions',
      this.emit.bind(this, 'showRevisions')
    )
    this.$el.on('click', '.js-duplicate', this.emit.bind(this, 'duplicate'))
  }

  serialize() {
    const data = this.model.toJSON()
    data.previewImageUrlSmall = getImageUrl({
      model: this.model,
      property: 'thumbnailImages',
      width: 80,
      height: 80,
      mode: 'pad'
    })

    data.__fullUrl = config.url + data.__urlPath
    const getVisibilityState = stateMap.visibility.get(
      this.model.getVisibilityState()
    )
    data.visibility = getVisibilityState()

    return data
  }

  render() {
    const data = this.serialize()

    this.$el.empty().append(
      template({
        data,
        allowed: this.serviceLocator.allowed,
        format: this.serviceLocator.format
      })
    )

    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }

  get template() {
    return template
  }
}

module.exports = ListItemView
