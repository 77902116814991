const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/author')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const AuthorService = require('./services/author')

const initAdmin = () => ({ authorAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.authorService = new AuthorService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'author',
    plural: 'authors',
    path: 'authors',
    service: serviceLocator.authorService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
