const schemata = require('schemata')
const required = require('validity-required')
const tagSchema = require('../tag/schema')

module.exports = () => {
  const schema = schemata({
    name: 'Faq',
    properties: {
      _id: { type: String },
      type: {
        type: String,
        validators: { all: [required] }
      },
      dateCreated: {
        type: Date,
        defaultValue: function() {
          return new Date()
        }
      },
      title: {
        type: String,
        validators: { all: [required] }
      },
      body: {
        type: String,
        validators: { draft: [], published: [required] }
      },
      tags: {
        type: schemata.Array(tagSchema()),
        resolve: faq => faq.tags && faq.tags.map(tag => tag.tag),
        resolveType: Array
      },
      state: {
        type: String,
        options: ['Draft', 'Published'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      account: {
        type: String,
        validators: {
          all: [required]
        }
      }
    }
  })

  return schema
}
