const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/dealership')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const DealershipService = require('./services/dealership')
const DealershipPushService = require('./services/dealership-push')
const createRevisionController = require('../revision/controllers/controller')
const createWidgetFactory = require('../widget/lib/base-factory')

function initAdmin() {
  return { dealershipAdmin: ['widget', init] }
}

function init(serviceLocator, cb) {
  serviceLocator.dealershipService = new DealershipService(serviceLocator)

  serviceLocator.widgetFactories.register(
    'dealershipBody',
    createWidgetFactory()
  )

  serviceLocator.dealershipPushService = new DealershipPushService(
    serviceLocator
  )

  const controllerOptions = {
    serviceLocator,
    singular: 'dealership',
    plural: 'dealerships',
    path: 'dealerships',
    service: serviceLocator.dealershipService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  createRevisionController(
    serviceLocator,
    serviceLocator.dealershipService,
    'dealership',
    'dealerships/:id/revisions',
    'dealerships',
    'location'
  )
  cb(null)
}

module.exports = initAdmin
