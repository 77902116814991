const mapFormToObject = require('cf-map-form-to-object')
const formErrorsDelegate = require('../../../../admin/source/js/lib/form-errors-delegate')()
const BaseWidgetView = require('../../widget/views/form/base')
const createMultiImageViewFunctions = require('../../lib/multi-image-view')
const dateTimePickers = require('../../../../admin/source/js/lib/init-date-time-pickers')
module.exports = (name, template) =>
  BaseWidgetView.extend({
    ...createMultiImageViewFunctions(),
    events: Object.assign({}, BaseWidgetView.prototype.events, {
      'click .js-cancel-item': 'handleCancel',
      'click .js-save-item': 'handleSave',
      'click .js-images-add': 'handleAddImages'
    }),

    initialize: function(model, serviceLocator) {
      this.serviceLocator = serviceLocator
      this.model = model
      this.render()
      this.dateTimePickers = []
      this.on('remove', () => {
        this.dateTimePickers.forEach(picker => picker.destroy())
      })
      this.initDateTimePickers = this.initDateTimePickers.bind(this)
      this.renderImageWidgetArea('images')
    },

    initDateTimePickers(el) {
      return dateTimePickers(
        window.config.locale.longDateFormat.LLLL,
        this.model
      )(el)
    },

    handleCancel: function(e) {
      e.preventDefault()
      this.trigger('cancel')
    },

    handleSave: function(e) {
      e.preventDefault()
      const formData = mapFormToObject(
        this.$el.find('form'),
        this.model.schemata
      )

      this.showErrors = formErrorsDelegate.showErrors
      this.clearErrors = formErrorsDelegate.clearErrors

      this.model.validate(formData, errors => {
        this.model.validationError = errors
        if (this.model.validationError) {
          this.showErrors(this.model.validationError)
        } else {
          this.model.set(formData)
          this.trigger('save', this.model)
        }
      })
    },

    render: function() {
      this.$el.empty().append(
        template({
          data: this.model ? this.model.toJSON() : {}
        })
      )
      this.dateTimePickers = this.$el
        .find('.js-date-time-picker')
        .map((i, el) => this.initDateTimePickers(el))
    }
  })
