const Model = require('../models/article')
const FormView = require('../views/form')
const createPublishFormViewController = require('../../base/controllers/publish-form-view-controller')
const async = require('async')

const createController = serviceLocator => {
  const initializeArticleFormView = createPublishFormViewController(
    serviceLocator,
    'articleService',
    'Article',
    'articles',
    FormView
  )

  // Create
  serviceLocator.router.route('articles/form', 'createArticle', () => {
    if (!serviceLocator.allow('article', 'create')) return false

    const accountId = serviceLocator.session.account
    const model = new Model(serviceLocator)

    if (!accountId)
      return alert(
        'Session must be associated with an account id to create/edit articles'
      )

    model.makeDefault((err, articleModel, account) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      initializeArticleFormView(model, account, true)
    })
  })

  // Duplicate
  serviceLocator.router.route(
    'articles/:id/duplicate',
    'duplicateArticle',
    id => {
      if (!serviceLocator.allow('article', 'update')) return false

      const accountId = serviceLocator.session.account
      if (!accountId)
        return alert(
          'Session must be associated with an account id to create/edit articles'
        )

      async.parallel(
        {
          article: serviceLocator.articleService.read.bind(
            serviceLocator.articleService,
            id
          ),
          account: serviceLocator.accountService.read.bind(
            serviceLocator.accountService,
            accountId
          )
        },
        (err, data) => {
          if (err) return serviceLocator.router.trigger('notFound', err.message)
          const article = new Model(serviceLocator)
          const articleData = data.article
          // Clear ID so it's new
          delete articleData._id
          // Clear slug as duplicate is not allowed
          delete articleData.slug
          // Clear previewId so a new one is generated
          delete articleData.previewId
          // Delete dateCreated so ordering is logical
          delete articleData.dateCreated
          article.set(article.schemata.makeDefault(articleData))
          initializeArticleFormView(article, data.account, true)
        }
      )
    }
  )

  // Edit
  serviceLocator.router.route('articles/:id/form', 'editArticle', id => {
    if (!serviceLocator.allow('article', 'update')) return false

    const accountId = serviceLocator.session.account
    if (!accountId)
      return alert(
        'Session must be associated with an account id to create/edit articles'
      )

    async.parallel(
      {
        article: serviceLocator.articleService.read.bind(
          serviceLocator.articleService,
          id
        ),
        account: serviceLocator.accountService.read.bind(
          serviceLocator.accountService,
          accountId
        )
      },
      (err, data) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        const model = new Model(serviceLocator, data.article)
        initializeArticleFormView(model, data.account, false)
      }
    )
  })
}

module.exports = createController
