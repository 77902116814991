import View from 'ventnor'
import moment from 'moment'

class EventSelect extends View {
  constructor(serviceLocator, selected) {
    super(serviceLocator)
    this.$el = $('<select id="articleId" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose an Event')
    this.selected = selected || []
  }

  initializeSelectize() {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.articleService.find(
      '',
      {
        _id: this.selected,
        articleType: 'event'
      },
      [],
      pagination,
      (err, res) => {
        if (err) return alert('Cannot find existing event articles')
        res.results.forEach(event => {
          this.el.selectize.addOption({
            value: event._id,
            text: this.formatSelectText(event),
            date: event.dateCreated
          })
          this.el.selectize.addItem(event._id)
        })
        this.el.selectize.on('change', this.updateSelection.bind(this))
      }
    )
  }

  formatSelectText(event) {
    const eventStartDate = moment(event.eventStartDate).format('Do MMMM YYYY')
    const eventEndDate = moment(event.eventEndDate).format('Do MMMM YYYY')

    const name = event.headline
    let data = `${name} (${event.state})`

    if (event.eventStartDate && event.eventEndDate) {
      data += ` (${eventStartDate} - ${eventEndDate})`
    }

    return data
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  load(query, cb) {
    const pagination = { page: 1, pageSize: 500 }
    this.serviceLocator.articleService.find(
      query,
      { articleType: 'event' },
      [],
      pagination,
      (err, data) => {
        if (err) return alert('Cannot load existing event articles')
        cb(
          data.results.map(event => ({
            value: event._id,
            text: this.formatSelectText(event),
            date: event.dateCreated
          }))
        )
      }
    )
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        load: this.load.bind(this),
        sortField: [{ field: 'name', direction: 'desc' }],
        preload: true,
        selectOnTab: true
      })
    }, 0)
    return this
  }
}

module.exports = EventSelect
