const required = require('validity-required')
const schemata = require('schemata')
const validity = require('validity')
const isUrl = require('validity-url')
const validateIfSet = require('validity-validate-if-set')
const createSpecificationSchema = require('./specification-schema')
const createDimensionSchema = require('./dimension-schema')
const isNotEmpty = value => value && value.length > 0
const nonEmptyValidator = validity.createValidatorAllowingFailureMessageOverride(
  validity.booleanToCallback(isNotEmpty),
  'At least one body type is required'
)
const { promisify } = require('util')
import promiseMemoize from 'promise-memoize'
const resolveImages = require('../../../lib/image-resolver')

const labelValue = schemata({
  name: 'Label Value Pair',
  properties: {
    label: {
      type: String
    },
    value: {
      type: String
    }
  }
})

const createUniqueSlugValidator = serviceLocator => (key, model, cb) => {
  // Get models with the same slug OR parentModel as the current model (without getting current model back)
  const query = {
    $and: [
      { slug: model.slug },
      { make: model.make },
      { parentModel: model.parentModel }
    ]
  }
  serviceLocator.modelService.find(query, (error, duplicateSlugModels) => {
    if (error) return cb(error)
    const filtered = duplicateSlugModels.filter(
      foundModel => foundModel._id !== model._id
    )
    return cb(null, filtered.length === 0)
  })
}
module.exports = serviceLocator => {
  const findCategory = promiseMemoize(
    async () => {
      const category = await promisify(
        serviceLocator.offerCategoryService.find
      )({
        name: 'Motability'
      })
      return category
    },
    { maxAge: 1000 * 100 }
  )

  const findMotabilityOffer = promiseMemoize(
    async id => {
      const [category] = await findCategory()

      if (!category) return

      const [offer] = await promisify(serviceLocator.offerService.find)({
        offerCategory: category._id,
        model: id
      })

      return offer
    },
    { maxAge: 1000 * 100 }
  )

  return schemata({
    name: 'Model',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      make: { type: String, validators: [required] },
      name: { type: String, validators: [required] },
      category: { type: String, validators: [required] },
      commonName: { type: String, validators: [required] },
      slug: {
        type: String,
        validators: [
          required,
          validity.createValidatorAllowingFailureMessageOverride(
            createUniqueSlugValidator(serviceLocator),
            'Slug already in use'
          )
        ]
      },
      bodyTypes: { type: Array, validators: [nonEmptyValidator] },
      offers: { type: Array },
      description: { type: String },
      createdDate: { type: Date, defaultValue: () => new Date() },
      isPopular: { type: Boolean, defaultValue: false },
      interior: { type: schemata.Array(labelValue) },
      exterior: { type: schemata.Array(labelValue) },
      environment: { type: schemata.Array(labelValue) },
      technical: { type: schemata.Array(labelValue) },
      safety: { type: schemata.Array(labelValue) },
      brochureUrl: { type: String },
      marketingHeader: { type: String },
      price: { type: String },
      pricePerMonth: { type: String },
      apr: { type: String },
      capCode: { type: String },
      motabilityAvailable: {
        type: Boolean,
        resolveType: Boolean,
        resolve: async model => {
          const offer = await findMotabilityOffer(model._id)
          return !!offer
        }
      },
      motabilityPrice: {
        type: String,
        resolveType: String,
        resolve: async model => {
          const offer = await findMotabilityOffer(model._id)
          return offer && offer.motabilityPrice
        }
      },
      motabilityDownpayment: {
        type: String,
        resolveType: String,
        resolve: async model => {
          const offer = await findMotabilityOffer(model._id)
          return offer && offer.motabilityDownpayment
        }
      },
      motabilityOfferUrl: {
        type: String,
        resolveType: String,
        resolve: async model => {
          const offer = await findMotabilityOffer(model._id)
          if (!offer) return ''

          let url = ''
          if (offer.slug) {
            url = offer.slug
          } else if (
            offer.callToActionLink.startsWith('/') ||
            offer.callToActionLink.startsWith('http')
          ) {
            url = offer.callToActionLink
          } else {
            url = '/' + offer.callToActionLink
          }

          return url
        }
      },
      thumbnailImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.thumbnailImages),
        resolveType: Array
      },
      backgroundImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.backgroundImages),
        resolveType: Array
      },
      lifestyleImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.lifestyleImages),
        resolveType: Array
      },
      heroImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.heroImages),
        resolveType: Array
      },
      signpostImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.signpostImages),
        resolveType: Array
      },
      calloutImages: {
        type: Object,
        defaultValue: () => [],
        resolve: model => resolveImages(model.calloutImages),
        resolveType: Array
      },
      calloutApplyToSubModels: {
        type: Boolean,
        defaultValue: () => false
      },
      calloutLink: {
        type: String,
        validators: [validateIfSet(isUrl)]
      },
      specifications: {
        type: schemata.Array(createSpecificationSchema()),
        defaultValue: () => []
      },
      dimensions: {
        type: schemata.Array(createDimensionSchema()),
        defaultValue: () => []
      },
      parentModel: { type: String },
      importHash: { type: String },
      state: {
        type: String,
        options: ['Draft', 'Published'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      order: { type: Number }
    }
  })
}
