const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"in-progress-header\"><h4>In progress</h4><div class=\"js-clear clear\">Clear all</div></div><div class=\"js-items\"></div>");;return buf.join("");
}
const InProgressItemView = require('./in-progress-item')
const BaseView = require('cf-base-view')
const debug = require('../../../../admin/source/js/lib/debug')(
  'asset in progress view'
)
const bindAll = require('lodash.bindall')

module.exports = BaseView.extend({
  className: 'asset-in-progress',

  events: { 'click .js-clear': 'handleClear' },

  initialize() {
    bindAll(this)
    this.listenTo(this.collection, 'add', this.onAdd)
    this.listenTo(this.collection, 'clear', this.onClear)
    this.render()
  },

  onAdd(model) {
    debug('Item added to in progress collection', model)
    var newItem = new InProgressItemView({ model: model })
    this.attachView(newItem)
    this.$('.js-items').append(newItem.$el)
    this.$el.show()
  },

  onClear(model) {
    this.collection.remove(model)
    if (this.collection.length === 0) this.$el.hide()
  },

  render() {
    this.$el.append(template())
    return this
  },

  handleClear() {
    this.collection
      .filter(model => {
        return model.isClearable
      })
      .forEach(model => {
        model.trigger('clear', model)
      })
  }
})
