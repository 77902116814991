const View = require('../views/autotrader')
const debug = require('../../../../admin/source/js/lib/debug')(
  'used-vehicle autotrader'
)

module.exports = serviceLocator => {
  const router = serviceLocator.router

  router.route('vehicles/:id/autotrader-data', 'viewAutoTraderData', id => {
    if (!serviceLocator.allow('usedVehicle', 'read')) return false
    debug('rendering autotrader view', id)
    serviceLocator.usedVehicleService.getAutoTraderData(id, (err, data) => {
      if (err) {
        return serviceLocator.router.trigger(
          'notFound',
          'This vehicle currently has no data on AutoTrader'
        )
      }
      const view = new View(serviceLocator, data)
      router.render(view)
    })
  })
}
