const schemata = require('schemata')

module.exports = () => {
  const customerWifiLoginsSchema = schemata({
    name: 'customerWifiLogin',
    properties: {
      firstName: { type: String },
      lastName: { type: String },
      email: { type: String },
      phone: { type: String },
      sendEmails: { type: Boolean },
      sendSMS: { type: Boolean },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return customerWifiLoginsSchema
}
