const CrudService = require('../../../../admin/source/js/lib/crud-service')

class careerService extends CrudService {
  get name() {
    return 'careerService'
  }
  get urlRoot() {
    return '/careers'
  }

  embellishCareersWithDealership(models, callback) {
    const dealershipIds = models.map(({ dealership }) => dealership)
    this.serviceLocator.dealershipService.find(
      '',
      { _id: { $in: dealershipIds } },
      [],
      { page: 1, pageSize: 500 },
      (error, dealerships) => {
        if (error) return callback(error)

        models.forEach(model => {
          const foundDealership = dealerships.results.find(
            dealership => dealership._id === model.dealership
          )
          model.__dealershipLocation = foundDealership
            ? foundDealership.location
            : null
        })

        return callback(null, models)
      }
    )
  }
}

module.exports = careerService
