const required = require('validity-required')
const schemata = require('schemata')
const hat = require('hat')

module.exports = () => {
  const schema = schemata({
    name: 'List Custom Item',
    properties: {
      _id: {
        type: String,
        defaultValue: () => hat()
      },
      shortHeadline: {
        type: String,
        validators: [required]
      },
      link: {
        type: String,
        // TODO: This needs a validator to ensure it is a valid URL or URL path.
        validators: [required]
      },
      type: {
        type: String
      },
      images: {
        type: Object,
        defaultValue: () => ({ widgets: [] })
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })

  return schema
}
