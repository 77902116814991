const categories = [
  { category: 'car', type: 'car' },
  { category: 'van', type: 'van' },
  { category: 'lifestyle', type: 'van' }
]

export const findCategory = category =>
  categories.find(f => f.category === category) || {}

export const isVan = category => findCategory(category).type === 'van'

export const isCar = category => findCategory(category).type === 'car'
