const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

const createController = serviceLocator => {
  serviceLocator.router.route('autotrader/download/:id', 'download', id => {
    if (!serviceLocator.allowed('autoTrader', 'read')) return false

    window.location = createSignedApiUrl(
      `/autotrader/download-file/${id}`,
      window.localStorage.apiKey,
      window.localStorage.apiId,
      window.config.apiUrl
    )

    serviceLocator.router.navigate('autotrader', { trigger: true })
  })
}

module.exports = createController
