const schemata = require('schemata')
const required = require('validity-required')
const metaSchema = require('./meta-schema')

module.exports = () =>
  schemata({
    name: 'Tag',
    properties: {
      _id: { type: String },
      tag: {
        type: String,
        validators: [required]
      },
      type: {
        type: String,
        validators: [required]
      },
      meta: {
        type: schemata.Array(metaSchema())
      },
      account: { type: String, validators: [required] },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      }
    }
  })
