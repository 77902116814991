const createImageUrlBuilder = require('cf-image-url-builder')
const config = window.config

module.exports = ({
  model,
  property = 'images',
  width = 150,
  height,
  mode
}) => {
  const data = model.get(property)
  const images = data && data.widgets ? data.widgets : data
  const urlBuilder = createImageUrlBuilder(
    config.darkroom.url,
    config.darkroom.salt,
    images
  )

  if (
    images &&
    images.length &&
    images[0] &&
    Object.keys(images[0]).length &&
    urlBuilder.getImage('All') &&
    urlBuilder.getImage('All').crop('Free')
  ) {
    try {
      return urlBuilder
        .getImage('All')
        .crop('Free')
        .constrain(width, height)
        .mode(mode)
        .url()
    } catch (e) {
      return '/assets/img/content/no-photo-medium.png'
    }
  } else {
    return '/assets/img/content/no-photo-medium.png'
  }
}
