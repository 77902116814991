const createAuthedFetch = require('../../../../admin/source/js/lib/authed-fetch')
const createSignedApiUrl = require('../../../../admin/source/js/lib/create-signed-api-url')

class DynavidAssetService {
  constructor(serviceLocator) {
    this.authedFetch = createAuthedFetch(serviceLocator.config.apiUrl).bind(
      this
    )
    this.serviceLocator = serviceLocator
  }

  get name() {
    return 'DynavidAssetService'
  }
  get urlRoot() {
    return '/dynavid-asset'
  }

  async upload(type, id, data) {
    let req
    try {
      req = await this.authedFetch(
        'POST',
        `${this.urlRoot}/upload-${type}/${id}`,
        data
      )

      const { ok, status } = req
      const res = await req.json()

      if (!ok) {
        return this.handleError(status, res)
      }

      return res
    } catch (err) {
      throw err
    }
  }

  getUrl(id, filename) {
    const url = createSignedApiUrl(
      `${this.urlRoot}/fetch-file/${id}/${encodeURIComponent(filename)}`,
      window.localStorage.apiKey,
      window.localStorage.apiId,
      this.serviceLocator.config.apiUrl
    )

    return url
  }

  getError(body) {
    if (body.errors) {
      var error = new Error('Validation Error')
      error.errors = body.errors
      return error
    }
    return new Error(body.error || body.status || body || 'Unknown Error')
  }

  handleError(statusCode, body) {
    if (statusCode === 401) {
      window.Sentry.addBreadcrumb({
        category: 'handleError',
        message: `Error in ${this.urlRoot}`,
        level: 'info'
      })
      window.Sentry.setContext('handleError.body', body)
      window.Sentry.captureException(new Error('handleError: Unauthorised'))

      return this.serviceLocator.router.trigger('noAccess', body)
    }
    throw this.getError(body)
  }
}

module.exports = DynavidAssetService
