const CrudService = require('../../../../admin/source/js/lib/crud-service')

class FaqService extends CrudService {
  get name() {
    return 'FaqService'
  }
  get urlRoot() {
    return '/api/faqs'
  }
}

module.exports = FaqService
