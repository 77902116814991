module.exports=[
  {
    "id": 3107,
    "range": "Ateca"
  },
  {
    "id": 3599,
    "range": "Born"
  },
  {
    "id": 3393,
    "range": "Formentor"
  },
  {
    "id": 3443,
    "range": "Leon"
  },
  {
    "id": 3444,
    "range": "Leon Estate"
  },
  {
    "id": 4171,
    "range": "Tavascan"
  }
]
