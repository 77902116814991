const Model = require('merstone')
const createSchema = require('../../../service/image-set/schema')

class ImageSetModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schemata = createSchema()
    this.attributes = this.schemata.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schemata.cast(attrs))
    })
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
}

module.exports = ImageSetModel
