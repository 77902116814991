const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')
const length = require('validity-length')

const enquiryTypes = [
  'General Enquiry',
  'Book a Test Drive',
  'Request a Video',
  'Book an Appointment'
]

const extraProperties = {
  title: {
    type: String
  },
  heading: {
    type: String
  },
  text: {
    type: String
  },
  registrationRequired: {
    type: Boolean
  },
  theme: {
    type: String
  },
  phoneNumberRequired: {
    type: Boolean
  },
  customerEmailType: {
    type: String
  },
  selectedLocations: {
    type: Array,
    validators: [required, length(1)]
  },
  locationLabel: {
    type: String,
    defaultValue: () => 'Location'
  },
  showPostcode: {
    type: Boolean
  },
  showRegistration: {
    type: Boolean
  },
  additionalInfoRequired: {
    type: Boolean
  },
  additionalInfoLabel: {
    type: String,
    defaultValue: () => 'My question',
    validators: [required]
  },
  internalEmailType: {
    type: String,
    defaultValue: () => 'contact',
    validators: [required]
  },
  enquiryType: {
    type: String,
    defaultValue: () => ''
  }
}

function create() {
  return createSchema('Contact Widget', extraProperties)
}

create.enquiryTypes = enquiryTypes

module.exports = create
