const listController = require('./controllers/list')
const formController = require('./controllers/form')
const ModelService = require('./services/model')
const createRevisionController = require('../revision/controllers/controller')

const initAdmin = () => ({ modelAdmin: ['makeAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.modelService = new ModelService(serviceLocator)
  listController(serviceLocator)
  formController(serviceLocator)
  createRevisionController(
    serviceLocator,
    serviceLocator.modelService,
    'model',
    'models/:id/revisions',
    'models',
    'name'
  )
  cb(null)
}

module.exports = initAdmin
