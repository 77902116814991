const modal = require('modal')

module.exports = submit =>
  function(e) {
    e.preventDefault()

    const isSold = this.model.get('isSold')

    modal({
      buttons: [
        { text: 'Cancel', event: 'cancel', className: 'btn' },
        { text: 'Confirm', event: 'confirm', className: 'btn btn--error' }
      ],
      content: `Are you sure you want to set this vehicle as ${
        isSold ? 'available' : 'sold'
      }?`
    }).on('confirm', () => {
      const nowSold = !isSold
      this.model.set('isSold', nowSold)
      this.model.set('expiredDate', nowSold ? new Date() : null)
      submit(e)
    })
  }
