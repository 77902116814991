const required = require('validity-required')
const schemata = require('schemata')
const createImagesSchema = require('./images-schema')

module.exports = ({ imageSetSave, imageSetConfigServiceRead } = {}) => {
  const validateReqiuredImagesWhenMarkingAsComplete = (
    key,
    msg,
    object,
    callback
  ) => {
    // Only apply validation when status is set to Complete
    if (object.status !== 'Complete') callback(null, undefined)
    if (!object.imageSetConfigId) callback(null, undefined)

    let message = undefined
    const missingImages = []

    imageSetConfigServiceRead(
      object.imageSetConfigId,
      (error, imageSetConfig) => {
        const uploadedImageIds = object.images.map(i => {
          return i.imageId
        })

        if (error || !imageSetConfig)
          return callback(
            null,
            'Unable to find related Image Set Config to validate against.'
          )
        imageSetConfig.setConfig.forEach(setConfig => {
          // If an image is required, then check the id as been uploaded
          if (setConfig.required) {
            if (!uploadedImageIds.includes(setConfig.imageId)) {
              missingImages.push(setConfig.name)
            }
          }
        })
        if (missingImages.length > 0) {
          message = `Please ensure all required images are uploaded. "${missingImages.join(
            '", "'
          )}" are missing.`
        }
        callback(null, message)
      }
    )
  }

  const schema = schemata({
    name: 'ImageSet',
    properties: {
      _id: { type: String },
      account: { type: String, validators: [required] },
      status: {
        type: String,
        options: ['In Progress', 'Complete'],
        defaultValue: 'In Progress',
        validators: [required]
      },
      author: { type: String, validators: [required] },
      dealership: { type: String, validators: [required] },
      make: { type: String, validators: [required] },
      model: { type: String, validators: [required] },
      registration: { type: String, validators: [required] },
      images: {
        type: schemata.Array(createImagesSchema()),
        defaultValue: () => [],
        validators: [validateReqiuredImagesWhenMarkingAsComplete]
      },
      imageSetConfigId: { type: String, validators: [required] },
      createdDate: { type: Date, defaultValue: () => new Date() }
    }
  })

  return schema
}
