const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (features, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><div id=\"field--name\" data-field=\"name\" class=\"form-row\"><label><span class=\"form-label-text\">Name<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"name\"" + (jade.attr("value", features.name, true, false)) + " autofocus=\"autofocus\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div></div></div><div class=\"js-features\"></div></form></div></div>");}.call(this,"features" in locals_for_with?locals_for_with.features:typeof features!=="undefined"?features:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const AdditionalDetailsView = require('./additional-details')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.additionalDetailsView = new AdditionalDetailsView(
      this.serviceLocator,
      this.model
    )
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Feature Set' : 'Edit Feature Set',
        features: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    this.additionalDetailsView
      .render()
      .$el.appendTo(this.$el.find('.js-features'))

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }
}

module.exports = FormView
