const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allStats, allowed, dealerships, groupwideStatisticsEnabled, type, undefined, userDealerships) {
if ( allowed('newInStock', 'view'))
{
buf.push("<div class=\"toolbar\"><div class=\"centering\"><div class=\"toolbar__right\">");
var toggledOff = type === 'Used' ? 'New' : 'Used'
var toggledOn = type
buf.push("<button" + (jade.attr("data-toggled-off", toggledOff, true, false)) + (jade.attr("data-toggled-on", toggledOn, true, false)) + " class=\"btn btn-primary js-toggle-type\">Show " + (jade.escape((jade_interp = toggledOff) == null ? '' : jade_interp)) + " Vehicle Overview</button></div></div></div>");
}
buf.push("<div class=\"page-content\"><div class=\"centering panel\"><div class=\"panel-content\">");
if ( !userDealerships.length)
{
buf.push("<div><h1>" + (jade.escape((jade_interp = type) == null ? '' : jade_interp)) + " Vehicle Overview</h1></div>");
if ( groupwideStatisticsEnabled)
{
buf.push("<div class=\"grid\"><div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Groupwide Statistics</h2></div><div class=\"panel-content\">");
if ( !allStats.length)
{
buf.push("<p>Loading...</p>");
}
else
{
buf.push("<ul>");
// iterate allStats
;(function(){
  var $$obj = allStats;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + " data-dealership=\"all\" class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + " data-dealership=\"all\" class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
buf.push("</div></div></div></div>");
}
buf.push("<hr/>");
}
else
{
buf.push("<h1>Your " + (jade.escape((jade_interp = type) == null ? '' : jade_interp)) + " Statistics</h1>");
}
buf.push("<div class=\"grid\">");
// iterate dealerships
;(function(){
  var $$obj = dealerships;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var dealership = $$obj[$index];

buf.push("<div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>" + (jade.escape(null == (jade_interp = dealership.shortName + ' Statistics') ? "" : jade_interp)) + "</h2></div><div class=\"panel-content\"><ul>");
// iterate dealership.stats
;(function(){
  var $$obj = dealership.stats;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + (jade.attr("data-dealership", dealership._id, true, false)) + " class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else if ( item.linkTo)
{
buf.push("<a" + (jade.attr("href", item.linkTo, true, false)) + ">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + (jade.attr("data-dealership", dealership._id, true, false)) + " class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else if ( item.linkTo)
{
buf.push("<a" + (jade.attr("href", item.linkTo, true, false)) + ">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  }
}).call(this);

buf.push("</ul></div></div></div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var dealership = $$obj[$index];

buf.push("<div class=\"grid__item desktop-one-half\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>" + (jade.escape(null == (jade_interp = dealership.shortName + ' Statistics') ? "" : jade_interp)) + "</h2></div><div class=\"panel-content\"><ul>");
// iterate dealership.stats
;(function(){
  var $$obj = dealership.stats;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + (jade.attr("data-dealership", dealership._id, true, false)) + " class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else if ( item.linkTo)
{
buf.push("<a" + (jade.attr("href", item.linkTo, true, false)) + ">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<li>");
if ( item.clickable)
{
buf.push("<a href=\"overview-list\"" + (jade.attr("data-index", i, true, false)) + (jade.attr("data-dealership", dealership._id, true, false)) + " class=\"js-vehicles-item\">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else if ( item.linkTo)
{
buf.push("<a" + (jade.attr("href", item.linkTo, true, false)) + ">" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</a>");
}
else
{
buf.push("<span>" + (jade.escape((jade_interp = item.total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = item.text) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</li>");
    }

  }
}).call(this);

buf.push("</ul></div></div></div>");
    }

  }
}).call(this);

buf.push("</div></div></div></div>");}.call(this,"allStats" in locals_for_with?locals_for_with.allStats:typeof allStats!=="undefined"?allStats:undefined,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"dealerships" in locals_for_with?locals_for_with.dealerships:typeof dealerships!=="undefined"?dealerships:undefined,"groupwideStatisticsEnabled" in locals_for_with?locals_for_with.groupwideStatisticsEnabled:typeof groupwideStatisticsEnabled!=="undefined"?groupwideStatisticsEnabled:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"userDealerships" in locals_for_with?locals_for_with.userDealerships:typeof userDealerships!=="undefined"?userDealerships:undefined));;return buf.join("");
}

class Overview extends View {
  constructor(serviceLocator, type) {
    super(serviceLocator)

    this.type = type
    this.dealerships = []
    this.allStats = []

    this.trigger = this.emit.bind(this)
    this.$el.on('click', '.js-vehicles-item', this.overviewClicked.bind(this))
    this.$el.on('click', '.js-toggle-type', this.toggleType.bind(this))
  }

  setDealerships(dealerships) {
    this.dealerships = dealerships
    this.render()
  }

  setAllStats(allStats) {
    this.allStats = allStats
    this.render()
  }

  overviewClicked(e) {
    e.preventDefault()

    const { dealership, index } = e.currentTarget.dataset
    let row, dealer

    if (dealership === 'all') {
      row = this.allStats[index]
    } else {
      dealer = this.dealerships.find(dealer => dealer._id === dealership)

      row = dealer.stats[index]
    }
    this.emit('renderItems', row, dealer ? dealer.shortName : 'All Dealerships')
  }

  toggleType(e) {
    e.preventDefault()
    const $button = this.$el.find('.js-toggle-type')
    const { toggledOff, toggledOn } = $button.data()

    $button.text('Showing ' + toggledOff)
    $button.data('toggledOff', toggledOn)
    $button.data('toggledOn', toggledOff)

    this.type = toggledOff
    this.emit('toggleType', toggledOff.toLowerCase())
  }

  render() {
    const { type, allStats, dealerships } = this
    this.$el.empty().append(
      template({
        type,
        allStats,
        dealerships: dealerships.map(dealership => {
          dealership.stats = dealership.stats
            .map(stat => {
              if (
                this.serviceLocator.config.enabledDashboardOverviews.includes(
                  stat.text
                )
              ) {
                return stat
              } else {
                return
              }
            })
            .filter(x => x)
          return dealership
        }),
        userDealerships: this.serviceLocator.session.dealerships || [],
        allowed: this.serviceLocator.allowed,
        groupwideStatisticsEnabled: this.serviceLocator.config.enabledDashboardOverviews.includes(
          'groupwide statistics'
        )
      })
    )

    return this
  }
}

module.exports = Overview
