const CrudService = require('../../../../admin/source/js/lib/crud-service')

class BodyshopSubmissionService extends CrudService {
  get name() {
    return 'BodyshopSubmission'
  }
  get urlRoot() {
    return '/bodyshop-submissions'
  }
}

module.exports = BodyshopSubmissionService
