const modal = require('modal')
const AssetCollection = require('../asset/collections/asset')
const AssetPickerView = require('../asset/views/picker')
const getImageFactory = require('../asset/lib/image-factory')()
const WidgetAreaView = require('../widget/views/widget-area')
const imageConfig = require('../../../lib/image-config.json')

const createMultiImageInputs = () => ({
  handleAddImages(e) {
    e.preventDefault()
    const imageContext = e.target.dataset.context
    const collection = new AssetCollection()
    const assetListView = new AssetPickerView({
      collection: collection,
      type: 'image',
      serviceLocator: this.serviceLocator
    })

    collection.getByType('image')

    modal({
      title: 'Images',
      className: 'modal-asset-view wide',
      content: assetListView.$el,
      buttons: [
        { text: 'Cancel', event: 'Cancel', keyCodes: [27], className: 'btn' },
        {
          text: 'Add selected images',
          event: 'add',
          className: 'btn btn--action',
          keyCodes: [13]
        }
      ]
    })
      .on('add', () =>
        this.addImages(assetListView.selectedCollection, imageContext)
      )
      .on('close', () => assetListView.remove(assetListView))
  },

  addImages(images, imageContext) {
    var ImageModel = getImageFactory('image').model
    images.map(model => {
      var image = new ImageModel(model.attributes)
      this.model[imageContext].add(image)
      return image
    })
  },

  renderImageWidgetArea(imageContext) {
    this.imageArea = new WidgetAreaView({
      model: this.model[imageContext],
      receiveDrops: false,
      serviceLocator: this.serviceLocator,
      widgetItemOptions: imageConfig
    })
    this.$el
      .find(`#field--${imageContext} .js-image-area`)
      .append(this.imageArea.$el)
  }
})
module.exports = createMultiImageInputs
