const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')

const extraProperties = {
  vehicleType: {
    type: String,
    validators: [required],
    defaultValue: 'cars'
  }
}

module.exports = () => createSchema('Homepage Hero', extraProperties)
