module.exports = createController
const Collection = require('chale')
const Model = require('merstone')
const ListView = require('../views/list')
const createGetRevisions = require('../lib/get-revisions')

function createController(
  serviceLocator,
  service,
  type,
  route,
  previousView,
  pageTitle
) {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  const getRevisions = createGetRevisions(
    serviceLocator,
    collection,
    paginationModel
  )

  serviceLocator.router.route(route, 'listRevisions', function(id) {
    service.read(id, function(err, data) {
      if (err) return serviceLocator.router.trigger('notFound', err.message)
      getRevisions(id, type)

      var list = new ListView(
        serviceLocator,
        collection,
        paginationModel,
        type,
        id,
        typeof pageTitle === 'function' ? pageTitle(data) : data[pageTitle]
      ).render()
      list.on('back', function() {
        serviceLocator.router.navigate(previousView, { trigger: true })
      })
      serviceLocator.router.render(list, 'Revisions')
    })
  })
}
