const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (format, formatter, history, id, isAutoloadit, isNaN, mini, title) {
jade_mixins["table"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<table class=\"table--striped js-items\"><tr> <th>Date</th><th>Old Price</th><th>New Price</th><th>Author</th></tr>");
if ( history.length === 0)
{
buf.push("<tr><td colspan=\"4\"> <span>No pricing history for this vehicle was recorded</span>");
if ( isAutoloadit)
{
buf.push("<br/><span>Vehicles imported from Autoloadit may not have pricing history</span>");
}
buf.push("</td></tr>");
}
// iterate (mini ? history.splice(0, 1) : history)
;(function(){
  var $$obj = (mini ? history.splice(0, 1) : history);
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = !isNaN(item.oldPrice) ? formatter.format(item.oldPrice) : 'Unpriced') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = formatter.format(item.newPrice)) ? "" : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = item.author) == null ? '' : jade_interp)) + "<br/>" + (jade.escape((jade_interp = item.authorEmail) == null ? '' : jade_interp)) + "</td></tr>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = !isNaN(item.oldPrice) ? formatter.format(item.oldPrice) : 'Unpriced') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = formatter.format(item.newPrice)) ? "" : jade_interp)) + "</td><td>" + (jade.escape((jade_interp = item.author) == null ? '' : jade_interp)) + "<br/>" + (jade.escape((jade_interp = item.authorEmail) == null ? '' : jade_interp)) + "</td></tr>");
    }

  }
}).call(this);

buf.push("</table>");
};
if ( mini)
{
jade_mixins["table"]();
if ( history.length > 1)
{
buf.push("<a" + (jade.attr("href", '/vehicles/'+ id + '/pricing-history', true, false)) + " target=\"_blank\">Show full history</a>");
}
}
else
{
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Pricing History - " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\">");
jade_mixins["table"]();
buf.push("</div></div></div></div>");
}}.call(this,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"formatter" in locals_for_with?locals_for_with.formatter:typeof formatter!=="undefined"?formatter:undefined,"history" in locals_for_with?locals_for_with.history:typeof history!=="undefined"?history:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"isAutoloadit" in locals_for_with?locals_for_with.isAutoloadit:typeof isAutoloadit!=="undefined"?isAutoloadit:undefined,"isNaN" in locals_for_with?locals_for_with.isNaN:typeof isNaN!=="undefined"?isNaN:undefined,"mini" in locals_for_with?locals_for_with.mini:typeof mini!=="undefined"?mini:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})

class PricingHistory extends View {
  constructor(serviceLocator, model, pricingHistory = [], mini) {
    super(...arguments)

    this.model = model
    this.pricingHistory = pricingHistory

    this.trigger = this.emit.bind(this)
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
    this.mini = mini
  }

  render() {
    const title = `${this.model.brand} ${this.model.model} - ${
      this.model.registration
    }`

    const isAutoloadit = this.model.dataOrigin === 'autoloadit'
    this.$el.empty().append(
      template({
        history: this.pricingHistory,
        format: this.serviceLocator.format,
        title,
        formatter,
        isAutoloadit,
        id: this.model._id,
        mini: this.mini
      })
    )
    return this
  }
}

module.exports = PricingHistory
