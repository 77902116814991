const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (types, undefined) {
buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"tag\">Tag Name</option><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option></select></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Tag Type</span><select name=\"type\" class=\"control control--choice form-field\"><option value=\"\">All Types</option>");
// iterate types
;(function(){
  var $$obj = types;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var type = $$obj[$index];

buf.push("<option>" + (jade.escape(null == (jade_interp = type) ? "" : jade_interp)) + "</option>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var type = $$obj[$index];

buf.push("<option>" + (jade.escape(null == (jade_interp = type) ? "" : jade_interp)) + "</option>");
    }

  }
}).call(this);

buf.push("</select></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");}.call(this,"types" in locals_for_with?locals_for_with.types:typeof types!=="undefined"?types:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

class ListFilterView extends View {
  constructor(...args) {
    super(...args)
    const ListView = args[1]
    this.listView = ListView
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
  }

  trigger(...args) {
    return this.emit(...args)
  }

  updateDisplay(params) {
    const backMap = {
      'dateCreated,asc': 'dateCreated',
      'dateCreated,desc': '-dateCreated',
      'score,desc': '-score'
    }

    if (params.filter.type) {
      this.$el
        .find('form [name=type] [value=' + params.filter.type + ']')
        .attr('selected', true)
    }

    if (Array.isArray(params.sort)) {
      this.$el
        .find(
          '[name=sort] option[value=' + backMap[params.sort.join(',')] + ']'
        )
        .attr('selected', true)
    }
  }

  handleSubmit(e) {
    if (e) e.preventDefault()

    const params = { filter: {}, sort: [] }
    const map = {
      dateCreated: ['dateCreated', 'asc'],
      '-dateCreated': ['dateCreated', 'desc'],
      '-score': ['score', 'desc']
    }
    const type = this.$el.find('form [name=type]').val()

    if (type) params.filter.type = type
    params.sort = map[this.$el.find('form [name=sort]').val()]

    this.emit('filter', params)
  }

  handleClear(e) {
    e.preventDefault()
    this.$el.find('form [name=sort]').val('-dateCreated')
    this.$el.find('form [name=type] [value=""]').attr('selected', true)
    this.handleSubmit()
  }

  render() {
    this.$el.empty().append(template({ types: this.listView.tagTypes }))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    return this
  }
}

module.exports = ListFilterView
