const types = [
  'ex-motability fleet vehicle',
  'dealer purchase (multiple previous keepers)',
  'dealer purchase (1 previous retail keeper)',
  'dealer part-ex vehicle (multiple previous keepers)',
  'dealer part-ex vehicle (1 previous retail keeper)',
  'former dealer demonstrator vehicle',
  'former rental vehicle',
  'former demonstrator vehicle',
  'former management vehicle'
]

module.exports = types
