const { promisify } = require('util')
const schemata = require('schemata')
const required = require('validity-required')
const moment = require('moment')
const tagSchema = require('../tag/schema')
const dateBeforeExpiryValidator = require('validity-date-before-property')(
  'expiryDate'
)
const dateBeforeEndDateValidator = require('validity-date-before-property')(
  'eventEndDate'
)
const createUniqueValidator = require('validity-unique-property')
const validateIfSet = require('validity-validate-if-set')
const isUrl = require('validity-url')
const resolveImages = require('../../../lib/image-resolver')

// TODO: I think this is a feature of validity already
const customValidityMessage = (fn, message) => (key, name, object, cb) => {
  fn(key, name, object, (error, valid) => {
    cb(error, valid ? message : undefined)
  })
}

const createSchemata = (find, serviceLocator) => {
  const fullUrlUniqueValidator = customValidityMessage(
    createUniqueValidator(find, { keys: ['sections'] }),
    'Slug within this section is already in use'
  )

  return schemata({
    name: 'Article',
    properties: {
      _id: {
        type: String
      },
      state: {
        type: String,
        options: ['Draft', 'Published', 'Archived', 'Trashed'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      headline: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: []
        }
      },
      shortHeadline: {
        type: String,
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      slug: {
        type: String,
        validators: {
          draft: [],
          published: [required, fullUrlUniqueValidator],
          archived: []
        }
      },
      body: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        resolve: async article => {
          return JSON.stringify(article.body.widgets)
        },
        resolveType: String
      },
      images: {
        type: Object,
        defaultValue: () => [],
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        resolve: article => resolveImages(article.images),
        resolveType: Array
      },
      alertMessage: { type: String },
      countdownDate: { type: Date },
      countdownLabel: { type: String },
      displayDate: {
        type: Date,
        defaultValue: () =>
          moment()
            .startOf('minute')
            .toDate(),
        validators: {
          draft: [],
          published: [required],
          archived: []
        }
      },
      liveDate: {
        type: Date,
        validators: {
          draft: [dateBeforeExpiryValidator],
          published: [dateBeforeExpiryValidator],
          archived: []
        }
      },
      expiryDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      articleType: {
        type: String,
        defaultValue: () => 'article'
      },
      eventStartDate: {
        type: Date,
        validators: {
          draft: [dateBeforeEndDateValidator],
          published: [dateBeforeEndDateValidator],
          archived: []
        }
      },
      eventEndDate: {
        type: Date,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },
      eventLocation: {
        type: String
      },
      locationUrl: {
        type: String,
        validators: {
          draft: [validateIfSet(isUrl)],
          published: [validateIfSet(isUrl)],
          archived: []
        }
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date(),
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      tags: {
        type: schemata.Array(tagSchema()),
        resolve: async article => article.tags.map(tag => tag.tag),
        resolveType: Array
      },

      metaTitle: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      metaDescription: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      shareTitle: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      shareDescription: {
        type: String,
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      // ID used by admin to preview
      previewId: {
        type: String,
        defaultValue: () =>
          Math.round(Math.random() * 100000000000).toString(36)
      },

      relatedWidgets: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        }
      },

      account: {
        type: String,
        validators: {
          draft: [required],
          published: [required],
          archived: [required]
        }
      },

      layout: {
        type: String,
        defaultValue: null
      },

      models: {
        type: Array
      },

      makes: {
        type: Array
      },

      author: {
        type: String,
        resolve: async article => {
          if (serviceLocator) {
            const authors = await promisify(serviceLocator.authorService.find)({
              _id: article.author
            })
            return (authors && authors.length > 0 && authors[0].name) || null
          }
          return article.author
        },
        resolveType: String
      },

      showCalendarLink: {
        type: Boolean,
        defaultValue: true
      },

      showRegisterLink: {
        type: Boolean,
        defaultValue: true
      },

      headingColour: {
        type: String,
        defaultValue: 'blue'
      },

      hypeStripColour: {
        type: String,
        defaultValue: 'dark'
      }
    }
  })
}

module.exports = createSchemata
