module.exports = constructUrl

var getActionHash = require('./get-action-hash')
  , escapeFilename = require('./escape-filename')

function constructUrl(
  darkroomUrl,
  salt,
  baseActions,
  extraActions,
  uri,
  filename
) {
  var hash = getActionHash(salt, baseActions, extraActions, uri)
    , query = new URLSearchParams(extraActions).toString()

  filename = filename ? '/' + escapeFilename(filename) : ''
  return (
    [darkroomUrl]
      .concat(baseActions)
      .concat([uri + ':' + hash])
      .join('/') +
    filename +
    (query.length > 1 ? '?' + query : '')
  )
}
