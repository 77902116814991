module.exports = initAdmin
const createContoller = require('./controllers/controller')
const LayoutService = require('./services/layout')

function initAdmin() {
  return { layout: init }
}

function init(serviceLocator, done) {
  serviceLocator.layoutService = new LayoutService(serviceLocator)
  createContoller(serviceLocator)
  done()
}
