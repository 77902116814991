const BaseListView = require('../../base/views/list')
const FilterView = require('./list-filter')
const ListItemView = require('./list-item')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, title) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><div class=\"control-group\"><div class=\"btn-group\">");
if ( allowed('article', 'delete'))
{
buf.push("<button type=\"button\" class=\"btn js-delete\">Delete Selected</button>");
}
if ( allowed('article', 'tag'))
{
buf.push("<button type=\"button\" class=\"btn js-tag\">Tag Selected</button>");
}
buf.push("</div><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn dropdown-toggle\">Selection (<span class=\"js-selection-count\">0 items</span>) <span class=\"caret\"></span></a><ul class=\"dropdown-menu\"><li><a class=\"js-select-all\">Add visible to selection</a></li><li><a class=\"js-select-none\">Clear selection</a></li></ul></div></div></div><div class=\"control-group\"><div class=\"btn-group\">");
if ( allowed('article', 'create'))
{
buf.push("<button type=\"button\" class=\"btn btn--action js-new\">New Article</button>");
}
buf.push("</div></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><div class=\"grid grid--reverse\"><div class=\"grid__item one-quarter\"><div class=\"js-filters\"></div></div><div class=\"grid__item three-quarters\"><div class=\"list-container\"><div class=\"js-controls\"></div><div data-layout=\"detail\" class=\"grid list-grid js-items\"></div><div class=\"pagination\"><p>Showing <b class=\"js-item-count\"></b> of <b class=\"js-total-item-count\"></b> items</p><button type=\"button\" class=\"btn js-more\">Load more</button></div></div></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const tagTypes = require('../../../service/tag/tag-config.json')
const createListTagDelegate = require('../lib/list-tag-delegate')

class ListView extends BaseListView {
  constructor(...args) {
    super(...args)
    this.$el.on('click', '.js-tag', this.handleTag.bind(this))
  }

  get name() {
    return { singular: 'Article', plural: 'Articles', resource: 'article' }
  }

  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get template() {
    return template
  }

  addListItem(model) {
    const listItem = new this.ListItemView(this.serviceLocator, model)
    this.listenTo(
      listItem,
      'preview',
      this.emit.bind(this, 'preview', model.id)
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(
      listItem,
      'duplicate',
      this.emit.bind(this, 'duplicate', model)
    )
    this.listenTo(
      listItem,
      'showRevisions',
      this.emit.bind(this, 'showRevisions', model)
    )
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }

  handleTag() {
    const addTags = createListTagDelegate(this.serviceLocator, tagTypes)
    const saveArticle = model => {
      this.serviceLocator.articleService.update(
        model.id,
        model.toJSON(),
        err => {
          if (err) this.serviceLocator.logger.error(err)
        }
      )
    }
    addTags(this.selectedCollection, saveArticle, this.selectNone.bind(this))
  }
}

module.exports = ListView
