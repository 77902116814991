const createAuthedRequester = require('../../../../admin/source/js/lib/authed-request')
const notify = require('../../notification/foreground')

module.exports = serviceLocator => {
  const authedRequest = createAuthedRequester(serviceLocator.config.apiUrl)

  $(document).on('click', '.js-deploy-netlify', () => {
    authedRequest('POST', '/deploy-netlify', {}, err => {
      if (err) {
        serviceLocator.logger.error(err, '/deploy-netlify')
        notify('Error', 'error')
      }
      notify('Success', 'success')
    })
  })
}
