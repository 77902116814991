const ListView = require('./views/list')
const FormView = require('./views/form')
const Model = require('./models/standard-data')
const baseListController = require('./controllers/list')
const baseFormController = require('../base/controllers/form')
const StandardDataService = require('./services/standard-data')

const initAdmin = () => ({ standardDataAdmin: [init] })

const currentParams = {
  keywords: '',
  filter: {},
  sort: ['custom', [['category', 'desc'], ['order', 'asc']]]
}

const init = (serviceLocator, cb) => {
  serviceLocator.standardDataService = new StandardDataService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'standardData',
    plural: 'Standard Data',
    path: 'standard-data',
    service: serviceLocator.standardDataService,
    currentParams,
    Model
  }
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  cb(null)
}

module.exports = initAdmin
