const ListView = require('../views/list')
const Collection = require('chale')
const TagModel = require('../models/tag')
const Model = require('merstone')
const async = require('async')
const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['dateCreated', 'desc']
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an tag is updated, reset the model with its new attributes
  serviceLocator.tagService.on('update', (id, attrs) => {
    const model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.tagService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getTags(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('tags(/)', 'listTags', () => {
    if (!serviceLocator.allow('tag', 'discover')) return false

    getTags(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel,
      serviceLocator.tagTypes
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('tag', 'create')) return false
      serviceLocator.router.navigate('tags/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('tag', 'update')) return false
      serviceLocator.router.navigate('tags/' + id + '/form', { trigger: true })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('tag', 'delete')) return false

      const deleteOne = (id, cb) => {
        serviceLocator.tagService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }

      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })
    })

    list.on('filter', params => {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      getTags(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendTags(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    serviceLocator.router.render(list, 'Tags')
  })

  const getTags = (keywords, filter, sort, pagination) => {
    serviceLocator.tagService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return serviceLocator.logger.error(err, 'Could not load tags')
        collection.reset(
          res.results.map(tag => new TagModel(serviceLocator, tag))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  const appendTags = (keywords, filter, sort, pagination) => {
    serviceLocator.tagService.find(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        res.results.forEach(tag =>
          collection.add(new TagModel(serviceLocator, tag))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}

module.exports = createController
