const compileJade = require('browjadify-compile')
const modal = require('modal')
const debug = require('../../../../admin/source/js/lib/debug')(
  'list-tag-delegate'
)
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (availableTagTypes, undefined) {
buf.push("<form><p>Select the tags to add to the chosen items:</p>");
// iterate availableTagTypes
;(function(){
  var $$obj = availableTagTypes;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var tagType = $$obj[$index];

buf.push("<div class=\"form-row\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['js-tags-' + tagType.type.toLowerCase()], [true])) + "></div></div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var tagType = $$obj[$index];

buf.push("<div class=\"form-row\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = tagType.label || tagType.type) ? "" : jade_interp)) + "</span><div" + (jade.cls(['js-tags-' + tagType.type.toLowerCase()], [true])) + "></div></div>");
    }

  }
}).call(this);

buf.push("</form>");}.call(this,"availableTagTypes" in locals_for_with?locals_for_with.availableTagTypes:typeof availableTagTypes!=="undefined"?availableTagTypes:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const TagCheckbox = require('../../tag/views/tag-checkbox')
const TagSelect = require('../../tag/views/tag-select')
const async = require('async')
const uniq = require('lodash.uniq')
const clone = require('lodash.clonedeep')

/*
 * Adds the ability to bulk tag items in a list view,
 * via the same mechanism as the delete functionality.

 * Takes a bundle so that it can know what tag type boxes to render,
 * and a tagProperty to know what property tags are added to on the entity
 * (usually 'tags').
 */
const createListTagDelegate = (
  serviceLocator,
  availableTagTypes,
  tagProperty = 'tags'
) => {
  /*
   * Pass in an array of models to be tagged. This function needs to be run in
   * the context of the list view. Something like: `listTagDelegate.call(this, models)`
   * will suffice.
   */
  const listTagDelegate = (toTag, onModelUpdate, cb) => {
    if (toTag.length === 0) return

    const tagChecked = () => {
      var tags = consolidateTags()
      debug('Adding tags to ' + toTag.length + ' entities', toTag, tags)

      toTag.forEach(entity => {
        var newTags = entity.get(tagProperty).concat(clone(tags))
        entity.set(
          tagProperty,
          uniq(newTags, ({ tag, type, account }) => {
            // uniq() does strict equality, so use a custom identity function
            // which just compares the JSON for each tag. Ordering might be different
            // for the object properties so the properties are re-injected consistently here.
            return JSON.stringify({ tag, type, account })
          })
        )
        onModelUpdate(entity)
      })
    }

    const consolidateTags = () => {
      var tags = []
      tagControls.forEach(tagControl => {
        tags = tags.concat(tagControl.tags.toJSON())
      })
      return tags
    }

    const $el = $(template({ availableTagTypes: availableTagTypes }))
    let tagControls

    renderTagControls.call(this, $el, (err, controls) => {
      if (err) return debug('Error rendering tags controls', err)
      tagControls = controls
      modal({
        title:
          'Tagging ' +
          toTag.length +
          ' ' +
          (toTag.length === 1 ? 'item' : 'items'),
        content: $el,
        buttons: [
          { text: 'Cancel', className: 'btn', keyCodes: [27] },
          { text: 'Tag', className: 'btn btn--action', event: 'confirm' }
        ]
      }).on('confirm', () => {
        tagChecked.call(this)
        if (typeof cb === 'function') cb(null)
      })
    })

    return this
  }

  const renderTagControls = ($el, cb) => {
    const tagControls = []
    let tagControl

    async.map(
      availableTagTypes,
      (tagType, cb) => {
        switch (tagType.inputStyle) {
          case 'checkbox':
            tagControl = new TagCheckbox(serviceLocator, [], tagType.type)
            tagControl.loadTags()
            break
          case 'textbox':
            var readOnly =
              tagType.readOnly || !serviceLocator.allowed('tag', 'create')
            tagControl = new TagSelect(
              serviceLocator,
              [],
              tagType.type,
              readOnly
            )
            tagControl.loadTags()
            break
        }

        tagControls.push(tagControl)
        $el
          .find('.js-tags-' + tagType.type.toLowerCase())
          .append(tagControl.render().$el)
        cb()
      },
      err => {
        if (err) return cb(err)
        cb(null, tagControls)
      }
    )
  }

  return listTagDelegate
}

module.exports = createListTagDelegate
