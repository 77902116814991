"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isSchemata = require('./is-schemata');
/*
 * Take an object and determine whether it is a schemata array type.
 */


var isArray = function isArray(obj) {
  // Schemata array types must be objects
  if (_typeof(obj) !== 'object') return false; // Array types look like { arraySchema: <schemata> }

  return isSchemata(obj.arraySchema);
}; // (typeof property.type.arraySchema !== 'undefined') &&
// (typeof property.type.arraySchema.stripUnknownProperties === 'function')


module.exports = isArray;