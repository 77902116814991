const ListView = require('../views/list')
const Collection = require('chale')
const VehicleMakeModel = require('../models/make')
const Model = require('merstone')
const async = require('async')
const buildPath = require('../../../service/make/lib/path-builder')
const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['createdDate', 'desc']
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an make is updated, reset the make with its new attributes
  serviceLocator.makeService.on('update', (id, attrs) => {
    const make = collection.get(id)
    if (make) make.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.makeService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getMakes(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('makes(/)', 'listMakes', () => {
    if (!serviceLocator.allow('make', 'discover')) return false

    getMakes(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('make', 'create')) return false
      serviceLocator.router.navigate('makes/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('make', 'update')) return false
      serviceLocator.router.navigate('makes/' + id + '/form', { trigger: true })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('make', 'delete')) return false
      const deleteOne = (id, cb) => {
        serviceLocator.makeService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })
    })

    list.on('filter', params => {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      getMakes(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendMakes(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', make => {
      if (!serviceLocator.allow('make', 'showRevisions')) return false
      serviceLocator.router.navigate('makes/' + make.id + '/revisions', {
        trigger: true
      })
    })

    list.on('duplicate', make => {
      if (!serviceLocator.allow('make', 'duplicate')) return false
      serviceLocator.router.navigate('makes/' + make.id + '/duplicate', {
        trigger: true
      })
    })

    serviceLocator.router.render(list, 'Makes')
  })

  const getMakes = (keywords, filter, sort, pagination) => {
    serviceLocator.makeService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return serviceLocator.logger.error(err, 'Could not load makes')

        res.results.map(make => {
          make.__fullPath = buildPath(make)
        })
        collection.reset(
          res.results.map(make => new VehicleMakeModel(serviceLocator, make))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }

  const appendMakes = (keywords, filter, sort, pagination) => {
    serviceLocator.makeService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)

        res.results.map(make => {
          make.__fullPath = buildPath(make)
        })
        res.results.forEach(make =>
          collection.add(new VehicleMakeModel(serviceLocator, make))
        )
        paginationModel.set('totalItems', res.totalItems)
        paginationModel.set('showing', collection.models.length)
      }
    )
  }
}

module.exports = createController
