const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (imageSetConfig, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};



































buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\">");
jade_mixins["inputField"]('make', 'Make', '', imageSetConfig.make, true, 'text');
jade_mixins["inputField"]('model', 'Model', 'If Make is set, set model to "Global" will create a global image set for that make', imageSetConfig.model, true, 'text');
buf.push("<div class=\"notification notification--notice\"><p>Only one image set config can be created for each make and model.</p><p>Setting both make a model to \"Global\" will create a global fall back Image Set config.</p></div></div></div><div class=\"grid js-setconfig\"><div class=\"grid__item\"><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Images</h2></div><div class=\"panel-content\"><div class=\"form-row\"><label><input value=\"Add image to set\" class=\"btn js-new-setconfig\"/></label></div><div class=\"js-error\"></div><div class=\"js-selected-setconfig-list drop-area\"></div></div></div></div></div></form></div></div>");}.call(this,"imageSetConfig" in locals_for_with?locals_for_with.imageSetConfig:typeof imageSetConfig!=="undefined"?imageSetConfig:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../lib/multi-image-view')
const createSetConfigSchema = require('../../../service/image-set-config/set-config-schema')
const createItemModel = require('../../base/models/item-model')
const createFormView = require('../../base/views/item-form')
const createItemView = require('../views/set-config-item-view')
const createItemedFunctions = require('../../base/views/lib/itemed-functions-creator')

const setConfigViewTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"list-item list-item-detail list-item-repeater\"><div class=\"image-wrapper\"><i" + (jade.attr("style", "background-image: url('" + data.previewImage + "');", true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-leading-actions\"><span class=\"label label--large sort-handle js-sort-handle\"><i class=\"icon icon--hamburger\">Move</i></span></div><div class=\"list-item-actions\"><div class=\"btn-group\"><a class=\"pull-right btn js-item-remove\"><i class=\"icon icon--cross--dark\"></i></a></div></div><h2><a class=\"js-edit\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>Required:</dt><dd>" + (jade.escape(null == (jade_interp = data.required ? 'Yes': 'No') ? "" : jade_interp)) + "</dd></dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}
const setConfigFormTemplate = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};




jade_mixins["checkField"] = jade_interp = function(property, label, fullLabel, value){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row form-row-boolean\"><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</span><div class=\"form-field\"><label><input type=\"checkbox\"" + (jade.attr("name", property, true, false)) + (jade.attr("checked", value, true, false)) + (jade.cls(['control','control--boolean',`js-${property}`], [null,null,true])) + "/>");
if ( fullLabel)
{
buf.push("<span>" + (jade.escape(null == (jade_interp = fullLabel) ? "" : jade_interp)) + "</span>");
}
buf.push("</label></div><div class=\"js-error\"></div></div>");
};






















buf.push("<form><div class=\"panel\"><div class=\"js-errors-summary\"></div><input type=\"hidden\" name=\"imageId\"" + (jade.attr("value", data.imageId, true, false)) + "/>");
jade_mixins["inputField"]('name', 'Name', '', data.name, true, 'text');
jade_mixins["checkField"]('required', 'Required', 'Whether this image is required in this image set', data.required);
buf.push("</div><div id=\"section-images\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Placeholder Image</h2></div><div class=\"panel-content\"><div id=\"field--images\" data-field=\"images\" class=\"form-row form-row-full-width\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"js-error\"></div></div><div class=\"form-row form-row-actions\"><input value=\"Add Placeholder\" data-context=\"images\" class=\"btn btn--success js-images-add\"/></div></div></div><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div></form>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}

const SetConfigModel = createItemModel(createSetConfigSchema())

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account

    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))

    this.on('save', () => {
      const setConfigs = this.setConfig.map(model => model.toJSON())
      this.model.set('setConfig', setConfigs)
    })

    this.setConfig = this.model
      .get('setConfig')
      .map(item => new SetConfigModel(item))

    const SetConfigFormView = createFormView('SetConfig', setConfigFormTemplate)

    const renderSetConfig = createItemedFunctions({
      property: 'setConfig',
      ItemModel: SetConfigModel,
      FormView: SetConfigFormView,
      ItemView: createItemView(
        'Image',
        setConfigViewTemplate,
        SetConfigFormView
      ),
      view: this,
      name: 'Image',
      slug: 'setconfig'
    })

    this.on('afterAppend', () => {
      renderSetConfig()
      this.$el.find('input[type=text]:first').focus()
    })

    Object.assign(this, createMultiImageViewFunctions())
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Image Set Config' : 'Edit Image Set Config',
        imageSetConfig: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    return this
  }
}

module.exports = FormView
