module.exports=[
  {
    "id": 1553,
    "range": "Amarok"
  },
  {
    "id": 2714,
    "range": "Arteon"
  },
  {
    "id": 3359,
    "range": "Arteon Shooting Brake"
  },
  {
    "id": 1431,
    "range": "Beetle"
  },
  {
    "id": 1731,
    "range": "Beetle Cabriolet"
  },
  {
    "id": 1630,
    "range": "CC"
  },
  {
    "id": 2322,
    "range": "Caddy"
  },
  {
    "id": 3134,
    "range": "Caddy Maxi"
  },
  {
    "id": 3477,
    "range": "Caddy California"
  },
  {
    "id": 3668,
    "range": "Caddy California Maxi"
  },
  {
    "id": 3478,
    "range": "Caddy Cargo"
  },
  {
    "id": 3569,
    "range": "Caddy Cargo Maxi"
  },
  {
    "id": 3128,
    "range": "Caddy Maxi Kombi"
  },
  {
    "id": 2341,
    "range": "California"
  },
  {
    "id": 2340,
    "range": "Caravelle"
  },
  {
    "id": 3146,
    "range": "Crafter Box / Luton"
  },
  {
    "id": 3148,
    "range": "Crafter Dropside"
  },
  {
    "id": 3147,
    "range": "Crafter Other Conversions"
  },
  {
    "id": 1369,
    "range": "Crafter Panel Van"
  },
  {
    "id": 3151,
    "range": "Crafter Tipper"
  },
  {
    "id": 1337,
    "range": "Eos"
  },
  {
    "id": 1339,
    "range": "Golf"
  },
  {
    "id": 1809,
    "range": "Golf Cabriolet"
  },
  {
    "id": 1806,
    "range": "Golf Estate"
  },
  {
    "id": 1807,
    "range": "Golf GTD"
  },
  {
    "id": 2168,
    "range": "Golf GTE"
  },
  {
    "id": 1808,
    "range": "Golf GTI"
  },
  {
    "id": 1450,
    "range": "Golf Plus"
  },
  {
    "id": 2330,
    "range": "Golf R"
  },
  {
    "id": 1805,
    "range": "Golf SV"
  },
  {
    "id": 3297,
    "range": "Grand California"
  },
  {
    "id": 3769,
    "range": "ID. Buzz"
  },
  {
    "id": 3771,
    "range": "ID. Buzz Cargo"
  },
  {
    "id": 3295,
    "range": "ID.3"
  },
  {
    "id": 3463,
    "range": "ID.4"
  },
  {
    "id": 3626,
    "range": "ID.5"
  },
  {
    "id": 4042,
    "range": "ID.7"
  },
  {
    "id": 4105,
    "range": "ID.7 Tourer"
  },
  {
    "id": 1341,
    "range": "Jetta"
  },
  {
    "id": 3988,
    "range": "Multivan"
  },
  {
    "id": 1347,
    "range": "Passat"
  },
  {
    "id": 1810,
    "range": "Passat Estate"
  },
  {
    "id": 4214,
    "range": "Passat Estate GTE"
  },
  {
    "id": 4215,
    "range": "Passat GTE"
  },
  {
    "id": 1348,
    "range": "Phaeton"
  },
  {
    "id": 1349,
    "range": "Polo"
  },
  {
    "id": 1351,
    "range": "Scirocco"
  },
  {
    "id": 1352,
    "range": "Sharan"
  },
  {
    "id": 3085,
    "range": "T-Cross"
  },
  {
    "id": 2769,
    "range": "T-Roc"
  },
  {
    "id": 3280,
    "range": "T-Roc Cabriolet"
  },
  {
    "id": 4187,
    "range": "T7 California"
  },
  {
    "id": 3607,
    "range": "Taigo"
  },
  {
    "id": 4199,
    "range": "Tayron"
  },
  {
    "id": 1418,
    "range": "Tiguan"
  },
  {
    "id": 2918,
    "range": "Tiguan Allspace"
  },
  {
    "id": 1366,
    "range": "Touareg"
  },
  {
    "id": 1367,
    "range": "Touran"
  },
  {
    "id": 3494,
    "range": "ABT e-Transporter Kombi"
  },
  {
    "id": 3493,
    "range": "ABT e-Transporter Panel Van"
  },
  {
    "id": 3136,
    "range": "Transporter Conversions"
  },
  {
    "id": 3753,
    "range": "Transporter Dropside"
  },
  {
    "id": 3137,
    "range": "Transporter Kombi"
  },
  {
    "id": 3138,
    "range": "Transporter Panel Van"
  },
  {
    "id": 3141,
    "range": "Transporter Window Van"
  },
  {
    "id": 2323,
    "range": "Transporter Shuttle"
  },
  {
    "id": 2329,
    "range": "e-Golf"
  },
  {
    "id": 2272,
    "range": "e-up!"
  },
  {
    "id": 1589,
    "range": "up!"
  }
]
