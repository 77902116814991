const schemata = require('schemata')

module.exports = () => {
  const schema = schemata({
    name: 'List Item',
    properties: {
      itemId: {
        type: String,
        tag: ['manual']
      },
      isCustom: {
        type: Boolean,
        defaultvalue: false
      },
      overrides: {
        type: Object,
        defaultvalue: () => ({})
      },
      properties: {
        type: Object,
        defaultvalue: () => ({})
      }
    }
  })

  return schema
}
