const ListView = require('./views/list')
const FormView = require('./views/form')
const listController = require('./controllers/list')
const formController = require('./controllers/form')
const overviewController = require('./controllers/overview')
const autoTraderController = require('./controllers/autotrader')
const UsedVehicleService = require('./services/used-vehicle')
const Model = require('./models/used-vehicle')
const createRevisionController = require('../revision/controllers/controller')

const initAdmin = () => ({ usedVehicleAdmin: ['dealershipAdmin', init] })

const init = (serviceLocator, cb) => {
  serviceLocator.usedVehicleService = new UsedVehicleService(serviceLocator)

  const currentParams = {
    keywords: '',
    filter: {},
    sort: ['createdDate', 'desc']
  }

  const controllerOptions = {
    serviceLocator,
    singular: 'usedVehicle',
    plural: 'Vehicles',
    basePath: 'vehicles',
    path: 'vehicles',
    service: serviceLocator.usedVehicleService,
    currentParams,
    Model
  }

  overviewController(serviceLocator)
  autoTraderController(serviceLocator)

  listController(
    Object.assign({}, controllerOptions, {
      ListView,
      path: 'vehicles/list'
    })
  )

  formController(
    Object.assign({}, controllerOptions, {
      FormView,
      listPath: 'vehicles/list'
    })
  )

  createRevisionController(
    serviceLocator,
    serviceLocator.usedVehicleService,
    'usedVehicle',
    'vehicles/:id/revisions',
    'vehicles/list',
    data => `${data.brand} ${data.model} - ${data.registration}`
  )

  cb()
}

module.exports = initAdmin
