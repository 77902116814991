const schemata = require('schemata')
const required = require('validity-required')
const createUniqueValidator = require('validity-unique-property')
const urlPathValidator = require('./lib/path-validator')
const urlOrPathValidator = require('./lib/url-or-path-validator')
const parseUrl = require('url').parse

module.exports = (serviceLocator, save) => {
  const findLoop = (redirect, cb) => {
    const conditions = [
      { redirectUrl: redirect.path },
      { redirectUrl: serviceLocator.config.url + redirect.path },
      { path: redirect.redirectUrl }
    ]

    if (!redirect.redirectUrl) {
      return cb(null, 0)
    }

    const urlParts = parseUrl(redirect.redirectUrl)

    if (urlParts.hostname === serviceLocator.config.hostname) {
      conditions.push({ path: urlParts.pathname })
    }

    save.count({ $or: conditions }, cb)
  }

  const noLoopValidator = (key, keyDisplayName, obj, cb) => {
    findLoop(obj, (err, count) => {
      var message = null

      if (err) return cb(err)

      if (count !== 0) {
        message =
          obj.path +
          ' to ' +
          obj.redirectUrl +
          ' may cause an infinite redirection loop'
      }
      cb(null, message)
    })
  }

  const notEqualRedirectValidator = (key, keyDisplayName, obj, cb) => {
    const pathUrl = serviceLocator.config.url + obj.path
    let message = null

    if (obj.redirectUrl === pathUrl) {
      message = 'The path can not equal the redirect URL'

      return cb(null, message)
    }

    cb()
  }

  return schemata({
    name: 'Redirect',
    properties: {
      _id: { type: String },
      path: {
        type: String,
        validators: [
          required,
          urlPathValidator,
          notEqualRedirectValidator,
          createUniqueValidator(save.findOne)
        ]
      },
      redirectUrl: {
        type: String,
        name: 'Redirect URL',
        validators: [required, urlOrPathValidator, noLoopValidator]
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      account: {
        type: String,
        validators: [required]
      }
    }
  })
}
