const Collection = require('chale')
const MerstoneModel = require('merstone')
const async = require('async')
const pageSize = 100
const defaultCurrentParams = {
  keywords: '',
  filter: {},
  sort: ['dateCreated', 'desc']
}

const bodyshopController = ({
  serviceLocator,
  service,
  plural,
  path,
  singular,
  Model,
  ListView,
  FormView,
  currentParams = defaultCurrentParams
}) => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new MerstoneModel(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  const pagination = { page: currentPage, pageSize: pageSize }

  serviceLocator.router.route(`${path}(/)`, `list${plural}`, function() {
    if (!serviceLocator.allow(singular, 'discover')) return false

    var list = new ListView(serviceLocator, collection, paginationModel)

    list.loadData(() => {
      list.render()
      getEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.displayFilterParams(currentParams)

    list.on('read', function(id) {
      if (!serviceLocator.allow(singular, 'read')) return false
      serviceLocator.router.navigate(`${path}/${id}/form`, {
        trigger: true
      })
    })

    list.on('delete', function(ids) {
      if (!serviceLocator.allow(singular, 'delete')) return false
      async.each(ids, deleteOne, function(err) {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })

      function deleteOne(id, cb) {
        service.delete(id, function(err) {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
    })

    list.on('filter', function(params) {
      currentParams = params
      var pagination = { page: currentPage, pageSize: pageSize }
      currentPage = 1
      getEntities(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', function() {
      currentPage += 1
      var pagination = { page: currentPage, pageSize: pageSize }
      appendEntities(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    const pageTitle = `${plural.charAt(0).toUpperCase()}${plural.slice(1)}`

    serviceLocator.router.render(list, pageTitle)
  })

  // View bodyshop
  serviceLocator.router.route(`${path}/:id/form`, `edit${singular}`, id => {
    if (!serviceLocator.allow(singular, 'read')) return false

    service.read(id, (err, entity) => {
      if (err) return serviceLocator.router.trigger('notFound', err.message)

      const form = new FormView(
        serviceLocator,
        new Model(serviceLocator, entity),
        false
      ).render()
      serviceLocator.router.render(form, `Edit ${singular}`)

      form.on('back', back)
    })
  })

  const back = () => serviceLocator.router.navigate(path, { trigger: true })

  const getEntities = (keywords, filter, sort, pagination) => {
    service.cachedFind(keywords, filter, sort, pagination, (err, res) => {
      if (err)
        return serviceLocator.logger.error(err, `Could not load ${plural}`)
      collection.reset(
        res.results.map(entity => new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }

  const appendEntities = (keywords, filter, sort, pagination) => {
    service.find(keywords, filter, sort, pagination, (err, res) => {
      if (err) return alert(err.message)
      res.results.forEach(entity =>
        collection.add(new Model(serviceLocator, entity))
      )
      paginationModel.set('totalItems', res.totalItems)
      paginationModel.set('showing', collection.models.length)
    })
  }
}

module.exports = bodyshopController
