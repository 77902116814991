module.exports=[
  {
    "type": "General",
    "label": "General Tags",
    "inputStyle": "textbox"
  },
  {
    "type": "Customer",
    "label": "Customer Tags",
    "inputStyle": "textbox"
  },
  {
    "type": "System",
    "label": "System Tags",
    "inputStyle": "checkbox"
  }
]
