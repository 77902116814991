const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (format, history, title, undefined) {
buf.push("<div class=\"page-content\"><div class=\"toolbar\"><div class=\"centering js-toolbar\"><div class=\"toolbar__left\"><button type=\"button\" class=\"btn js-btn-back\">Back to List</button></div></div></div><div class=\"centering\"><header class=\"page-header\"><h1>Scout History - " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h1></header><div class=\"panel panel-styled\"><div class=\"panel-content\"><table class=\"table--striped js-items\"><tr> <th>Date</th><th>Location</th><th>Entry/Exit</th><th>Image</th></tr>");
if ( history.length === 0)
{
buf.push("<tr><td colspan=\"4\"> <span>No scout history for this vehicle has been recorded</span></td></tr>");
}
// iterate history
;(function(){
  var $$obj = history;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.scout === '0001' ? 'Slough Prep - Apron' : 'Slough Prep - Second Floor') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.entryOrExit.charAt(0).toUpperCase() + item.entryOrExit.slice(1)) ? "" : jade_interp)) + "</td><td><a" + (jade.attr("href", item.image.url, true, false)) + " target=\"_blank\"><img" + (jade.attr("src", item.image.previewUrl, true, false)) + "/></a></td></tr>");
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var item = $$obj[i];

buf.push("<tr><td>" + (jade.escape(null == (jade_interp = format(item.createdDate, 'calendar')) ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.scout === '0001' ? 'Slough Prep - Apron' : 'Slough Prep - Second Floor') ? "" : jade_interp)) + "</td><td>" + (jade.escape(null == (jade_interp = item.entryOrExit.charAt(0).toUpperCase() + item.entryOrExit.slice(1)) ? "" : jade_interp)) + "</td><td><a" + (jade.attr("href", item.image.url, true, false)) + " target=\"_blank\"><img" + (jade.attr("src", item.image.previewUrl, true, false)) + "/></a></td></tr>");
    }

  }
}).call(this);

buf.push("</table></div></div></div></div>");}.call(this,"format" in locals_for_with?locals_for_with.format:typeof format!=="undefined"?format:undefined,"history" in locals_for_with?locals_for_with.history:typeof history!=="undefined"?history:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const createDarkroomUrlBuilder = require('darkroom-url-builder')

class ScoutHistory extends View {
  constructor(serviceLocator, model, scoutHistory = []) {
    super(...arguments)

    this.model = model
    this.scoutHistory = scoutHistory

    this.trigger = this.emit.bind(this)
    this.$el.on('click', '.js-btn-back', this.emit.bind(this, 'back'))
  }

  addImageUrls(history, config) {
    const urlBuilder = createDarkroomUrlBuilder(config.url, config.salt)

    return history.map(item => {
      const previewUrl = urlBuilder()
        .resource(item.image)
        .width(200)
        .url()
      const url = urlBuilder()
        .resource(item.image)
        .url()

      return Object.assign({}, item, { image: { previewUrl, url } })
    })
  }

  render() {
    const title = `${this.model.brand} ${this.model.model} - ${
      this.model.registration
    }`

    const embellishedHistory = this.addImageUrls(
      this.scoutHistory,
      this.serviceLocator.config.darkroom
    )

    this.$el.empty().append(
      template({
        history: embellishedHistory,
        format: this.serviceLocator.format,
        title,
        id: this.model._id
      })
    )
    return this
  }
}

module.exports = ScoutHistory
