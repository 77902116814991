const visibility = require('regg')()

visibility

  .register('draft', () => {
    return {
      labelClass: 'label-status',
      iconClass: 'icon--draft',
      title: 'Draft',
      description:
        'This content is not published.<br>It is not visible on the site'
    }
  })

  .register('published', () => {
    return {
      labelClass: 'label-status label--standard',
      iconClass: 'icon--published',
      title: 'Published',
      description: 'This content is visible on the site.'
    }
  })

module.exports = { visibility: visibility }
