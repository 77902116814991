const BaseFormView = require('../../base/views/form')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, imageSet, title) {
buf.push("<div class=\"page-content\"><div class=\"js-toolbar-root\"></div><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</h1></header><form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type, readonly){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.attr("readonly", readonly, true, false)) + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( block)
{
buf.push("<div class=\"form-row-description form-copy\">");
block && block();
buf.push("</div>");
}
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};













jade_mixins["selectField"] = jade_interp = function(property, label, required, description, showAdd){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><div" + (jade.cls(['form-field','js-' + property], [null,true])) + "></div></label>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
if ( showAdd && allowed('usedVehicleDropdowns', property))
{
buf.push("<div class=\"form-row-description form-copy\"><a" + (jade.cls(['btn','js-add-' + property], [null,true])) + ">Add " + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</a></div>");
}
buf.push("<div class=\"js-error\"></div></div>");
};




buf.push("<div id=\"section-basic-details\" class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Basic Details</h2></div><div class=\"panel-content\"><input type=\"hidden\" name=\"author\"" + (jade.attr("value", imageSet.author, true, false)) + "/><div id=\"field--status\" data-field=\"status\" class=\"form-row\"><label><span class=\"form-label-text\">Status<abbr title=\"This field is required\">*</abbr></span><select name=\"status\" class=\"control control--choice form-field\"><option value=\"In Progress\"" + (jade.attr("selected", imageSet.status === 'In Progress', true, false)) + ">In Progress</option><option value=\"Complete\"" + (jade.attr("selected", imageSet.status === 'Complete', true, false)) + ">Complete</option></select></label><div class=\"js-error\"></div></div>");
jade_mixins["selectField"]('dealership', 'Dealership', true);
jade_mixins["inputField"]('make', 'Make', '', imageSet.make, true, 'text');
jade_mixins["inputField"]('model', 'Model', '', imageSet.model, true, 'text');
jade_mixins["inputField"]('registration', 'Registration', '', imageSet.registration, true, 'text');
buf.push("<div id=\"field--imageSetConfigId\" data-field=\"imageSetConfigId\" class=\"form-row\"><label><span class=\"form-label-text\">Image Set Config<abbr title=\"This field is required\">*</abbr></span><div class=\"form-field js-image-set-config-select\"></div></label><div class=\"js-error\"></div></div></div></div><div class=\"js-uploaded-images-manager\"></div></form></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"imageSet" in locals_for_with?locals_for_with.imageSet:typeof imageSet!=="undefined"?imageSet:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}
const BaseSelect = require('../../base/views/select')
const UploadedImagesManager = require('./uploaded-images/list')
const ImageModel = require('../models/items')

class FormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    const { account } = args
    this.account = account
    this.imageSetConfig = null
    this.uploadedImageManagerView = null

    this.uploadedImages = this.model
      .get('images')
      .map(item => (new ImageModel(item)))

    this.on('save', () => {
      const images = this.uploadedImages.map(model => model.toJSON())
      this.model.set('images', images)
    })

    this.on('saveAndClose', () => {
      const images = this.uploadedImages.map(model => model.toJSON())
      this.model.set('images', images)
    })

    if (!this.model.get('author')) {
      this.model.set('author', window.localStorage.getItem('apiId'))
    }

    this.on('afterAppend', () => {
      this.$el.find('input[type=text]:first').focus()
    })

    if (this.model.get('imageSetConfigId')) {
      this.fetchConfig()
    }
  }

  fetchConfig() {
    if (!this.model.get('imageSetConfigId')) {
      this.$el.find('.js-uploaded-images-manager').empty()
      return
    }
    this.serviceLocator.imageSetConfigService.read(this.model.get('imageSetConfigId'), (error, imageSetConfig) => {
      if (error || !imageSetConfig) alert(`Unable to find image set config for: ${this.model.get('imageSetConfigId')}`)
      this.imageSetConfig = imageSetConfig
      this.uploadedImageManagerView = new UploadedImagesManager(this.serviceLocator, this.model, this.imageSetConfig)
      this.uploadedImageManagerView.on('uploadedImages', (uploadedImages) => {
        this.uploadedImages = uploadedImages
      })
      this.$el.find('.js-uploaded-images-manager').empty().append(this.uploadedImageManagerView.render().$el)
      this.attachView(this.uploadedImageManagerView)
    })
  }

  renderImageSetConfigSelect() {
    const configSelectView = new BaseSelect(this.serviceLocator, {
      service: 'imageSetConfigService',
      selected: this.model.get('imageSetConfigId'),
      limitToOneValue: true,
      singular: 'Image Set Config',
      plural: 'Image Set Configs',
      textProperty: data => data && `${data.make} ${data.model}`,
      sortProperty: 'make'
    })

    this.$el.find('.js-image-set-config-select').append(configSelectView.render().$el)
    configSelectView.on('change', imageSetConfig => {
      this.model.set('imageSetConfigId', imageSetConfig)
      // TODO add confirmation / warning, that existing images will be lost by changing
      this.fetchConfig()
    })
    this.attachView(configSelectView)
  }

  renderDealershipSelect() {
    const dealershipSelect = new BaseSelect(this.serviceLocator, {
      service: 'dealershipService',
      selected: this.model.get('dealership'),
      limitToOneValue: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName',
      displayNoneOption: true,
      filter: { disableVehicleAllocating: { $ne: true } }
    })

    this.$el.find('.js-dealership').append(dealershipSelect.render().$el)
    dealershipSelect.on('change', dealership =>
      this.model.set('dealership', dealership)
    )
    this.attachView(dealershipSelect)
  }

  render() {
    // Render the template
    this.$el.append(
      template({
        title: this.isNew ? 'New Image Set' : 'Edit Image Set',
        imageSet: this.model.toJSON(),
        account: this.account,
        format: this.serviceLocator.format
      })
    )

    // Render the toolbar
    this.toolbar.render().$el.appendTo(this.$el.find('.js-toolbar-root'))

    this.renderImageSetConfigSelect()
    this.renderDealershipSelect()
    return this
  }
}

module.exports = FormView
