module.exports = function handleCheckAutoTrader() {
  this.serviceLocator.usedVehicleService.getAdvertInfo(
    { registration: this.model.get('registration') },
    (err, res) => {
      if (err) {
        alert('Could not find vehicle on AutoTrader')
        if (err.message.includes('[object Object]')) {
          return this.serviceLocator.logger.error(err)
        }
        return this.$el.find('.js-autotrader-text').text(err.message)
      }

      if (!res.autoTraderId) return

      const url = `https://www.autotrader.co.uk/classified/advert/${
        res.autoTraderId
      }`

      this.model.set(res)
      this.$el.find('.js-autotrader-text').replaceWith(
        $(`
            <span>
            <a target="_blank" href='${url}'>${url}</a>
            <br />
            Images: ${res.autoTraderImageCount}
            </span>
          `)
      )

      this.$el.find('.js-check-autotrader').hide()
    }
  )
}
