const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, undefined) {






























jade_mixins["expander-list"] = jade_interp = function(title, expanded){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.cls(['expander','expander-list','js-expander',expanded ? 'expander-expanded': 'expander-collapsed'], [null,null,null,true])) + "><h3 class=\"expander-list__heading\"><button class=\"expander-header js-expander-toggle\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</button></h3><div class=\"expander-content\">");
block && block();
buf.push("</div></div>");
};
buf.push("<div class=\"grid grid--reverse\"><div class=\"grid__item one-whole\"><div style=\"max-height: 450px; overflow:auto\" class=\"form-row\">");
// iterate data.features.formatted
;(function(){
  var $$obj = data.features.formatted;
  if ('number' == typeof $$obj.length) {

    for (var title = 0, $$l = $$obj.length; title < $$l; title++) {
      var items = $$obj[title];

buf.push("<div" + (jade.attr("id", `features-${title.replaceAll(' ', '-').toLowerCase()}`, true, false)) + ">");
jade_mixins["expander-list"].call({
block: function(){
if ( items.length > 0)
{
buf.push("<ul class=\"list--unstyled\">");
// iterate items
;(function(){
  var $$obj = items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div class=\"checkbox\"><input type=\"checkbox\" name=\"feature\"" + (jade.attr("value", `${item.name}`, true, false)) + " class=\"feature-checkbox\"/></div><div class=\"feature__name\"><div><strong>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.type) ? "" : jade_interp)) + "</div></div>");
if ( item.basicPrice > 0)
{
buf.push("<div class=\"feature__price\"><div>Price: £" + (jade.escape((jade_interp = item.basicPrice) == null ? '' : jade_interp)) + "</div><div class=\"footnote\">VAT: £" + (jade.escape((jade_interp = item.vatPrice) == null ? '' : jade_interp)) + "</div></div>");
}
buf.push("</li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div class=\"checkbox\"><input type=\"checkbox\" name=\"feature\"" + (jade.attr("value", `${item.name}`, true, false)) + " class=\"feature-checkbox\"/></div><div class=\"feature__name\"><div><strong>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.type) ? "" : jade_interp)) + "</div></div>");
if ( item.basicPrice > 0)
{
buf.push("<div class=\"feature__price\"><div>Price: £" + (jade.escape((jade_interp = item.basicPrice) == null ? '' : jade_interp)) + "</div><div class=\"footnote\">VAT: £" + (jade.escape((jade_interp = item.vatPrice) == null ? '' : jade_interp)) + "</div></div>");
}
buf.push("</li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
else
{
buf.push("<p>No items in this category</p>");
}
}
}, `${title} (${items.length})`, items.length > 0);
buf.push("</div>");
    }

  } else {
    var $$l = 0;
    for (var title in $$obj) {
      $$l++;      var items = $$obj[title];

buf.push("<div" + (jade.attr("id", `features-${title.replaceAll(' ', '-').toLowerCase()}`, true, false)) + ">");
jade_mixins["expander-list"].call({
block: function(){
if ( items.length > 0)
{
buf.push("<ul class=\"list--unstyled\">");
// iterate items
;(function(){
  var $$obj = items;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div class=\"checkbox\"><input type=\"checkbox\" name=\"feature\"" + (jade.attr("value", `${item.name}`, true, false)) + " class=\"feature-checkbox\"/></div><div class=\"feature__name\"><div><strong>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.type) ? "" : jade_interp)) + "</div></div>");
if ( item.basicPrice > 0)
{
buf.push("<div class=\"feature__price\"><div>Price: £" + (jade.escape((jade_interp = item.basicPrice) == null ? '' : jade_interp)) + "</div><div class=\"footnote\">VAT: £" + (jade.escape((jade_interp = item.vatPrice) == null ? '' : jade_interp)) + "</div></div>");
}
buf.push("</li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

buf.push("<li class=\"feature\"><div class=\"checkbox\"><input type=\"checkbox\" name=\"feature\"" + (jade.attr("value", `${item.name}`, true, false)) + " class=\"feature-checkbox\"/></div><div class=\"feature__name\"><div><strong>" + (jade.escape(null == (jade_interp = item.name) ? "" : jade_interp)) + "</strong></div><div class=\"footnote\">" + (jade.escape(null == (jade_interp = item.type) ? "" : jade_interp)) + "</div></div>");
if ( item.basicPrice > 0)
{
buf.push("<div class=\"feature__price\"><div>Price: £" + (jade.escape((jade_interp = item.basicPrice) == null ? '' : jade_interp)) + "</div><div class=\"footnote\">VAT: £" + (jade.escape((jade_interp = item.vatPrice) == null ? '' : jade_interp)) + "</div></div>");
}
buf.push("</li>");
    }

  }
}).call(this);

buf.push("</ul>");
}
else
{
buf.push("<p>No items in this category</p>");
}
}
}, `${title} (${items.length})`, items.length > 0);
buf.push("</div>");
    }

  }
}).call(this);

buf.push("</div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const modal = require('modal')

class AutotraderFeaturePicker extends View {
  constructor(serviceLocator, category, data) {
    super(...arguments)

    this.serviceLocator = serviceLocator
    this.category = category
    this.data = data
    this.selectedFeatures = new Set()

    this.onFeatureChange = this.onFeatureChange.bind(this)
    this.onFeatureClick = this.onFeatureClick.bind(this)
  }

  onFeatureChange(event) {
    const checkbox = event.target
    const featureName = checkbox.value

    if (checkbox.checked) {
      this.selectedFeatures.add(featureName)
    } else {
      this.selectedFeatures.delete(featureName)
    }

    this.emit('featureChange', Array.from(this.selectedFeatures))
  }

  onFeatureClick(event) {
    if (event.target.classList.contains('feature-checkbox')) {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    const li = event.currentTarget
    const checkbox = li.querySelector('.feature-checkbox')
    checkbox.checked = !checkbox.checked

    // Manually trigger the change event for the checkbox
    const changeEvent = new Event('change', { bubbles: true })
    checkbox.dispatchEvent(changeEvent)
  }

  render() {
    // Render the template
    this.$el.append(template({ category: this.category, data: this.data }))

    this.$el.find('.feature-checkbox').on('change', this.onFeatureChange)
    this.$el.find('li.feature').on('click', this.onFeatureClick)

    this.modal = modal({
      title: `${this.category} Features`,
      content: this.$el,
      buttons: [
        {
          text: 'Add',
          event: 'add',
          className: 'btn btn--success',
          keyCodes: [27]
        },
        { text: 'Cancel', event: 'cancel', className: 'btn' }
      ],
      className: 'wide'
    })

    this.modal.on('add', this.emit.bind(this, 'add'))

    return this
  }
}

module.exports = AutotraderFeaturePicker
