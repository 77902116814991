const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (crops, image, undefined) {
buf.push("<ul class=\"tabs js-crops\">");
// iterate crops
;(function(){
  var $$obj = crops;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var crop = $$obj[$index];

buf.push("<li class=\"tabs__tab js-selectCrop\"><button type=\"button\" class=\"text-btn\">" + (jade.escape(null == (jade_interp = crop.name) ? "" : jade_interp)) + "</button></li>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var crop = $$obj[$index];

buf.push("<li class=\"tabs__tab js-selectCrop\"><button type=\"button\" class=\"text-btn\">" + (jade.escape(null == (jade_interp = crop.name) ? "" : jade_interp)) + "</button></li>");
    }

  }
}).call(this);

buf.push("</ul><div class=\"crop-preview-wrapper\"><img" + (jade.attr("src", image, true, false)) + " class=\"js-crop-target\"/></div><div class=\"modal-controls\"><button type=\"button\" class=\"btn js-cancel\">Cancel</button><button type=\"button\" class=\"btn btn--action js-save\">Save</button></div>");}.call(this,"crops" in locals_for_with?locals_for_with.crops:typeof crops!=="undefined"?crops:undefined,"image" in locals_for_with?locals_for_with.image:typeof image!=="undefined"?image:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const BaseWidgetView = require('../../../widget/views/form/base')
const debug = require('../../../../../admin/source/js/lib/debug')(
  'image edit view'
)
const imageHelper = require('../../lib/image-crop-helper')
const extend = require('lodash.assign')
const clone = require('lodash.clonedeep')

require('../../../../../admin/source/js/lib/vendor/jquery.Jcrop')

module.exports = BaseWidgetView.extend({
  template,
  debug,
  jCropApi: null,
  events: extend({}, BaseWidgetView.prototype.events, {
    'click .js-selectCrop': 'switchCrop',
    'click .js-cancel': 'handleCancel'
  }),

  currentCrop: null,
  currentCropIndex: null,
  provisionalCrops: null,
  widthRatio: null,
  heightRatio: null,

  initialize(options) {
    this.options = options
    debug('init crop tool with crops:', this.model.get('crops'))

    this.provisionalCrops = clone(this.model.get('crops')) || []

    this.on('afterRender', this.postRender)

    BaseWidgetView.prototype.initialize.apply(this, arguments)
  },
  postRender() {
    debug('render crop tool')

    const $cropControls = this.$('img.js-crop-target')
    const self = this

    this.$('.js-selectCrop')
      .first()
      .addClass('is-active')

    $cropControls.on('load', () => {
      debug('image loaded, applying crop', $cropControls)

      self.calculateRatios()

      $cropControls.Jcrop(
        {
          onSelect: coordinates => {
            debug('Selection made')

            // On occasion jCrop returns negative coordinates.
            coordinates = self.validateJcropCoordinates(coordinates)

            self.setCrop(coordinates)
          }
        },
        function() {
          // Needs to be function, jcrop only passes reference by context
          self.jCropApi = this
          self
            .$('li')
            .first()
            .trigger('click')
        }
      )
    })
  },
  validateJcropCoordinates(coordinates) {
    Object.keys(coordinates).forEach(key => {
      var coordinate = coordinates[key]
      if (coordinate < 0) {
        coordinates[key] = 0
        debug('fixing crop coord:', key, coordinates[key])
      }
    })
    return coordinates
  },
  calculateRatios() {
    // Get original image dimension
    const originalWidth = this.model.get('width')
    const originalHeight = this.model.get('height')

    // Get displayed image dimension
    const $image = this.$('img')
    const localHeight = $image.height()
    const localWidth = $image.width()

    // Calculate and set the difference ratio
    this.widthRatio = originalWidth / localWidth
    this.heightRatio = originalHeight / localHeight
  },
  handleSave() {
    debug('handle save')
    this.model.set('crops', this.provisionalCrops)
    debug('Retrieving new crops')
    this.model.setCrops()
    this.trigger('save', this.model)
  },
  switchCrop(e) {
    /* eslint-disable one-var */

    var $target = $(e.currentTarget)
    this.currentCropIndex = this.$('.js-selectCrop').index($target)

    var currentCrop = this.provisionalCrops[this.currentCropIndex]

    debug('switching to ' + this.currentCropIndex + ': ', $target.text())
    this.setSelectedStyles($target)

    if (currentCrop) {
      this.currentCrop = clone(currentCrop)

      var cropArray = this.cropToArray(this.currentCrop.coordinates),
        aspectRatio = this.currentCrop.aspectRatio,
        cropOptions = { setSelect: this.globalToLocal(cropArray) }

      if (aspectRatio === '*') {
        cropOptions.aspectRatio = 0
      } else {
        cropOptions.aspectRatio = imageHelper.parseRatioToFloat(aspectRatio)
      }

      this.jCropApi.setOptions(cropOptions)
    }
  },
  setCrop(coordinates) {
    debug('Setting coordinates', coordinates)
    this.currentCrop.coordinates = this.localToGlobal(
      this.JCropToArray(coordinates)
    )

    // Update the provisional crop object.
    this.provisionalCrops[this.currentCropIndex] = this.currentCrop
  },
  setSelectedStyles($target) {
    this.$('.js-selectCrop').removeClass('is-active')
    $target.addClass('is-active')
  },
  cropToArray(crops) {
    return [crops.x1, crops.y1, crops.x2, crops.y2]
  },
  JCropToArray(crops) {
    return [crops.x, crops.y, crops.x2, crops.y2, crops.w, crops.h]
  },
  localToGlobal(local) {
    var global = {}

    // Adjust coordinates by the ratio
    global.x1 = local[0] * this.widthRatio
    global.x2 = local[2] * this.widthRatio
    global.y1 = local[1] * this.heightRatio
    global.y2 = local[3] * this.heightRatio

    global.w = local[4] * this.widthRatio
    global.h = local[5] * this.heightRatio

    return global
  },
  globalToLocal(global) {
    var local = clone(global)

    // Adjust coordinates by the ratio
    local[0] /= this.widthRatio
    local[2] /= this.widthRatio
    local[1] /= this.heightRatio
    local[3] /= this.heightRatio

    return local
  },
  render() {
    this.$el.empty().append(
      this.template({
        className: 'base',
        data: this.model.toJSON(),
        image: this.model.buildPreviewUrl(580),
        crops: this.options.crops
      })
    )

    // this.$(':input[name=liveDate]').dateTimeSelect(this.model.get('liveDate'))
    // this.$(':input[name=expiryDate]').dateTimeSelect(this.model.get('expiryDate'))

    this.trigger('afterRender')
  }
})
