const compileJade = require('browjadify-compile')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"dd-handle-icon\"></div><div class=\"dd-item-content\"><div class=\"multiline-title\"><p>" + (jade.escape((jade_interp = data.category) == null ? '' : jade_interp)) + " - " + (jade.escape((jade_interp = data.value || '<blank entry -- spacer item>') == null ? '' : jade_interp)) + "</p></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const View = require('ventnor')

class ReorderListItem extends View {
  constructor(serviceLocator, model) {
    super(...arguments)
    this.model = model
    this.$el.addClass('dd-handle')
    this.$el.addClass('compact')
  }

  get template() {
    return template
  }

  render() {
    this.$el.empty().append(template({ data: this.model.toJSON() }))
    return this
  }
}

module.exports = ReorderListItem
