module.exports = initWidget

function initWidget() {
  return { automotiveCards: ['sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    name: 'Automotive Cards',
    description: 'Displays row of cards',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }
  ;['section', 'offerBody', 'dealershipBody'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('automotiveCards', widget)
  )
  done()
}
