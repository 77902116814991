const FormView = require('../views/form')
const Model = require('../models/administrator')
const notify = require('../../notification/foreground')
const async = require('async')

const createController = serviceLocator => {
  // Edit
  serviceLocator.router.route(
    'administrators/:id/form',
    'editAdministrator',
    id => {
      if (!serviceLocator.allow('administrator', 'update')) return false

      async.parallel(
        {
          roles: serviceLocator.roleService.find.bind(
            serviceLocator.roleService,
            '',
            {},
            [],
            {}
          ),
          administrator: serviceLocator.administratorService.read.bind(
            serviceLocator.administratorService,
            id
          )
        },
        (err, data) => {
          if (err) return serviceLocator.router.trigger('notFound', err.message)

          const attributes = {
            adminRoles: data.roles.results,
            ...data.administrator
          }
          const form = new FormView(
            serviceLocator,
            new Model(serviceLocator, attributes)
          ).render()

          serviceLocator.router.render(form, 'Edit Administrator')

          form.on('back', back)

          form.on('save', () => {
            saveExisting(id, form, err => {
              if (err) return
              notify('Saved', 'save')
            })
          })

          form.on('saveAndClose', () => {
            saveExisting(id, form, err => {
              if (err) return
              notify('Saved', 'save')
              serviceLocator.router.navigate('administrators', {
                trigger: true
              })
            })
          })
        }
      )
    }
  )

  // Create
  serviceLocator.router.route(
    'administrators/form',
    'createAdministrator',
    () => {
      if (!serviceLocator.allow('administrator', 'create')) return false

      serviceLocator.roleService.find('', {}, [], {}, (err, roles) => {
        if (err) return serviceLocator.router.trigger('notFound', err.message)
        const attributes = { adminRoles: roles.results }
        const model = new Model(serviceLocator, attributes)

        model.set(model.schemata.makeDefault())

        const form = new FormView(serviceLocator, model, true).render()

        serviceLocator.router.render(form, 'New Administrator')

        form.on('back', back)

        form.on('save', () => {
          saveNew(form, (err, saved) => {
            if (err) return
            notify('Saved', 'save')
            serviceLocator.router.navigate(
              'administrators/' + saved._id + '/form',
              { trigger: true }
            )
          })
        })

        form.on('saveAndClose', () => {
          saveNew(form, err => {
            if (err) return
            notify('Saved', 'save')
            serviceLocator.router.navigate('administrators', { trigger: true })
          })
        })
      })
    }
  )

  const saveExisting = (id, form, cb) => {
    serviceLocator.administratorService.update(
      id,
      form.model.toJSON(),
      (err, administrator) => {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.clearUnsavedChanges()
        form.clearErrors()
        cb(null, administrator)
      }
    )
  }

  const saveNew = (form, cb) => {
    serviceLocator.administratorService.create(
      form.model.toJSON(),
      (err, administrator) => {
        if (err) {
          form.showErrors(err.errors)
          return cb(err)
        }
        form.clearUnsavedChanges()
        cb(null, administrator)
      }
    )
  }

  const back = () => {
    serviceLocator.router.navigate('administrators', { trigger: true })
  }
}

module.exports = createController
