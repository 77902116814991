const ListView = require('../views/list')
const ReorderView = require('../views/reorder')
const Collection = require('chale')
const VehicleModelModel = require('../models/model')
const Model = require('merstone')
const async = require('async')
const notify = require('../../notification/foreground')

const pageSize = 100

const createController = serviceLocator => {
  const collection = new Collection(serviceLocator, [], ['select', 'deSelect'])
  const paginationModel = new Model(serviceLocator, {
    totalItems: 0,
    showing: 0
  })
  let currentPage = 1
  let currentParams = {
    keywords: '',
    filter: {},
    sort: ['createdDate', 'desc']
  }
  const pagination = { page: currentPage, pageSize: pageSize }

  // Whenever an model is updated, reset the model with its new attributes
  serviceLocator.modelService.on('update', (id, attrs) => {
    const model = collection.get(id)
    if (model) model.reset(attrs)
  })

  // Reload the first page of the current filters when a new item is created in case it should appear there
  serviceLocator.modelService.on('create', () => {
    currentPage = 1
    const pagination = { page: currentPage, pageSize: pageSize }
    getModels(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )
  })

  serviceLocator.router.route('models(/)', 'listModels', () => {
    if (!serviceLocator.allow('model', 'discover')) return false

    getModels(
      currentParams.keywords,
      currentParams.filter,
      currentParams.sort,
      pagination
    )

    const list = new ListView(
      serviceLocator,
      collection,
      paginationModel
    ).render()

    list.displayFilterParams(currentParams)

    list.on('createNew', () => {
      if (!serviceLocator.allow('model', 'create')) return false
      serviceLocator.router.navigate('models/form', { trigger: true })
    })

    list.on('edit', id => {
      if (!serviceLocator.allow('model', 'update')) return false
      serviceLocator.router.navigate('models/' + id + '/form', {
        trigger: true
      })
    })

    list.on('delete', ids => {
      if (!serviceLocator.allow('model', 'delete')) return false
      const deleteOne = (id, cb) => {
        serviceLocator.modelService.delete(id, err => {
          if (err) return cb(err)
          collection.remove(id)
          cb()
        })
      }
      async.each(ids, deleteOne, err => {
        if (err) return alert(err.message)
        paginationModel.set(
          'totalItems',
          paginationModel.get('totalItems') - ids.length
        )
        paginationModel.set('showing', collection.models.length)
      })
    })

    list.on('filter', params => {
      currentParams = params
      currentPage = 1
      const pagination = { page: currentPage, pageSize: pageSize }
      getModels(params.keywords, params.filter, params.sort, pagination)
    })

    list.on('loadMore', () => {
      currentPage += 1
      const pagination = { page: currentPage, pageSize: pageSize }
      appendModels(
        currentParams.keywords,
        currentParams.filter,
        currentParams.sort,
        pagination
      )
    })

    list.on('showRevisions', model => {
      if (!serviceLocator.allow('model', 'showRevisions')) return false
      serviceLocator.router.navigate('models/' + model.id + '/revisions', {
        trigger: true
      })
    })

    list.on('duplicate', model => {
      if (!serviceLocator.allow('model', 'duplicate')) return false
      serviceLocator.router.navigate('models/' + model.id + '/duplicate', {
        trigger: true
      })
    })

    list.on('reorder', () => {
      if (!serviceLocator.allow('model', 'update')) return false

      serviceLocator.router.navigate(`models/reorder`, { trigger: true })
    })

    serviceLocator.router.render(list, 'Models')
  })

  const updateModel = (id, model, cb) => {
    serviceLocator.modelService.update(id, model.toJSON(), (err, item) => {
      if (err) {
        return cb(err)
      }

      cb(null, item)
    })
  }

  const back = () => {
    serviceLocator.router.navigate('models', { trigger: true })
  }

  serviceLocator.router.route(`models/reorder(/)`, 'reorderModels', () => {
    if (!serviceLocator.allow('model', 'update')) return false

    const reorderCollection = new Collection(serviceLocator)

    var reorderView = new ReorderView(serviceLocator, reorderCollection)

    serviceLocator.router.render(reorderView.render(), 'Reorder Models')

    reorderView.on('cancel', back)
    reorderView.on('update', (model, cb) => {
      updateModel(model.get('_id'), model, err => {
        if (err) return
        cb()
      })
    })

    reorderView.on('save', () => {
      notify('Saved', 'save')
      back()
    })

    reorderView.on('filter', (make, bodyType) => {
      serviceLocator.modelService.cachedFind(
        '',
        { make, bodyTypes: bodyType, parentModel: null },
        ['order', 'asc'],
        { pageSize: 1000 },
        (err, res) => {
          if (err)
            return serviceLocator.logger.error(err, 'Could not load models')

          reorderCollection.reset(
            res.results.map(
              model => new VehicleModelModel(serviceLocator, model)
            )
          )

          reorderView.setupNestable()
        }
      )
    })
  })

  const getModels = (keywords, filter, sort, pagination) => {
    serviceLocator.modelService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err)
          return serviceLocator.logger.error(err, 'Could not load models')
        embellishModelsWithMake(res.results, (error, embellishedModels) => {
          if (error)
            return serviceLocator.logger.error(
              err,
              'Could not embellish models'
            )

          embellishModelsWithUrlPath(
            embellishedModels,
            (error, modelsWithUrlPath) => {
              if (error)
                return serviceLocator.logger.error(
                  err,
                  'Could not embellish models with Clean URL'
                )

              collection.reset(
                modelsWithUrlPath.map(
                  model => new VehicleModelModel(serviceLocator, model)
                )
              )
              paginationModel.set('totalItems', res.totalItems)
              paginationModel.set('showing', collection.models.length)
            }
          )
        })
      }
    )
  }

  const appendModels = (keywords, filter, sort, pagination) => {
    serviceLocator.modelService.cachedFind(
      keywords,
      filter,
      sort,
      pagination,
      (err, res) => {
        if (err) return alert(err.message)
        embellishModelsWithMake(res.results, (error, embellishedModels) => {
          if (error)
            return serviceLocator.logger.error(
              err,
              'Could not embellish models'
            )

          embellishModelsWithUrlPath(
            embellishedModels,
            (error, modelsWithUrlPath) => {
              if (error)
                return serviceLocator.logger.error(
                  error,
                  'Could not embellish models with URL Path'
                )

              modelsWithUrlPath.forEach(model =>
                collection.add(new VehicleModelModel(serviceLocator, model))
              )
              paginationModel.set('totalItems', res.totalItems)
              paginationModel.set('showing', collection.models.length)
            }
          )
        })
      }
    )
  }

  const embellishModelsWithUrlPath = (vehicles, callback) => {
    serviceLocator.modelService.embellishModelsWithUrlPath(
      vehicles,
      (error, embellishedModels) => {
        if (error) return callback(error)
        return callback(null, embellishedModels)
      }
    )
  }

  const embellishModelsWithMake = (vehicles, callback) => {
    serviceLocator.makeService.embellishModelsWithMake(
      vehicles,
      (error, embellishedModels) => {
        if (error) return callback(error)
        return callback(null, embellishedModels)
      }
    )
  }
}

module.exports = createController
