const createList = require('./list-view')
const AvailabilityHistory = require('../views/availability-history')
const PricingHistory = require('../views/pricing-history')
const ReservationHistory = require('../views/reservation-history')
const ScoutHistory = require('../views/scout-history')
const async = require('async')

const baseListController = ({ serviceLocator, plural, path, basePath }) => {
  serviceLocator.router.route(`${path}(/)`, `list${plural}`, function() {
    if (!serviceLocator.allow('usedVehicle', 'discover')) return false

    const renderList = createList(
      serviceLocator,
      { state: { $in: ['Draft', 'Published'] }, saleType: 'used' },
      plural
    )

    renderList((err, list) => {
      if (err) return
      serviceLocator.router.render(list)
    })
  })

  const createHistoryRoute = (historyPath, Page) => {
    serviceLocator.router.route(
      `${basePath}/:id/${historyPath}`,
      `${path}${plural}`,
      id => {
        if (!serviceLocator.allow('usedVehicle', 'read')) return false

        const service = serviceLocator.usedVehicleService
        const history = service.getHistory.bind(service, historyPath, id)

        async.parallel(
          {
            vehicle: service.read.bind(service, id),
            history
          },
          (error, response) => {
            if (error) return
            const history = new Page(
              serviceLocator,
              response.vehicle,
              response.history || []
            ).render()

            serviceLocator.router.render(history)

            history.on('back', function() {
              serviceLocator.router.navigate(path, { trigger: true })
            })
          }
        )
      }
    )
  }

  createHistoryRoute('pricing-history', PricingHistory)
  createHistoryRoute('scout-history', ScoutHistory)
  createHistoryRoute('availability-history', AvailabilityHistory)
  createHistoryRoute('reservation-history', ReservationHistory)
}

module.exports = baseListController
