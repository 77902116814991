const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/case-study')
const baseFormController = require('../base/controllers/form')
const baseListController = require('../base/controllers/list')
const CaseStudyService = require('./services/case-study')

const initAdmin = () => ({ caseStudyAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.caseStudyService = new CaseStudyService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'case study',
    plural: 'case studies',
    path: 'case-studies',
    service: serviceLocator.caseStudyService,
    Model
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
