const createSchema = require('../../../../service/widget/model/base-widget')

const extraProperties = {
  title: {
    type: String
  },
  heading: {
    type: String
  },
  text: {
    type: String
  },
  theme: {
    type: String
  }
}

function create() {
  return createSchema('Complaint Widget', extraProperties)
}

module.exports = create
