"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var expectedInterface = ['makeBlank', 'makeDefault', 'getName', 'validate'];

var isSchemata = function isSchemata(obj) {
  // Schemata instances must be objects
  if (_typeof(obj) !== 'object') return false;
  return expectedInterface.every(function (func) {
    return obj[func] !== undefined;
  });
};

module.exports = isSchemata;