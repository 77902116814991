const createImagePreview = getImageUrl => ({
  initialise360(list, speed, index) {
    if (this.loop) {
      clearInterval(this.loop)
    }

    this.images = list
    this.index = index || 0
    this.paused = true

    this.setImage(0)

    this.loop = setInterval(() => {
      if (!this.paused) this.setImage(1)
    }, speed)

    this.$el.find('.js-remove').show()
  },
  setImage(inc) {
    this.index = this.index + inc
    if (this.index < 0) this.index = this.images.length - 1

    this.$el.find('.js-image').attr('src', this.getImage())
  },
  removeImages() {
    this.model.set('exteriorImages', [])
    this.$el.find('.js-image').attr('src', null)
    this.$el.find('.js-remove').hide()
  },
  getImage() {
    return getImageUrl(this.images[this.index % this.images.length])
  },
  togglePaused() {
    this.paused = !this.paused

    this.$el.find('.js-pause').text(this.paused ? 'Play' : 'Pause')
  }
})

module.exports = createImagePreview
