const FormView = require('./views/form')
const ListView = require('./views/list')
const Model = require('./models/image-set')
const baseFormController = require('../base/controllers/form')
const listController = require('./controllers/list')
const ImageSetService = require('./services/image-set')

function initAdmin() {
  return { imageSetAdmin: ['widget', 'imageSetConfigAdmin', init] }
}

function init(serviceLocator, cb) {
  serviceLocator.imageSetService = new ImageSetService(serviceLocator)

  const controllerOptions = {
    serviceLocator,
    singular: 'Image Set',
    plural: 'Image Sets',
    path: 'image-sets',
    service: serviceLocator.imageSetService,
    Model,
    currentParams: {
      keywords: '',
      filter: {},
      sort: ['createdDate', 'desc']
    }
  }
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  listController(Object.assign({}, controllerOptions, { ListView }))
  cb(null)
}

module.exports = initAdmin
