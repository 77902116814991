module.exports={
  "topLevel": {
    "dependancies": "dependencies"
   ,"dependecies": "dependencies"
   ,"depdenencies": "dependencies"
   ,"devEependencies": "devDependencies"
   ,"depends": "dependencies"
   ,"dev-dependencies": "devDependencies"
   ,"devDependences": "devDependencies"
   ,"devDepenencies": "devDependencies"
   ,"devdependencies": "devDependencies"
   ,"repostitory": "repository"
   ,"repo": "repository"
   ,"prefereGlobal": "preferGlobal"
   ,"hompage": "homepage"
   ,"hampage": "homepage"
   ,"autohr": "author"
   ,"autor": "author"
   ,"contributers": "contributors"
   ,"publicationConfig": "publishConfig"
   ,"script": "scripts"
  },
  "bugs": { "web": "url", "name": "url" },
  "script": { "server": "start", "tests": "test" }
}
