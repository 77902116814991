const schemata = require('schemata')
const required = require('validity-required')
const resolveImages = require('../../../lib/image-resolver')

module.exports = () =>
  schemata({
    name: 'Images',
    properties: {
      imageId: { type: String, validators: [required] },
      name: { type: String, validators: [required] },
      images: {
        type: Object,
        defaultValue: () => [],
        resolve: data => resolveImages(data.images),
        resolveType: Array
      }
    }
  })
