const BaseModel = require('cf-base-model')
const schema = require('../../../../../service/account/news-strip-item-schema')

module.exports = BaseModel.extend({
  schemata: schema,

  defaults() {
    return this.schemata.makeDefault()
  },
  validate(cb) {
    this.schemata.validate(this.attributes, (ignoreErr, errors) => {
      return cb(Object.keys(errors || {}).length > 0 ? errors : undefined)
    })
  }
})
