const compileJade = require('browjadify-compile')
const BaseListView = require('../../base/views/list')
const join = require('path')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (name) {
buf.push("<div class=\"page-content\"><div class=\"centering\"><header class=\"page-header\"><h1>" + (jade.escape(null == (jade_interp = name.plural) ? "" : jade_interp)) + "</h1></header><div class=\"js-upload-area\"></div></div></div>");}.call(this,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
}

const createAuthedHeaders = require('../../../../admin/source/js/lib/authed-headers')

// Import the plugins
const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')

class UploadView extends BaseListView {
  constructor(...args) {
    super(...args)
    this.on('afterAppend', () => this.uploadDialog())
  }

  uploadDialog() {
    const url = '/idealfile-upload/upload-file'

    const uppy = Uppy()
      .use(Dashboard, {
        inline: true,
        target: '.js-upload-area'
      })

      .use(XHRUpload, {
        endpoint: this.serviceLocator.config.apiUrl + url,
        headers: () => {
          const headers = createAuthedHeaders('POST', url, null, {
            headers: {},
            isForm: true
          })
          return headers
        },
        formData: true,
        getResponseError(responseText, xhr) {
          if (xhr.status === 0) {
            return new Error('Failed to upload -- too large for Cloudflare')
          }
          if (responseText === '') {
            return new Error('Failed to upload')
          }
          return new Error(JSON.parse(responseText).error)
        }
      })

    uppy.on('file-added', file => {
      file.xhrUpload = {
        headers: createAuthedHeaders('POST', url, null, {
          headers: {},
          isForm: true
        })
      }
    })

    uppy.on('complete', () =>
      this.serviceLocator.idealfileUploadService.emit('create')
    )

    uppy.getPlugin('Dashboard').openModal()
  }

  get name() {
    return {
      singular: 'Upload',
      plural: 'Uploads',
      resource: 'idealfileUpload'
    }
  }

  render() {
    this.$el.empty().append(template({ name: this.name }))

    return this
  }
}

module.exports = UploadView
