const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/><div class=\"panel panel-styled\"><div class=\"panel-header\"><h2>Image</h2><p>For the best results, use images that are the same width. All images will be resized to be 150 pixels high.</p></div><div class=\"panel-content\"><div id=\"field--images\" class=\"form-row\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"form-row form-row-actions\"><input type=\"button\" value=\"Choose Image\" data-context=\"images\" class=\"btn btn-success js-images-add\"/></div></div></div></div><div id=\"field--altText\" data-field=\"altText\" class=\"form-row\"><label><span class=\"form-label-text\">Alt Text</span><input type=\"text\" name=\"altText\"" + (jade.attr("value", data.altText, true, false)) + " autocomplete=\"off\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div><div id=\"field--link\" data-field=\"link\" class=\"form-row\"><label><span class=\"form-label-text\">URL<abbr title=\"This field is required\">*</abbr></span><input type=\"text\" name=\"link\"" + (jade.attr("value", data.link, true, false)) + " autocomplete=\"off\" class=\"control control--text form-field\"/></label><div class=\"js-error\"></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../../../lib/multi-image-view')

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template

    Object.assign(this, createMultiImageViewFunctions())
    this.$el.on('click', '.js-images-add', e => this.handleAddImages(e))
  }

  render() {
    super.render()
    this.renderImageWidgetArea('images')

    return this
  }
}

module.exports = ItemRepeaterFormView
