const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const extraProperties = {
  make: {
    type: String,
    validators: [required]
  },
  heading: {
    type: String
  },
  description: {
    type: String
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('Range By Body Type', extraProperties),
  type: 'rangeByBodyType',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'rangeByBodyType' })
  },

  validate: validateDelegate
})
