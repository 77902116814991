const ListView = require('./views/list')
const FormView = require('./views/form')
const Model = require('./models/features')
const baseListController = require('../base/controllers/list')
const baseFormController = require('../base/controllers/form')
const FeaturesService = require('./services/features')

const initAdmin = () => ({ featuresAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.featuresService = new FeaturesService(serviceLocator)
  const controllerOptions = {
    serviceLocator,
    singular: 'features',
    plural: 'features',
    path: 'features',
    service: serviceLocator.featuresService,
    Model
  }
  baseListController(Object.assign({}, controllerOptions, { ListView }))
  baseFormController(Object.assign({}, controllerOptions, { FormView }))
  cb(null)
}

module.exports = initAdmin
