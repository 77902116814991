const CrudService = require('../../../../admin/source/js/lib/crud-service')

class PrepFormEntryService extends CrudService {
  get name() {
    return 'PrepFormEntryService'
  }
  get urlRoot() {
    return '/prep-form-entry'
  }
}

module.exports = PrepFormEntryService
