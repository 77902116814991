const Model = require('merstone')
const createSchema = require('../../../service/model/schema')
const ImageAreaModel = require('../../asset/models/image-area')

class ModelModel extends Model {
  constructor(serviceLocator, attributes) {
    super(serviceLocator, attributes)

    this.schemata = createSchema()
    this.attributes = this.schemata.cast(attributes || {})

    this.hook('preSet', (attrs, cb) => {
      cb(null, this.schemata.cast(attrs))
    })
    ;[
      'images',
      'heroImages',
      'thumbnailImages',
      'lifestyleImages',
      'backgroundImages',
      'signpostImages',
      'calloutImages'
    ].forEach(imageType => {
      this[imageType] = new ImageAreaModel(this.get(imageType))

      this[imageType].on('add remove change', () => {
        this.set(imageType, this[imageType].toJSON())
      })
    })
  }

  validate(cb) {
    this.schemata.validate(this.attributes, cb)
  }

  reset(attrs) {
    attrs = this.schemata.cast(attrs || {})
    Model.prototype.reset.call(this, attrs)
  }
  getVisibilityState() {
    switch (this.get('state')) {
      case 'Draft':
        return 'draft'
      case 'Published':
        return 'published'
    }
  }
}

module.exports = ModelModel
