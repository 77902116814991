const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterItemView = require('../../../widget/views/item/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data, id) {
buf.push("<div" + (jade.attr("data-id", id, true, false)) + " class=\"list-item list-item-detail list-item-repeater js-item\"><div class=\"image-wrapper\"><i" + (jade.attr("style", "background-image: url('" + data.previewImageUrl + "');", true, false)) + " class=\"image image-detail\"></i></div><div class=\"list-item-header\"><div class=\"list-item-actions\"><div class=\"btn-group\"><a class=\"pull-right btn js-remove-item\"><i class=\"icon icon--cross--dark\"></i></a></div></div><h2><a class=\"js-edit-item\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</a></h2></div><div class=\"list-item-content\"><dl><dt>&nbsp;</dt></dl></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined));;return buf.join("");
}
const getImageUrl = require('../../../lib/default-image-url-generator')

class ItemRepeaterItemView extends BaseItemRepeaterItemView {
  constructor(serviceLocator, model, ...args) {
    super(serviceLocator, model, ...args)
    this.template = template
  }

  render() {
    const data = this.model.toJSON()
    data.previewImageUrl = getImageUrl({
      model: this.model,
      width: 80,
      height: 80,
      mode: 'pad'
    })
    this.$el.append(
      this.template({ data: data, id: this.model.id })
    )
    return this
  }
}

module.exports = ItemRepeaterItemView
