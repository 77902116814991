const BaseModel = require('cf-base-model')
const createSchema = require('./schema')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()

module.exports = BaseModel.extend({
  schemata: createSchema(),

  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'heading' })
  },

  validate: validateDelegate
})
