const compileJade = require('browjadify-compile')
const join = require('path')
const BaseItemRepeaterFormView = require('../../../../widget/views/form/base-item-repeater')
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
jade_mixins["inputField"] = jade_interp = function(property, label, description, value, required, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"form-row\"><label><span class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)));
if ( required)
{
buf.push("<abbr title=\"This field is required\">*</abbr>");
}
buf.push("</span><input" + (jade.attr("type", type, true, false)) + (jade.attr("name", property, true, false)) + (jade.attr("value", value, true, false)) + " autocomplete=\"off\"" + (jade.cls(['control','control--text','form-field',`js-${property}`], [null,null,null,true])) + "/></label><div class=\"js-error\"></div>");
if ( description)
{
buf.push("<div class=\"form-row-description form-copy\"><p>" + (jade.escape(null == (jade_interp = description) ? "" : jade_interp)) + "</p></div>");
}
buf.push("</div>");
};
buf.push("<form><div class=\"js-errors-summary\"></div><input type=\"submit\" class=\"hidden\"/>");
jade_mixins["inputField"]('altText', 'Alt', 'Should describe the contents of the image', data.altText, true, 'text');
jade_mixins["inputField"]('link', 'Link', 'Where the image will link to when clicked', data.link, false, 'url');
buf.push("<div id=\"field--image\" class=\"form-row\"><div class=\"js-image-area\"><div class=\"widget-beancan-area\"></div></div><div class=\"form-row form-row-actions\"><input type=\"submit\" value=\"Choose Image\" data-context=\"image\" class=\"btn btn-success js-image-add\"/></div></div><div id=\"field--liveDate\" data-field=\"liveDate\" class=\"form-row\"><label><span class=\"form-label-text\">Live Date</span><span class=\"datepicker\"><input type=\"text\" name=\"liveDate\"" + (jade.attr("value", data.liveDate, true, false)) + " readonly=\"readonly\" class=\"control control--text form-field js-date-time-picker\"/></span></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Promo won’t show on the site until this date.</p></div></div><div id=\"field--expiryDate\" data-field=\"expiryDate\" class=\"form-row\"><label><span class=\"form-label-text\">Expiry Date</span><span class=\"datepicker\"><input type=\"text\" name=\"expiryDate\"" + (jade.attr("value", data.expiryDate, true, false)) + " readonly=\"readonly\" class=\"control control--text form-field js-date-time-picker\"/></span></label><div class=\"js-error\"></div><div class=\"form-row-description form-copy\"><p>Promo will hide after this date</p></div></div></form><div class=\"modal-controls\"><button class=\"btn js-cancel-item\">Cancel</button><button class=\"btn btn--action js-save-item\">Save</button></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
const createMultiImageViewFunctions = require('../../../../lib/multi-image-view')
const dateTimePickers = require('../../../../../../admin/source/js/lib/init-date-time-pickers')

class ItemRepeaterFormView extends BaseItemRepeaterFormView {
  constructor(...args) {
    super(...args)
    this.template = template
    Object.assign(this, createMultiImageViewFunctions())
    this.$el.on('click', '.js-image-add', e => this.handleAddImages(e))

    this.initDateTimePickers = dateTimePickers(
      window.config.locale.longDateFormat.LLLL,
      this.model
    )
  }

  render() {
    super.render()
    this.renderImageWidgetArea('image')

    this.dateTimePickers = this.$el
      .find('.js-date-time-picker')
      .toArray()
      .map(this.initDateTimePickers)

    return this
  }
}

module.exports = ItemRepeaterFormView
