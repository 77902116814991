const createController = require('./controllers/controller')
const NewsletterService = require('./services/newsletter')

const initAdmin = () => ({ newsletterAdmin: [init] })

const init = (serviceLocator, cb) => {
  serviceLocator.newsletterService = new NewsletterService(serviceLocator)
  createController(serviceLocator)
  cb(null)
}

module.exports = initAdmin
