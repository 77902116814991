const View = require('ventnor')
const compileJade = require('browjadify-compile')
const join = require('path').join
const container = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (spec, undefined) {




















jade_mixins["textField"] = jade_interp = function(property, label, description, value){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("id", 'field--' + property, true, false)) + (jade.attr("data-field", property, true, false)) + " class=\"text-row\"><label><strong class=\"form-label-text\">" + (jade.escape(null == (jade_interp = label) ? "" : jade_interp)) + "</strong><span" + (jade.cls(['form-field','form-label-field',`js-${property}`], [null,null,true])) + ">" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)) + "</span></label></div>");
};































buf.push("<h3>Specifications</h3><div class=\"form-row\">");
jade_mixins["textField"]('brand', 'Brand', '', spec.brand);
jade_mixins["textField"]('model', 'Model', '', spec.model);
jade_mixins["textField"]('derivative', 'Derivative', '', spec.derivative);
jade_mixins["textField"]('year', 'Year', '', spec.year);
jade_mixins["textField"]('bodyStyle', 'Body Style', '', spec.bodyStyle);
jade_mixins["textField"]('doors', 'Doors', '', spec.doors);
jade_mixins["textField"]('fuel', 'Fuel', '', spec.fuel);
buf.push("</div><h3>Features</h3><table><tr><th>Group</th><th>Description</th><th>Category</th></tr>");
// iterate spec.specOptions
;(function(){
  var $$obj = spec.specOptions;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var row = $$obj[$index];

buf.push("<tr class=\"js-spec-item\"><td style=\"width: 33%\" class=\"valign-middle js-label\">" + (jade.escape(null == (jade_interp = row.label) ? "" : jade_interp)) + "</td><td class=\"valign-middle js-value\">" + (jade.escape(null == (jade_interp = row.description) ? "" : jade_interp)) + "</td><td><select name=\"specItemCategory\" class=\"js-category-select control control--choice form-field\"><option value=\"\"" + (jade.attr("selected", !row.category, true, false)) + " disabled=\"disabled\">--</option><option value=\"safety\"" + (jade.attr("selected", row.category === 'safety', true, false)) + ">Safety</option><option value=\"interior\"" + (jade.attr("selected", row.category === 'interior', true, false)) + ">Interior</option><option value=\"exterior\"" + (jade.attr("selected", row.category === 'exterior', true, false)) + ">Exterior</option><option value=\"technical\"" + (jade.attr("selected", row.category === 'technical', true, false)) + ">Technical</option></select></td></tr>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var row = $$obj[$index];

buf.push("<tr class=\"js-spec-item\"><td style=\"width: 33%\" class=\"valign-middle js-label\">" + (jade.escape(null == (jade_interp = row.label) ? "" : jade_interp)) + "</td><td class=\"valign-middle js-value\">" + (jade.escape(null == (jade_interp = row.description) ? "" : jade_interp)) + "</td><td><select name=\"specItemCategory\" class=\"js-category-select control control--choice form-field\"><option value=\"\"" + (jade.attr("selected", !row.category, true, false)) + " disabled=\"disabled\">--</option><option value=\"safety\"" + (jade.attr("selected", row.category === 'safety', true, false)) + ">Safety</option><option value=\"interior\"" + (jade.attr("selected", row.category === 'interior', true, false)) + ">Interior</option><option value=\"exterior\"" + (jade.attr("selected", row.category === 'exterior', true, false)) + ">Exterior</option><option value=\"technical\"" + (jade.attr("selected", row.category === 'technical', true, false)) + ">Technical</option></select></td></tr>");
    }

  }
}).call(this);

buf.push("</table><div class=\"js-error\"></div><div class=\"form-row\"><a class=\"btn btn-confirm js-confirm\">Confirm Specifications</a></div>");}.call(this,"spec" in locals_for_with?locals_for_with.spec:typeof spec!=="undefined"?spec:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}
const equal = require('deep-equal')

class ChooseSpecOption extends View {
  constructor(serviceLocator, parentModel) {
    super(serviceLocator)
    this.vehicle = parentModel

    this.$el.on('click', '.js-confirm', this.applyToModel.bind(this))
    this.$el.on('change', '.js-category-select', this.greyOutText.bind(this))
  }

  greyOutText() {
    this.$el.find('.js-spec-item').each((index, el) => {
      const hiddenCategories = ['exterior', 'interior', 'safety']
      const category = $(el)
        .find('.js-category-select')
        .val()
      if (!category) {
        return
      }
      const labelElement = $(el).find('.js-label')

      if (hiddenCategories.includes(category)) {
        labelElement.toggleClass('grey-out-label', true)
      } else {
        labelElement.toggleClass('grey-out-label', false)
      }
    })
  }

  applyToModel() {
    this.$el.find('.js-spec-item').each((index, el) => {
      const category = $(el)
        .find('.js-category-select')
        .val()

      if (!category) {
        return
      }

      const features = this.vehicle.get(category)
      const label = $(el)
        .find('.js-label')
        .text()
      const value = $(el)
        .find('.js-value')
        .text()

      if (!features.find(item => equal(item, { label, value }))) {
        features.push({ label, value })
      }

      this.vehicle.set(category, features)
    })

    this.vehicle.set('specOptions', [])
    this.emit('chooseSpec')
  }

  render() {
    this.$el.append(
      container({
        spec: this.vehicle.toJSON()
      })
    )

    this.greyOutText()

    return this
  }
}

module.exports = ChooseSpecOption
