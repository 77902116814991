const BaseModel = require('cf-base-model')
const createSchema = require('../../../../service/widget/model/base-widget')
const validateDelegate = require('../../../../../admin/source/js/lib/validate-delegate')()
const required = require('validity-required')

const extraProperties = {
  title: {
    type: String,
    validators: [required]
  },
  description: {
    type: String,
    validators: [required]
  },
  sortType: {
    type: String,
    validators: [required]
  },
  ctaText: {
    type: String,
    validators: [required]
  },
  ctaHref: {
    type: String,
    validators: [required]
  },
  reviewEntities: {
    type: Array
  }
}

module.exports = BaseModel.extend({
  schemata: createSchema('All Reviews', extraProperties),
  type: 'reviews',
  defaults() {
    return this.schemata.makeDefault({ type: this.type || 'reviews' })
  },

  validate: validateDelegate
})
