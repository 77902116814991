const BaseFormView = require('../../../base/views/form')
const formErrorsDelegate = require('../../../../../admin/source/js/lib/form-errors-delegate')()

class BaseItemRepeaterFormView extends BaseFormView {
  constructor(...args) {
    super(...args)
    this.initialData = this.model.toJSON()
    this.$el.on('click', '.js-cancel-item', this.handleCancel.bind(this))
    this.$el.on('click', '.js-save-item', this.handleSave.bind(this))

    this.showErrors = formErrorsDelegate.showErrors
    this.clearErrors = formErrorsDelegate.clearErrors

    this.template = () => {}
  }

  setupToolbar() {}

  handleSave() {
    this.clearErrors()
    this.model.validate(errors => {
      if (errors && Object.keys(errors).length) {
        this.showErrors(errors)
        return
      }
      this.emit('save')
    })
  }

  handleCancel() {
    this.model.set(this.initialData)
    this.emit('cancel')
  }

  render() {
    this.$el.append(this.template({ data: this.model.toJSON() }))
    return this
  }
}

module.exports = BaseItemRepeaterFormView
