const compileJade = require('browjadify-compile')
const Factory = require('../../widget/lib/base-factory')
const join = require('path').join

module.exports = () => {
  const factory = new Factory()
  factory.register('image', {
    editView: require('../views/widget-views/image-form'),
    cropView: require('../views/widget-views/image-crop'),
    model: require('../models/image'),
    name: 'Image',
    description: 'An image asset',
    itemView: require('../views/widget-views/image-item'),
    itemTemplate: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"list-item list-item-thumbnail list-item-thumbnail-short\"><div class=\"image-wrapper\"><i" + (jade.attr("style", 'background-image: url("' + data.imageUrl + '");', true, false)) + " class=\"image image-thumbnail\"></i></div><div class=\"list-item-actions\"><label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label><span data-toggle=\"tooltip\"" + (jade.attr("title", '' + (data.size) + '<br>' + (data.width) + ' x ' + (data.height) + '', true, false)) + " class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--info\"></i></span><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\">");
if ( allowed('asset', 'update'))
{
buf.push("<li><a class=\"js-edit\">Edit</a></li>");
}
buf.push("<li><a" + (jade.attr("href", data.original, true, false)) + " target=\"_blank\" class=\"js-download\">View Original</a></li>");
if ( allowed('asset', 'delete'))
{
buf.push("<li class=\"divider\"></li><li><a class=\"js-delete-item\">Delete</a></li>");
}
buf.push("</ul></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
},
    editTemplate: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<div class=\"form js-asset-form-view\"><div class=\"grid\"><div class=\"grid__item\"><div class=\"asset-preview-large\"><img" + (jade.attr("src", (data.previewDataUri) ? data.previewDataUri : data.imageUrl, true, false)) + "/></div></div><div class=\"asset-info\"><div class=\"grid__item one-third\"><dl><dt>Filename</dt><dd><span class=\"label label--notice label--wrap\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span></dd></dl></div><div class=\"grid__item one-third\"><dl><dt>Size</dt><dd><span class=\"label label--notice\">" + (jade.escape(null == (jade_interp = data.size) ? "" : jade_interp)) + "</span></dd></dl></div><div class=\"grid__item one-third\"><dl><dt>Dimensions</dt><dd><span class=\"label label--notice\">" + (jade.escape((jade_interp = data.width) == null ? '' : jade_interp)) + " x " + (jade.escape((jade_interp = data.height) == null ? '' : jade_interp)) + "</span></dd></dl></div></div></div></div>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
  })

  // This is only here in case a binary asset sneaks in. If binary assets are supported,
  // some binary views/templates must actually be brought it. For now it's just fine and
  // doesn't error using exactly the same views/models/templates.
  factory.register('binary', {
    editView: require('../views/widget-views/image-form'),
    model: require('../models/binary'),
    name: 'Binary',
    description: 'A binary asset',
    itemView: require('../views/widget-views/binary-item'),
    itemTemplate: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (allowed, data) {
buf.push("<div class=\"list-item list-item-thumbnail list-item-thumbnail-short\"><a" + (jade.attr("href", data.original, true, false)) + " class=\"button-file-type\">");
var fileType = 'txt'
if ( data.name)
{
var fileParts = data.name.split('.')
fileType = fileParts[fileParts.length - 1]
}
buf.push("<i" + (jade.cls(['file-icon','file-icon--' + fileType], [null,true])) + ">" + (jade.escape(null == (jade_interp = fileType) ? "" : jade_interp)) + "</i><span>" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span></a><div class=\"list-item-actions\"><label class=\"list-item-select\"><input type=\"checkbox\" class=\"js-select\"/></label><span data-toggle=\"tooltip\"" + (jade.attr("title", data.size, true, false)) + " class=\"label label--large js-tooltip-trigger\"><i class=\"icon icon--info\"></i></span><div class=\"control-group\"><div class=\"btn-group\"><a data-toggle=\"dropdown\" class=\"btn btn--small dropdown-toggle\"><span class=\"caret\"></span></a><ul class=\"dropdown-menu pull-right\">");
if ( allowed('asset', 'update'))
{
buf.push("<li><a class=\"js-edit\">Edit</a></li>");
}
buf.push("<li><a" + (jade.attr("href", data.original, true, false)) + " target=\"_blank\" class=\"js-download\">View Original</a></li>");
if ( allowed('asset', 'delete'))
{
buf.push("<li class=\"divider\"></li><li><a class=\"js-delete-item\">Delete</a></li>");
}
buf.push("</ul></div></div></div></div>");}.call(this,"allowed" in locals_for_with?locals_for_with.allowed:typeof allowed!=="undefined"?allowed:undefined,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
},
    editTemplate: function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (data) {
buf.push("<form class=\"js-asset-form-view\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Caption</span><input type=\"text\" name=\"caption\"" + (jade.attr("value", data.caption, true, false)) + " class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Credits</span><input type=\"text\" name=\"credits\"" + (jade.attr("value", data.credits, true, false)) + " class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Link</span><input type=\"text\" name=\"originalUrl\"" + (jade.attr("value", data.originalUrl, true, false)) + " class=\"control control--text form-field\"/></label></div><div class=\"asset-info\"><div class=\"grid\"><div class=\"grid__item one-half\"><dl><dt>Size</dt><dd><span class=\"label label--notice\">" + (jade.escape(null == (jade_interp = data.size) ? "" : jade_interp)) + "</span></dd></dl></div><div class=\"grid__item one-half\"><dl><dt>Filename</dt><dd><span class=\"label label--notice\">" + (jade.escape(null == (jade_interp = data.name) ? "" : jade_interp)) + "</span></dd></dl></div></div></div></form>");}.call(this,"data" in locals_for_with?locals_for_with.data:typeof data!=="undefined"?data:undefined));;return buf.join("");
}
  })

  factory.type = 'image'

  return factory
}
