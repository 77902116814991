const BaseListView = require('../../base/views/list')
const ListItemView = require('./list-item')
const FilterView = require('./list-filter')

class ListView extends BaseListView {
  get FilterView() {
    return FilterView
  }

  get ListItemView() {
    return ListItemView
  }

  get name() {
    return { singular: 'FAQ', plural: 'FAQs', resource: 'faq' }
  }

  addListItem(model) {
    const listItem = new this.ListItemView(
      this.serviceLocator,
      model,
      this.accounts
    )
    this.listenTo(listItem, 'edit', this.emit.bind(this, 'edit', model.id))
    this.listenTo(listItem, 'delete', this.emit.bind(this, 'delete', model.id))
    this.attachView(listItem)
    this.$el.find('.js-items').append(listItem.render().$el)
  }

  render() {
    BaseListView.prototype.render.call(this)
    return this
  }
}

module.exports = ListView
