const createSchema = require('../../../../service/widget/model/base-widget')
const required = require('validity-required')

const extraProperties = {
  items: {
    type: Array,
    defaultValue: () => []
  },
  vehicleType: {
    type: String,
    validators: [required],
    defaultValue: 'cars'
  }
}

module.exports = () => createSchema('Search Hero', extraProperties)
