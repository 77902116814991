import BaseModel from 'cf-base-model'
import createSchema from './schema'
import validateDelegate from '../../../../../admin/source/js/lib/validate-delegate'

module.exports = BaseModel.extend({
  schemata: createSchema(),
  type: 'buttonGroup',
  defaults() {
    return this.schemata.makeDefault({ type: 'buttonGroup' })
  },
  validate: validateDelegate()
})
