const visibility = require('regg')()

visibility

  .register('draft', () => {
    return {
      labelClass: 'label-status',
      iconClass: 'icon--draft',
      title: 'Draft',
      description:
        'This vehicle is not published.<br>It is not visible on the site'
    }
  })

  .register('published', () => {
    return {
      labelClass: 'label-status label--standard',
      iconClass: 'icon--published',
      title: 'Published',
      description: 'This vehicle is visible on the site.'
    }
  })

  .register('sold', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--published',
      title: 'Sold',
      description: `This vehicle will no longer appear on the site unless directly linked.<br>
        It will also no longer be sent to 3rd parties e.g. AutoTrader<br>
        After 30 days, it will be automatically archived.`
    }
  })

  .register('soldArchived', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Sold and Archived',
      description: `This vehicle will no longer appear on the site.<br>
        It will also no longer be sent to 3rd parties e.g. AutoTrader`
    }
  })

  .register('archived', () => {
    return {
      labelClass: 'label-status label--inverse',
      iconClass: 'icon--archived',
      title: 'Archived',
      description: `This vehicle will no longer appear on the site.<br>
        It will also no longer be sent to 3rd parties e.g. AutoTrader`
    }
  })

module.exports = { visibility }
