const compileJade = require('browjadify-compile')
const View = require('ventnor')
const join = require('path').join
const template = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel panel-styled panel-fixed\"><div class=\"panel-header\"><h2>Filter</h2></div><div class=\"panel-content\"><form action=\"#\" method=\"get\"><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Search <div class=\"form-label-text__right\"><span data-toggle=\"tooltip\" title=\"Use double quotes to search for a phrase and minus to negate a word, e.g.&lt;br /&gt;[ &quot;Fashion Week&quot; ] or [ Fashion -Week ]\" class=\"js-tooltip-trigger label\"><i class=\"icon icon--question\">Help</i></span></div></span><input type=\"text\" name=\"keywords\" placeholder=\"Keywords\" class=\"control control--text form-field\"/></label></div><div class=\"form-row form-row-full-width\"><label><span class=\"form-label-text\">Order</span><select name=\"sort\" class=\"control control--choice form-field\"><option value=\"-dateCreated\">Newest First</option><option value=\"dateCreated\">Oldest First</option><option value=\"-score\">Relevance</option></select></label></div><div class=\"form-row form-row-full-width js-dealership-select\"><label><span class=\"form-label-text\">Dealership</span><div class=\"js-dealership\"></div></label></div><div class=\"form-row form-row-actions\"><input type=\"reset\" value=\"Clear\" class=\"btn\"/><input type=\"submit\" value=\"Filter\" class=\"btn btn--action\"/></div></form></div></div>");;return buf.join("");
}
const BaseSelect = require('../../base/views/select')

class ListFilterView extends View {
  constructor(...args) {
    super(...args)
    this.$el.addClass('list-filters')
    this.$el.on('submit', 'form', this.handleSubmit.bind(this))
    this.$el.on('click', 'input[type=reset]', this.handleClear.bind(this))
  }

  trigger(...args) {
    return this.emit(...args)
  }

  updateDisplay(params) {
    this.$el.find('form [name=keywords]').val(params.keywords)

    if (params.filter.dealership) {
      this.renderDealershipSelect(params.filter.dealership)
    }

    const backMap = {
      'createdDate,asc': 'dateCreated',
      'createdDate,desc': '-dateCreated',
      'score,desc': '-score'
    }

    if (Array.isArray(params.sort)) {
      this.$el
        .find(
          '[name=sort] option[value=' + backMap[params.sort.join(',')] + ']'
        )
        .attr('selected', true)
    }
  }

  handleSubmit(e) {
    if (e) e.preventDefault()

    const params = { filter: { successful: false }, sort: [] }
    const map = {
      dateCreated: ['createdDate', 'asc'],
      '-dateCreated': ['createdDate', 'desc'],
      '-score': ['score', 'desc']
    }
    const keywords = this.$el.find('form [name=keywords]').val()

    params.sort = map[this.$el.find('form [name=sort]').val()]

    if (this.currentDealership && this.currentDealership !== 'all')
      params.filter.dealership = this.currentDealership

    if (keywords.length) params.keywords = keywords

    this.emit('filter', params)
  }

  handleClear(e) {
    e.preventDefault()
    this.$el.find('form [name=keywords]').val('')
    this.$el.find('form [name=sort]').val('-dateCreated')
    this.renderDealershipSelect()

    this.handleSubmit()
  }

  render() {
    this.$el.empty().append(template({}))
    this.$el.find('.js-tooltip-trigger').tooltip({ html: true })
    this.renderDealershipSelect()

    return this
  }

  renderDealershipSelect(selected) {
    const options = {
      service: 'dealershipService',
      limitToOneValue: true,
      displayAllOption: true,
      displayNoneOption: true,
      singular: 'dealership',
      plural: 'dealerships',
      textProperty: 'shortName',
      sortProperty: 'shortName'
    }
    if (selected && selected.$in) {
      options.selected = 'all'
    } else if (selected) {
      options.selected = selected
    } else {
      options.selected = 'all'
    }

    this.currentDealership = options.selected
    const dealershipSelect = new BaseSelect(this.serviceLocator, options)

    this.$el
      .find('.js-dealership')
      .empty()
      .append(dealershipSelect.render().$el)

    dealershipSelect.on(
      'change',
      dealership => (this.currentDealership = dealership)
    )
    this.attachView(dealershipSelect)
  }
}

module.exports = ListFilterView
