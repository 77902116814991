import View from 'ventnor'

const options = [
  { value: 'latest', label: 'Latest' },
  { value: 'top', label: 'Most Rated' }
]

class EventSelect extends View {
  constructor(selected) {
    super()
    this.$el = $('<select id="filter" />')
    this.$el.addClass('control control--choice control--multiline')
    this.el = this.$el[0]
    this.$el.attr('placeholder', 'Choose default filter')
    this.selected = selected
  }

  initializeSelectize() {
    options.forEach(option => {
      this.el.selectize.addOption({
        value: option.value,
        text: option.label
      })
      this.el.selectize.addItem(this.selected)
    })
    this.el.selectize.on('change', this.updateSelection.bind(this))
  }

  updateSelection() {
    this.selected = this.el.selectize.getValue()
    this.emit('change', this.selected)
  }

  render() {
    setTimeout(() => {
      this.$el.selectize({
        create: false,
        onInitialize: this.initializeSelectize.bind(this),
        sortField: [{ field: 'name', direction: 'desc' }],
        preload: true,
        selectOnTab: true
      })
    }, 0)
    return this
  }
}

module.exports = EventSelect
