const schemata = require('schemata')
const required = require('validity-required')
const regexMatch = require('validity-regex-match')
const createUniqueValidator = require('validity-unique-property')
const validateIfSet = require('validity-validate-if-set')
const newsStripItemSchema = require('./news-strip-item-schema')
const resolveImages = require('../../../lib/image-resolver')

module.exports = findOne =>
  schemata({
    name: 'Account',
    properties: {
      _id: {
        type: String
      },
      name: {
        type: String,
        validators: [required]
      },
      logo: {
        type: String
      },
      domain: {
        type: String,
        validators: [
          required,
          validateIfSet(
            regexMatch(
              new RegExp(
                '^((?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                  '[0-9a-z][0-9a-z-]*)(\\.(?=[^.]{1,63}(?:\\.|$))(?![^.]*-(?:\\.|$))' +
                  '[0-9a-z][0-9a-z-]*)+$'
              ),
              'Must be a valid domain name'
            )
          ),
          createUniqueValidator(findOne)
        ]
      },
      supportEmailAddress: {
        type: String,
        validators: [required]
      },
      addThisId: {
        type: String
      },
      facebookId: {
        type: String
      },
      twitterId: {
        type: String
      },
      googlePlusId: {
        type: String
      },
      pinterestId: {
        type: String
      },
      instagramId: {
        type: String
      },
      googleAnalyticsPropertyId: {
        type: String
      },
      footerHtml: {
        type: String
      },
      eventId: {
        type: String
      },
      dateCreated: {
        type: Date,
        defaultValue: () => new Date()
      },
      brandPromiseImages: {
        type: Object,
        defaultValue: () => [],
        resolve: account => resolveImages(account.brandPromiseImages),
        resolveType: Array
      },
      autoTraderImages: {
        type: Object,
        defaultValue: () => [],
        resolve: account => resolveImages(account.autoTraderImages),
        resolveType: Array
      },
      autoTraderPlaceholderImages: {
        type: Object,
        defaultValue: () => [],
        resolve: account => resolveImages(account.autoTraderPlaceholderImages),
        resolveType: Array
      },
      newsStripItems: {
        type: schemata.Array(newsStripItemSchema),
        defaultValue: () => []
      },
      replaceNewStock: {
        type: Boolean,
        defaultValue: false
      },
      replaceUsedStock: {
        type: Boolean,
        defaultValue: false
      }
    }
  })
